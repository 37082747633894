import { usePaginateCompaniesQuery } from 'api/admin/companies';
import { useBackfillUserCompaniesMutation, usePaginateUsersQuery } from 'api/admin/users';
import { BanCircleIcon, Button, Pagination, Table, TickCircleIcon } from 'evergreen-ui';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ICompany } from 'types/company';
import { IUser } from 'types/user';
import { dateStrToLocaleStr } from 'utils/strings';

const limit = 10;

const CompanyRow = ({company}: {company: ICompany}) => {
  const nav = useNavigate();

  const goToCompany = () => {
    nav(`/admin/companies/${company.id}`);
  }

  return <Table.Row
    onClick={goToCompany}
    isSelectable
  >
    <Table.TextCell>{company.name || <em>unnamed company</em>}</Table.TextCell>
    <Table.TextCell>{company.status}</Table.TextCell>
    <Table.TextCell>{dateStrToLocaleStr(company.created_at)}</Table.TextCell>
  </Table.Row>
}

const CompaniesTable = () => {
  const [params, setSearchParams] = useSearchParams();

  // TODO: make this logic a util function or hook
  const offset = isNaN(parseInt(params.get('o') || '0')) ? 0 : parseInt(params.get('o') || '0');
  const {
    data,
  } = usePaginateCompaniesQuery({limit: 10, offset});

  const onParamChange = (paramName: string, paramValue: string | null) => {
    setSearchParams(newParams => {
      // reset the page when search params change
      if (paramName !== 'o') {
        newParams.delete('o');
      }

      if (paramValue === null) {
        newParams.delete(paramName);
      } else {
        newParams.set(paramName, paramValue);
      }
      return newParams;
    });
  };

  const onPrevPageClick = () => {
    if (!data) return;

    const currentOffset = (data.pagination.this - 1) * limit;
    if (currentOffset > 0) {
      onParamChange('o', (currentOffset - limit).toString());
    }
  };

  const onNextPage = () => {
    if (!data) return;

    const pagination = data.pagination;
    const currentOffset = (pagination.this - 1) * limit;
    if (pagination.this < pagination.last) {
      onParamChange('o', (currentOffset + limit).toString());
    }
  };

  const onPageChange = (page: number) => {
    onParamChange('o', ((page - 1) * limit).toString());
  };


  return <div>
    <Table>
      <Table.Head>
        <Table.TextHeaderCell>Name</Table.TextHeaderCell>
        <Table.TextHeaderCell>Status</Table.TextHeaderCell>
        <Table.TextHeaderCell>Created at</Table.TextHeaderCell>
      </Table.Head>
      <Table.Body>
        {data?.items.map((comp) => <CompanyRow company={comp} key={comp.id} />)}
      </Table.Body>
    </Table>
    {data && (
      <Pagination
        totalPages={data.pagination.last}
        page={data.pagination.this}
        onNextPage={onNextPage}
        onPreviousPage={onPrevPageClick}
        onPageChange={onPageChange}
      />
    )}
  </div>
}


const AdminCompaniesPage = () => {
  const [backfill] = useBackfillUserCompaniesMutation();

  return <div className="admin-page-content--container">
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
      <h2>All companies</h2>
      <Button onClick={() => backfill()}>Backfill companies</Button>
    </div>
    <div>
      <CompaniesTable />
    </div>
  </div>
}


export default AdminCompaniesPage;
