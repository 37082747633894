import { defaultTheme, mergeTheme } from 'evergreen-ui';

const tinyTheme = mergeTheme(defaultTheme, {
  colors: {
    'blue900': '#011828', 
    'blue800': '#022F50', 
    'blue700': '#034777', 
    'blue600': '#045F9F', 
    'blue500': '#0576C7', 
    'blue400': '#068AE8', 
    'blue300': '#5CBBFF', 
    'blue200': '#ADDDFF', 
    'blue100': '#D6EDFF', 
    'blue50': '#EBF6FF', 
    'blue25': '#EFF7FD'
  }
});

export default tinyTheme;