import AdminCadencesTable from 'components/admin/CadencesTable';


const AdminCadencesPage = () => {

  return <div className="admin-page-content--container">
    <h2>All cadences</h2>
    <div>
      <AdminCadencesTable />
    </div>
  </div>
}


export default AdminCadencesPage;
