/**
 * Take the difference between the dates and divide by milliseconds per day.
 * Round to nearest whole number to deal with DST.
 */
export function datediff(first: Date, second: Date) {
  return Math.round((second.valueOf() - first.valueOf()) / (1000 * 60 * 60 * 24));
}

const units: {unit: Intl.RelativeTimeFormatUnit; ms: number}[] = [
  {unit: 'year', ms: 31536000000},
  {unit: 'month', ms: 2628000000},
  {unit: 'day', ms: 86400000},
  {unit: 'hour', ms: 3600000},
  {unit: 'minute', ms: 60000},
  {unit: 'second', ms: 1000},
];
const rtf = new Intl.RelativeTimeFormat('default', {numeric: 'auto'});

/**
* Get language-sensitive relative time message from Dates.
* @param relative  - the relative dateTime, generally is in the past or future
* @param pivot     - the dateTime of reference, generally is the current time
*/
export function relativeTimeFromDates(relative: Date | null, pivot: Date = new Date()): string {
  if (!relative) return '';
  const elapsed = relative.getTime() - pivot.getTime();
  return relativeTimeFromElapsed(elapsed);
}

/**
 * Get language-sensitive relative time message from elapsed time.
 * @param elapsed   - the elapsed time in milliseconds
 */
function relativeTimeFromElapsed(elapsed: number): string {
  for (const {unit, ms} of units) {
    if (Math.abs(elapsed) >= ms || unit === 'second') {
      return rtf.format(Math.round(elapsed / ms), unit);
    }
  }
  return '';
}

export const strToTodayTimeOrShortDate = (dateStr: string) => {
  const date = new Date(dateStr);
  const isInvalid = isNaN(date.getTime());
  if (isInvalid) {
    return '';
  }
  const now = new Date();
  const isSameDay = date.getDate() === now.getDate() && date.getMonth() === now.getMonth() && date.getFullYear() === now.getFullYear();

  if (isSameDay) {
    return date.toLocaleTimeString('default', {hour: '2-digit', minute: '2-digit'});
  } else {
    return date.toLocaleDateString('default', {month: 'short', day: 'numeric'});
  }
}