import _, {M} from 'constants/i18n';


const NotificationsSettings = () => {
  return (
    <div className="settings-content--container">
      <h3 className="settings-content--header">{_(M.NAV_NOTIF_SETTINGS)}</h3>
      <p>coming soon!</p>
    </div>
  )
}

export default NotificationsSettings;