import { Button, DrawerRightIcon, IconButton, Position, Spinner, Tooltip, toaster } from 'evergreen-ui';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import EmailEditor from 'components/shared/EmailEditor';
import _, { M } from 'constants/i18n';
import { dateStrToLocaleStr, getContactName } from 'utils/strings';
import { ICadence, ICadenceEnrollment } from 'types/cadence';
import { ISendEmailNodeParams } from 'types/cadence';
import { useCancelCadenceContactMutation, useFetchCadenceEnrollmentQuery, usePostResumeEnrollmentMutation } from 'api/cadence';
import { TrackEventNames, tracker } from 'utils/tracking';

import './style.css';
import ContactDrawer from 'components/crm/ContactDrawer';


const getPausedNode = (enrollment: ICadenceEnrollment, cadence: ICadence) => {
  const currentNodeId = enrollment.current_node_id;

  return cadence.data.nodes.find((node) => node.id === currentNodeId);
};


const pausedOnEmailSend = (enrollment: ICadenceEnrollment, cadence: ICadence): boolean => {
  if (enrollment.status !== 'paused') return false;

  const pausedNode = getPausedNode(enrollment, cadence);

  if (!pausedNode) return false;

  return pausedNode.action.type === 'SEND_EMAIL';
};

const EditEmailSend = ({ emailNodeParams, enrollment, cadence }: { emailNodeParams: ISendEmailNodeParams, enrollment: ICadenceEnrollment, cadence: ICadence }) => {
  const [subject, setSubject] = useState(emailNodeParams.subject);
  const [bodyHtml, setBodyHTML] = useState(emailNodeParams.body.html);
  const [bodyText, setBodyText] = useState(emailNodeParams.body.text);
  const [resume, resumeResult] = usePostResumeEnrollmentMutation()

  const submit = async () => {
    try {
      // TODO: track heap
      await resume({id: enrollment.id, params: {
        ...emailNodeParams,
        subject,
        body: {
          text: bodyText,
          html: bodyHtml,
        },
      }});
      toaster.success(_(M.SUCCESS));
      setTimeout(() => window.location.reload(), 1000);
    } catch (err) {
      // TODO: alert user to error
    }
  };

  const onUpdateBody = (val: {html: string, plainText: string}) => {
    setBodyHTML(val.html);
    setBodyText(val.plainText);
  }

  return <div className="edit-enrollment-email--container">
    <p>{_(M.SEND_EMAIL_TO_RESUME_CADENCE)}</p>
    <EmailEditor
      subject={subject}
      subjectDisabled={cadence.data.options?.send_emails_as_replies}
      subjectHint={cadence.data.options?.send_emails_as_replies ? _(M.EMAIL_REPLY_STAYS_SAME) : undefined}
      onSubjectChange={setSubject}
      onBodyChange={onUpdateBody}
      bodyHtml={bodyHtml}
    />
    <Button alignSelf="flex-end" marginTop={24} onClick={submit} isLoading={resumeResult.isLoading} appearance='primary' >Send</Button>
  </div>
};


const EditEnrollment = () => {
  const { enrollmentId: enrollmentIdStr='' } = useParams<{enrollmentId: string, cadenceId: string}>();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const enrollmentId = parseInt(enrollmentIdStr || '0');

  const {
    isLoading,
    isFetching,
    isError,
    data,
  } = useFetchCadenceEnrollmentQuery(enrollmentId);

  const [cancel, cancelRes] = useCancelCadenceContactMutation();

  useEffect(() => {
    if (cancelRes.isError) {
      toaster.danger(_(M.ERROR));
    } else if (cancelRes.isSuccess) {
      tracker.track(TrackEventNames.CE, {enrollmentId})
      toaster.success(_(M.SUCCESS));
    }
  }, [cancelRes.isError, cancelRes.isSuccess, enrollmentId]);

  const loading = isLoading || isFetching;
  const error = isError || !data;
  const canCancel = ['paused', 'in_progress'].includes(data?.cadence_contact.status || '');

  if (loading) {
    return <Spinner delay={100}>{_(M.LOADING)}</Spinner>
  }

  if (error) {
    return <div>{_(M.ERROR)}</div>
  }

  return <div className="edit-enrollment--container">
    <ContactDrawer isShown={drawerOpen} contactId={data.contact.id} onClose={() => setDrawerOpen(false)}/>
    <div className="edit-enrollment-info--container">
      <div className="edit-enrollment-data--container">
        <div className="edit-enrollment-data--label">{_(M.CONTACT)}</div>
        <div onClick={() => setDrawerOpen(true)} className="edit-enrollment-contact--name">
          {getContactName(data.contact)}
          <Tooltip content={_(M.PREVIEW)} position={Position.TOP}>
            <IconButton
              appearance='minimal'
              marginLeft={8}
              icon={DrawerRightIcon}
            />
          </Tooltip>
        </div>
      </div>
      <div className="edit-enrollment-data--container">
        <div className="edit-enrollment-data--label">{_(M.LAST_ENROLLMENT_STEP)}</div>
        <div>{dateStrToLocaleStr(data.cadence_contact.last_step_at || data.cadence_contact.started_at)}</div>
      </div>
      {canCancel && <Button onClick={() => cancel({id: enrollmentId})} isLoading={cancelRes.isLoading} intent="danger">{_(M.UNENROLL)}</Button>}
    </div>
    {pausedOnEmailSend(data.cadence_contact, data.cadence) && <EditEmailSend cadence={data.cadence} enrollment={data.cadence_contact} emailNodeParams={getPausedNode(data.cadence_contact, data.cadence)?.action.params as ISendEmailNodeParams}/>}
  </div>
}

export default EditEnrollment;