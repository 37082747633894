import { useBackfillSetActiveUsersInCRMMutation, usePaginateUsersQuery } from 'api/admin/users';
import { BanCircleIcon, Button, Pagination, Table, TickCircleIcon } from 'evergreen-ui';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IUser } from 'types/user';
import { dateStrToLocaleStr } from 'utils/strings';

const limit = 10;

const UserRow = ({user}: {user: IUser}) => {
  const nav = useNavigate();

  const goToUser = () => {
    nav(`/admin/users/${user.id}`);
  }

  return <Table.Row
    onClick={goToUser}
    isSelectable
  >
    <Table.TextCell>{user.full_name}</Table.TextCell>
    <Table.TextCell>{user.email}</Table.TextCell>
    <Table.TextCell>{user.is_superuser ? <TickCircleIcon color="success"/> : <BanCircleIcon color="danger" />}</Table.TextCell>
    <Table.TextCell>{user.status}</Table.TextCell>
    <Table.TextCell>{dateStrToLocaleStr(user.created_at)}</Table.TextCell>
  </Table.Row>
}

const UsersTable = () => {
  const [offset, setOffset] = useState(0);
  const {
    data,
  } = usePaginateUsersQuery({limit: 10, offset});

  const onPrevPageClick = () => {
    if (!data) return;

    const currentOffset = (data.pagination.this - 1) * limit;
    if (currentOffset > 0) {
      setOffset(currentOffset - limit);
    }
  };

  const onNextPage = () => {
    if (!data) return;

    const pagination = data.pagination;
    const currentOffset = (pagination.this - 1) * limit;
    if (pagination.this < pagination.last) {
      setOffset(currentOffset + limit);
    }
  };

  const onPageChange = (page: number) => {
    setOffset((page - 1) * limit);
  };


  return <div>
    <Table>
      <Table.Head>
        <Table.TextHeaderCell>Name</Table.TextHeaderCell>
        <Table.TextHeaderCell>Email</Table.TextHeaderCell>
        <Table.TextHeaderCell>Is admin</Table.TextHeaderCell>
        <Table.TextHeaderCell>Status</Table.TextHeaderCell>
        <Table.TextHeaderCell>Signed up at</Table.TextHeaderCell>
      </Table.Head>
      <Table.Body>
        {data?.items.map((user) => <UserRow user={user} key={user.id} />)}
      </Table.Body>
    </Table>
    {data && (
      <Pagination
        totalPages={data.pagination.last}
        page={data.pagination.this}
        onNextPage={onNextPage}
        onPreviousPage={onPrevPageClick}
        onPageChange={onPageChange}
      />
    )}
  </div>
}


const AdminUsersPage = () => {
  const [backfill] = useBackfillSetActiveUsersInCRMMutation();

  return <div className="admin-page-content--container">
    <h2>All users</h2>
    <div>
      <Button onClick={() => backfill()}>Backfill active users in CRM</Button>
      <UsersTable />
    </div>
  </div>
}


export default AdminUsersPage;
