import { BlockedPersonIcon } from 'evergreen-ui';

import { ICadenceEvent } from 'types/cadence';
import _, {M} from 'constants/i18n';


const AutomatedUnenrollEvent = ({event}: {event: ICadenceEvent}) => {
  const unenrollReason = event.event_data.due_to === 'email_reply' ? M.CADENCE_EVENT_AUTOMATED_UNENROLL_BC_EMAIL : M.CADENCE_EVENT_AUTOMATED_UNENROLL_BC_MTG
  return <div>
    <div className="enrolled-event-title--container">
      <BlockedPersonIcon className="enrolled-event-title--icon" color="var(--color-blue-2-4)"/>
      <div className="enrolled-event-title--text">{_(M.CADENCE_EVENT_AUTOMATED_UNENROLL_TITLE)}</div>
    </div>
    <p className="enrolled-event--description">{_(M.CADENCE_EVENT_AUTOMATED_UNENROLL_DESC)} {_(unenrollReason)}</p>
  </div>
};

export default AutomatedUnenrollEvent;