import { MouseEvent } from 'react';
import { Button } from 'evergreen-ui';
import cx from 'classnames';

import HubspotIcon from 'components/icons/hubspot';
import _, { M } from 'constants/i18n';

import './style.css';


export interface IHubspotButtonProps {
  label?: string;
  disabled?: boolean;
  onClick?: (e: MouseEvent) => void;
  className?: string;
}

const HubspotButton = ({
  label,
  onClick,
  disabled,
  className,
}: IHubspotButtonProps) => {
  return <Button size='large' className={cx(className, 'hubspot-connect--btn')} iconBefore={() => <HubspotIcon fill="white"/>} onClick={onClick} disabled={disabled}>{label || _(M.CONNECT_HUBSPOT)}</Button>
};


export default HubspotButton;