import { Outlet } from 'react-router-dom';
import cx from 'classnames';

import { useAppDispatch, useAppSelector } from 'hooks';
import { getNewEnrollmentMethod, getNewEnrollmentStep } from 'store/cadence/selector';
import { cancelCSVMapping, resetNewEnrollment, stepBackFromReview } from 'store/cadence/slice';
import './style.css';
import { useEffect } from 'react';

const CadenceEnrollOutlet = () => {

  const step = useAppSelector(getNewEnrollmentStep);
  const method = useAppSelector(getNewEnrollmentMethod);
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetNewEnrollment());
    };
  }, [dispatch]);

  // TODO: i18n
  return (
    <div className="cadence-enroll--outlet">
      <div className="cadence-enroll-outlet--steps">
        <div className={cx('cadence-enroll-outlet--step', {active: step === 'choose_method', done: step !== 'choose_method'})} onClick={() => dispatch(resetNewEnrollment())}>
          <div className={cx('cadence-enroll-outlet--step-bubble', {active: step === 'choose_method', done: step !== 'choose_method'})} />
          Method
        </div>
        <div className={cx('cadence-enroll-outlet--step', {active: step === 'configure_method', done: ['csv_mapping', 'review'].includes(step)})} onClick={() => dispatch(cancelCSVMapping())}>
          <div className={cx('cadence-enroll-outlet--step-bubble', {active: step === 'configure_method', done: ['csv_mapping', 'review'].includes(step)})} />
          Contacts
        </div>
        <div className={cx('cadence-enroll-outlet--step', {active: step === 'csv_mapping', done: step === 'review', cancel: step === 'review' && method !== 'bulk_csv'})} onClick={() => step === 'review' && dispatch(stepBackFromReview())}>
          <div className={cx('cadence-enroll-outlet--step-bubble', {active: step === 'csv_mapping', done: step === 'review', cancel: step === 'review' && method !== 'bulk_csv'})} />
          Mapping
        </div>
        <div className={cx('cadence-enroll-outlet--step', {active: step === 'review'})}>
          <div className={cx('cadence-enroll-outlet--step-bubble', {active: step === 'review'})} />
          Review
        </div>
      </div>
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default CadenceEnrollOutlet;