import { getGoogleOAuthURI } from 'api/auth';
import GoogleButton from 'components/shared/GoogleButton';
import _, { M } from 'constants/i18n';
import { useAppSelector } from 'hooks';
import { getConnectorsArray } from 'store/user/selector';
import Connector from 'components/settings/Connector';
import HubspotButton from 'components/shared/HubspotButton';
import { getHubSpotOauthUrl } from 'constants/app';

import './style.css';


const openGoogle = async () => {
  try {
    const {data} = await getGoogleOAuthURI();
    window.location.href = data.authorization_url;
  } catch (err) {
    console.warn('Could not get google auth URI', err);
  }
};


const AccountsSettings = () => {

  const connectors = useAppSelector(getConnectorsArray);

  return (
    <div className="settings-content--container">
      <h3 className="settings-content--header">{_(M.NAV_ACCOUNTS)}</h3>
      <div className="settings-connect-account--container">
        <p>{_(M.CONNECT_ACCOUNT)}</p>
        <GoogleButton onClick={openGoogle} />
        <HubspotButton onClick={() => window.location.href = getHubSpotOauthUrl()} />
      </div>
      <div>
        <p>{_(M.YOUR_ACCOUNTS)}</p>
        {connectors.map(c => <Connector connector={c} key={c.id}/>)}
      </div>
    </div>
  )
}

export default AccountsSettings;