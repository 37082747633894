import { NavLink, Outlet, useParams } from 'react-router-dom';

import { useFetchCompanyQuery } from 'api/admin/companies';
import './style.css';

const AdminCompanyOutlet = () => {
  const {companyId = ''} = useParams<{companyId: string}>()

  const {
    data,
    isLoading,
    isFetching,
    isError,
  } = useFetchCompanyQuery(companyId);

  if (!data || isLoading || isFetching) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error</div>
  }

  const company = data.company;

  return <div className="admin-page-content--container">
    <h2>{company.name || <em>unnamed company</em>} (id: {company.id})</h2>
    <div className="admin-page-content-sub-nav--container">
      <NavLink className="admin-page-content-sub-nav--link" to={`/admin/companies/${companyId}`} end>Users</NavLink>
      <NavLink className="admin-page-content-sub-nav--link" to={`/admin/companies/${companyId}/cadences`}>Cadences</NavLink>
      <NavLink className="admin-page-content-sub-nav--link" to={`/admin/companies/${companyId}/enrollments`}>Enrollments</NavLink>
      <NavLink className="admin-page-content-sub-nav--link" to={`/admin/companies/${companyId}/connectors`}>Connectors</NavLink>
      <NavLink className="admin-page-content-sub-nav--link" to={`/admin/companies/${companyId}/contacts`}>Contacts</NavLink>
      <NavLink className="admin-page-content-sub-nav--link" to={`/admin/companies/${companyId}/contact-lists`}>Contact Lists</NavLink>
    </div>
    <Outlet />
  </div>
};

export default AdminCompanyOutlet;