import { NavLink, Outlet, useParams } from 'react-router-dom';

import { useFetchContactListQuery } from 'api/admin/contact_list';
import './style.css';

const AdminContactListOutlet = () => {
  const {contactListId = ''} = useParams<{contactListId: string}>()

  const {
    data,
    isLoading,
    isFetching,
    isError,
  } = useFetchContactListQuery(contactListId);

  if (!data || isLoading || isFetching) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error</div>
  }

  const list = data.data;

  return <div className="admin-page-content--container">
    <h2>{list.name}</h2>
    <div className="admin-page-content-sub-nav--container">
      <NavLink className="admin-page-content-sub-nav--link" to={`/admin/contact-lists/${contactListId}`} end>Summary</NavLink>
      <NavLink className="admin-page-content-sub-nav--link" to={`/admin/contact-lists/${contactListId}/contacts`}>Contacts</NavLink>
    </div>
    <Outlet />
  </div>
};

export default AdminContactListOutlet;