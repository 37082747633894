import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EnvelopeIcon, IconButton, Select, SettingsIcon, } from 'evergreen-ui';
import cx from 'classnames';

import { AuthorType, ConversationStatus, IConversation } from 'types/conversation';
import { useFetchContactQuery } from 'api/contact';
import { getAddrAndNameFromEmail, getContactName } from 'utils/strings';
import { useFetchUserQuery } from 'api/user';
import { useInboxConversations } from 'store/inbox/hooks';
import { useCreateNewConversationMutation } from 'api/v2/inboxes';
import { useVisibility } from 'hooks';
import './style.css';
import { strToTodayTimeOrShortDate } from 'utils/date';

interface IAuthorProps {
  authorId: number
  rawFrom?: string
}

const ContactAuthor = ({authorId, rawFrom}: IAuthorProps) => {
  const {email, name} = getAddrAndNameFromEmail(rawFrom || '');
  const unknownRawFrom = email === 'unknown' && name === 'unknown';
  const contactRes = useFetchContactQuery(authorId, {skip: !unknownRawFrom});
  const nameOrEmail = name === 'unknown' ? email : name

  return <div className="conversation-list-conversation--contact">{!unknownRawFrom ? nameOrEmail : getContactName(contactRes.data?.contact)}</div>;
};

const UserAuthor = ({authorId}: IAuthorProps) => {
  const userRes = useFetchUserQuery({userId: authorId});

  const user = userRes.data?.user;

  return <div className="conversation-list-conversation--contact">{user?.full_name || user?.email || <em>Teammate</em>}</div>;
};

const authorTypeToTitle: Record<AuthorType, FunctionComponent<IAuthorProps>> = {
  [AuthorType.USER]: UserAuthor,
  [AuthorType.ADMIN]: () => <div>admin author</div>,
  [AuthorType.SYSTEM]: () => <div>system author</div>,
  [AuthorType.CONTACT]: ContactAuthor,
}


const ConversationInSidebar = ({conversation, onClick, isSelected}: {conversation: IConversation, onClick: (cid: number) => void, isSelected: boolean}) => {
  const AuthorComponent = conversation.latest_author ? authorTypeToTitle[conversation.latest_author?.type] : () => <div />;
  const isUnread = !!conversation.last_message_at && (!conversation.last_read || new Date(conversation.last_message_at) > new Date(conversation.last_read));

  return (
    <div onClick={() => onClick(conversation.id)} className={cx('conversations-list-conversation--container', { selected: isSelected, unread: isUnread })}>
      <div className="conversation-list-conv--header">
        <AuthorComponent authorId={conversation.latest_author?.id as number} rawFrom={conversation.latest_author?.raw_from || undefined} />
        <div className="conversation-list-conv--date">{strToTodayTimeOrShortDate(conversation.last_message_at || '')}</div>
      </div>
      <div className="conversation-list-conv-subject--container">
        <div className="conversation-list-conversation--subject">{conversation.subject || <em>no subject</em>}</div>
        {isUnread && <div className="conversation-list-conv-unread--marker"/>}
      </div>
      <div className="conversation-list-conversation--preview">{conversation.preview || <em>no preview available</em>}</div>
    </div>
  )
};

const StatusOptions = [
  {label: 'Inbox', value: ConversationStatus.ACTIVE},
  {label: 'Snoozed', value: ConversationStatus.SNOOZED},
  {label: 'Archive', value: ConversationStatus.ARCHIVED},
  {label: 'Deleted', value: ConversationStatus.DELETED},
  {label: 'All', value: undefined},
]

const InboxConversationSidebar = () => {
  const {inboxId, conversationId} = useParams();
  const [isBottomVisible, _checkInView, bottomOfSidebarRef] = useVisibility<HTMLDivElement>(100, 250);
  const [before, setBefore] = useState<undefined | string>(undefined);
  const [status, setStatus] = useState<ConversationStatus | undefined>(StatusOptions[0].value);
  const [create] = useCreateNewConversationMutation();
  const nav = useNavigate();
  const {
    data,
    isFetching,
    isLoading,
  } = useInboxConversations([{inboxId: inboxId as string, before, status}, {skip: !inboxId}]);

  useEffect(() => {
    const earliestConv = data?.at(-1);
    if (isBottomVisible && !(isFetching || isLoading) && earliestConv) {
      setBefore(earliestConv.last_message_at || earliestConv.created_at);
    }
  }, [isBottomVisible, isFetching, isLoading, data]);

  const [selectedConversation, setSelectedConversation] = useState<null | number | string>(conversationId ? parseInt(conversationId) : null);

  const onChangeStaus = (e: ChangeEvent<HTMLSelectElement>) => {
    const val = e.target.value;

    if (Object.values<string>(ConversationStatus).includes(val)) {
      setBefore(undefined);
      setStatus(val as ConversationStatus);
    } else {
      setBefore(undefined);
      setStatus(undefined);
    }
  }

  const onClickNewConversation = () => {
    const res = create({
      inboxId: inboxId as string,
      status: ConversationStatus.ACTIVE,
      subject: 'New Conversation',
    });
  };

  const onSelectConversation = (conversationId: number) => {
    setSelectedConversation(conversationId);
    nav(`/inboxes/${inboxId}/conversations/${conversationId}`);
  };

  return <div className="conversations-list--container">
    <div className="conversations-list-filter--container">
      <Select
        value={status}
        onChange={onChangeStaus}
        width={200}
      >
        {StatusOptions.map(({label, value}) => <option key={value} value={value} selected={status === value}>{label}</option>)}
      </Select>
      <IconButton icon={SettingsIcon} appearance='minimal'/>
      <IconButton icon={EnvelopeIcon} appearance='minimal' />
    </div>
    {data?.map(conv => <ConversationInSidebar key={conv.id} conversation={conv} onClick={onSelectConversation} isSelected={conv.id === selectedConversation}/>)}
    <div ref={bottomOfSidebarRef} />
  </div>
};

export default InboxConversationSidebar;