import { useParams } from 'react-router-dom';
import { Button } from 'evergreen-ui';

import { useDeleteScheduledTaskMutation, useFetchTaskQuery, useResetScheduledTaskMutation } from 'api/admin/tasks';
import './style.css';



const AdminTaskPage = () => {
  const {taskId = ''} = useParams<{taskId: string}>()
  const [reset, resetRes] = useResetScheduledTaskMutation();
  const [deleteTask, delRes] = useDeleteScheduledTaskMutation();

  const {
    data,
    isLoading,
    isFetching,
    isError,
  } = useFetchTaskQuery(taskId);

  if (!data || isLoading || isFetching) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error</div>
  }

  const task = data.task;

  return <div className="admin-page-content--container">
    <h2>{task.name}</h2>
    <div className="admin-page-actions--container">
      {task.status === 'error' && <Button intent='success' isLoading={resetRes.isLoading} onClick={() => reset(taskId)}>Re-run task</Button>}
      {<Button intent='danger' isLoading={delRes.isLoading} onClick={() => deleteTask(taskId)}>Delete task</Button>}
    </div>
    <div>
      <h3>Task data</h3>
      <pre>{JSON.stringify(task.data, undefined, 2)}</pre>
      <h3>Task meta</h3>
      <pre>{JSON.stringify(task.meta, undefined, 2)}</pre>
    </div>
  </div>
}

export default AdminTaskPage;