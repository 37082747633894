import { FunctionComponent } from 'react';
import { AnyMessage, MessageTypes } from 'types/conversation';


import EmailMessage from './Email';
import PrivateCommentMessage from './Comment';
import DraftInConversation from './Draft';
import './style.css';
import DeletedMessage from './deleted';


const NoOpMessage = (props: {message: AnyMessage}) => {
  return null;
};

const messageTypeToComponent: Record<MessageTypes, FunctionComponent<{message: AnyMessage}>> = {
  [MessageTypes.EMAIL]: EmailMessage,
  [MessageTypes.IM]: NoOpMessage,

  [MessageTypes.COMMENT]: PrivateCommentMessage,
  [MessageTypes.NOTE]: NoOpMessage,
  [MessageTypes.DRAFT]: DraftInConversation,

  [MessageTypes.ARCHIVE]: NoOpMessage,
  [MessageTypes.DELETE]: NoOpMessage,
  [MessageTypes.LINK]: NoOpMessage,
  [MessageTypes.SNOOZE]: NoOpMessage,
  [MessageTypes.UNARCHIVE]: NoOpMessage,
  [MessageTypes.UNDELETE]: NoOpMessage,
  [MessageTypes.UNSNOOZE]: NoOpMessage,
  [MessageTypes.DRAFT_SEND]: NoOpMessage,
}


const Message = ({message}: {message: AnyMessage}) => {
  const Component = messageTypeToComponent[message.message_type];

  if (message.deleted_at) {
    return <DeletedMessage message={message} />
  }

  return <Component message={message} />
};

export default Message;