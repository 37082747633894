import { useFetchConversationMessagesQuery, useFetchInboxConversationsQuery } from 'api/v2/inboxes';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect } from 'react';
import { receiveConversations, receiveMessages } from './slice';
import { getConversation, sortedConversationsForInbox, sortedMessagesForConversation } from './selectors';
import { useFetchConversationQuery } from 'api/v2/conversations';
import { IConversation } from 'types/conversation';

export const useConversationMessages = ({conversationId, inboxId, before}: {conversationId: number, inboxId: string | number, before?: string}) => {
  const res = useFetchConversationMessagesQuery({
    inboxId,
    conversationId,
    before,
  }, {skip: !conversationId || !inboxId});
  const dispatch = useAppDispatch();
  const msgs = useAppSelector(s => sortedMessagesForConversation(s, conversationId));

  useEffect(() => {
    if (res.data) {
      dispatch(receiveMessages({msgs: res.data.items}));
    }
  }, [res.data, dispatch]);

  return {
    ...res,
    data: res.isSuccess ? msgs : undefined,
    hasMore:  (res.data?.items.length || 0) > 0,
  };
};

export const useInboxConversations = ([params, _options]: Parameters<typeof useFetchInboxConversationsQuery>) => {
  const inboxId = typeof params === 'object' ? params.inboxId : 0;
  const before = typeof params === 'object' ? params.before : undefined;
  const status = typeof params === 'object' ? params.status : undefined;
  const limit = typeof params === 'object' ? params.limit : undefined;
  const res = useFetchInboxConversationsQuery({inboxId, before, status, limit}, {skip: !inboxId});

  const dispatch = useAppDispatch();
  const convs = useAppSelector(s => sortedConversationsForInbox(s, inboxId as number, status));

  useEffect(() => {
    if (res.data) {
      dispatch(receiveConversations({convs: res.data.items}));
    }
  }, [res.data, dispatch]);

  return {
    ...res,
    data: res.isSuccess ? convs : undefined,
  };
}

export const useInboxConversation = (conversationId: number) => {
  const conv: IConversation | undefined = useAppSelector(s => getConversation(s, conversationId));

  const res = useFetchConversationQuery(conversationId, {skip: !!conv});

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (res.data) {
      dispatch(receiveConversations({convs: [res.data.conversation]}));
    }
  }, [res.data, dispatch]);

  return {
    ...res,
    data: conv,
  };
};