import { Outlet, useParams } from 'react-router-dom';

import { useFetchConnectorQuery } from 'api/admin/connector';
import './style.css';

const AdminConnectorOutlet = () => {
  const {connectorId = ''} = useParams<{connectorId: string}>()

  const {
    data,
    isLoading,
    isFetching,
    isError,
  } = useFetchConnectorQuery(connectorId);

  if (!data || isLoading || isFetching) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error</div>
  }

  return <div className="admin-page-content--container">
    <Outlet />
  </div>
};

export default AdminConnectorOutlet;