import { Button, Checkbox, FileCard, FileRejection, FileUploader, MimeType, TextInputField } from 'evergreen-ui';
import { ChangeEvent, useCallback, useState } from 'react';

import _, { M } from 'constants/i18n';
import { Messages } from 'constants/i18n/locales/base';
import { useAppDispatch } from 'hooks';
import { resetNewEnrollment, setCsvColumnsForMapping, setEnrollConfiguration } from 'store/cadence/slice';
import { useParams } from 'react-router-dom';


const errorReasoToi18n: Record<string, Messages> = {
  no_content: M.CSV_UPLOAD_INVALID_NO_CONTENT,
  email_column_required: M.CSV_UPLOAD_INVALID_NO_EMAIL_COL,
  invalid_rows: M.CSV_UPLOAD_INVALID_BAD_ROWS,
}

type CsvValidationResult = {valid: boolean, reason?: string, invalidRowNumbers?: number[], numRows?: number, columns: string[]};


const validateCSVFileContent = (fileContent: string): CsvValidationResult => {
  const carrotReturnSplitLines = fileContent.split('\r\n');
  const lines = carrotReturnSplitLines.length === 1 ?
    fileContent.split('\n')
    :
    carrotReturnSplitLines;

  if (lines.length < 2) {
    return {valid: false, reason: 'no_content', numRows: lines.filter(l => !!l).length - 1, columns: []};
  }

  const columns = lines[0].split(',');
  // TODO: just validate on the server side, the user will do a column mapping in the app

  // const columns = lines[0].split(',').map(str => str.toLowerCase().replaceAll(' ', '').trim());

  // if (!columns.includes('email')) {
  //   return {valid: false, reason: 'email_column_required', numRows: lines.length - 1};
  // }

  // const rows: Record<string, string>[] = [];

  // lines.slice(1).forEach(line => {
  //   if (!line) {
  //     return;
  //   }
  //   const row: Record<string, string> = {};
  //   line.split(',').forEach((value, idx) => {
  //     const column = columns[idx]
  //     row[column] = value;
  //     if (column === 'email' && !validateEmail(value)) {
  //       row._error = 'invalid_email';
  //     }
  //   });

  //   rows.push(row);
  // });

  // const invalidRowNumbers: number[] = rows.reduce((soFar, nextUp, idx) => {
  //   if (nextUp.hasOwnProperty('_error')) {
  //     soFar.push(idx + 2); // +1 to account for the header row and +1 to account for 1-index count for spreadsheets
  //   }
  //   return soFar;
  // }, [] as number[]);

  // if (invalidRowNumbers.length !== 0) {
  //   return {valid: false, reason: 'invalid_rows', invalidRowNumbers, numRows: lines.length - 1};
  // }

  return {valid: true, numRows: lines.filter(l => !!l).length - 1, columns};
}


const EnrollByCSV = () => {
  const [csvListName, setCsvListName] = useState('');
  const [createListFromCsv, setCreateListFromCsv] = useState(true);
  const [files, setFiles] = useState<File[]>([])
  const [csvValidationResult, setCSVValidationResult] = useState<CsvValidationResult | null>(null);
  const [validatingCsv, setValidatingCsv] = useState(false);
  const [fileRejections, setFileRejections] = useState<FileRejection[]>([])
  const {cadenceId: cadenceIdStr='0'} = useParams<{cadenceId: string}>();
  const cadenceId = parseInt(cadenceIdStr || '0');
  const dispatch = useAppDispatch();

  const handleFileChange = useCallback((files: File[]) => {
    const file = files[0];
    setFiles([file]);
    setValidatingCsv(true);
    file.text()
      .then(validateCSVFileContent)
      .then((result) => {
        setCSVValidationResult(result);
        setValidatingCsv(false);
      })
      .catch((e) => {
        console.warn(`[TC.app] failed to validate CSV: ${e}`);
        setValidatingCsv(false);
      })
  }, []);
  const handleRejected = useCallback((fileRejections: FileRejection[]) => setFileRejections([fileRejections[0]]), [])
  const handleRemove = useCallback(() => {
    setFiles([]);
    setFileRejections([]);
    setCSVValidationResult(null);
  }, [])


  const onSubmit = async () => {
    dispatch(setCsvColumnsForMapping(csvValidationResult?.columns || []));
    dispatch(setEnrollConfiguration({args: {cadenceId, file: files[0], createList: createListFromCsv, listName: csvListName}, estSize: csvValidationResult?.numRows}))
  };

  const confirmDisabled = (
    !csvValidationResult ||
    !csvValidationResult.valid
  );


  return (
    <>
      <FileUploader
        label={_(M.CSV_UPLOAD_HEADER)}
        description={_(M.CSV_UPLOAD_DESCRIPTION)}
        maxSizeInBytes={10 * 1024 ** 2}
        maxFiles={1}
        onChange={handleFileChange}
        onRejected={handleRejected}
        acceptedMimeTypes={[MimeType.csv]}
        renderFile={(file) => {
          const { name, size, type } = file
          const fileRejection = fileRejections.find((fileRejection) => fileRejection.file === file)
          const { message } = fileRejection || {}
          return (
            <FileCard
              key={name}
              isInvalid={fileRejection != null}
              name={name}
              onRemove={handleRemove}
              sizeInBytes={size}
              type={type}
              validationMessage={message}
            />
          )
        }}
        values={files}
      />
      <Checkbox checked={createListFromCsv} onChange={() => setCreateListFromCsv(!createListFromCsv)} label={_(M.CREATE_CONTACT_LIST_FROM_CSV)}/>
      {createListFromCsv && <TextInputField label={_(M.CONTACT_LIST_NAME)} value={csvListName} onChange={(e: ChangeEvent<HTMLInputElement>) => setCsvListName(e.target.value)}/>}
      {csvValidationResult && !csvValidationResult.valid && <div className="enroll-contact-csv--error">{_(errorReasoToi18n[csvValidationResult.reason || ''] || M.CSV_UPLOAD_INVALID)} {csvValidationResult.invalidRowNumbers?.join(', ')}</div>}
      <div className="enroll-dialog--footer">
        <Button onClick={() => dispatch(resetNewEnrollment())}>{_(M.BACK)}</Button>
        <Button intent="success" appearance="primary" marginLeft={16} disabled={confirmDisabled} isLoading={validatingCsv} onClick={onSubmit}>{_(M.NEXT)}</Button>
      </div>
    </>
  )

};

export default EnrollByCSV;