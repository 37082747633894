import { setupListeners } from '@reduxjs/toolkit/query';
import { configureStore } from '@reduxjs/toolkit';

import { API } from 'api';
import { adminQueries, queries, v2queries } from 'api/queries';
import { DEBUG } from 'constants/resources';
import userReducer from 'store/user/slice';
import cadenceReducer from 'store/cadence/slice';
import inboxReducer from 'store/inbox/slice';

const loggingMiddleware = () => (next: (action: unknown) => unknown) => (action: unknown) => {
  // TODO: remove "&& false" to log actions
  const shouldLog = DEBUG;
  if (shouldLog) {
    console.info('[TC.app] applying action', action);
  }
  return next(action);
};

export const store = configureStore({
  reducer: {
    user: userReducer,
    cadence: cadenceReducer,
    inbox: inboxReducer,
    [queries.reducerPath]: queries.reducer,
    [adminQueries.reducerPath]: adminQueries.reducer,
    [v2queries.reducerPath]: v2queries.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(queries.middleware, adminQueries.middleware, v2queries.middleware, loggingMiddleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

declare global {
  interface Window {
    store: typeof store;
    API: typeof API;
  }
}

if (DEBUG) {
  window.store = store;
  window.API = API;
}