import { CSSProperties, MouseEvent, PureComponent } from 'react';

import { GoogleIcon } from './icons';
import { darkStyle, disabledStyle, hoverStyle, lightStyle } from './styles';


export interface IGoogleButtonProps {
  label?: string;
  disabled?: boolean;
  tabIndex?: number;
  onClick?: (e: MouseEvent) => void;
  type?: 'light' | 'dark';
  style?: CSSProperties;
  className?: string;
}

export default class GoogleButton extends PureComponent<IGoogleButtonProps, {hovered: boolean}> {
  public static defaultProps = {
    label: 'Sign in with Google',
    disabled: false,
    type: 'dark',
    tabIndex: 0,
    onClick: () => {},
    className: '',
  };

  state = {
    hovered: false
  };

  getStyle = (propStyles: CSSProperties | undefined) => {
    const baseStyle = this.props.type === 'dark' ? darkStyle : lightStyle;
    if (this.state.hovered) {
      return { ...baseStyle, ...hoverStyle, ...propStyles };
    }
    if (this.props.disabled) {
      return { ...baseStyle, ...disabledStyle, ...propStyles };
    }
    return { ...baseStyle, ...propStyles };
  };

  mouseOver = () => {
    if (!this.props.disabled) {
      this.setState({ hovered: true });
    }
  };

  mouseOut = () => {
    if (!this.props.disabled) {
      this.setState({ hovered: false });
    }
  };

  click = (e: MouseEvent<HTMLDivElement>) => {
    if (!this.props.disabled && this.props.onClick) {
      this.props.onClick(e);
    }
  };

  render() {
    const { label, style, ...otherProps } = this.props;

    return (
      <div
        {...otherProps}
        role="button"
        onClick={this.click}
        style={this.getStyle(style)}
        onMouseOver={this.mouseOver}
        onMouseOut={this.mouseOut}
      >
        <GoogleIcon {...this.props} />
        <span>{label}</span>
      </div>
    );
  }
}