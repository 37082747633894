import { usePaginateJobsQuery } from 'api/admin/worker_jobs';
import { Pagination, Table } from 'evergreen-ui';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IWorkerJob } from 'types/worker_job';
import { dateStrToLocaleStr } from 'utils/strings';

const limit = 10;

const WorkerJobRow = ({job}: {job: IWorkerJob}) => {
  const nav = useNavigate();

  const goToJob = () => {
    nav(`/admin/jobs/${job.id}`);
  }

  return <Table.Row
    onClick={goToJob}
    isSelectable
  >
    <Table.TextCell>{job.name}</Table.TextCell>
    <Table.TextCell>{job.status}</Table.TextCell>
    <Table.TextCell>{Math.round((job.progress || 0) * 100)}%</Table.TextCell>
    <Table.TextCell>{dateStrToLocaleStr(job.created_at)}</Table.TextCell>
    <Table.TextCell>{dateStrToLocaleStr(job.updated_at)}</Table.TextCell>
    <Table.TextCell>{job.meta.task_invocations}</Table.TextCell>
  </Table.Row>
}


const JobsTable = () => {
  const [offset, setOffset] = useState(0);
  const {
    data,
  } = usePaginateJobsQuery({limit: 10, offset});

  const onPrevPageClick = () => {
    if (!data) return;

    const currentOffset = (data.pagination.this - 1) * limit;
    if (currentOffset > 0) {
      setOffset(currentOffset - limit);
    }
  };

  const onNextPage = () => {
    if (!data) return;

    const pagination = data.pagination;
    const currentOffset = (pagination.this - 1) * limit;
    if (pagination.this < pagination.last) {
      setOffset(currentOffset + limit);
    }
  };

  const onPageChange = (page: number) => {
    setOffset((page - 1) * limit);
  };


  return <div>
    <Table>
      <Table.Head>
        <Table.TextHeaderCell>Job</Table.TextHeaderCell>
        <Table.TextHeaderCell>Status</Table.TextHeaderCell>
        <Table.TextHeaderCell>Progress</Table.TextHeaderCell>
        <Table.TextHeaderCell>Started at</Table.TextHeaderCell>
        <Table.TextHeaderCell>Updated at</Table.TextHeaderCell>
        <Table.TextHeaderCell>Num Batches Ran</Table.TextHeaderCell>
      </Table.Head>
      <Table.Body>
        {data?.items.map((job) => <WorkerJobRow job={job} key={job.id} />)}
      </Table.Body>
    </Table>
    {data && (
      <Pagination
        totalPages={data.pagination.last}
        page={data.pagination.this}
        onNextPage={onNextPage}
        onPreviousPage={onPrevPageClick}
        onPageChange={onPageChange}
      />
    )}
  </div>
}


const AdminWorkerJobsPage= () => {

  return <div className="admin-page-content--container">
    <h2>All jobs</h2>
    <div>
      <JobsTable />
    </div>
  </div>
}


export default AdminWorkerJobsPage;
