import { API } from 'api';
import { IConnector, IHubspotConnectorSettings } from 'types/user';

import { queries } from './queries';

interface IConnectParams {
  code: string | null;
}

interface IPatchSettingsParams {
  connectorId: number;
  body: IHubspotConnectorSettings
}


export const fetchGmailConnectorLabels = () => API.get<{labels: {id: string, name: string}[], failed_connectors: number[]}>('/api/v1/connectors/gmail/labels');

const cadencesQueries = queries.injectEndpoints({
  endpoints: (build) => ({

    connectGoogle: build.mutation<IConnector[], IConnectParams>({
      query: () => ({
        url: '/connectors/integrate/google',
        method: 'POST',
        body: {integration_response: window.location.href.replace('http:', 'https:')},
      }),

      invalidatesTags: ['Connector'],
    }),

    connectHubspot: build.mutation<IConnector[], IConnectParams>({
      query: ({code}) => {
        return {
          url: '/connectors/integrate/hubspot',
          method: 'POST',
          body: {code: code || ''},
        }
      },

      invalidatesTags: ['Connector'],
    }),

    patchConnectorSettings: build.mutation<IConnector, IPatchSettingsParams>({
      query: ({connectorId, body}) => {
        return {
          url: `/connectors/${connectorId}/settings`,
          method: 'PATCH',
          body,
        }
      },

      invalidatesTags: ['Connector'],
    }),

    manuallyConnectGitHub: build.mutation<IConnector, {api_key: string}>({
      query: (body) => {
        return {
          url: '/connectors/integrate/manual-github',
          method: 'POST',
          body,
        };
      },

      invalidatesTags: ['Connector'],
    }),

    sendTestEmail: build.mutation<{status: string, sent_to: string}, {subject: string, body: {html: string, text: string}}>({
      query: (body) => {
        return {
          url: '/connectors/email/send-test',
          method: 'POST',
          body,
        }
      }
    }),
  }),

  overrideExisting: false,
});

export const disableConnector = (connectorId: number) => API.post(`/api/v1/connectors/${connectorId}/disable`)

export const enableConnector = (connectorId: number) => API.post(`/api/v1/connectors/${connectorId}/enable`)

export const { useConnectGoogleMutation, useConnectHubspotMutation, usePatchConnectorSettingsMutation, useManuallyConnectGitHubMutation, useSendTestEmailMutation } = cadencesQueries;