import { Button, EmptyState, ErrorIcon } from 'evergreen-ui';
import { useNavigate } from 'react-router-dom';

import _, {M} from 'constants/i18n';

const SuspendedAccountAlert = () => {
  const navigate = useNavigate();

  return (
    <EmptyState
      title={_(M.FREE_TRIAL_EXPIRED_TITLE)}
      description={_(M.FREE_TRIAL_EXPIRED_DESC)}
      icon={<ErrorIcon color='var(--color-red-5)' />}
      iconBgColor='var(--color-red-2)'
      primaryCta={
        <Button
          onClick={() => navigate('/settings/billing')}
        >
          {_(M.FREE_TRIAL_EXPIRED_BTN)}
        </Button>
      }
    />
  );
}

export default SuspendedAccountAlert;