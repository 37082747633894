import cx from 'classnames';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Button, CrossIcon, IconButton, TickIcon, TrashIcon } from 'evergreen-ui';

import { useDeleteMessageMutation, usePatchMessageMutation, useSendConversationDraftMutation } from 'api/v2/conversations';
import Composer from 'components/shared/Composer';
import { useAppDispatch, useAppSelector } from 'hooks';
import { receiveMessages } from 'store/inbox/slice';
import { AnyMessage, DraftMessage } from 'types/conversation';
import _, {M} from 'constants/i18n';
import LoadingDots from 'components/shared/LoadingDots';
import MultiEmailInput from 'components/inbox/MultiEmailInput';
import { getNamespaceForMessage } from 'store/inbox/selectors';
import debounce from 'lodash.debounce';


const DraftInConversation = ({message}: {message: AnyMessage}) => {
  const draft = message as DraftMessage;
  const isSent = draft.meta.is_sent;
  const namespace = useAppSelector(s => getNamespaceForMessage(s, message.id));
  const [deleteMessage] = useDeleteMessageMutation();
  const [patch, patchRes] = usePatchMessageMutation();
  const [saveStatus, setSaveStatus] = useState<'saving' | 'failed' | 'success' | null>(null);
  const dispatch = useAppDispatch();
  const [lastSavedHtml, setLastSavedHtml] = useState(draft.body_html || '');
  const [send] = useSendConversationDraftMutation();
  const parsedCc: string[] = useMemo(() => draft.email_addrs?.cc.map(({email}) => email) || [], [draft]);
  const parsedTo: string[] = useMemo(() => draft.email_addrs?.to.map(({email}) => email) || [], [draft]);

  const save = async (newDraft: Partial<DraftMessage>) => {
    try {
      setSaveStatus('saving');
      const html = newDraft.body_html || draft.body_html || '';
      const subject = newDraft.context?.subject || draft.context.subject;
      const res = await patch({
        body_html: html,
        body_text: newDraft.body_text || draft.body_text || '',
        messageId: message.id,
        conversationId: message.conversation_id,
        context: {
          to: newDraft.context?.to === undefined ? draft.context.to : newDraft.context?.to,
          from_: newDraft.context?.from_ === undefined ? draft.context.from_ : newDraft.context?.from_,
          cc: newDraft.context?.cc === undefined ? draft.context.cc : newDraft.context?.cc,
          subject,
          references: draft.context.references,
        }
      }).unwrap();
      setLastSavedHtml(html);
      dispatch(receiveMessages({msgs: [res.message]}));
    } catch (err) {
      setSaveStatus('failed');
      console.warn(err);
    }
  };

  const saveDraft = async ({html, plainText}: {html: string, plainText: string}) => {
    if (html === lastSavedHtml) return;
    save({
      body_html: html,
      body_text: plainText,
    });
  };

  const saveSubject = async (subject: string) => {
    save({
      context: {
        ...draft.context,
        subject,
      }
    });
  };

  const saveTo = async (toEmails: string[]) => {
    save({
      context: {
        ...draft.context,
        to: toEmails.join(','),
      }
    })
  };

  const saveCc = async (ccEmails: string[]) => {
    save({
      context: {
        ...draft.context,
        cc: ccEmails.join(','),
      }
    })
  };

  useEffect(() => {
    if (patchRes.isSuccess) {
      setSaveStatus('success');
    } else if (patchRes.isError) {
      setSaveStatus('failed');
    }
  }, [patchRes]);

  const onClickDelete = async () => {
    try {
      const res = await deleteMessage({
        messageId: message.id,
        conversationId: message.conversation_id,
      }).unwrap();
      dispatch(receiveMessages({msgs: [res.message]}));
    } catch (err) {
      // TODO: alert the user
      console.warn(err);
    }
  };

  const sendDraft = async () => {
    try {
      send({draftId: message.id, conversationId: message.conversation_id});
    } catch (err) {
      console.warn(err);
    }
  };

  if (isSent) {
    return null;
  }

  return <div className="conversation-draft--container">
    <div>{draft.context.from_}</div>
    <div className="conversation-draft-emails-input--container">
      <MultiEmailInput emails={parsedTo} onChange={saveTo} namespace={namespace} label="To" />
    </div>
    <div className="conversation-draft-emails-input--container">
      <MultiEmailInput emails={parsedCc} onChange={saveCc} namespace={namespace} label="Cc" />
    </div>
    <div className="conversation-draft-subject-input--container">
      <input defaultValue={draft.context.subject} className="conversation-draft-subject--input" onChange={debounce((e: ChangeEvent<HTMLInputElement>) => saveSubject(e.target.value), 250)} />
    </div>
    <Composer
      initialHtmlString={draft.body_html || ''}
      onChange={saveDraft}
      onChangeDebounceMs={250}
    />
    <div className="conversation-draft-action--bar">
      <div className="conversation-draft-actions--group">
        <IconButton appearance='minimal' icon={TrashIcon} onClick={onClickDelete} />
      </div>
      <div className="conversation-draft-actions--group">
        <div className={cx('conversation-draft-save--status', {success: saveStatus === 'success', loading: saveStatus === 'saving'})}>
          {saveStatus === 'saving' && <div>{_(M.SAVING_DRAFT)}<LoadingDots /></div>}
          {saveStatus === 'success' && <div><TickIcon marginRight={4} color="var(--color-green-2-4)" />{_(M.SAVE_DRAFT_SUCCESS)}</div>}
          {saveStatus === 'failed' && <div><CrossIcon marginRight={4} color="var(--color-red-4)" />{_(M.SAVE_DRAFT_FAILED)}</div>}
        </div>
        <Button marginLeft={16} borderRadius={25} size="large" appearance="primary" intent="success" onClick={sendDraft}>{_(M.SEND_EMAIL)}</Button>
      </div>
    </div>
  </div>
};

export default DraftInConversation;