import HubspotIcon from 'components/icons/hubspot';
import { ICadenceEvent } from 'types/cadence';
import _, {M} from 'constants/i18n';


const HubspotActivityCreateEvent = ({event}: {event: ICadenceEvent}) => {
  const createResult = event.event_data.sync_status === 'contact_does_not_exist' ? M.CADENCE_EVENT_HS_CONTACT_CREATE_ALREADY_EXISTS : M.CADENCE_EVENT_HS_CONTACT_CREATE_SUCCESS
  return <div>
    <div className="enrolled-event-title--container">
      <HubspotIcon className="enrolled-event-title--icon"/>
      <div className="enrolled-event-title--text">{_(M.CADENCE_EVENT_HS_CONTACT_CREATE_TITLE)}</div>
    </div>
    <p className="enrolled-event--description">{_(M.CADENCE_EVENT_HS_CONTACT_CREATE_DESC)} {_(createResult)}</p>
  </div>
};

export default HubspotActivityCreateEvent;