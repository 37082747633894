import { MouseEvent, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  EmptyState,
  IconButton,
  Table,
  EditIcon,
  Pagination,
  Spinner,
  Tooltip,
  Position,
  TrashIcon,
} from 'evergreen-ui';

import CadenceIcon from 'components/icons/cadence';
import _, { M } from 'constants/i18n';
import { usePrefetch } from 'api/contact';
import { IContactWithProperties } from 'types/contact';
import { dateStrToLocaleStr, getContactEmail, getContactName } from 'utils/strings';
import './style.css';
import { usePaginateContactsInListQuery, useRemoveContactsFromListMutation } from 'api/contactLists';
import AddContactToListDialog from '../AddContactToListDialog';


const ContactRow = ({ contact, listId }: { contact: IContactWithProperties, listId: number }) => {
  const nav = useNavigate();
  const fetchContact = usePrefetch('fetchContact');
  const [remove, result] = useRemoveContactsFromListMutation();

  const goToContact = (e: MouseEvent, edit: boolean = false) => {
    e.stopPropagation();
    const url = edit
      ? `/contacts/${contact.id}/edit`
      : `/contacts/${contact.id}`;
    nav(url);
  };

  const deleteContact = (e: MouseEvent) => {
    e.stopPropagation();
    remove({listId, contactIds: [contact.id], emails: []});
  }

  return (
    <Table.Row
      onClick={goToContact}
      isSelectable
      onMouseEnter={() => fetchContact(contact.id)}
    >
      <Table.TextCell>{getContactName(contact, [])}</Table.TextCell>
      <Table.TextCell>{getContactEmail(contact)}</Table.TextCell>
      <Table.TextCell>{dateStrToLocaleStr(contact.created_at)}</Table.TextCell>
      <Table.Cell justifyContent='right'>
        <Tooltip content={_(M.EDIT)} position={Position.TOP}>
          <IconButton
            appearance='minimal'
            icon={EditIcon}
            onClick={(e: MouseEvent) => goToContact(e, true)}
          />
        </Tooltip>
        <Tooltip content={_(M.DELETE)} position={Position.TOP}>
          <IconButton
            appearance='minimal'
            icon={TrashIcon}
            onClick={deleteContact}
            isLoading={result.isLoading}
          />
        </Tooltip>
      </Table.Cell>
    </Table.Row>
  );
};


const ContactList = () => {
  const limit = 5;
  const { listId: listIdStr='' } = useParams<{listId: string}>();
  const listId = parseInt(listIdStr);
  const [offset, setOffset] = useState(0);
  const [addContactOpen, setAddContactOpen] = useState(false);

  const { isLoading, isFetching, isError, data } = usePaginateContactsInListQuery({
    listId,
    limit,
    offset,
  });

  const loading = isLoading || isFetching;

  if (loading) {
    return <Spinner delay={100}>{_(M.LOADING)}</Spinner>;
  }

  if (
    !loading &&
    !isError &&
    data?.items.length === 0
  ) {
    return (
      <>
        <EmptyState
          title={_(M.NO_CONTACTS_IN_LIST_TITLE)}
          description={_(M.NO_CONTACTS_IN_LIST_DESC)}
          icon={<CadenceIcon />}
          iconBgColor='var(--color-green-2)'
          primaryCta={
            <Button
              appearance='primary'
              onClick={() => setAddContactOpen(true)}
            >
              {_(M.ADD_CONTACT_TO_LIST)}
            </Button>
          }
        />
        <AddContactToListDialog listId={listId} onClose={() => setAddContactOpen(false)} isShown={addContactOpen} />
      </>
    );
  }

  const onPrevPageClick = () => {
    if (!data) return;

    const currentOffset = (data.pagination.this - 1) * limit;
    if (currentOffset > 0) {
      setOffset(currentOffset - limit);
    }
  };

  const onNextPage = () => {
    if (!data) return;

    const pagination = data.pagination;
    const currentOffset = (pagination.this - 1) * limit;
    if (pagination.this < pagination.last) {
      setOffset(currentOffset + limit);
    }
  };

  const onPageChange = (page: number) => {
    setOffset((page - 1) * limit);
  };

  return (
    <>
      <div className='contact-list--container'>
        <div className="contact-list--header">
          <h2>{_(M.NAV_CONTACTS)}</h2>
          <Button
            appearance='primary'
            onClick={() => setAddContactOpen(true)}
          >
            {_(M.ADD_CONTACT_TO_LIST)}
          </Button>
        </div>
        <Table>
          <Table.Head>
            <Table.TextHeaderCell>{_(M.NAME)}</Table.TextHeaderCell>
            <Table.TextHeaderCell>{_(M.EMAIL)}</Table.TextHeaderCell>
            <Table.TextHeaderCell>{_(M.CREATED_AT)}</Table.TextHeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Head>
          <Table.Body>
            {data?.items.map((contact) => (
              <ContactRow contact={contact.contact} key={contact.id} listId={listId} />
            ))}
          </Table.Body>
        </Table>
        {data && (
          <Pagination
            totalPages={data.pagination.last}
            page={data.pagination.this}
            onNextPage={onNextPage}
            onPreviousPage={onPrevPageClick}
            onPageChange={onPageChange}
          />
        )}
      </div>
      <AddContactToListDialog listId={listId} onClose={() => setAddContactOpen(false)} isShown={addContactOpen} />
    </>
  );
};

export default ContactList;
