import { useParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { Button, Label, toaster } from 'evergreen-ui';
import Select from 'react-select/async';

import _, { M } from 'constants/i18n';
import { useAppSelector } from 'hooks';
import { getFirstHubSpotConnector } from 'store/user/selector';
import { useEditCadenceHSListAutoEnrollmentMutation, useFetchCadenceQuery, useLazySearchHubspotListsQuery, useSyncHSListContactsNowMutation } from 'api/cadence';
import { getEditingCadence } from 'store/cadence/selector';


const HubSpotListSyncForCadence = () => {
  const { cadenceId: cadenceIdStr='' } = useParams<{cadenceId: string}>();
  const firstHsConnector = useAppSelector(getFirstHubSpotConnector);
  const hasHsConnector = !!firstHsConnector;
  const [trigger, triggerRes] = useLazySearchHubspotListsQuery();
  const editingCadence = useAppSelector(getEditingCadence);
  const [setHsListAutoEnroll, setHsListAutoEnrollRes] = useEditCadenceHSListAutoEnrollmentMutation();
  const [syncHsListNow, syncHsListNowRes] = useSyncHSListContactsNowMutation();
  const cadenceId = parseInt(cadenceIdStr);
  const {data} = useFetchCadenceQuery(cadenceId);

  const loadOptions = (
    searchTerm: string,
    callback: (options: {label: string, value: number, connectorId: number}[]) => void
  ) => {
    trigger({searchTerm})
      .unwrap()
      .then(({lists}) => {
        callback(lists.map(list => ({
          label: `${list.name} (${list.size} contacts)`,
          value: list.id,
          connectorId: list.connector_id,
        })));
      })
  };

  const autoEnrollHsListId = (data?.cadence.data.hs_list_for_new_contact_auto_enrollment || [])[0]
  const defaultHsListOption = useMemo(() => {
    if (autoEnrollHsListId && triggerRes.data) {
      const list = triggerRes.data.lists.find(({id}) => id === autoEnrollHsListId);
      if (list) {
        return {
          label: `${list.name} (${list.size} contacts)`,
          value: list.id,
          connectorId: list.connector_id,
        }
      }
    }
  }, [autoEnrollHsListId, triggerRes]);

  useEffect(() => {
    if (setHsListAutoEnrollRes.isError) {
      toaster.danger(_(M.ERROR));
    } else if (setHsListAutoEnrollRes.isSuccess) {
      toaster.success(_(M.SUCCESS));
    }
  }, [setHsListAutoEnrollRes]);

  const onSyncNowClick = () => {
    const listIds = editingCadence?.data.hs_list_for_new_contact_auto_enrollment;

    if (listIds && listIds.length !== 0 && firstHsConnector) {
      syncHsListNow({cadenceId, hsListId: listIds[0], connectorId: firstHsConnector.id})
        .unwrap()
        .then(() => toaster.success(_(M.CADENCE_ENROLLMENT_HS_LIST_SYNC_SUCCESS)))
        .catch((res) => {
          if ('status' in res && res.status === 409) {
            toaster.danger(_(M.CADENCE_ENROLLMENT_HS_LIST_SYNC_TOO_FAST))
          } else {
            toaster.danger(_(M.ERROR))
          }
        });
    }
  };

  if (!hasHsConnector) {
    return null;
  }

  return <div>
    <h3 className="view-cadence-settings--header">{_(M.CADENCE_ENROLLMENT_OPTIONS_TITLE)}</h3>
    <div>
      <Label>{_(M.CADENCE_ENROLLMENT_HS_LIST_TITLE)}</Label>
      <Select
        cacheOptions
        onChange={s => {
          setHsListAutoEnroll({
            cadenceId,
            hsListId: s?.value || (editingCadence?.data.hs_list_for_new_contact_auto_enrollment || [0])[0],
            connectorId: s?.connectorId || firstHsConnector.id,
            clearListener: !s,
          });
        }}
        loadOptions={loadOptions}
        defaultOptions
        isClearable
        isDisabled={setHsListAutoEnrollRes.isLoading}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          control: styles => ({...styles, fontSize: '12px'}),
          option: styles => ({...styles, fontSize: '12px'}),
          container: styles => ({...styles, maxWidth: '400px'}),
        }}
        menuPortalTarget={document.body}
        value={defaultHsListOption}
      />
      <div className="view-cadence-settings-hs-list-enrollment--help">{_(M.CADENCE_ENROLLMENT_HS_LIST_DESC)}</div>
      <Button size="small" marginTop="8px" intent="info" onClick={onSyncNowClick} isLoading={syncHsListNowRes.isLoading} disabled={syncHsListNowRes.isLoading}>{_(M.CADENCE_ENROLLMENT_HS_LIST_SYNC_NOW)}</Button>
    </div>
  </div>
};

export default HubSpotListSyncForCadence;