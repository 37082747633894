import { IConversation, AnyMessage, ConversationStatus } from 'types/conversation';
import { v2queries } from '../queries';
import { IPagination } from 'api/cadence';
import { IInbox, InboxStatus } from 'types/inbox';


interface IFetchInboxConversationsArgs {
  inboxId: string | number,
  before?: string | null,
  status?: ConversationStatus | null,
  limit?: number | null,
}


const inboxQueries = v2queries.injectEndpoints({
  endpoints: (build) => ({
    fetchInboxes: build.query<{items: IInbox[], pagination: IPagination}, {limit?: number, status?: InboxStatus}>({
      query: () => ({
        url: '/inboxes/'
      }),

      providesTags: (res) => res ? res.items.map(({id}) => ({type: 'Inbox' as const, id})) : [],
    }),

    fetchInboxConversations: build.query<{items: IConversation[], pagination: IPagination}, IFetchInboxConversationsArgs>({
      query: ({inboxId, before, status, limit}) => {
        const qs = new URLSearchParams();
        if (before) {
          qs.set('before', before);
        }
        if (status) {
          qs.set('status', status);
        }
        if (limit) {
          qs.set('limit', limit.toString());
        }
        return {
          url: `/inboxes/${inboxId}/conversations?${qs}`,
        }
      },

      providesTags: (res) => res ? [{type: 'Conversation', id: 'PAGE'}, ...res.items.map(({id}) => ({type: 'Conversation' as const, id}))] : [],
    }),

    fetchConversationMessages: build.query<{items: AnyMessage[], pagination: IPagination}, {inboxId: string | number, conversationId: number, before?: string}>({
      query: ({inboxId, conversationId, before}) => {
        const qs = new URLSearchParams();
        if (before) {
          qs.set('before', before);
        }
        return {
          url: `/inboxes/${inboxId}/conversations/${conversationId}/messages?${qs}`,
        }
      },

      providesTags: (res) => res ? res.items.map(({id}) => ({type: 'Messaage' as const, id})) : [],
    }),

    createNewConversation: build.mutation<{conversation: IConversation}, {inboxId: string | number, status: ConversationStatus, subject: string}>({
      query: ({inboxId, ...body}) => ({
        url: `/inboxes/${inboxId}/conversations`,
        method: 'POST',
        body,
      }),

      invalidatesTags: res => res ? [{type: 'Conversation', id: 'PAGE'}] : []
    }),
  }),
});

export const {
  useFetchInboxesQuery,
  useFetchInboxConversationsQuery,
  useFetchConversationMessagesQuery,
  useCreateNewConversationMutation,
} = inboxQueries;