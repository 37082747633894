import { Pagination, Table } from 'evergreen-ui';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { dateStrToLocaleStr } from 'utils/strings';
import { IConnector } from 'types/user';
import { usePaginateConnectorsQuery } from 'api/admin/connector';

const limit = 10;

const ConnectorRow = ({connector}: {connector: IConnector}) => {
  const nav = useNavigate();

  const goToConnector = () => {
    nav(`/admin/connectors/${connector.id}`);
  }

  return <Table.Row
    onClick={goToConnector}
    isSelectable
  >
    <Table.TextCell>{connector.provider}</Table.TextCell>
    <Table.TextCell>{connector.status}</Table.TextCell>
    <Table.TextCell>{connector.external_account_id}</Table.TextCell>
    <Table.TextCell><Link to={`/admin/users/${connector.user_id}`} onClick={e => e.stopPropagation()}>{connector.user_id}</Link></Table.TextCell>
    <Table.TextCell>{dateStrToLocaleStr(connector.created_at)}</Table.TextCell>
    <Table.TextCell>{dateStrToLocaleStr(connector.updated_at)}</Table.TextCell>
  </Table.Row>
}


const AdminConnectorsTable = ({userId}: {userId?: string | number}) => {
  const [offset, setOffset] = useState(0);
  const {
    data,
  } = usePaginateConnectorsQuery({limit: 10, offset, user_id: userId});

  const onPrevPageClick = () => {
    if (!data) return;

    const currentOffset = (data.meta.pagination.this - 1) * limit;
    if (currentOffset > 0) {
      setOffset(currentOffset - limit);
    }
  };

  const onNextPage = () => {
    if (!data) return;

    const pagination = data.meta.pagination;
    const currentOffset = (pagination.this - 1) * limit;
    if (pagination.this < pagination.last) {
      setOffset(currentOffset + limit);
    }
  };

  const onPageChange = (page: number) => {
    setOffset((page - 1) * limit);
  };

  return <div>
    <Table>
      <Table.Head>
        <Table.TextHeaderCell>Provider</Table.TextHeaderCell>
        <Table.TextHeaderCell>Status</Table.TextHeaderCell>
        <Table.TextHeaderCell>Ext Acct Id</Table.TextHeaderCell>
        <Table.TextHeaderCell>User Id</Table.TextHeaderCell>
        <Table.TextHeaderCell>Created at</Table.TextHeaderCell>
        <Table.TextHeaderCell>Updated at</Table.TextHeaderCell>
      </Table.Head>
      <Table.Body>
        {data?.connectors.map((connector) => <ConnectorRow connector={connector} key={connector.id} />)}
      </Table.Body>
    </Table>
    {data && (
      <Pagination
        totalPages={data.meta.pagination.last}
        page={data.meta.pagination.this}
        onNextPage={onNextPage}
        onPreviousPage={onPrevPageClick}
        onPageChange={onPageChange}
      />
    )}
  </div>
}


export default AdminConnectorsTable;
