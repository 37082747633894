import { useParams } from 'react-router-dom';

import AdminCadencesTable from 'components/admin/CadencesTable';


const AdminUserCadencesPage = () => {
  const {userId = ''} = useParams<{userId: string}>()

  return <div>
    <AdminCadencesTable userId={userId} />
  </div>
}

export default AdminUserCadencesPage;