import { nodeFullHeight, nodeFullWidth } from './constants';


const NodeArrow = ({
  fromNodeId,
  toNodeId,
}: {fromNodeId: string, toNodeId: string}) => {
  const $from = document.getElementById(`grid-node--${fromNodeId}`);
  const $to = document.getElementById(`grid-node--${toNodeId}`);

  if (!$from || !$to) return null;

  const fromStyle = $from.style;
  const toStyle = $to.style;

  const startX = parseInt(fromStyle.left.replace('px', '')) + (nodeFullWidth / 2);
  const startY = parseInt(fromStyle.top.replace('px', '')) + nodeFullHeight - 4;
  const endX = parseInt(toStyle.left.replace('px', '')) + (nodeFullWidth / 2);
  const endY = parseInt(toStyle.top.replace('px', '')) + 8;

  const startDString = `
  M ${startX},${startY}
  L ${startX},${startY}
  `;
  const dString = `
  M ${startX},${startY}
  ${startX === endX
    ? `L ${endX},${endY}`  // straight line down to the next node
    : `l 0,60 l ${endX - startX},0 l 0,${endY - startY - 60}`} // line down then left/right then down again to the next node
  `;

  const pathId = `grid-line-from-${fromNodeId}-to-${toNodeId}`;

  return <g key={toNodeId}>
    <path d={dString} stroke="var(--color-neutral-10)" strokeWidth="2" id={pathId} fill="none">
      <animate
        attributeName="d"
        attributeType="XML"
        from={startDString}
        to={dString}
        dur="1s"
      />
    </path>
    <path d={`M ${endX-4},${endY} L ${endX+4},${endY} L ${endX},${endY+6} Z`} fill={'var(--color-neutral-10)'}/>
  </g>
};

export default NodeArrow;