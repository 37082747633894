import { useParams } from 'react-router-dom';
import { Button, toaster } from 'evergreen-ui';
import { useEffect } from 'react';

import { useBackfillConnversationsMutation, useEmptyInboxMutation, useFetchInboxQuery } from 'api/admin/inbox';
import './style.css';
import { useReindexContactsForNamespaceMutation } from 'api/admin/search';



const AdminInboxPage = () => {
  const {inboxId = ''} = useParams<{inboxId: string}>()

  const {
    data,
    isLoading,
    isFetching,
    isError,
  } = useFetchInboxQuery(inboxId);

  const [reingest, reingestRes] = useBackfillConnversationsMutation();
  const [empty, emptyRes] = useEmptyInboxMutation();
  const [reindex, reindexRes] = useReindexContactsForNamespaceMutation();

  useEffect(() => {
    if (reingestRes.isError) {
      toaster.danger('Failed to reingest latest conversations');
    } else if (reingestRes.isSuccess) {
      toaster.success('Reingested latest conversations');
    }
  }, [reingestRes]);

  useEffect(() => {
    if (emptyRes.isError) {
      toaster.danger('Failed to empty inbox');
    } else if (emptyRes.isSuccess) {
      toaster.success('Began emptying inbox');
    }
  }, [emptyRes]);


  if (!data || isLoading || isFetching) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error</div>
  }

  const inbox = data.inbox;

  return <div className="admin-page-content--container">
    <h2>{inbox.name}</h2>
    <div className="admin-page-actions--container">
      <Button onClick={() => reingest({inboxId})} isLoading={reingestRes.isLoading}>Import latest conversations</Button>
      <Button onClick={() => empty({inboxId})} isLoading={emptyRes.isLoading}>Empty inbox</Button>
      <Button onClick={() => reindex({namespace: inbox.namespace})} isLoading={reindexRes.isLoading}>Reindex inbox contacts</Button>
    </div>
    <div>
      <h3>Inbox data</h3>
      <pre>{JSON.stringify(inbox, undefined, 2)}</pre>
    </div>
  </div>
}

export default AdminInboxPage;