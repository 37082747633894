import { useParams } from 'react-router-dom';

import { useFetchContactListQuery } from 'api/admin/contact_list';
import { dateStrToLocaleStr } from 'utils/strings';


const AdminContactListPage = () => {
  const {contactListId = ''} = useParams<{contactListId: string}>()

  const {
    data,
    isLoading,
    isFetching,
    isError,
  } = useFetchContactListQuery(contactListId);

  if (!data || isLoading || isFetching) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error</div>
  }

  const list = data.data;

  return <div>
    <div>
      <h3>Contact List details</h3>
      <div className="admin-user-page-detail--container">
        <div className="admin-user-page-detail--label">Name</div>
        <div className="admin-user-page-detail--value">{list.name}</div>
      </div>
      <div className="admin-user-page-detail--container">
        <div className="admin-user-page-detail--label">Description</div>
        <div className="admin-user-page-detail--value">{list.description}</div>
      </div>
      <div className="admin-user-page-detail--container">
        <div className="admin-user-page-detail--label">Namespace</div>
        <div className="admin-user-page-detail--value">{list.namespace}</div>
      </div>
      <div className="admin-user-page-detail--container">
        <div className="admin-user-page-detail--label">Created</div>
        <div className="admin-user-page-detail--value">{dateStrToLocaleStr(list.created_at)}</div>
      </div>
      <div className="admin-user-page-detail--container">
        <div className="admin-user-page-detail--label">Updated</div>
        <div className="admin-user-page-detail--value">{dateStrToLocaleStr(list.updated_at)}</div>
      </div>
    </div>
  </div>
}

export default AdminContactListPage;