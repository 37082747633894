import { ICadenceEvent } from 'types/cadence';
import _, {M} from 'constants/i18n';
import HubspotIcon from 'components/icons/hubspot';


const HubspotPropertiesPulledEvent = (_props: {event: ICadenceEvent}) => {
  return <div>
    <div className="enrolled-event-title--container">
      <HubspotIcon className="enrolled-event-title--icon"/>
      <div className="enrolled-event-title--text">{_(M.CADENCE_EVENT_HS_PROP_PULL_TITLE)}</div>
    </div>
    <p className="enrolled-event--description">{_(M.CADENCE_EVENT_HS_PROP_PULL_DESC)}</p>
  </div>
};

export default HubspotPropertiesPulledEvent;