import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import InboxConversationSidebar from 'components/inbox/InboxConversationSidebar';
import { listen } from 'utils/socket';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getCurrentUser } from 'store/user/selector';
import { IConversationInboxUpdate, IInboxUpdate, INewMessageInboxUpdate } from 'types/inbox';
import { wsReceiveConvUpdate, wsReceiveNewMessage } from 'store/inbox/slice';
import './style.css';


const InboxesOutlet = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (currentUser) {
      listen<IInboxUpdate>(`user=${currentUser.id}`, 'INBOX_UPDATE', ({inbox_update_type, payload}) => {
        if (inbox_update_type === 'new_message') {
          dispatch(wsReceiveNewMessage(payload as INewMessageInboxUpdate))
        } else if (inbox_update_type === 'conversation_update') {
          dispatch(wsReceiveConvUpdate(payload as IConversationInboxUpdate))
        }
      });
    }
  }, [currentUser, dispatch]);

  return <div className="inboxes-outlet--container">
    <div className="inboxes-outlet-sidebar--container">
      <InboxConversationSidebar />
    </div>
    <div className='inboxes-outlet-content--container'>
      <Outlet />
    </div>
  </div>
};

export default InboxesOutlet;