import { IBrowserContext } from 'utils/context'

export enum AuthorType {
  CONTACT = 'contact',
  ADMIN = 'admin',
  SYSTEM = 'system',
  USER = 'user',
}

export interface IDraftMessageContext {
  to: string
  from_: string
  cc: string | null
  subject: string
  references?: string
}


export interface IEmailMessageContext extends IDraftMessageContext {
  received_at: string
  sent_at: string
  delivered_to: string
  return_path: string
  message_id?: string
}

export interface IPrivateCommentContext extends IBrowserContext {}


export enum MessageTypes {
  EMAIL = 'message.email',
  IM = 'message.instant_message',
  NOTE = 'private.note',
  COMMENT = 'private.comment',
  DRAFT = 'private.draft_start',
  DRAFT_SEND = 'private.draft_send',
  LINK = 'conversation_event.link',
  SNOOZE = 'conversation_event.snooze',
  UNSNOOZE = 'conversation_event.unsnooze',
  ARCHIVE = 'conversation_event.archive',
  UNARCHIVE = 'conversation_event.unarchive',
  DELETE = 'conversation_event.delete',
  UNDELETE = 'conversation_event.undelete',
}

export enum ConversationChannels {
  GMAIL = 'gmail',
}

export enum ConversationStatus {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
  DELETED = 'deleted',
  SNOOZED = 'snoozed',
}


export interface IConversation {
  id: number
  inbox_id: number
  status: ConversationStatus
  created_at: string
  created_by: number | null
  updated_at: string | null
  last_message_at: string | null
  last_read: string | null
  updated_by: number | null
  conversation_channel_type: ConversationChannels
  subject: string | null
  preview: string | null
  latest_author: {
    id: number
    type: AuthorType
    raw_from: string | null
  } | null
}

interface IMessage<T=IEmailMessageContext> {
  id: number
  conversation_id: number
  created_at: string
  author_id: number
  author_type: AuthorType
  message_type: MessageTypes
  preview?: string | null
  body_html?: string | null
  body_text?: string | null
  context: T
  email_addrs?: null | {
    to: {email: string, name: string}[],
    from_: {email: string, name: string}[],
    bcc: {email: string, name: string}[],
    cc: {email: string, name: string}[],
  }
  meta: Record<string, unknown>
  deleted_at?: string | null
  deleted_by?: string | null
}

export type EmailMessage = IMessage<IEmailMessageContext>
export type PrivateComment = IMessage<IPrivateCommentContext>
export type DraftMessage = IMessage<IDraftMessageContext>

export type AnyMessage = EmailMessage | PrivateComment | DraftMessage
export type AnyMessageContext = AnyMessage['context']
