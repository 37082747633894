import { IContactInList, IContactList, IContactListWithSize } from 'types/contact';

import { queries } from './queries';
import { IPagination } from './cadence';


const contactListQueries = queries.injectEndpoints({
  endpoints: (build) => ({
    fetchContactList: build.query<{data: IContactList}, number>({
      query: (listId) => ({
        url: `/contact-lists/${listId}`
      }),

      providesTags: (res) => res ? [{type: 'ContactList' as const, id: res.data.id}] : [],
    }),

    paginateContactLists: build.query<{items: IContactListWithSize[], pagination: IPagination}, {offset: number, limit: number}>({
      query: ({limit, offset}) => ({
        url: `/contact-lists/?limit=${limit}&offset=${offset}`
      }),

      providesTags: (res) => res ? [{type: 'ContactList', id: 'PAGE'}, ...res.items.map(c => ({type: 'ContactList' as const, id: c.id}))] : [],
    }),

    createContactList: build.mutation<{data: IContactList}, {name: string, description?: string | null, file?: File | null}>({
      query: (body) => {
        const formData = new FormData();
        if (body.file) {
          formData.append('file', body.file);
        }
        formData.append('name', body.name);
        if (body.description) {
          formData.append('description', body.description);
        }

        return {
          url: '/contact-lists/',
          method: 'POST',
          body: formData,
        }
      },

      invalidatesTags: [{type: 'ContactList', id: 'PAGE'}],
    }),

    deleteContactList: build.mutation<void, number>({
      query: (listId) => ({
        url: `/contact-lists/${listId}`,
        method: 'DELETE',
      }),

      invalidatesTags: (res, _, _2) => res ? [
        {type: 'ContactList', id: 'PAGE'},
      ] : [],
    }),

    paginateContactsInList: build.query<{items: IContactInList[], pagination: IPagination}, {listId: number, offset: number, limit: number}>({
      query: ({listId, offset, limit}) => {
        const qs = new URLSearchParams();
        qs.set('limit', limit.toString());
        qs.set('offset', offset.toString());
        return {
          url: `/contact-lists/${listId}/contacts?${qs}`,
        }
      },

      providesTags: (res) => res ? [{type: 'ContactInList', id: 'PAGE'}, ...res.items.map(c => ({type: 'ContactInList' as const, id: c.id}))] : [],
    }),

    addContactsToList: build.mutation<{data: IContactList}, {listId: number | string, contactIds: number[], emails: string[]}>({
      query: ({listId, contactIds: contact_ids, emails: email_addresses}) => ({
        url: `/contact-lists/${listId}/contacts/add`,
        body: {contact_ids, email_addresses},
        method: 'POST',
      }),

      invalidatesTags: (res, _, _2) => res ? [{type: 'ContactInList', id: 'PAGE'}] : [],
    }),

    removeContactsFromList: build.mutation<{data: IContactList}, {listId: number | string, contactIds: number[], emails: string[]}>({
      query: ({listId, contactIds: contact_ids, emails: email_addresses}) => ({
        url: `/contact-lists/${listId}/contacts/remove`,
        body: {contact_ids, email_addresses},
        method: 'POST',
      }),

      invalidatesTags: (res, _, _2) => res ? [{type: 'ContactInList', id: 'PAGE'}] : [],
    }),
  }),

  overrideExisting: false,
})

export const {
  useFetchContactListQuery,
  usePrefetch,
  useCreateContactListMutation,
  usePaginateContactListsQuery,
  useDeleteContactListMutation,
  usePaginateContactsInListQuery,
  useAddContactsToListMutation,
  useRemoveContactsFromListMutation,
} = contactListQueries;