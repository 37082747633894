import { LinkIcon } from 'evergreen-ui';

import { ICadenceEvent } from 'types/cadence';
import _, {M} from 'constants/i18n';


const EmailClickEvent = ({event}: {event: ICadenceEvent}) => {
  return <div>
    <div className="enrolled-event-title--container">
      <LinkIcon className="enrolled-event-title--icon" color="var(--color-purple-4)"/>
      <div className="enrolled-event-title--text">{_(M.CADENCE_EVENT_EMAIL_CLICK_TITLE)}</div>
    </div>
    <p className="enrolled-event--description">{_(M.CADENCE_EVENT_EMAIL_CLICK_DESC)}: {event.event_data.clicked_url as string}</p>
  </div>
};

export default EmailClickEvent;