import { DEBUG, HEAP_APP_ID } from 'constants/resources';
import { IUser } from 'types/user';

declare const heap: {load: (appId: string) => void; identify: (id: string) => void, addUserProperties: (props: Record<string, unknown>) => void, track: (event: string, props: Record<string, unknown>) => void};

// Central place to show all events that we track
export enum TrackEventNames {
  S = 'Search',
  SU = 'Signup',
  CC = 'Create Cadence',
  PC = 'Patch Cadence',
  LC = 'List Cadences',
  VC = 'View Cadence',
  LE = 'List Enrollment',
  VE = 'View Enrollment',
  PE = 'Patch Enrollment',
  ME = 'Manual Enrolllment',
  BE = 'Bulk Enrollment',
  CE = 'Cancel Enrolllment',
  HE = 'HubSpot List Enrollment',
  CLE = 'Contact List Enrollment',
}

const embedHeap = () => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.defer = true;
  script.async = true;
  script.innerText = 'window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};';
  document.body.appendChild(script);
}

class Tracker {

  constructor() {
    if (DEBUG) return;
    embedHeap();
    try {
      heap.load(HEAP_APP_ID);
    } catch (err) {
      console.warn('[TC.app] failed to initialize heap', err);
    }
  }

  identify(user: IUser) {
    if (DEBUG) return;
    try {
      heap.identify(user.email);
      heap.addUserProperties({id: user.id, name: user.full_name, status: user.status});
    } catch (err) {
      console.warn('[TC.app] failed to identify user with tracker', err);
    }
  }

  setSignupVersion() {
    if (DEBUG) return;
    try {
      heap.addUserProperties({'signupVersion': process.env.REACT_APP_VERSION});
    } catch (err) {
      console.warn('[TC.app] failed to add user properties', err);
    }
  }

  track(event: TrackEventNames, properties: Record<string, unknown> = {}) {
    if (DEBUG) return;
    // TODO: maybe restrict tracking to non-anonymous users
    try {
      heap.track(event, properties);
    } catch (err) {
      console.warn('[TC.app] failed to track user action', err);
    }
  }
}

export const tracker = new Tracker();