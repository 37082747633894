import { useParams } from 'react-router-dom';
import { ArchiveIcon, IconButton, Tooltip, TrashIcon, UnarchiveIcon } from 'evergreen-ui';
import { UIEvent, useEffect, useMemo, useRef, useState } from 'react';

import Composer from 'components/shared/Composer';
import { useMarkConversationReadMutation, usePostConversationCommentMutation } from 'api/v2/conversations';
import getContext from 'utils/context';
import { useConversationMessages, useInboxConversation } from 'store/inbox/hooks';
import { lclId } from 'utils/id';
import { useAppDispatch, useAppSelector, useVisibility } from 'hooks';
import { markConvAsRead, optimisticallyUpdateConversation, receiveMessages } from 'store/inbox/slice';
import { oldestMessageDateForConversation } from 'store/inbox/selectors';
import Message from '../Message';
import _, {M} from 'constants/i18n';
import './style.css';
import { ConversationStatus } from 'types/conversation';


const ViewConversation = () => {
  const {inboxId, conversationId: convIdStr} = useParams<{inboxId: string, conversationId: string}>();
  const [read] = useMarkConversationReadMutation();
  const conversationId = typeof convIdStr === 'string' ? parseInt(convIdStr) : convIdStr || 0
  const [before, setBefore] = useState<undefined | string>(undefined);
  const [isTopVisible, _checkInView, topOfConvRef] = useVisibility<HTMLDivElement>(100, 250);
  const oldestCreatedAt = useAppSelector(s => oldestMessageDateForConversation(s, conversationId));
  const bottomRef = useRef<HTMLDivElement>(null);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const {data, isLoading, isFetching, hasMore, isSuccess} = useConversationMessages({
    inboxId: inboxId as string,
    conversationId,
    before
  });
  const {data: conversation, isLoading: isConvLoading} = useInboxConversation(conversationId);
  const [comment, commentRes] = usePostConversationCommentMutation()
  const dispatch = useAppDispatch();

  useEffect(() => {
    setBefore(undefined);
    setIsAtBottom(true);
    read(conversationId);
    dispatch(markConvAsRead({convId: conversationId}));
  }, [conversationId, read, dispatch]);

  useEffect(() => {
    if (isTopVisible && topOfConvRef.current && before !== oldestCreatedAt && !(isLoading || isFetching) && !isAtBottom && hasMore && isSuccess) {
      setBefore(oldestCreatedAt);
    }
  }, [isTopVisible, topOfConvRef, before, isLoading, isFetching, oldestCreatedAt, isAtBottom, hasMore, isSuccess]);

  const onSubmitComment = useMemo(() => ({clearEditor, html, plainText}: {clearEditor: () => void, html: string, plainText: string}) => {
    if (!!conversationId) {
      const commentPayload = {
        body_html: html,
        body_text: plainText,
        conversationId,
        meta: {
          clientId: lclId(),
        },
        context: getContext(),
      }
      comment(commentPayload).then(clearEditor);
    }
  }, [conversationId, comment]);

  useEffect(() => {
    if (commentRes.isSuccess) {
      dispatch(receiveMessages({msgs: [commentRes.data.message]}));
    }
  }, [commentRes, dispatch]);

  useEffect(() => {
    if (isAtBottom && data) {
      bottomRef.current?.scrollIntoView({behavior: 'smooth'});
    }
  }, [isAtBottom, data]);

  const onEnter = (enterArgs: {clearEditor: () => void, html: string, plainText: string}) => {
    onSubmitComment(enterArgs);
  }

  const maybeUnsetIsAtBottom = (event: UIEvent<HTMLDivElement>) => {
    const bottom = event.currentTarget.scrollHeight - event.currentTarget.scrollTop === event.currentTarget.clientHeight;
    setIsAtBottom(bottom);
  }

  const onArchive = () => {
    dispatch(optimisticallyUpdateConversation({
      conversationId,
      payload: {status: ConversationStatus.ARCHIVED},
    }));
  };

  const onUnarchive = () => {
    dispatch(optimisticallyUpdateConversation({
      conversationId,
      payload: {status: ConversationStatus.ACTIVE},
    }));
  };

  const onDelete = () => {
    dispatch(optimisticallyUpdateConversation({
      conversationId,
      payload: {status: ConversationStatus.DELETED},
    }));
  };

  return <div className="view-conversation--container">
    <div className="view-conversation--header">
      <div className="view-conversation-header--subject">{isConvLoading || !conversation ? '...' : conversation.subject}</div>
      <div className="view-conversation-header--actions">
        {conversation?.status !== ConversationStatus.ARCHIVED && <Tooltip content={_(M.ARCHIVE)}>
          <IconButton appearance="minimal" icon={ArchiveIcon} onClick={onArchive} />
        </Tooltip>}
        {conversation?.status === ConversationStatus.ARCHIVED && <Tooltip content={_(M.UNARCHIVE)}>
          <IconButton appearance="minimal" icon={UnarchiveIcon} onClick={onUnarchive} />
        </Tooltip>}
        <Tooltip content={_(M.DELETE)}>
          <IconButton appearance="minimal" icon={TrashIcon} onClick={onDelete} />
        </Tooltip>
      </div>
    </div>
    <div className="view-conversation--timeline" onScroll={maybeUnsetIsAtBottom}>
      {hasMore && <div ref={topOfConvRef} />}
      {(data || []).map(msg => <Message key={msg.id} message={msg} />)}
      <div ref={bottomRef} />
    </div>
    <div className="view-conversation-footer--wrapper">
      <div className="view-conversation-composer--wrapper">
        <Composer disableToolbar placeholder='Write a private note' onEnter={onEnter} />
      </div>
    </div>
  </div>
};

export default ViewConversation;