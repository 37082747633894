import { useParams } from 'react-router-dom';

import AdminCadenceEnrollmentsTable from 'components/admin/EnrollmentsTable';


const AdminUserEnrollmentsPage = () => {
  const {userId = ''} = useParams<{userId: string}>()

  return <div>
    <AdminCadenceEnrollmentsTable userId={userId} />
  </div>
}

export default AdminUserEnrollmentsPage;