import { API } from 'api';
import { IBootstrapResponse, ISignupParams, IUser } from 'types/user';
import { queries } from './queries';


export const fetchBootstrap = () => API.get<IBootstrapResponse>('/api/v1/users/me/boot');

export const signupViaEmail = (params: ISignupParams) => API.post(
  '/api/v1/users/',
  params,
  {headers: {'Content-Type': 'application/json'}}
);

export const loginViaGoogle = () => API.post(
  '/api/v1/users/oauth/google-login-finish',
  {integration_response: window.location.href.replace('http:', 'https:')},
  {headers: {'Content-Type': 'application/json'}},
)

export const resetPasswordViaEmail = (email: string) => API.post('/api/v1/users/reset-pw', {email});

export const verifyUser = (token: string) => API.post('/api/v1/users/verify', token);

export const resendVerificationEmail = () => API.post('/api/v1/users/resend-verify');

export const putSetting = (name: string, prop_type: 'boolean' | 'string' | 'json' | 'number', value?: string) => API.put<{user: IUser}>(`/api/v1/users/me/settings/${name}`, {value, prop_type});

const userQueries = queries.injectEndpoints({
  endpoints: (build) => ({

    patchCurrentUser: build.mutation<{user: IUser}, {full_name?: string | null, password?: string | null}>({
      query: (payload) => ({
        url: '/users/me',
        method: 'PATCH',
        body: payload,
      }),

      invalidatesTags: [{type: 'User', id: 'ME'}],
    }),

    fetchUser: build.query<{user: IUser}, {userId: number}>({
      query: ({userId}) => `/users/${userId}`,

      providesTags: res => res ? [{type: 'User', id: res.user.id}] : [],
    })
  }),

  overrideExisting: false,
});


export const { usePatchCurrentUserMutation, useFetchUserQuery } = userQueries;