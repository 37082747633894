import { APP_URL, HUBSPOT_CLIENT_ID } from './resources';

export enum ConnectorProviders {
  GMAIL = 'gmail',
  GDRIVE = 'gdrive',
  GCAL = 'gcal',
  HUBSPOT = 'hubspot',
  GITHUB = 'github',
}

export const getHubSpotOauthUrl = () => {
  const params = new URLSearchParams({
    redirect_uri: `${APP_URL}/connect-success?provider=hubspot`,
    client_id: HUBSPOT_CLIENT_ID,
    scope: 'oauth crm.lists.read crm.objects.contacts.read crm.objects.contacts.write crm.lists.write crm.objects.owners.read'
  });
  return `https://app.hubspot.com/oauth/authorize?${params}`;
}

export const UnknownBillingPlan = {
  name: 'Unknown plan',
  description: 'Unable to determin billing plan, please contact support for assistance.',
};

export const BillingPlans: Record<string, {name: string, description: string}> = {
  'tinycadence-plan:friends-and-family': {
    name: 'Friends and Family',
    description: 'Get all features and capabilities of TinyCadence for free, forever.',
  },
  'tinycadence-plan:summer-2023': {
    name: 'Solo plan ($10/month)',
    description: 'Get all single-player features and capabilities of TinyCadence for $10 per month.',
  }
}

export const GOOGLE_ADDON_URL = 'https://workspace.google.com/u/0/marketplace/app/tinycadence/135986546067';

export enum OnboardingSteps {
  'onboarding.home.connect_google' = 'onboarding.home.connect_google',
  'onboarding.home.install_addon' = 'onboarding.home.install_addon',
  'onboarding.home.connect_crm' = 'onboarding.home.connect_crm'
}