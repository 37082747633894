import { ChangeEvent, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Spinner, TextInput } from 'evergreen-ui';

import GridContainer from 'components/cadence/Grid';
import { useFetchCadenceQuery, usePatchCadenceMutation } from 'api/cadence';
import _, { M } from 'constants/i18n';
import { useAppDispatch, useAppSelector } from 'hooks';
import { editCadence, setEditingCadence } from 'store/cadence/slice';
import { editingCadenceHasChanged, getEditingCadence } from 'store/cadence/selector';
import { TrackEventNames, tracker } from 'utils/tracking';

import './style.css';


const EditCadence = () => {
  const dispatch = useAppDispatch();
  const { cadenceId='' } = useParams<{cadenceId: string}>();
  const editingCadence = useAppSelector(getEditingCadence);
  const [patch, patchRes] = usePatchCadenceMutation();
  const saving = patchRes.isLoading;
  const nav = useNavigate();

  const {
    isLoading,
    isFetching,
    isError: error,
    data,
  } = useFetchCadenceQuery(parseInt(cadenceId));

  const hasChanged = useAppSelector(s => editingCadenceHasChanged(s, data?.cadence));

  const loading = isLoading;

  const onSaveCadence = async () => {
    if (saving || !editingCadence) return;

    patch(editingCadence).then(() => tracker.track(TrackEventNames.PC));
  };

  // set the cadence we're editing if it's not in the store
  useEffect(() => {
    const noEditingCadence = editingCadence === null;
    const editingCadenceIsNew = data && editingCadence !== null && data?.cadence.id !== editingCadence.id;
    if ((noEditingCadence || editingCadenceIsNew) && data?.cadence) {
      dispatch(setEditingCadence(data.cadence));
    }
  }, [data, dispatch, editingCadence]);

  if (loading) {
    return <Spinner delay={100}>{_(M.LOADING)}</Spinner>
  }

  if (error || !data || !editingCadence) {
    return <div>{_(M.ERROR_CADENCE_NOT_FOUND)}</div>;
  }

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => dispatch(editCadence({...editingCadence, name: e.currentTarget.value}));
  const onChangeDescription = (e: ChangeEvent<HTMLInputElement>) => dispatch(editCadence({...editingCadence, description: e.currentTarget.value}));
  const onDiscardChanges = () => dispatch(editCadence(data.cadence));

  const {
    name,
    description,
  } = editingCadence;

  return (
    <div className='cadence-edit--container'>
      <div className="cadence-view--header">
        <div>
          <TextInput className="cadence-view-header-field--edit" value={name} onChange={onChangeName}/>
          <TextInput className="cadence-view-header-field--edit" value={description || ''} placeholder={_(M.NEW_CADENCE_DESC)} onChange={onChangeDescription} />
        </div>
        <div className="cadence-edit-actions--container">
          <div>
            {hasChanged && <span className="cadence-edit-actions-warntext">You have unsaved changes</span>}
            <Button isLoading={saving || isFetching} disabled={!hasChanged} onClick={onSaveCadence} appearance='primary'>{_(M.SAVE)}</Button>
            <Button
              appearance="primary"
              intent="success"
              marginLeft={16}
              onClick={() => nav(`/cadences/${cadenceId}/enroll`)}
            >
              {_(M.ENROLL)}
            </Button>
          </div>
          {hasChanged && <Button className="cadence-edit-actions--discard" onClick={onDiscardChanges}>Discard</Button>}
        </div>
      </div>
      <GridContainer />
    </div>
  );
};

export default EditCadence;