import { useParams } from 'react-router-dom';

import AdminContactsInListTable from 'components/admin/ContactsInListTable';

const AdminContactListContactsPage = () => {
  const {contactListId = ''} = useParams<{contactListId: string}>()

  return <AdminContactsInListTable contactListId={contactListId} />
}

export default AdminContactListContactsPage;
