import { Link } from 'react-router-dom';
import cx from 'classnames';
import { Alert, Button, TextInput } from 'evergreen-ui';
import { ChangeEvent, FocusEvent, FormEvent, MouseEvent, useState } from 'react';

import { API } from 'api';
import { getGoogleOAuthURI, loginViaEmail } from 'api/auth';
import { setToken } from 'utils/auth';
import { validateEmail } from 'utils/strings';
import _, { M } from 'constants/i18n';
import GoogleButton from 'components/shared/GoogleButton';

import './style.css';

const LOGIN_ERROR = {
  title: 'Login invalid. Try again',
  children: <div className="onboarding-alert-children">{_(M.ERROR_BAD_LOGIN)} <Link className="login-link" to="/auth/signup">{_(M.NAV_SIGNUP)}</Link> {_(M.OR)} <Link className="login-link" to="/auth/reset-pw">{_(M.NAV_RESET_PW)}</Link></div>
};


const openGoogle = async () => {
  try {
    const {data} = await getGoogleOAuthURI(true);
    window.location.href = data.authorization_url;
  } catch (err) {
    console.warn('Could not get google auth URI', err);
  }
};


const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<typeof LOGIN_ERROR | null>(null);
  const [isEmailValid, setIsEmailValid] = useState(true);

  const onSubmitEmail = (event: MouseEvent<HTMLButtonElement> & FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setError(null);
    loginViaEmail(email, password)
      .then(({ data }) => {
        setToken(data.access_token);
        API.defaults.headers['Authorization'] = `Bearer ${data.access_token}`;
        window.location.href = '/';
      })
      .catch(() => {
        setError(LOGIN_ERROR);
        setLoading(false);
      })
  };

  const onCheckEmailValidity = (e: FocusEvent<HTMLInputElement>) => {
    setIsEmailValid(validateEmail(e.target.value));
  };

  return (
    <>
      {error && <Alert intent="warning" title={error.title} marginBottom={24}>{error.children}</Alert>}
      <div className="login-google-prompt--container">
        <GoogleButton onClick={openGoogle}/>
        <div className="login-google-prompt-alternative--container">
          <div className="login-google-prompt-alternative--line" />
        </div>
      </div>
      <form onSubmit={onSubmitEmail}>
        <label className="login-input--label" htmlFor="email">{_(M.EMAIL)}</label>
        <TextInput className={cx('login-input', { 'login-input--invalid': !isEmailValid })} width="100%" height="40px" type="email" id="email" onBlur={onCheckEmailValidity} isInvalid={!isEmailValid} value={email} onChange={(e: ChangeEvent<HTMLInputElement>) => { setEmail(e.target.value); }} />
        {!isEmailValid && <div className="login-input--error">{_(M.ERROR_INVALID_EMAIL)}</div>}
        <label className="login-input--label" htmlFor="password">{_(M.PW)}</label>
        <TextInput className="login-input" width="100%" height="40px" type="password" id="password" value={password} onChange={(e: ChangeEvent<HTMLInputElement>) => { setPassword(e.target.value); }} />
        <Button appearance="primary" type="submit" className="login-submit-button" onClick={onSubmitEmail} disabled={!email || !password} isLoading={loading}>{_(M.NAV_LOGIN)}</Button>
        <div className="login-card-footer">
          <div>{_(M.ASK_CREATE_ACCOUNT)} <Link className="login-link" to="/auth/signup">{_(M.NAV_SIGNUP)}</Link></div>
          <Link className="login-link forgot-password-link" to="/auth/reset-pw">{_(M.ASK_FORGOT_PW)}</Link>
        </div>
      </form>
    </>
  );
};

export default Login;
