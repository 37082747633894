import { LabelIcon } from 'evergreen-ui';
import { ICadenceEvent } from 'types/cadence';

import _, {M} from 'constants/i18n';


const LabelAppliedEvent = ({event}: {event: ICadenceEvent}) => {
  const firstLabel = (event.event_data.labels_applied as string[])[0] || 'thread';
  return <div>
    <div className="enrolled-event-title--container">
      <LabelIcon className="enrolled-event-title--icon" color="var(--color-pink-5)"/>
      <div className="enrolled-event-title--text">{_(M.CADENCE_EVENT_LABELLED_TITLE)}</div>
    </div>
    <p className="enrolled-event--description">{_(M.CADENCE_EVENT_LABELLED_DESC)} <a href={`https://mail.google.com/mail/?#inbox/${event.event_data.gmail_thread_id as string}`} target="_blank" rel="noreferrer">{firstLabel}</a></p>
  </div>
};

export default LabelAppliedEvent;