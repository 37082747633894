import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {COMMAND_PRIORITY_NORMAL, EditorConfig, TextNode, LexicalNode, NodeKey, SerializedTextNode, Spread, createCommand, DOMExportOutput, DOMConversionMap, DOMConversionOutput, $getSelection, $isRangeSelection, $insertNodes} from 'lexical';


export type SerializedTemplateVariableNode = Spread<
  {
    tvType: 'contactProperty';
    tvLabel: string;
    tvValue: string;
    type: 'templateVariable';
    version: 1;
  },
  SerializedTextNode
>;


function convertTemplateVarElement(
  domNode: HTMLElement
): DOMConversionOutput | null {
  const textContent = domNode.textContent;
  const label = domNode.getAttribute('data-tv-label');
  const value = domNode.getAttribute('data-tv-value');
  // const type = domNode.getAttribute('data-lexical-tv-type');

  if (label !== null && value !== null) {
    const node = $createTemplateVariableNode(label, value, textContent || undefined);
    return {
      node
    };
  }

  return null;
}

export class TemplateVariableNode extends TextNode {
  __tvLabel: string = '';
  __tvValue: string = '';
  __tvType: 'contactProperty' = 'contactProperty';

  constructor(tvLabel: string, tvValue: string, text?: string, key?: NodeKey) {
    super(text ?? tvLabel, key);
    this.__tvLabel = tvLabel;
    this.__tvValue = tvValue;
  }

  static getType(): string {
    return 'templateVariable';
  }

  static clone(node: TemplateVariableNode): TemplateVariableNode {
    return new TemplateVariableNode(node.__tvLabel, node.__tvValue, node.__text, node.__key);
  }

  createDOM(config: EditorConfig): HTMLElement {
    const dom = super.createDOM(config);
    dom.setAttribute('data-tv-label', this.__tvLabel);
    dom.setAttribute('data-tv-value', this.__tvValue);
    dom.setAttribute('data-tv-type', this.__tvType);
    dom.style.cssText = `
      background: var(--color-red-1);
      border-radius: 5px;
      padding: 2px 6px;
      margin: 0;
      font-size: 10px;
      color: var(--color-red-5);
    `;
    return dom;
  }

  static importJSON(serializedNode: SerializedTemplateVariableNode): TemplateVariableNode {
    const node = $createTemplateVariableNode(serializedNode.tvLabel, serializedNode.tvValue, serializedNode.text);
    node.setTextContent(serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  exportJSON(): SerializedTemplateVariableNode {
    return {
      ...super.exportJSON(),
      tvType: 'contactProperty',
      tvLabel: this.__tvLabel,
      tvValue: this.__tvValue,
      type: 'templateVariable',
      version: 1
    };
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('span');
    element.setAttribute('data-tv-label', this.__tvLabel);
    element.setAttribute('data-tv-value', this.__tvValue);
    element.setAttribute('data-tv-type', this.__tvType);
    element.textContent = this.__text;
    return { element };
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute('data-tv-label')) {
          return null;
        }
        return {
          conversion: convertTemplateVarElement,
          priority: 1
        };
      }
    };
  }

  updateDOM(_prevNode: unknown, _dom: HTMLElement, _config: EditorConfig): boolean {
    // TODO
    return false;
  }

  isTextEntity(): true {
    return true;
  }

  isToken(): true {
    return true;
  }

  isSegmented(): false {
    return false;
  }
}

export const $createTemplateVariableNode = (tvLabel: string, tvValue: string, text: string = ''): TemplateVariableNode => {
  return new TemplateVariableNode(tvLabel, tvValue, text);
}

export const $isTemplateVariableNode = (node: LexicalNode | null | undefined): node is TemplateVariableNode => {
  return node?.getType() === TemplateVariableNode.getType();
}

export const INSERT_TEMPLATE_VAR_COMMAND = createCommand('insertTemplateVariable');


export const TemplateVariablePlugin = () => {
  const [editor] = useLexicalComposerContext();
  if (!editor.hasNode(TemplateVariableNode)) {
    throw new Error('[TC] Failed to register TemplateVariableNode but used TemplateVariablePlugin. Import and register TemplateVariableNode.');
  }

  editor.registerCommand(INSERT_TEMPLATE_VAR_COMMAND, (payload: {label: string, value: string}) => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      $insertNodes([$createTemplateVariableNode(payload.label, payload.value, payload.label)]);
    }
    // return true to stop propagation to other command listeners
    return true;
  }, COMMAND_PRIORITY_NORMAL)

  return null;
};