import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { createPortal } from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignCenter, faAlignJustify, faAlignLeft, faAlignRight, faBold, faItalic, faLink, faRedo, faStrikethrough, faUnderline, faUndo } from '@fortawesome/free-solid-svg-icons';
import { ArrayIcon, Dialog, Label, TextInputField } from 'evergreen-ui';
import { $getSelection, $isRangeSelection, CAN_REDO_COMMAND, CAN_UNDO_COMMAND, COMMAND_PRIORITY_LOW, FORMAT_ELEMENT_COMMAND, FORMAT_TEXT_COMMAND, REDO_COMMAND, RangeSelection, TextFormatType, UNDO_COMMAND } from 'lexical';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { mergeRegister } from '@lexical/utils';
import { $isAtNodeEnd } from '@lexical/selection';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import cx from 'classnames';
import CreatableSelect from 'react-select/creatable';

import { useAppSelector } from 'hooks';
import _, {M} from 'constants/i18n';
import { getContactPropertyDefinitions } from 'store/user/selector';
import { $isTemplateVariableNode, INSERT_TEMPLATE_VAR_COMMAND } from './templateVar';
import { FloatingLinkEditor } from './link';
import { useCreateContactPropDefMutation } from 'api/contact';


const getSelectedNode = (selection: RangeSelection) => {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }
}


const Divider = () => {
  return <div className="composer-toolbar--divider" />;
}

interface IInsertTemplateVariableDialogProps {
  isShown: boolean,
  onClose: () => void,
  onConfirm: (val: {label: string, value: string}) => void
}

const InsertTemplateVariableDialog = ({isShown, onClose, onConfirm}: IInsertTemplateVariableDialogProps) => {
  const propDefs = useAppSelector(getContactPropertyDefinitions);
  const [createPropDef, result] = useCreateContactPropDefMutation();
  const [selectedProp, setSelectedProp] = useState<typeof options[0] | null>(null);
  const [fallbackText, setFallbackText] = useState('');

  const onSubmit = () => {
    if (!selectedProp) return;

    onConfirm({
      label: `{{ contact.${selectedProp.value} or '${fallbackText}' }}`,
      value: selectedProp.value,
    });
    onClose();
  }

  const options = propDefs.map(propdef => ({
    value: propdef.code_name,
    label: propdef.display_name,
    is_from_hubspot: propdef.meta?.source === 'hubspot',
  }))

  const onCreatePropDef = (propDefLabel: string) => {
    // TODO: allow other property types
    createPropDef({displayName: propDefLabel, propertyType: 'string'}).then((res) => {
      if ('data' in res && res.data !== undefined) {
        const definition = res.data.prop_def;
        const newOption = {
          label: definition.display_name,
          value: definition.code_name,
          is_from_hubspot: false,
        };
        options.push(newOption);
        setSelectedProp(newOption);
      }
    });
  }

  return <Dialog
    isShown={isShown}
    title={_(M.INSERT_TEMPLATE_VAR_TITLE)}
    onCancel={onClose}
    onCloseComplete={onClose}
    cancelLabel={_(M.CANCEL)}
    confirmLabel={_(M.CONFIRM)}
    isConfirmDisabled={!selectedProp}
    onConfirm={onSubmit}
  >
    <div>
      <div>
        <Label>{_(M.INSERT_TEMPLATE_VAR_LABEL)}</Label>
        <CreatableSelect
          options={options}
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, fontSize: '12px', zIndex: 9999 }) }}
          isClearable={false}
          isMulti={false}
          placeholder={_(M.SELECT_PLACEHOLDER)}
          onChange={setSelectedProp}
          isDisabled={result.isLoading}
          isLoading={result.isLoading}
          onCreateOption={onCreatePropDef}
          value={selectedProp}
        />
      </div>
      <div className="composer-insert-tv-fallback--container">
        <TextInputField label={_(M.INSERT_TEMPLATE_VAR_FALLBACK_LABEL)} description={_(M.INSERT_TEMPLATE_VAR_FALLBACK_HELP)} value={fallbackText} onChange={(e: ChangeEvent<HTMLInputElement>) => setFallbackText(e.target.value)}/>
      </div>
    </div>
  </Dialog>
}


const ToolbarPlugin = () => {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const [isTemplateVar, setIsTemplateVar] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [templateVarDialogVisible, setTemplateVarDialogvisible] = useState(false);


  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      setIsStrikethrough(selection.hasFormat('strikethrough'));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }

      if ($isTemplateVariableNode(parent) || $isTemplateVariableNode(node)) {
        setIsTemplateVar(true);
      } else {
        setIsTemplateVar(false);
      }
    }
  }, []);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        COMMAND_PRIORITY_LOW
      )
    );
  }, [editor, updateToolbar]);

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, 'https://');
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  const insetTemplateVar = useCallback((payload: {label: string, value: string}) => {
    editor.dispatchCommand(INSERT_TEMPLATE_VAR_COMMAND, payload)
  }, [editor]);

  const makeDispatchFormat = (formatType: TextFormatType) => () => editor.dispatchCommand(FORMAT_TEXT_COMMAND, formatType)

  return (
    <div className="composer-editor--toolbar" ref={toolbarRef}>
      <InsertTemplateVariableDialog isShown={templateVarDialogVisible} onClose={() => setTemplateVarDialogvisible(false)} onConfirm={insetTemplateVar}/>
      <button
        disabled={!canUndo}
        onClick={() => {
          editor.dispatchCommand(UNDO_COMMAND, undefined);
        }}
        className="composer-editor-toolbar--button"
        aria-label="Undo"
      >
        <FontAwesomeIcon icon={faUndo}/>
      </button>
      <button
        disabled={!canRedo}
        onClick={() => {
          editor.dispatchCommand(REDO_COMMAND, undefined);
        }}
        className="composer-editor-toolbar--button"
        aria-label="Redo"
      >
        <FontAwesomeIcon icon={faRedo}/>
      </button>
      <Divider />
      <button
        onClick={makeDispatchFormat('bold')}
        className={cx('composer-editor-toolbar--button', {active: isBold})}
        aria-label="Format Bold"
      >
        <FontAwesomeIcon icon={faBold}/>
      </button>
      <button
        onClick={makeDispatchFormat('italic')}
        className={cx('composer-editor-toolbar--button', {active: isItalic})}
        aria-label="Format Italics"
      >
        <FontAwesomeIcon icon={faItalic}/>
      </button>
      <button
        onClick={makeDispatchFormat('underline')}
        className={cx('composer-editor-toolbar--button', {active: isUnderline})}
        aria-label="Format Underline"
      >
        <FontAwesomeIcon icon={faUnderline}/>
      </button>
      <button
        onClick={makeDispatchFormat('strikethrough')}
        className={cx('composer-editor-toolbar--button', {active: isStrikethrough})}
        aria-label="Format Strikethrough"
      >
        <FontAwesomeIcon icon={faStrikethrough}/>
      </button>
      <button
        onClick={insertLink}
        className={cx('composer-editor-toolbar--button', {active: isLink})}
        aria-label="Insert Link"
      >
        <FontAwesomeIcon icon={faLink}/>
      </button>
      {isLink &&
        createPortal(<FloatingLinkEditor editor={editor} />, document.body)}
      <button
        onClick={() => setTemplateVarDialogvisible(true)}
        className={cx('composer-editor-toolbar--button', {active: isTemplateVar})}
        aria-label="Insert Template Variable"
      >
        <ArrayIcon />
      </button>
      <Divider />
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left');
        }}
        className='composer-editor-toolbar--button'
        aria-label="Left Align"
      >
        <FontAwesomeIcon icon={faAlignLeft}/>
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center');
        }}
        className='composer-editor-toolbar--button'
        aria-label="Center Align"
      >
        <FontAwesomeIcon icon={faAlignCenter}/>
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right');
        }}
        className='composer-editor-toolbar--button'
        aria-label="Right Align"
      >
        <FontAwesomeIcon icon={faAlignRight}/>
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify');
        }}
        className='composer-editor-toolbar--button'
        aria-label="Justify Align"
      >
        <FontAwesomeIcon icon={faAlignJustify}/>
      </button>
    </div>
  );
}

export default ToolbarPlugin;