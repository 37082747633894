import { Locale, Messages } from './locales/base';
import EN_US from './locales/en-US';
import FR_FR from './locales/fr-FR';


const browserLocale = window.navigator.language;
const fallbackLocale = 'en-US';


const localeNameToMessages: Record<string, Locale> = {
  'en': EN_US,
  'en-US': EN_US,
  'fr-FR': FR_FR,
  'fr': FR_FR,
}


const getMessage = (messageCode: Messages, locale: string=browserLocale): string => {
  if (!(locale in localeNameToMessages)) {
    console.warn(`[TC.app] ${browserLocale} language not yet supported, defaulting to en-US`);
    locale = fallbackLocale;
  }
  const localeMsgs = localeNameToMessages[locale] || localeNameToMessages[fallbackLocale];

  return localeMsgs[messageCode];
};

export const M = Messages;

export default getMessage;