import { MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  EmptyState,
  IconButton,
  Table,
  EditIcon,
  Pagination,
  Spinner,
  DrawerRightIcon,
  Tooltip,
  Position,
} from 'evergreen-ui';

import CadenceIcon from 'components/icons/cadence';
import _, { M } from 'constants/i18n';
import { usePaginateContactsQuery, usePrefetch } from 'api/contact';
import { IContactWithProperties } from 'types/contact';
import { dateStrToLocaleStr, getContactEmail, getContactName } from 'utils/strings';
import ContactDrawer from 'components/crm/ContactDrawer';
import './style.css';


const ContactRow = ({ contact, onClick }: { contact: IContactWithProperties, onClick: () => void }) => {
  const nav = useNavigate();
  const fetchContact = usePrefetch('fetchContact');

  const goToContact = (e: MouseEvent, edit: boolean = false) => {
    e.stopPropagation();
    onClick();
    const url = edit
      ? `/contacts/${contact.id}/edit`
      : `/contacts/${contact.id}`;
    nav(url);
  };

  return (
    <Table.Row
      onClick={goToContact}
      isSelectable
      onMouseEnter={() => fetchContact(contact.id)}
    >
      <Table.TextCell>{getContactName(contact, [])}</Table.TextCell>
      <Table.TextCell>{getContactEmail(contact)}</Table.TextCell>
      <Table.TextCell>{dateStrToLocaleStr(contact.created_at)}</Table.TextCell>
      <Table.Cell justifyContent='right'>
        <Tooltip content={_(M.PREVIEW)} position={Position.TOP}>
          <IconButton
            appearance='minimal'
            icon={DrawerRightIcon}
            onClick={(e: MouseEvent) => {
              e.stopPropagation()
              onClick()
            }}
          />
        </Tooltip>
        <Tooltip content={_(M.BUTTON_CADENCE_EDIT)} position={Position.TOP}>
          <IconButton
            appearance='minimal'
            icon={EditIcon}
            onClick={(e: MouseEvent) => goToContact(e, true)}
          />
        </Tooltip>
      </Table.Cell>
    </Table.Row>
  );
};


const ContactsList = () => {
  const navigate = useNavigate();
  const [showContactId, setShowContactId] = useState<number | null>(null);
  const limit = 5;
  const [offset, setOffset] = useState(0);
  const prefetch = usePrefetch('paginateContacts');

  const { isLoading, isFetching, isError, data } = usePaginateContactsQuery({
    limit,
    offset,
  });

  const loading = isLoading || isFetching;

  if (loading) {
    return <Spinner delay={100}>{_(M.LOADING)}</Spinner>;
  }

  if (
    !loading &&
    !isError &&
    data?.items.length === 0
  ) {
    return (
      <EmptyState
        title={_(M.EMPTY_CONTACTS_TITLE)}
        description={_(M.EMPTY_CONTACTS_DESC)}
        icon={<CadenceIcon />}
        iconBgColor='var(--color-green-2)'
        primaryCta={
          <Button
            appearance='primary'
            onClick={() => navigate('/settings/accounts')}
          >
            {_(M.YOUR_ACCOUNTS)}
          </Button>
        }
      />
    );
  }

  const onPrevPageClick = () => {
    if (!data) return;

    const currentOffset = (data.pagination.this - 1) * limit;
    if (currentOffset > 0) {
      setOffset(currentOffset - limit);
    }
  };

  const onNextPage = () => {
    if (!data) return;

    const pagination = data.pagination;
    const currentOffset = (pagination.this - 1) * limit;
    if (pagination.this < pagination.last) {
      setOffset(currentOffset + limit);
    }
  };

  const onPageChange = (page: number) => {
    setOffset((page - 1) * limit);
  };

  const onPrefetch = (innerText: string) => {
    try {
      const newPage = parseInt(innerText);
      const newOffset = (newPage - 1) * limit;
      prefetch({limit, offset: newOffset});
    } catch (err) {
      return;
    }
  }

  return (
    <div className='contact-list--container'>
      <h2>{_(M.NAV_CONTACTS)}</h2>
      <Table>
        <Table.Head>
          <Table.TextHeaderCell>{_(M.NAME)}</Table.TextHeaderCell>
          <Table.TextHeaderCell>{_(M.EMAIL)}</Table.TextHeaderCell>
          <Table.TextHeaderCell>{_(M.CREATED_AT)}</Table.TextHeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Head>
        <Table.Body>
          {data?.items.map((contact) => (
            <ContactRow contact={contact} key={contact.id} onClick={() => setShowContactId(contact.id)} />
          ))}
        </Table.Body>
      </Table>
      {data && (
        <Pagination
          totalPages={data.pagination.last}
          page={data.pagination.this}
          onNextPage={onNextPage}
          onPreviousPage={onPrevPageClick}
          onPageChange={onPageChange}
          // @ts-ignore
          onMouseEnter={({target}: MouseEvent<HTMLButtonElement>) => onPrefetch(target.innerText)}
        />
      )}
      <ContactDrawer isShown={!!showContactId} contactId={showContactId} onClose={() => setShowContactId(null)}/>
    </div>
  );
};

export default ContactsList;
