import { useFetchContactQuery } from 'api/contact';
import { Spinner, Tab, Tablist } from 'evergreen-ui';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import _, {M} from 'constants/i18n';
import { getContactName } from 'utils/strings';
import { useAppSelector } from 'hooks';
import { userHasGate } from 'store/user/selector';
import { FeatureGateNames } from 'constants/gates';

import './style.css';


const ContactOutlet = () => {
  const { contactId='' } = useParams<{contactId: string}>();
  const hasEngMgrFeatures = useAppSelector(s => userHasGate(s, FeatureGateNames.MGR_INSIGHTS));
  const nav = useNavigate();
  const {pathname} = useLocation();

  const {
    isFetching,
    isLoading,
    isError: error,
    data,
  } = useFetchContactQuery(parseInt(contactId));

  const loading = isLoading || isFetching;

  if (loading) {
    return <Spinner delay={100}>{_(M.LOADING)}</Spinner>
  }

  if (!data || error) {
    return <div>{_(M.ERROR)}</div>
  }

  const contact = data.contact;

  return <div className='view-contact--container'>
    <div className="outlet-breadcrumb--container">
      <Link to="/contacts" className="outlet-breadcrumb--link">{_(M.NAV_CONTACTS)}</Link>
      <div className="outlet-breadcrumb--slash">/</div>
      <div className="outlet-breadcrumb--link-active">{getContactName(contact)}</div>
    </div>
    <div className="view-contact--header">
      <div>
        <h2 className="view-contact--title">{getContactName(contact)}</h2>
      </div>
    </div>
    <Tablist marginBottom={16}>
      <Tab
        isSelected={pathname.endsWith(`/contacts/${contactId}`)}
        onSelect={() => nav(`/contacts/${contactId}`)}
      >
        {_(M.CONTACT_SUBNAV_SUMMARY)}
      </Tab>
      {hasEngMgrFeatures && <Tab
        isSelected={pathname.endsWith(`/contacts/${contactId}/metrics`)}
        onSelect={() => nav(`/contacts/${contactId}/metrics`)}
      >
        {_(M.CONTACT_SUBNAV_METRICS)}
      </Tab>}
      <Tab
        isSelected={pathname.endsWith(`/contacts/${contactId}/edit`)}
        onSelect={() => nav(`/contacts/${contactId}/edit`)}
      >
        {_(M.CONTACT_SUBNAV_EDIT)}
      </Tab>
      <Tab
        isSelected={pathname.endsWith(`/contacts/${contactId}/events`)}
        onSelect={() => nav(`/contacts/${contactId}/events`)}
      >
        {_(M.CONTACT_SUBNAV_CADENCE_EVENTS)}
      </Tab>
    </Tablist>

    <div>
      <Outlet />
    </div>
  </div>;

};

export default ContactOutlet;