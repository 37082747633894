import { Navigate, useLocation } from 'react-router-dom';

import { useAppSelector } from 'hooks';
import { getCurrentUser, getIsUserSuspended } from 'store/user/selector';

import Sidebar from './Sidebar';
import SuspendedAccountAlert from './SuspendedAccountAlert';
import InboxesSidebar from './Sidebar/inboxes';


const LoginRequiredRoute = ({ children }: {children: JSX.Element | null}) => {
  const isLoggedIn = useAppSelector(getCurrentUser);
  const isSuspended = useAppSelector(getIsUserSuspended);

  const location = useLocation();

  const nextSearch = btoa(new URLSearchParams(location.search).toString());

  const nextParams = new URLSearchParams({next: location.pathname, search: nextSearch});

  const isInInboxes = location.pathname.startsWith('/inboxes');

  if (!isLoggedIn) {
    return <Navigate to={`/auth/login?${nextParams}`} replace />;
  }

  if (isSuspended && !location.pathname.startsWith('/settings')) {
    return (
      <div className="app--full-height">
        <div className="sidebar-sibling-content--container">
          <div className="app--full-width">
            <SuspendedAccountAlert />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="app--full-height">
      <div className="sidebar-sibling-content--container">
        {!isInInboxes && <Sidebar />}
        {isInInboxes && <InboxesSidebar />}
        <div className="app--full-width">
          {children}
        </div>
      </div>
    </div>
  );
};


export default LoginRequiredRoute;