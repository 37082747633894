import { dissocPath, equals, flatten, omit } from 'ramda';

import { RootState } from 'store';
import { IBranchNodeParams, ICadence, ICadenceDataOptions, ICadenceNode } from 'types/cadence';

const root = (state: RootState) => state.cadence;

export const getEditingCadence = (state: RootState) => root(state).editingCadence;
const getNewEnrollment = (state: RootState) => root(state).newEnrollment;

const getGmailLabelsRoot = (state: RootState) => root(state).gmailLabels;
export const getGmailLabelsNeverLoaded = (state: RootState) => getGmailLabelsRoot(state).data === null;
export const getGmailLabelsLoading = (state: RootState) => getGmailLabelsRoot(state).isFetching;
export const getGmailLabels = (state: RootState) => getGmailLabelsRoot(state).data;


const getEditingCadenceNodes = (state: RootState) => {
  const editingCadence = getEditingCadence(state);

  if (!editingCadence) return [];

  return editingCadence.data.nodes;
};

const getEditingCadenceOptions = (state: RootState) => getEditingCadence(state)?.data.options
export const getEditingCadenceOption = (state: RootState, option: keyof ICadenceDataOptions) => (getEditingCadenceOptions(state) || {})[option];

const getNodeFromEditingCadence = (state: RootState, nodeId: string) => getEditingCadenceNodes(state).find(({id}) => nodeId === id);

export const getNextNodeIdForNode = (state: RootState, nodeId: string) => {
  const node = getNodeFromEditingCadence(state, nodeId);

  return node?.next_node_id || null;
};

const getParentNode = (state: RootState, nodeId: string) => {
  const nodes = getEditingCadenceNodes(state);

  return nodes.find(node => {
    if (node.next_node_id === nodeId) return true;

    if (node.action.type === 'BRANCH') {
      const params: IBranchNodeParams = (node.action.params as IBranchNodeParams);
      return params.conditions.conditions_fail_next_node_id === nodeId;
    } else {
      return false;
    }
  });
};

export const getIsNodeSuccessBranchChild = (state: RootState, nodeId: string) => {
  const parent = getParentNode(state, nodeId);

  if (!parent) return false;

  if (parent.action.type !== 'BRANCH') return false;

  return parent.next_node_id === nodeId;
};

export const getIsNodeFailBranchChild = (state: RootState, nodeId: string) => {
  const parent = getParentNode(state, nodeId);

  if (!parent) return false;

  if (parent.action.type !== 'BRANCH') return false;

  return (parent.action.params as IBranchNodeParams).conditions.conditions_fail_next_node_id === nodeId;
};

const getAncestorNodes = (state: RootState, nodeId: string) => {
  const nodes = getEditingCadenceNodes(state);
  const node = getNodeFromEditingCadence(state, nodeId);

  if (!node) return [];

  const descendentIds = getAllDescendentNodes(node, nodes).map(node => node.id);

  return nodes
    .filter(n => {
      return n.id !== nodeId && !descendentIds.includes(n.id);
    });
};

export const getSendEmailNodeAncestor = (state: RootState, nodeId: string) => {
  const ancestorNodes = getAncestorNodes(state, nodeId);
  const isNewNode = getNodeFromEditingCadence(state, nodeId) === undefined;
  const nodes = getEditingCadenceNodes(state);
  const anyEmailNode = nodes.find(n => n.action.type === 'SEND_EMAIL');
  if (!isNewNode) {
    return ancestorNodes.find(n => n.action.type === 'SEND_EMAIL');
  } else {
    return anyEmailNode;
  }
};


export const editingCadenceHasChanged = (state: RootState, cadence?: ICadence) => {
  const editingCadence = getEditingCadence(state);

  if (!editingCadence || !cadence) return false;

  const omitData = (cad: ICadence) => dissocPath(['data', 'hs_list_for_new_contact_auto_enrollment'], omit(['updated_at'], cad));

  return !equals(omitData(editingCadence), omitData(cadence));
};

export const getChildrenNodeIds = (node: ICadenceNode): string[] => {
  const childIds: string[] = [];

  const actionParams = node.action.params;
  if ('conditions' in actionParams) {
    const branchParams = actionParams as IBranchNodeParams;
    if (branchParams.conditions.conditions_fail_next_node_id) {
      childIds.push(branchParams.conditions.conditions_fail_next_node_id);
    }
  }

  if (node.next_node_id) {
    childIds.push(node.next_node_id);
  }

  return childIds;
};

const getChildrenNodes = (node: ICadenceNode, nodes: ICadenceNode[]): ICadenceNode[] => {
  return (getChildrenNodeIds(node)
    .map(nodeId => nodes.find(({id}) => id === nodeId))
    .filter(node => !!node)
  ) as ICadenceNode[];
};

const getAllDescendentNodes = (node: ICadenceNode, nodes: ICadenceNode[]) => {
  const nodesById = {
    [node.id]: node,
  };

  const notVisitedNodeIds = [node.id];

  const getNode = (nodeId: string) => nodes.find(n => nodeId === n.id);

  while (notVisitedNodeIds.length !== 0) {
    const nextNodeId = notVisitedNodeIds.shift();

    if (!nextNodeId) continue;

    const nextNode = getNode(nextNodeId);

    if (nextNode) {
      nodesById[nextNode.id] = nextNode;
      getChildrenNodes(nextNode, nodes).forEach(n => {
        notVisitedNodeIds.push(n.id);
      });
    }
  }

  return Object.values(nodesById);
};


const isOrphanNode = (node: ICadenceNode, allNodes: ICadenceNode[]) => {
  const allChildIds = flatten(allNodes.map(getChildrenNodeIds));
  return !allChildIds.includes(node.id);
};

export const getOrderedCadenceNodeLists = (state: RootState) => {
  const cadence = getEditingCadence(state);

  if (!cadence) return [];

  const nodeLevels = [];
  let nodesRemaining = cadence.data.nodes;

  const startNode = nodesRemaining.find(({id}) => id === cadence.data.start_node);

  // TODO: handle the case where the start node was deleted
  if (!startNode) return [];

  nodeLevels.push([startNode]);
  nodesRemaining = nodesRemaining
    .filter(({id}) => id !== startNode.id)
    .filter(node => !isOrphanNode(node, cadence.data.nodes));
  const visitedNodes = new Set();

  while(nodesRemaining.length !== 0) {
    const nodesAtPreviousLevel = nodeLevels.at(-1);
    let thisLevelNodes: ICadenceNode[] = [];
    // TODO: fix this
    // eslint-disable-next-line no-loop-func
    nodesAtPreviousLevel?.forEach(node => {
      if (!visitedNodes.has(node.id)) {
        thisLevelNodes = thisLevelNodes.concat(getChildrenNodes(node, nodesRemaining));
        visitedNodes.add(node.id);
      }
    });

    nodeLevels.push(thisLevelNodes);
    const nodeIds = new Set(thisLevelNodes.map(({id}) => id));
    nodesRemaining = nodesRemaining.filter(({id}) => !nodeIds.has(id));
  }

  return nodeLevels;
};


export const getNewEnrollmentStep = (state: RootState) => getNewEnrollment(state).step;
export const getNewEnrollmentMethod = (state: RootState) => getNewEnrollment(state).enrollmentMethod;
export const getNewEnrollmentEstNumContacts = (state: RootState) => getNewEnrollment(state).estimatedNumContacts || 0;
export const getEnrollmentArgs = (state: RootState) => getNewEnrollment(state).enrollmentArguments;
export const getNewEnrollmentScheduledFor = (state: RootState) => {
  const args = getEnrollmentArgs(state);
  if (!!args && 'scheduledFor' in args) {
    return args.scheduledFor;
  } else {
    return null;
  }
};
export const getNewEnrollmentCsvFile = (state: RootState) => {
  const args = getEnrollmentArgs(state);
  if (!!args && 'file' in args) {
    return args.file;
  }
};
export const getCsvColumnMapping = (state: RootState) => getNewEnrollment(state).csvColumnToPropDefIdMap;
export const getIsEnrollmentLoading = (state: RootState) => getNewEnrollment(state).isEnrolling;
export const getEnrollmentResult = (state: RootState) => getNewEnrollment(state).enrollResult;