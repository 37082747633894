import { Link, useLocation } from 'react-router-dom';
import { Alert } from 'evergreen-ui';
import { ComponentType, useEffect, useState } from 'react';

import { getGoogleOAuthURI } from 'api/auth';
import _, { M } from 'constants/i18n';

import './style.css';
import GoogleButton from 'components/shared/GoogleButton';
import { loginViaGoogle } from 'api/user';
import { AppDispatch } from 'store';
import { setToken } from 'utils/auth';
import { API } from 'api';
import { TrackEventNames, tracker } from 'utils/tracking';


const openGoogle = async () => {
  try {
    const {data} = await getGoogleOAuthURI(true);
    window.location.href = data.authorization_url;
  } catch (err) {
    console.warn('Could not get google auth URI', err);
  }
};

interface IProviderConfig {
  name: string,
  icon: ComponentType<{className?: string, onClick?: () => void, width?: number, height?: number}> | typeof GoogleButton,
  connectFn: typeof loginViaGoogle,
  tryAgainFn: (user: {id: number} | null, dispatch: AppDispatch) => void,
  showOnlyIcon?: boolean,
}


const providerConfigs: Record<string, IProviderConfig> = {
  google: {
    name: 'Google',
    icon: GoogleButton,
    connectFn: loginViaGoogle,
    tryAgainFn: openGoogle,
    showOnlyIcon: true,
  },
};


const LOGIN_ERROR = {
  title: 'Login failed. Try again',
  children: <div className="onboarding-alert-children">{_(M.ERROR_BAD_LOGIN)} <Link className="login-link" to="/auth/signup">{_(M.NAV_SIGNUP)}</Link> {_(M.OR)} <Link className="login-link" to="/auth/reset-pw">{_(M.NAV_RESET_PW)}</Link></div>
};


const OauthReturn = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<typeof LOGIN_ERROR | null>(null);
  let provider = new URLSearchParams(location.search).get('provider');

  if (!provider) {
    console.warn('[TC.app.VerifyConnector] "provider" query param not given. Defaulting to Google.');
    provider = 'google';
  } else {
    provider = provider.toLowerCase();
  }

  const providerConfig = providerConfigs[provider];

  useEffect(() => {
    const connect = async () => {
      if (loading) {
        return;
      }
      setLoading(true);
      providerConfig.connectFn()
        .then(({ data }) => {
          setToken(data.token.access_token);
          if (data.is_new_user) {
            tracker.track(TrackEventNames.SU);
            tracker.setSignupVersion();
          }
          API.defaults.headers['Authorization'] = `Bearer ${data.access_token}`;
          window.location.href = '/';
        })
        .catch(() => {
          setError(LOGIN_ERROR);
          setLoading(false);
        });
    };

    if (!loading && !error) {
      connect();
    }

  }, [location.search, loading, providerConfig, error]);

  return (
    <>
      {error && <Alert intent="warning" title={error.title} marginBottom={24}>{error.children}</Alert>}
      {loading && <div>Logging you in...</div>}
      {!loading && error && <div>
        <GoogleButton onClick={openGoogle}/>
      </div>}
    </>
  );
};

export default OauthReturn;
