import { adminQueries } from 'api/queries';
import { IPagination } from 'api/cadence';
import { IConnector } from 'types/user';
import { IInbox, IInboxConnector } from 'types/inbox';


const inboxesPage = {type: 'Inbox' as const, id: 'PAGE'};

interface IListInboxesArgs {
  offset: number;
  limit: number;
  user_id?: string | number;
  namespace?: string;
}


const inboxesQueries = adminQueries.injectEndpoints({
  endpoints: (build) => ({
    paginateInboxes: build.query<{items: IInbox[], pagination: IPagination}, IListInboxesArgs>({
      query: (body) => {
        const qs = new URLSearchParams();
        qs.set('limit', body.limit.toString());
        qs.set('offset', body.offset.toString());
        if (body.user_id) {
          qs.set('user_id', body.user_id.toString());
        }
        if (body.namespace) {
          qs.set('namespace', body.namespace);
        }
        return {
          url: `/inboxes/?${qs}`,
          method: 'GET',
        }
      },

      providesTags: (res) => res ? [inboxesPage, ...res.items.map(c => ({type: 'Connector', id: c.id}))] : [],
    }),

    fetchInbox: build.query<{inbox: IInbox}, string>({
      query: (id: number | string) => `/inboxes/${id}`,

      providesTags: (res) => res ? [{type: 'Inbox', id: res.inbox.id}] : [],
    }),

    createInboxFromConnector: build.mutation<{connector: IConnector, inbox: IInbox, inbox_connector: IInboxConnector, created: boolean}, {connectorId: string | number}>({
      query: ({connectorId}) => {
        return {
          url: `/connectors/${connectorId}/create-inbox`,
          method: 'POST'
        };
      },

      invalidatesTags: (res) => res ? [{type: 'Inbox', id: res.inbox.id}, inboxesPage]: [],
    }),

    deleteInboxForConnector: build.mutation<{deleted_inbox_connectors: number, deleted_inboxes: number}, {connectorId: string | number}>({
      query: ({connectorId}) => {
        return {
          url: `/connectors/${connectorId}/delete-inbox`,
          method: 'POST'
        };
      },

      invalidatesTags: (res) => res ? [inboxesPage]: [],
    }),

    backfillConnversations: build.mutation<{status: string}, {inboxId: string | number}>({
      query: ({inboxId}) => ({
        url: `/inboxes/${inboxId}/backfill-conversations`,
        method: 'POST',
      }),
    }),

    emptyInbox: build.mutation<{status: string}, {inboxId: string | number}>({
      query: ({inboxId}) => ({
        url: `/inboxes/${inboxId}/empty`,
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: false,
});


export const {
  useCreateInboxFromConnectorMutation,
  useDeleteInboxForConnectorMutation,
  useFetchInboxQuery,
  usePaginateInboxesQuery,
  useBackfillConnversationsMutation,
  useEmptyInboxMutation,
} = inboxesQueries;