import { ChangeEvent, useCallback, useState } from 'react';
import { Dialog, FileCard, FileRejection, FileUploader, MimeType, TextInputField, toaster } from 'evergreen-ui';

import _, { M } from 'constants/i18n';
import { Messages } from 'constants/i18n/locales/base';
import { validateEmail } from 'utils/strings';

import './style.css';
import { useCreateContactListMutation } from 'api/contactLists';


const errorReasoToi18n: Record<string, Messages> = {
  no_content: M.CSV_UPLOAD_INVALID_NO_CONTENT,
  email_column_required: M.CSV_UPLOAD_INVALID_NO_EMAIL_COL,
  invalid_rows: M.CSV_UPLOAD_INVALID_BAD_ROWS,
}

type CsvValidationResult = {valid: boolean, reason?: string, invalidRowNumbers?: number[]};


const validateCSVFiieContent = (fileContent: string): CsvValidationResult => {
  const carrotReturnSplitLines = fileContent.split('\r\n');
  const lines = carrotReturnSplitLines.length === 1 ?
    fileContent.split('\n')
    :
    carrotReturnSplitLines;

  if (lines.length < 2) {
    return {valid: false, reason: 'no_content'};
  }

  const columns = lines[0].split(',').map(str => str.toLowerCase().replaceAll(' ', '').trim());

  if (!columns.includes('email')) {
    return {valid: false, reason: 'email_column_required'};
  }

  const rows: Record<string, string>[] = [];

  lines.slice(1).forEach(line => {
    if (!line) {
      return;
    }
    const row: Record<string, string> = {};
    line.split(',').forEach((value, idx) => {
      const column = columns[idx]
      row[column] = value;
      if (column === 'email' && !validateEmail(value)) {
        row._error = 'invalid_email';
      }
    });

    rows.push(row);
  });

  const invalidRowNumbers: number[] = rows.reduce((soFar, nextUp, idx) => {
    if (nextUp.hasOwnProperty('_error')) {
      soFar.push(idx + 2); // +1 to account for the header row and +1 to account for 1-index count for spreadsheets
    }
    return soFar;
  }, [] as number[]);

  if (invalidRowNumbers.length !== 0) {
    return {valid: false, reason: 'invalid_rows', invalidRowNumbers};
  }

  return {valid: true};
}


const CreateListDialog = ({
  isShown,
  onClose,
}: {isShown?: boolean, onClose: () => void}) => {
  const [listName, setListName] = useState('');
  const [listDescription, setListDescription] = useState('');
  const [files, setFiles] = useState<File[]>([])
  const [csvValidationResult, setCSVValidationResult] = useState<CsvValidationResult | null>(null);
  const [validatingCsv, setValidatingCsv] = useState(false);
  const [fileRejections, setFileRejections] = useState<FileRejection[]>([])
  const [create, createResult] = useCreateContactListMutation();
  const handleFileChange = useCallback((files: File[]) => {
    const file = files[0];
    setFiles([file]);
    setValidatingCsv(true);
    file.text()
      .then(validateCSVFiieContent)
      .then((result) => {
        setCSVValidationResult(result);
        setValidatingCsv(false);
      })
      .catch((e) => {
        console.warn(`[TC.app] failed to validate CSV: ${e}`);
        setValidatingCsv(false);
      })
  }, []);
  const handleRejected = useCallback((fileRejections: FileRejection[]) => setFileRejections([fileRejections[0]]), [])
  const handleRemove = useCallback(() => {
    setFiles([]);
    setFileRejections([]);
    setCSVValidationResult(null);
  }, [])

  const onSubmit = async () => {
    await create({name: listName, description: listDescription, file: files[0]})
      .then((res) => {
        if ('error' in res) {
          toaster.danger(_(M.ERROR));
        } else {
          toaster.success(_(M.SUCCESS));
          onClose();
          clearState();
        }
      });
  };

  const clearState = () => {
    setFiles([]);
    setFileRejections([]);
    setCSVValidationResult(null);
    setListDescription('');
    setListName('');
  }

  const loading = createResult.isLoading || validatingCsv;

  const confirmDisabled = (
    (!csvValidationResult ||
    !csvValidationResult.valid)
    &&
    !listName
  );

  return <Dialog isShown={isShown} isConfirmLoading={loading} isConfirmDisabled={confirmDisabled} onConfirm={onSubmit} title={_(M.NEW_CONTACT_LIST)} onCloseComplete={onClose} confirmLabel={_(M.CONFIRM)} cancelLabel={_(M.CANCEL)}>
    <TextInputField value={listName} onChange={(e: ChangeEvent<HTMLInputElement>) => setListName(e.target.value)} label={_(M.NAME)} required/>
    <TextInputField value={listDescription} onChange={(e: ChangeEvent<HTMLInputElement>) => setListDescription(e.target.value)} label={_(M.DESCRIPTION)}/>
    <FileUploader
      label={_(M.CSV_UPLOAD_HEADER)}
      description={_(M.CSV_UPLOAD_DESCRIPTION)}
      maxSizeInBytes={10 * 1024 ** 2}
      maxFiles={1}
      onChange={handleFileChange}
      onRejected={handleRejected}
      acceptedMimeTypes={[MimeType.csv]}
      renderFile={(file) => {
        const { name, size, type } = file
        const fileRejection = fileRejections.find((fileRejection) => fileRejection.file === file)
        const { message } = fileRejection || {}
        return (
          <FileCard
            key={name}
            isInvalid={fileRejection != null}
            name={name}
            onRemove={handleRemove}
            sizeInBytes={size}
            type={type}
            validationMessage={message}
          />
        )
      }}
      values={files}
    />
    {csvValidationResult && !csvValidationResult.valid && <div className="enroll-contact-csv--error">{_(errorReasoToi18n[csvValidationResult.reason || ''] || M.CSV_UPLOAD_INVALID)} {csvValidationResult.invalidRowNumbers?.join(', ')}</div>}
  </Dialog>;
};

export default CreateListDialog;