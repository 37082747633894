import { Card, EnvelopeIcon, GitBranchIcon, IconComponent, PhoneIcon, SideSheet, Spinner } from 'evergreen-ui';
import { indexBy, prop } from 'ramda';

import { useFetchContactQuery } from 'api/contact';
import _, { M } from 'constants/i18n';
import { getContactName } from 'utils/strings';
import { useAppSelector } from 'hooks';
import { getContactPropertyDefinitions } from 'store/user/selector';
import { IContactHandle, IContactProperty } from 'types/contact';
import { IContactPropertyDefinition } from 'types/user';

import './style.css';
import { Link } from 'react-router-dom';


const handleSourceToIcon: Record<string, IconComponent> = {
  email: EnvelopeIcon,
  phone: PhoneIcon,
  github: GitBranchIcon,
}


export const ContactHandle = ({handle}: {handle: IContactHandle}) => {
  const Icon = handleSourceToIcon[handle.source];

  return <div className="contact-drawer-handle--container">
    {Icon ? <Icon className="contact-drawer-handle--icon" /> : `${handle.source}:`} {handle.handle}
  </div>
};


export const ContactProperty = ({property, definition}: {property: IContactProperty, definition: IContactPropertyDefinition, }) => {
  if (!definition) return null;

  return <div className="contact-drawer-property--container">
    <div className="contact-drawer-prop-def--container">
      <div className="contact-drawer-prop--name">{definition.display_name}</div>
      <div className="contact-drawer-prop--code">{definition.code_name}</div>
    </div>
    <div className="contact-drawer-prop--value">{property.raw_value}</div>
  </div>
}


const ContactDrawer = ({
  contactId,
  isShown,
  onClose,
}: {contactId: number | null | undefined, isShown: boolean, onClose?: () => void}) => {
  const {
    isLoading,
    isFetching,
    data,
  } = useFetchContactQuery(contactId as number, {skip: !contactId})

  const propDefs = useAppSelector(getContactPropertyDefinitions);

  const propDefsById = indexBy(prop('id'), propDefs);

  return <SideSheet isShown={isShown} onCloseComplete={onClose}>
    {(isFetching || isLoading) && <div>{_(M.LOADING)} <Spinner /></div>}
    {data && <div className="contact-drawer--container">
      <div className="contact-drawer-header--container">
        <h2 className="contact-drawer-header--title">{_(M.CONTACT_DRAWER_TITLE)}</h2>
        <Link className="contact-drawer-header--link" to={`/contacts/${contactId}`}>{_(M.CONTACT_DRAWER_LINK)}</Link>
      </div>
      <div className="contact-drawer-content--contaier">
        <Card background='white' padding="8px" marginBottom="8px">
          <div>{getContactName(data.contact)}</div>
        </Card>
        <Card background='white' padding="8px" marginBottom="8px">
          <h3 className="contact-drawer-section--header">{_(M.CONTACT_DRAWER_HANDLES_TITLE)}</h3>
          <div>
            {data.contact.handles.map(handle => <ContactHandle key={`${handle.source}-${handle.handle}`} handle={handle}/>)}
          </div>
        </Card>
        <Card background='white' padding="8px" marginBottom="8px">
          <h3 className="contact-drawer-section--header">{_(M.CONTACT_DRAWER_PROPERTIES_TITLE)}</h3>
          <div>{data.contact.properties.map(prop => (<ContactProperty key={prop.id} property={prop} definition={propDefsById[prop.prop_def_id]}/>))}</div>
        </Card>
      </div>
    </div>}
  </SideSheet>
}

export default ContactDrawer;