import { EmptyState, EnvelopeIcon } from 'evergreen-ui';


const SelectAConversation = () => {
  // TODO: i18n
  return <EmptyState
    title="Select a conversation"
    icon={<EnvelopeIcon />}
    iconBgColor="var(--color-blue-2-2)"
    description="Select a conversation to view your messages"
  />;

};

export default SelectAConversation;