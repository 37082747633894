import { ComponentType, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Spinner, toaster } from 'evergreen-ui';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError, QueryStatus } from '@reduxjs/toolkit/query';

import { AppDispatch } from 'store';
import GoogleButton from 'components/shared/GoogleButton';
import { getGoogleOAuthURI } from 'api/auth';
import { APP_URL } from 'constants/resources';
import { receiveConnectors } from 'store/user/slice';
import { useConnectGoogleMutation, useConnectHubspotMutation } from 'api/connectors';
import { getCurrentUser } from 'store/user/selector';
import { useAppDispatch, useAppSelector } from 'hooks';
import _, { M } from 'constants/i18n';
import { getHubSpotOauthUrl } from 'constants/app';
import HubspotIcon from 'components/icons/hubspot';
import ConnectorSettingsDialog from 'components/settings/Connector/settings';
import { IConnector } from 'types/user';
import { connectorHasSettings } from 'utils/data';

import './style.css';


const openGoogle = async (user: {id: number} | null) => {
  if (!user) {
    toaster.warning(_(M.SIGNIN_REQUIRED));
    return;
  }

  try {
    const {data} = await getGoogleOAuthURI();
    window.location.href = data.authorization_url;
  } catch (err) {
    console.warn('Could not get google auth URI', err);
  }
};

interface IProviderConfig {
  name: string,
  icon: ComponentType<{className?: string, onClick?: () => void, width?: number, height?: number}> | typeof GoogleButton,
  connectFn: typeof useConnectGoogleMutation,
  tryAgainFn: (user: {id: number} | null, dispatch: AppDispatch) => void,
  showOnlyIcon?: boolean,
}


const providerConfigs: Record<string, IProviderConfig> = {
  google: {
    name: 'Google',
    icon: GoogleButton,
    connectFn: useConnectGoogleMutation,
    tryAgainFn: openGoogle,
    showOnlyIcon: true,
  },
  hubspot: {
    name: 'HubSpot',
    icon: HubspotIcon,
    connectFn: useConnectHubspotMutation,
    tryAgainFn: () => window.location.href = getHubSpotOauthUrl(),
    showOnlyIcon: false,
  }
};

const stateFromParams = (params: URLSearchParams): {userId?: string} => {
  try {
    return JSON.parse(atob(params.get('state') || '{}'));
  } catch (err) {
    return {};
  }
};


const ConnectSuccessView = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  let provider = new URLSearchParams(location.search).get('provider');
  const user = useAppSelector(getCurrentUser);
  const [connector, setConnector] = useState<IConnector | null>(null);
  const [settingsIsShown, setSettingsIsShown] = useState(false);

  if (!provider) {
    console.warn('[TC.app.VerifyConnector] "provider" query param not given. Defaulting to Google.');
    provider = 'google';
  } else {
    provider = provider.toLowerCase();
  }

  const providerConfig = providerConfigs[provider];
  const providerName = providerConfig.name;
  const [create, result] = providerConfig.connectFn({fixedCacheKey: location.search});


  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const connect = async (payload: {code: string | null, user_id?: string, redirect_uri: string}) => {
      try {
        const res = await create({code: payload.code}).unwrap();
        dispatch(receiveConnectors(res));
        setConnector(res[0]);
        if (connectorHasSettings(res[0].provider)) {
          setTimeout(() => {
            setSettingsIsShown(true);
          }, 500);
        }
        toaster.success(_(M.GENERIC_CONNECTION_SUCCESS));
      } catch (err) {
        const status = ('data' in (err as FetchBaseQueryError | SerializedError)) ? (err as FetchBaseQueryError).status : -1;
        const errDetail = ('data' in (err as FetchBaseQueryError | SerializedError)) ? ((err as FetchBaseQueryError).data as undefined | {detail: string})?.detail : null;

        // user did not grant us access to everything we need from gmail/google calendar
        const isWrongGmailScopesErr = errDetail === 'WRONG_GMAIL_SCOPES';
        const isWrongGdriveScopesErr = errDetail === 'WRONG_GDRIVE_SCOPES';
        const isWrongGcalScopesErr = errDetail === 'WRONG_GCAL_SCOPES';
        const isNoScopesGrantedErr = errDetail === 'NO_GOOGLE_SCOPES';
        const isPartialScopesError = errDetail === 'PARTIAL_GOOGLE_SCOPES';

        let message = M.GENERIC_CONNECTION_FAILED;

        if (isPartialScopesError) {
          message = M.CONN_FAILED_PARTIAL_GOOGLE_SCOPES;
          // partial scopes means one or more new connectors
          // were created
          // TODO: re-enable later
          // fetchAllConnectors()
          //   .then(({ data }) => dispatch(receiveConnectors(data)))
          //   .catch(console.warn);
        } else if (isWrongGcalScopesErr) {
          message = M.CONN_FAILED_GCAL_SCOPES;
        } else if (isNoScopesGrantedErr) {
          message = M.CONN_FAILED_NO_GOOGLE_SCOPES;
        } else if (isWrongGdriveScopesErr) {
          message = M.CONN_FAILED_GDRIVE_SCOPES;
        } else if (isWrongGmailScopesErr) {
          message = M.CONN_FAILED_GMAIL_SCOPES;
        } else if (typeof(status) === 'number' && status >= 400 && status < 500) {
          message = M.CONN_FAILED_PROVIDER;
        } else if (typeof(status) === 'number' && status >= 500) {
          message = M.CONN_FAILED_500;
        }

        toaster.danger(_(message), {duration: 30});
      }
    };

    if (result.status === QueryStatus.uninitialized) {
      const state = stateFromParams(params);
      connect({
        code: params.get('code'),
        user_id: state.userId,
        redirect_uri: `${APP_URL}/connect-success?provider=${params.get('provider')}`
      });
    }

  }, [create, result.status, providerName, location.search, dispatch]);

  const loading = [QueryStatus.pending, QueryStatus.uninitialized].includes(result.status);
  const Icon = providerConfig.icon;
  const tryAgain = () => providerConfig.tryAgainFn(user, dispatch);

  return (
    <div className='connect-success--container sidebar-sibling-content--container'>
      <div>
        <h3 className='connect-success--headline'>{_(M.CONN_AUTH_TITLE)}</h3>
        <div className='connect-success--description'>{_(M.CONN_AUTH_DESC)}</div>
        <div>
          {loading && <div className='connect-success--loading'>{_(M.CONN_AUTH_LOADING)}<Spinner /></div>}
          {result.isError && <div className='connect-success--failed'>{_(M.CONN_AUTH_FAILED)}</div>}
          {result.isError && !providerConfig.showOnlyIcon && <Button className='connect-success--btn' onClick={tryAgain}>
            <Icon className='connect-success--icon' />
            {_(M.CONN_AUTH_TRY_AGAIN)}
          </Button>}
          {result.isError && providerConfig.showOnlyIcon && <Icon onClick={tryAgain} />}
          {result.isSuccess && <div className='connect-success--success'>{_(M.CONN_AUTH_SUCCESS)}</div>}
        </div>
      </div>
      {connector && connectorHasSettings(connector.provider) &&
        <ConnectorSettingsDialog connector={connector} isShown={settingsIsShown} onClose={() => setSettingsIsShown(false)}/>
      }
    </div>
  );
};


export default ConnectSuccessView;