import { NavLink } from 'react-router-dom';

import './style.css';

const CRMSidebar = () => {
  return <div className="crm-sidebar-list--container">
    <NavLink to="/contacts" className="crm-sidebar-nav--link">Contacts</NavLink>
    <NavLink to="/contact-lists" className="crm-sidebar-nav--link">Lists</NavLink>
  </div>;

};

export default CRMSidebar;