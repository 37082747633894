import { usePaginateTasksQuery } from 'api/admin/tasks';
import { Pagination, Tab, Table, Tablist } from 'evergreen-ui';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IScheduledTask } from 'types/user';
import { dateStrToLocaleStr } from 'utils/strings';

const limit = 10;

const TaskRow = ({task}: {task: IScheduledTask}) => {
  const nav = useNavigate();

  const goToTask = () => {
    nav(`/admin/tasks/${task.id}`);
  }

  return <Table.Row
    onClick={goToTask}
    isSelectable
  >
    <Table.TextCell>{task.name}</Table.TextCell>
    <Table.TextCell>{dateStrToLocaleStr(task.scheduled_for)}</Table.TextCell>
    <Table.TextCell>{task.ttl ? dateStrToLocaleStr(task.ttl) : <em>none</em>}</Table.TextCell>
    <Table.TextCell>{task.updated_at ? dateStrToLocaleStr(task.updated_at) : <em>none</em>}</Table.TextCell>
  </Table.Row>
}


const TasksTable = () => {
  const [offset, setOffset] = useState(0);
  const [status, setStatus] = useState('not_started');
  const {
    data,
  } = usePaginateTasksQuery({limit: 10, offset, status});

  const onPrevPageClick = () => {
    if (!data) return;

    const currentOffset = (data.pagination.this - 1) * limit;
    if (currentOffset > 0) {
      setOffset(currentOffset - limit);
    }
  };

  const onNextPage = () => {
    if (!data) return;

    const pagination = data.pagination;
    const currentOffset = (pagination.this - 1) * limit;
    if (pagination.this < pagination.last) {
      setOffset(currentOffset + limit);
    }
  };

  const onPageChange = (page: number) => {
    setOffset((page - 1) * limit);
  };


  return <div>
    <Tablist marginBottom={16}>
      <Tab
        isSelected={status === 'not_started'}
        key={'not_started'}
        onSelect={() => setStatus('not_started')}
      >
        Scheduled
      </Tab>
      <Tab
        isSelected={status === 'in_progress'}
        key={'in_progress'}
        onSelect={() => setStatus('in_progress')}
      >
        In progress
      </Tab>
      <Tab
        isSelected={status === 'done'}
        key={'done'}
        onSelect={() => setStatus('done')}
      >
        Completed
      </Tab>
      <Tab
        isSelected={status === 'error'}
        key={'error'}
        onSelect={() => setStatus('error')}
      >
        Failed
      </Tab>
    </Tablist>
    <Table>
      <Table.Head>
        <Table.TextHeaderCell>Name</Table.TextHeaderCell>
        <Table.TextHeaderCell>Scheduled for</Table.TextHeaderCell>
        <Table.TextHeaderCell>TTL</Table.TextHeaderCell>
        <Table.TextHeaderCell>Updated at</Table.TextHeaderCell>
      </Table.Head>
      <Table.Body>
        {data?.items.map((task) => <TaskRow task={task} key={task.id} />)}
      </Table.Body>
    </Table>
    {data && (
      <Pagination
        totalPages={data.pagination.last}
        page={data.pagination.this}
        onNextPage={onNextPage}
        onPreviousPage={onPrevPageClick}
        onPageChange={onPageChange}
      />
    )}
  </div>
}


const AdminTasksPage = () => {

  return <div className="admin-page-content--container">
    <h2>All tasks</h2>
    <div>
      <TasksTable />
    </div>
  </div>
}


export default AdminTasksPage;
