import { faEllipsis, faReply, faReplyAll } from '@fortawesome/free-solid-svg-icons';
import { useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, CollapseAllIcon, ExpandAllIcon, IconButton, Menu, Popover } from 'evergreen-ui';

import { useCreateConversationDraftMutation } from 'api/v2/conversations';
import { useAppDispatch, useAppSelector } from 'hooks';
import { receiveMessages } from 'store/inbox/slice';
import { getCurrentUser } from 'store/user/selector';
import { EmailMessage } from 'types/conversation';
import { dateStrToLocaleStr, makeEmailName } from 'utils/strings';


const MessageHeader = ({message, isCollapsed, onToggleCollapse}: {message: EmailMessage, isCollapsed?: boolean, onToggleCollapse?: () => void}) => {
  const ctx = message.context;
  const user = useAppSelector(getCurrentUser);
  const [createDraft, createDraftRes] = useCreateConversationDraftMutation();
  const dispatch = useAppDispatch();
  const [isExpanded, setIsExpanded] = useState(false);
  const {name, email} = useMemo(() => {
    return message.email_addrs?.from_.at(0) as {name: string, email: string};
  }, [message]);

  const onClickReply = async (replyAll = false) => {
    if (createDraftRes.isLoading) return;

    if (email === '') return;

    const dtStr = new Date(message.created_at).toLocaleString('default', {weekday: 'short', day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric'});
    const replyLineText = `On ${dtStr} ${name === '' ? email : name} wrote:`;
    const allRecipientEmails = message.email_addrs?.to.concat(message.email_addrs.cc) || [];
    // this doesn't work when the current user's email is not the recipient
    const nonMeRecipients = allRecipientEmails.filter(e => e.email !== user?.email);
    const cc = replyAll ? nonMeRecipients.map(makeEmailName).join(',') : null
    // TODO: optimistically create a draft and allow the user to save it locally, offline
    try {
      const subject = message.context.subject;
      const draft = await createDraft({
        conversationId: message.conversation_id,
        body_text: `\n\n${replyLineText}\n${message.body_text}`,
        body_html: `<br /><br /><p>${replyLineText}</p><br /><blockquote>${message.body_html}</blockquote>`,
        meta: {},
        context: {
          to: email,
          cc,
          subject: subject.toLowerCase().startsWith('re: ') ? subject : `Re: ${subject}`,
          from_: user?.full_name ? `${user?.full_name} <${user?.email}>` : `<${user?.email}>`,
          references: message.context.message_id,
        }
      }).unwrap();
      dispatch(receiveMessages({msgs: [draft.message]}));
    } catch (err) {
      // TODO: alert user
      console.warn(err);
    }
  };

  return <div className="message-header--container">
    <div className="message-header-from--container">
      <div><strong>{name}</strong> ({email})</div>
      {isExpanded && <div>
        <div className="message-header-to--container">To: {message.context.to}</div>
        <div className="message-header-to--container">Cc: {message.context.cc}</div>
      </div>}
      <div>{ctx.subject}</div>
    </div>
    <div className="message-header-right-side--container">
      <div className="message-header-actions--container">
        <Button width={24} padding={0} height={24} onClick={() => onClickReply()} disabled={createDraftRes.isLoading}><FontAwesomeIcon size="sm" icon={faReply} /></Button>
        <Button width={24} padding={0} height={24} onClick={() => onClickReply(true)} disabled={createDraftRes.isLoading}><FontAwesomeIcon size="sm" icon={faReplyAll} /></Button>
        {onToggleCollapse && <IconButton height={24} icon={isCollapsed ? ExpandAllIcon : CollapseAllIcon} onClick={onToggleCollapse} />}
        <Popover content={
          <Menu>
            <Menu.Group>
              <Menu.Item onSelect={() => setIsExpanded(!isExpanded)}>Toggle details</Menu.Item>
            </Menu.Group>
            <Menu.Divider />
            <Menu.Group>
              <Menu.Item onSelect={() => console.log('Delete')} intent="danger">
                Delete...
              </Menu.Item>
            </Menu.Group>
          </Menu>
        }>
          <Button width={24} padding={0} height={24}><FontAwesomeIcon size="sm" icon={faEllipsis} /></Button>
        </Popover>
      </div>
      <div className="message-header--date">{dateStrToLocaleStr(message.created_at)}</div>
    </div>
  </div>
};

export default MessageHeader;