import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Button, toaster } from 'evergreen-ui';

import { useFetchJobQuery, usePauseJobMutation, useRestartJobMutation } from 'api/admin/worker_jobs';
import './style.css';



const AdminWorkerJobPage = () => {
  const {jobId = ''} = useParams<{jobId: string}>()
  const [pause, pauseRes] = usePauseJobMutation();
  const [restart, restartRes] = useRestartJobMutation();

  const {
    data,
    isLoading,
    isFetching,
    isError,
  } = useFetchJobQuery(jobId);

  useEffect(() => {
    if (pauseRes.isSuccess) {
      toaster.success('Paused job');
    } else if (pauseRes.isError) {
      toaster.danger('Failed to pause job');
    }
  }, [pauseRes]);

  useEffect(() => {
    if (restartRes.isSuccess) {
      toaster.success('Restarted job');
    } else if (restartRes.isError) {
      toaster.danger('Failed to restart job');
    }
  }, [restartRes]);

  if (!data || isLoading || isFetching) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error</div>
  }

  const job = data.job;

  const canRestart = job.status === 'paused' || job.status === 'error';

  return <div className="admin-page-content--container">
    <h2>{job.name}</h2>
    <div className="admin-page-actions--container">
      {canRestart && <Button intent='success' isLoading={restartRes.isLoading} onClick={() => restart(jobId)}>Restart job</Button>}
      {job.status === 'in_progress' && <Button intent='danger' isLoading={pauseRes.isLoading} onClick={() => pause(jobId)}>Pause job</Button>}
    </div>
    <div>
      <h3>Worker Job data</h3>
      <pre>{JSON.stringify(job.data, undefined, 2)}</pre>
      <h3>Worker Job meta</h3>
      <pre>{JSON.stringify(job.meta, undefined, 2)}</pre>
    </div>
  </div>
}

export default AdminWorkerJobPage;