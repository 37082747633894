import { PayloadAction, createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { fetchConversation, fetchConversationMessage, patchConversation } from 'api/v2/conversations';
import { isAxiosError } from 'axios';
import { mergeDeepRight } from 'ramda';
import { AppDispatch, RootState } from 'store';

import { AnyMessage, IConversation } from 'types/conversation';
import { IConversationInboxUpdate, IInbox, INewMessageInboxUpdate } from 'types/inbox';

export interface InboxState {
  messagesById: Record<number, AnyMessage>,
  conversationsById: Record<number, IConversation>,
  inboxesById: Record<number, IInbox>,
  msgIdsByConversationId: Record<number, number[]>,
  convIdsByInboxId: Record<number, number[]>,
  optimisticConvRollbackById: Record<number, IConversation>,
}

const initialState: InboxState = {
  messagesById: {},
  conversationsById: {},
  inboxesById: {},
  msgIdsByConversationId: {},
  convIdsByInboxId: {},
  optimisticConvRollbackById: {},
};

export const wsReceiveNewMessage = createAsyncThunk<{message: AnyMessage, conversation: IConversation}, INewMessageInboxUpdate, {
  dispatch: AppDispatch,
  state: RootState,
}>(
  'inbox/wsRefreshFromNewMsg',
  async ({message_id, conversation_id}) => {
    const [
      res,
      convRes
    ] = await Promise.all([
      fetchConversationMessage(conversation_id, message_id),
      fetchConversation(conversation_id),
    ])
    return {message: res.data.message, conversation: convRes.data.conversation};
  },
);

export const wsReceiveConvUpdate = createAsyncThunk<{conversation: IConversation}, IConversationInboxUpdate, {
  dispatch: AppDispatch,
  state: RootState,
}>(
  'inbox/wsRefreshFromConvUpdate',
  async ({conversation_id}) => {
    const convRes = await fetchConversation(conversation_id);
    return {conversation: convRes.data.conversation};
  },
);

export const optimisticallyUpdateConversation = createAsyncThunk<{conversation: IConversation}, {conversationId: number | string, payload: Partial<IConversation>}, {
  dispatch: AppDispatch,
  state: RootState,
}>(
  'inbox/optimisticallyUpdateConversation',
  async ({conversationId, payload}) => {
    const convRes = await patchConversation(conversationId, payload);
    if (isAxiosError(convRes)) {
      throw convRes;
    }
    return convRes.data;
  },
);

export const inboxSlice = createSlice({
  name: 'inbox',
  initialState,
  reducers: {
    receiveMessages: (state, action: PayloadAction<{msgs: AnyMessage[]}>) => {
      action.payload.msgs.forEach(msg => {
        state.messagesById[msg.id] = msg;
        const convId = msg.conversation_id;
        if (!(convId in state.msgIdsByConversationId)) {
          state.msgIdsByConversationId[convId] = [msg.id];
        } else if (!state.msgIdsByConversationId[convId].includes(msg.id)) {
          state.msgIdsByConversationId[convId].push(msg.id);
        }
      })
    },

    receiveConversations: (state, action: PayloadAction<{convs: IConversation[]}>) => {
      action.payload.convs.forEach(conv => {
        state.conversationsById[conv.id] = conv;
        const inboxId = conv.inbox_id;
        if (!(inboxId in state.convIdsByInboxId)) {
          state.convIdsByInboxId[inboxId] = [conv.id];
        } else if (!state.convIdsByInboxId[inboxId].includes(conv.id)) {
          state.convIdsByInboxId[inboxId].push(conv.id);
        }
      })
    },

    markConvAsRead: (state, action: PayloadAction<{convId: number, readAt?: string}>) => {
      const conv = state.conversationsById[action.payload.convId];
      if (conv) {
        conv.last_read = action.payload.readAt || new Date().toISOString();
      }
    },

    receiveInboxes: (state, action: PayloadAction<{inboxes: IInbox[]}>) => {
      action.payload.inboxes.forEach(inbox => {
        state.inboxesById[inbox.id] = inbox;
      })
    },
  },

  extraReducers(builder) {
    builder.addCase(wsReceiveNewMessage.fulfilled, (state, action) => {
      const {
        message,
        conversation,
      } = action.payload;
      state.messagesById[message.id] = message;
      const convId = message.conversation_id;
      if (!(convId in state.msgIdsByConversationId)) {
        state.msgIdsByConversationId[convId] = [message.id];
      } else if (!state.msgIdsByConversationId[convId].includes(message.id)) {
        state.msgIdsByConversationId[convId].push(message.id);
      }
      state.conversationsById[conversation.id] = conversation;
      const inboxId = conversation.inbox_id;
      if (!(inboxId in state.convIdsByInboxId)) {
        state.convIdsByInboxId[inboxId] = [conversation.id];
      } else if (!state.convIdsByInboxId[inboxId].includes(conversation.id)) {
        state.convIdsByInboxId[inboxId].push(conversation.id);
      }
    });

    builder.addCase(wsReceiveConvUpdate.fulfilled, (state, action) => {
      const {
        conversation,
      } = action.payload;
      state.conversationsById[conversation.id] = conversation;
      const inboxId = conversation.inbox_id;
      if (!(inboxId in state.convIdsByInboxId)) {
        state.convIdsByInboxId[inboxId] = [conversation.id];
      } else if (!state.convIdsByInboxId[inboxId].includes(conversation.id)) {
        state.convIdsByInboxId[inboxId].push(conversation.id);
      }
    });

    builder.addCase(optimisticallyUpdateConversation.pending, (state, action) => {
      const {
        conversationId,
        payload,
      } = action.meta.arg;

      const cid = typeof conversationId === 'number' ? conversationId : parseInt(conversationId);

      if (state.conversationsById[cid]) {
        state.optimisticConvRollbackById[cid] = current(state.conversationsById[cid]);
        state.conversationsById[cid] = mergeDeepRight(state.conversationsById[cid], payload);
      }
    });

    builder.addCase(optimisticallyUpdateConversation.fulfilled, (state, action) => {
      const {
        conversation,
      } = action.payload;

      state.conversationsById[conversation.id] = conversation;
      delete state.optimisticConvRollbackById[conversation.id];
    });

    builder.addCase(optimisticallyUpdateConversation.rejected, (state, action) => {
      const {
        conversationId,
      } = action.meta.arg;

      const cid = typeof conversationId === 'number' ? conversationId : parseInt(conversationId);

      if (state.optimisticConvRollbackById[cid]) {
        state.conversationsById[cid] = state.optimisticConvRollbackById[cid];
      }
    });
  },
});

export const { receiveMessages, receiveConversations, markConvAsRead, receiveInboxes } = inboxSlice.actions;
export default inboxSlice.reducer;