import { BlockedPersonIcon } from 'evergreen-ui';
import { ICadenceEvent } from 'types/cadence';

import _, {M} from 'constants/i18n';


const CancelledEvent = ({event}: {event: ICadenceEvent}) => {
  return <div>
    <div className="enrolled-event-title--container">
      <BlockedPersonIcon className="enrolled-event-title--icon" color="var(--color-pink-6)"/>
      <div className="enrolled-event-title--text">{_(M.CADENCE_EVENT_CANCELLED_TITLE)}</div>
    </div>
    <p className="enrolled-event--description">{_(M.CADENCE_EVENT_CANCELLED_DESC)}</p>
  </div>
};

export default CancelledEvent;