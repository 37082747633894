import { Link } from 'react-router-dom';
import cx from 'classnames';
import debounce from 'lodash.debounce';
import { useSelector } from 'react-redux';
import { Alert, Button, TextInput } from 'evergreen-ui';
import { ChangeEvent, FocusEvent, FormEvent, MouseEvent, useState } from 'react';

import { API } from 'api';
import { getCurrentUser } from 'store/user/selector';
import { setToken } from 'utils/auth';
import { signupViaEmail } from 'api/user';
import { TrackEventNames, tracker } from 'utils/tracking';
import { validateEmail } from 'utils/strings';
import _, { M } from 'constants/i18n';
import GoogleButton from 'components/shared/GoogleButton';
import { getGoogleOAuthURI } from 'api/auth';

import './style.css';

const SIGNUP_ERROR = {
  title: _(M.ERROR_SIGNUP_FAILED),
  children: _(M.ASK_TRY_AGAIN),
};

const openGoogle = async () => {
  try {
    const {data} = await getGoogleOAuthURI(true);
    window.location.href = data.authorization_url;
  } catch (err) {
    console.warn('Could not get google auth URI', err);
  }
};


const Signup = () => {
  const [firstName, setFirstName] = useState('');
  const [isFirstNameInvalid, setIsFirstNameInvalid] = useState(false);
  const [lastName, setLastName] = useState('');
  const [isLastNameInvalid, setLastNameInvalid] = useState(false);
  const [email, setEmail] = useState('');
  const [isEmailInvalid, setEmailInvalid] = useState(false);
  const [password, setPassword] = useState('');
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState<typeof SIGNUP_ERROR | null>(null);
  const currentUser = useSelector(getCurrentUser);

  const buttonDisabled = !firstName || !lastName || !email || !password ||
  isFirstNameInvalid || isLastNameInvalid || isEmailInvalid || isPasswordInvalid;

  if (currentUser) {
    window.location.href = '/';
  }

  const onCheckFirstName = (e: FocusEvent<HTMLInputElement>) => {
    setIsFirstNameInvalid(!e.target.value);
  };

  const onCheckLastName = (e: FocusEvent<HTMLInputElement>) => {
    setLastNameInvalid(!e.target.value);
  };

  const onCheckEmail = (e: FocusEvent<HTMLInputElement>) => {
    setEmailInvalid(!validateEmail(e.target.value));
  };

  const onCheckPassword = debounce((newPassword: string) => {
    setIsPasswordInvalid(newPassword.length < 12);
  }, 500);

  const onChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    onCheckPassword(e.target.value);
  };

  const onSubmitEmail = (e: MouseEvent<HTMLButtonElement> & FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    const fullName = `${firstName} ${lastName}`;
    signupViaEmail({ email, password, full_name: fullName })
      .then(({ data })=> {
        setToken(data.token.access_token);
        API.defaults.headers['Authorization'] = `Bearer ${data.token.access_token}`;
        tracker.track(TrackEventNames.SU);
        tracker.setSignupVersion();
        window.location.href = '/';
      })
      .catch(() => {
        setError(SIGNUP_ERROR);
        setIsLoading(false);
      });
  };

  return (
    <>
      {error && <Alert intent="warning" className="login-alert" title={error.title} marginBottom={24}>{error.children}</Alert>}
      <div className="login-google-prompt--container">
        <GoogleButton onClick={openGoogle}/>
        <div className="login-google-prompt-alternative--container">
          <div className="login-google-prompt-alternative--line" />
        </div>
      </div>
      <form onSubmit={onSubmitEmail}>
        <label className="login-input--label">{_(M.FIRST_NAME)}</label>
        <TextInput className={cx('login-input', { 'login-input--invalid': isFirstNameInvalid })} width="100%" height="40px" onBlur={onCheckFirstName} isInvalid={isFirstNameInvalid} value={firstName} onChange={(e: ChangeEvent<HTMLInputElement>) => { setFirstName(e.target.value); }} />
        {isFirstNameInvalid && <div className="login-input--error">{_(M.ERROR_FIELD_REQUIRED)}</div>}
        <label className="login-input--label">{_(M.LAST_NAME)}</label>
        <TextInput className={cx('login-input', { 'login-input--invalid': isLastNameInvalid })} width="100%" height="40px" onBlur={onCheckLastName} isInvalid={isLastNameInvalid} value={lastName} onChange={(e: ChangeEvent<HTMLInputElement>) => { setLastName(e.target.value); }} />
        {isLastNameInvalid && <div className="login-input--error">{_(M.ERROR_FIELD_REQUIRED)}</div>}
        <label className="login-input--label">{_(M.EMAIL)}</label>
        <TextInput className={cx('login-input', { 'login-input--invalid': isEmailInvalid })} width="100%" height="40px" onBlur={onCheckEmail} isInvalid={isEmailInvalid} value={email} onChange={(e: ChangeEvent<HTMLInputElement>) => { setEmail(e.target.value); }} />
        {isEmailInvalid && <div className="login-input--error">{_(M.ERROR_INVALID_EMAIL)}</div>}
        <label className="login-input--label">{_(M.PW)}</label>
        <TextInput className={cx('login-input', { 'login-input--invalid': isPasswordInvalid })} width="100%" height="40px" isInvalid={isPasswordInvalid} type="password" value={password} onChange={onChangePassword} />
        {isPasswordInvalid && <div className="login-input--error">{_(M.ERROR_SHORT_PW)}</div>}
        <div className="signup-card-helper-text">
          {_(M.TOS_AGREE)} <a className="signup-card-link" href="https://app.tinycadence.com/terms_of_use.html" target="_blank" rel="noopener noreferrer">{_(M.TOS)}</a>.
        </div>
        <Button appearance="primary" disabled={buttonDisabled} type="submit" className="login-submit-button" isLoading={loading} onClick={onSubmitEmail}>{_(M.NAV_SIGNUP)}</Button>
        <div className="signup-card-helper-text signup-card-footer">
          {_(M.ASK_LOGIN)} <Link className="login-link" to="/auth/login">{_(M.NAV_LOGIN)}</Link>
        </div>
      </form>
    </>
  );
};

export default Signup;
