import { ChangeEvent, useState } from 'react';
import { Checkbox, Dialog } from 'evergreen-ui';
import { mergeDeepRight } from 'ramda';
import cx from 'classnames';

import _, { M } from 'constants/i18n';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getEditingCadence, getEditingCadenceOption } from 'store/cadence/selector';
import { IBizHourSchedule, ICadence, ISingleDaySchedule } from 'types/cadence';
import { editCadence } from 'store/cadence/slice';
import './style.css';
import { nullOrUndefined } from 'utils/data';

const defaultDaySchedule = {
  start_hour: 9,
  start_minute: 0,
  end_hour: 17,
  end_minute: 0,
};

const DefaultSchedule: IBizHourSchedule = {
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  monday: defaultDaySchedule,
  tuesday: defaultDaySchedule,
  wednesday: defaultDaySchedule,
  thursday: defaultDaySchedule,
  friday: defaultDaySchedule,
  saturday: null,
  sunday: null,
}

type DayOfWeek = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday'

const DayTimeEdit = ({
  day,
  startHour,
  startMinute,
  endHour,
  endMinute,
  onChange,
}: {
  day: DayOfWeek,
  startHour?: number | null,
  startMinute?: number | null,
  endHour?: number | null,
  endMinute?: number | null,
  onChange: (value: ISingleDaySchedule) => void
}) => {
  const isChecked = startHour !== undefined || endHour !== undefined;

  const onEdit = (e: ChangeEvent<HTMLInputElement>, startOrEnd: 'start' | 'end') => {
    const hourAndMinute = e.target.value.split(':');
    if (hourAndMinute.length === 2) {
      const [hourStr, minuteStr] = hourAndMinute;
      onChange({
        start_hour: startOrEnd === 'start' ? parseInt(hourStr) : startHour,
        start_minute: startOrEnd === 'start' ? parseInt(minuteStr) : startMinute,
        end_hour: startOrEnd === 'end' ? parseInt(hourStr) : endHour,
        end_minute: startOrEnd === 'end' ? parseInt(minuteStr) : endMinute,
      });
    } else {
      onChange({
        start_hour: startOrEnd === 'start' ? undefined : startHour,
        start_minute: startOrEnd === 'start' ? undefined : startMinute,
        end_hour: startOrEnd === 'end' ? undefined : endHour,
        end_minute: startOrEnd === 'end' ? undefined : endMinute,
      });
    }
  };

  const onToggle = () => {
    if (isChecked) {
      onChange({
        start_hour: undefined,
        start_minute: undefined,
        end_hour: undefined,
        end_minute: undefined,
      });
    } else {
      onChange({
        start_hour: defaultDaySchedule.start_hour,
        start_minute: defaultDaySchedule.start_minute,
        end_hour: defaultDaySchedule.end_hour,
        end_minute: defaultDaySchedule.end_minute,
      });
    }
  }

  const startString = `${(startHour?.toString() || '9').padStart(2, '0')}:${(startMinute?.toString() || '0').padStart(2, '0')}`;
  const startValue = !isChecked ? '' : startString;
  const endString = `${(endHour?.toString() || '9').padStart(2, '0')}:${(endMinute?.toString() || '0').padStart(2, '0')}`;
  const endValue = !isChecked ? '' : endString;

  const isInvalid = startHour !== undefined &&
    startHour !== null &&
    endHour !== null &&
    endHour !== undefined &&
    (startHour > endHour || (
      startHour === endHour &&
      startMinute !== null &&
      endMinute !== null &&
      startMinute !== undefined &&
      endMinute !== undefined &&
      startMinute > endMinute
    ))

  // TODO: i18n
  return (
    <div className="biz-hours-dialog-edit-day--container">
      <div className={cx('biz-hours-dialog-edit-day--name', {disabled: !isChecked})}>
        <Checkbox checked={isChecked} onChange={onToggle} marginRight={12}/>
        <div>{day}</div>
      </div>
      <div>
        <input type="time" className={cx('biz-hours-dialog-edit--time', {invalid: isInvalid, disabled: !isChecked})} value={startValue} onChange={(e) => onEdit(e, 'start')} disabled={!isChecked} />
        to
        <input type="time" className={cx('biz-hours-dialog-edit--time', {invalid: isInvalid, disabled: !isChecked})} value={endValue} onChange={(e) => onEdit(e, 'end')} disabled={!isChecked} />
      </div>
    </div>
  )
}


const EditBizHoursSendDialog = ({
  isShown,
  onClose,
}: {isShown?: boolean, onClose: () => void}) => {
  const savedSchedule = (useAppSelector(s => getEditingCadenceOption(s, 'biz_hour_schedule')) as IBizHourSchedule | null | undefined);
  const editingCadence = useAppSelector(getEditingCadence);
  const [schedule, setSchedule] = useState<IBizHourSchedule>(savedSchedule || DefaultSchedule);
  const dispatch = useAppDispatch();

  const onSubmit = () => {
    const newCadence = mergeDeepRight(editingCadence || {}, {
      data: { options: { ...(editingCadence?.data.options || {}), biz_hour_schedule: schedule }}
    });
    dispatch(editCadence(newCadence as Partial<ICadence>));
    onClose();
  }

  const onTimeChange = (day: DayOfWeek, newSchedule: ISingleDaySchedule) => {
    const allAreUnset =  nullOrUndefined(newSchedule.start_hour) && nullOrUndefined(newSchedule.start_minute) && nullOrUndefined(newSchedule.end_hour) && nullOrUndefined(newSchedule.start_minute)
    setSchedule({...schedule, [day]: allAreUnset ? null : newSchedule})
  };

  // TODO: i18n
  return <Dialog isShown={isShown} onConfirm={onSubmit} title={_(M.EDIT_BIZ_HOURS_TITLE)} onCloseComplete={onClose} confirmLabel={_(M.CONFIRM)} cancelLabel={_(M.CANCEL)}>
    <div>
      <div className="edit-biz-hours-dialog--timezone">
        timezone: {schedule.timezone}
      </div>
      <div>
        <DayTimeEdit
          day="monday"
          startHour={schedule.monday?.start_hour}
          startMinute={schedule.monday?.start_minute}
          endHour={schedule.monday?.end_hour}
          endMinute={schedule.monday?.end_minute}
          onChange={(newSchedule) => onTimeChange('monday', newSchedule)}
        />
        <DayTimeEdit
          day="tuesday"
          startHour={schedule.tuesday?.start_hour}
          startMinute={schedule.tuesday?.start_minute}
          endHour={schedule.tuesday?.end_hour}
          endMinute={schedule.tuesday?.end_minute}
          onChange={(newSchedule) => onTimeChange('tuesday', newSchedule)}
        />
        <DayTimeEdit
          day="wednesday"
          startHour={schedule.wednesday?.start_hour}
          startMinute={schedule.wednesday?.start_minute}
          endHour={schedule.wednesday?.end_hour}
          endMinute={schedule.wednesday?.end_minute}
          onChange={(newSchedule) => onTimeChange('wednesday', newSchedule)}
        />
        <DayTimeEdit
          day="thursday"
          startHour={schedule.thursday?.start_hour}
          startMinute={schedule.thursday?.start_minute}
          endHour={schedule.thursday?.end_hour}
          endMinute={schedule.thursday?.end_minute}
          onChange={(newSchedule) => onTimeChange('thursday', newSchedule)}
        />
        <DayTimeEdit
          day="friday"
          startHour={schedule.friday?.start_hour}
          startMinute={schedule.friday?.start_minute}
          endHour={schedule.friday?.end_hour}
          endMinute={schedule.friday?.end_minute}
          onChange={(newSchedule) => onTimeChange('friday', newSchedule)}
        />
        <DayTimeEdit
          day="saturday"
          startHour={schedule.saturday?.start_hour}
          startMinute={schedule.saturday?.start_minute}
          endHour={schedule.saturday?.end_hour}
          endMinute={schedule.saturday?.end_minute}
          onChange={(newSchedule) => onTimeChange('saturday', newSchedule)}
        />
        <DayTimeEdit
          day="sunday"
          startHour={schedule.sunday?.start_hour}
          startMinute={schedule.sunday?.start_minute}
          endHour={schedule.sunday?.end_hour}
          endMinute={schedule.sunday?.end_minute}
          onChange={(newSchedule) => onTimeChange('sunday', newSchedule)}
        />
      </div>
    </div>
  </Dialog>;
};

export default EditBizHoursSendDialog;