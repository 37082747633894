
const CadenceIcon = ({width=752, height=752}: {width?: number, height?: number}) => (
  <svg width={`${width}pt`} height={`${height}pt`} version="1.1" viewBox={`0 0 ${width} ${height}`} xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="m235.41 531.39h2.0664l5.668-17.004 3.293-1.2422c0.94141-0.35547 1.8633-0.72656 2.7734-1.1328l3.2031-1.4336 16 8 13.387-13.387-8-16 1.4336-3.2031c0.41406-0.92578 0.77734-1.8555 1.1484-2.8047l1.25-3.2617 16.977-5.6641v-18.938l-16.977-5.6602-1.25-3.2617c-0.37109-0.94531-0.73828-1.8867-1.1484-2.8047l-1.4336-3.2031 8-16-13.387-13.387-16 8-3.2031-1.4336c-0.91016-0.40625-1.8359-0.77734-2.7734-1.1328l-3.293-1.2422-5.668-17h-2.0664v-59.199h221.99c5.4297 0 10.684-1.0078 15.68-2.8945 2.9141-18.641 13.504-34.777 28.527-44.922-1.9688-23.113-21.031-40.977-44.207-40.977-24.484 0-44.398 19.914-44.398 44.398h-14.801c0-27.422 18.766-50.48 44.117-57.176-2.6992-26.043-24.773-46.422-51.516-46.422-8.3477 0-16.422 2.0664-23.723 5.8477 5.6523 11.684 8.9219 24.73 8.9219 38.551h-14.801c0-40.801-33.195-73.996-73.996-73.996s-73.996 33.195-73.996 73.996c0 2.2773 0.14844 4.5195 0.34766 6.7422l0.73828 8.0742-8.8555-0.023437c-24.113 0.007812-44.027 19.918-44.027 44.406 0 24.484 19.914 44.398 44.398 44.398h14.801v59.199h-2.0664l-5.668 17.004-3.293 1.2422c-0.94141 0.35547-1.8633 0.72656-2.7734 1.1328l-3.2031 1.4336-16-8.0078-13.387 13.387 8 16-1.4336 3.2031c-0.41406 0.92578-0.77734 1.8555-1.1484 2.8047l-1.25 3.2617-16.977 5.668v18.938l16.977 5.6602 1.25 3.2617c0.37109 0.94531 0.73828 1.8867 1.1484 2.8047l1.4336 3.2031-8 16 13.387 13.387 16-8 3.2031 1.4336c0.91016 0.40625 1.8359 0.77734 2.7734 1.1328l3.293 1.2422 5.668 17h2.0664v44.398h133.2v-14.801h-118.4zm-22.199-207.19h-14.797v-14.801h14.801zm59.199 0h-14.801v-14.801h14.801zm14.797-147.99v14.801c-24.484 0-44.398 19.914-44.398 44.398 0 4.3125 0.62109 8.582 1.8438 12.691l-14.191 4.2188c-1.6289-5.4805-2.4492-11.172-2.4492-16.914 0-32.641 26.559-59.195 59.195-59.195zm-59.195 133.19h14.801v14.801h-14.801zm-29.598 155.39c0-16.348 13.254-29.598 29.598-29.598 16.348 0 29.598 13.254 29.598 29.598 0 16.348-13.254 29.598-29.598 29.598-16.348 0.003907-29.598-13.25-29.598-29.598z"/>
      <path d="m538.79 294.61c-28.555 0-51.797 23.234-51.797 51.797 0 26.039 19.344 47.594 44.398 51.207v-38.465c-4.4023-2.5586-7.3984-7.2812-7.3984-12.75 0-8.1758 6.6211-14.801 14.801-14.801 8.1758 0 14.801 6.6211 14.801 14.801 0 5.4609-2.9961 10.184-7.3984 12.75v38.465c25.055-3.6094 44.398-25.168 44.398-51.207-0.003906-28.562-23.246-51.797-51.805-51.797z"/>
      <path d="m524 411.28v16.52c0 5.4531 2.9961 10.176 7.3984 12.742v120.45h-73.996v14.801h88.797v-135.25c4.4023-2.5664 7.3984-7.2891 7.3984-12.742v-16.516c-4.7656 1.0859-9.707 1.7148-14.801 1.7148-5.0898 0-10.031-0.62891-14.797-1.7188z"/>
      <path d="m420.4 494.4 14.801-44.398h-37l-29.598 73.996h44.398l-19.285 64.289c-1.457 4.8398 2.168 9.707 7.2227 9.707 2.8555 0 5.4688-1.6133 6.75-4.168l49.711-99.426z"/>
      <path d="m531.39 235.41h14.801v29.598h-14.801z"/>
      <path d="m561.77 269.11 14.797-29.598 13.238 6.6172-14.797 29.598z"/>
    </g>
  </svg>
);

export default CadenceIcon;