import { IContactWithProperties } from 'types/contact';
import { v2queries } from '../queries';


export interface IHomeAnalyticsResponse {
  results: {
    actor: string,
    event_type: string,
    count: number,
    day: string,
    org_name: string,
  }[];
  contacts: IContactWithProperties[];
}


export interface IContactAnalyticsResponse {
  results: {
    count: number,
    day: string,
    event_type: string,
  }[];
}

interface IContactAnalyticsRequest {
  after: string,
  before: string, // TODO: dates?
  contactId: string | number,
}


const analyticsQueries = v2queries.injectEndpoints({
  endpoints: (build) => ({

    homeAnalytics: build.query<IHomeAnalyticsResponse, void>({
      query: () => '/analytics/home',
      keepUnusedDataFor: 10,
      providesTags: (res) => res ? [{type: 'Analytics', id: 'HomeV2'}] : [],
    }),

    contactAnalytics: build.query<IContactAnalyticsResponse, IContactAnalyticsRequest>({
      query: ({contactId, before, after}) => {
        const params = new URLSearchParams();
        params.set('after', after);
        params.set('before', before);
        params.set('contact_id', contactId.toString());
        return `/analytics/contact?${params}`;
      },
      keepUnusedDataFor: 10,
    }),

  }),

  overrideExisting: false,
});


export const { useHomeAnalyticsQuery, useContactAnalyticsQuery } = analyticsQueries;