import { adminQueries } from 'api/queries';
import { IPagination } from 'api/cadence';
import { IScheduledTask } from 'types/user';


const tasksPage = {type: 'ScheduledTask' as const, id: 'PAGE'};

interface IListTasksArgs {
  offset: number;
  limit: number;
  status: string;
}

const tasksQueries = adminQueries.injectEndpoints({
  endpoints: (build) => ({
    paginateTasks: build.query<{items: IScheduledTask[], pagination: IPagination}, IListTasksArgs>({
      query: (body) => {
        const qs = new URLSearchParams();
        qs.set('limit', body.limit.toString());
        qs.set('offset', body.offset.toString());
        qs.set('status', body.status);
        return {
          url: `/scheduled_tasks/?${qs}`,
          method: 'GET',
        }
      },

      providesTags: (res) => res ? [tasksPage, ...res.items.map(c => ({type: 'ScheduledTask', id: c.id}))] : [],
    }),

    fetchTask: build.query<{task: IScheduledTask}, number | string>({
      query: (id: number | string) => `/scheduled_tasks/${id}`,

      providesTags: (res) => res ? [tasksPage, {type: 'ScheduledTask', id: res.task.id}] : [],
    }),

    resetScheduledTask: build.mutation<{task: IScheduledTask}, string | number>({
      query: (id) => ({
        url: `/scheduled_tasks/${id}/reset`,
        method: 'POST',
      }),

      invalidatesTags: (res) => res ? [tasksPage, {type: 'ScheduledTask', id: res.task.id}] : [],
    }),

    deleteScheduledTask: build.mutation<void, string | number>({
      query: (id) => ({
        url: `/scheduled_tasks/${id}`,
        method: 'DELETE',
      }),

      invalidatesTags: (_, _a, args) => [tasksPage, {type: 'ScheduledTask', id: args}],
    }),
  }),
  overrideExisting: false,
});


export const {
  usePaginateTasksQuery,
  useFetchTaskQuery,
  useResetScheduledTaskMutation,
  useDeleteScheduledTaskMutation,
} = tasksQueries;