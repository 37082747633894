import { Pagination, Table } from 'evergreen-ui';
import { useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { usePaginateCadencesQuery } from 'api/admin/cadence';
import { ICadence } from 'types/cadence';
import { dateStrToLocaleStr } from 'utils/strings';
import { usePaginateCompanyUsersQuery } from 'api/admin/companies';
import { ICompanyUser } from 'types/company';

const limit = 10;

const CompanyUserRow = ({cu}: {cu: ICompanyUser}) => {
  const nav = useNavigate();

  // const goToCadence = () => {
  //   nav(`/admin/cadences/${cadence.id}`);
  // }

  return <Table.Row
    // onClick={goToCadence}
    isSelectable
  >
    <Table.TextCell>{cu.user.full_name}</Table.TextCell>
    <Table.TextCell>{cu.user.email}</Table.TextCell>
    <Table.TextCell>{cu.user.status}</Table.TextCell>
    <Table.TextCell>{cu.role}</Table.TextCell>
    <Table.TextCell>{dateStrToLocaleStr(cu.user.created_at)}</Table.TextCell>
    <Table.TextCell>{dateStrToLocaleStr(cu.user.updated_at)}</Table.TextCell>
  </Table.Row>
}


const AdminCompanyUsersTable = ({companyId}: {companyId: string | number}) => {
  const [params, setSearchParams] = useSearchParams();

  // TODO: make this logic a util function or hook
  const offset = isNaN(parseInt(params.get('o') || '0')) ? 0 : parseInt(params.get('o') || '0');

  const onParamChange = (paramName: string, paramValue: string | null) => {
    setSearchParams(newParams => {
      // reset the page when search params change
      if (paramName !== 'o') {
        newParams.delete('o');
      }

      if (paramValue === null) {
        newParams.delete(paramName);
      } else {
        newParams.set(paramName, paramValue);
      }
      return newParams;
    });
  };

  const {
    data,
  } = usePaginateCompanyUsersQuery({limit: 10, offset, companyId});

  const onPrevPageClick = () => {
    if (!data) return;

    const currentOffset = (data.pagination.this - 1) * limit;
    if (currentOffset > 0) {
      onParamChange('o', (currentOffset - limit).toString());
    }
  };

  const onNextPage = () => {
    if (!data) return;

    const pagination = data.pagination;
    const currentOffset = (pagination.this - 1) * limit;
    if (pagination.this < pagination.last) {
      onParamChange('o', (currentOffset + limit).toString());
    }
  };

  const onPageChange = (page: number) => {
    onParamChange('o', ((page - 1) * limit).toString());
  };

  return <div>
    <Table>
      <Table.Head>
        <Table.TextHeaderCell>Name</Table.TextHeaderCell>
        <Table.TextHeaderCell>Email</Table.TextHeaderCell>
        <Table.TextHeaderCell>Status</Table.TextHeaderCell>
        <Table.TextHeaderCell>Role</Table.TextHeaderCell>
        <Table.TextHeaderCell>Created at</Table.TextHeaderCell>
        <Table.TextHeaderCell>Updated at</Table.TextHeaderCell>
      </Table.Head>
      <Table.Body>
        {data?.items.map((cu) => <CompanyUserRow cu={cu} key={cu.id} />)}
      </Table.Body>
    </Table>
    {data && (
      <Pagination
        totalPages={data.pagination.last}
        page={data.pagination.this}
        onNextPage={onNextPage}
        onPreviousPage={onPrevPageClick}
        onPageChange={onPageChange}
      />
    )}
  </div>
}


const AdminCompanyUsersPage = () => {
  const {companyId = ''} = useParams<{companyId: string}>()

  return <div className="admin-page-content--container">
    <h2>Company users</h2>
    <div>
      <AdminCompanyUsersTable companyId={companyId} />
    </div>
  </div>
}


export default AdminCompanyUsersPage;
