import { useParams } from 'react-router-dom';

import { useFetchCadenceQuery } from 'api/admin/cadence';
import { dateStrToLocaleStr } from 'utils/strings';


const AdminCadencePage = () => {
  const {cadenceId = ''} = useParams<{cadenceId: string}>()

  const {
    data,
    isLoading,
    isFetching,
    isError,
  } = useFetchCadenceQuery(cadenceId);

  if (!data || isLoading || isFetching) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error</div>
  }

  const cadence = data.cadence;

  return <div>
    <div>
      <h3>Cadence details</h3>
      <div className="admin-user-page-detail--container">
        <div className="admin-user-page-detail--label">Name</div>
        <div className="admin-user-page-detail--value">{cadence.name}</div>
      </div>
      <div className="admin-user-page-detail--container">
        <div className="admin-user-page-detail--label">email</div>
        <div className="admin-user-page-detail--value">{cadence.description}</div>
      </div>
      <div className="admin-user-page-detail--container">
        <div className="admin-user-page-detail--label">status</div>
        <div className="admin-user-page-detail--value">{cadence.status}</div>
      </div>
      <div className="admin-user-page-detail--container">
        <div className="admin-user-page-detail--label">Namespace</div>
        <div className="admin-user-page-detail--value">{cadence.namespace}</div>
      </div>
      <div className="admin-user-page-detail--container">
        <div className="admin-user-page-detail--label">Created</div>
        <div className="admin-user-page-detail--value">{dateStrToLocaleStr(cadence.created_at)}</div>
      </div>
      <div className="admin-user-page-detail--container">
        <div className="admin-user-page-detail--label">Updated</div>
        <div className="admin-user-page-detail--value">{dateStrToLocaleStr(cadence.updated_at)}</div>
      </div>
    </div>
    <div>
      <h3>Cadence data</h3>
      <pre>{JSON.stringify(cadence.data, undefined, 2)}</pre>
    </div>
    <div>
      <h3>Cadence meta</h3>
      <pre>{JSON.stringify(cadence.meta, undefined, 2)}</pre>
    </div>
  </div>
}

export default AdminCadencePage;