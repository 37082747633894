import axios, {AxiosError} from 'axios';

import { API_URL } from 'constants/resources';
import { getToken } from 'utils/auth';


export const API = axios.create({
  baseURL: API_URL,
  timeout: 30000, // 30 second timeout
  headers: {
    'X-App-Version': process.env.REACT_APP_VERSION || 'no-version',
    'Authorization': `Bearer ${getToken()}`
  }
});

export const bootToLogin = () => {
  if (!window.location.pathname.startsWith('/auth') && !window.location.pathname.startsWith('/oauth-login')) {
    window.location.href = `/auth/login?next=${encodeURIComponent(window.location.pathname)}`;
  }
};

API.interceptors.response.use((x) => x,
  (err: Error | AxiosError) => {
    if (axios.isAxiosError(err) && err.response?.status === 401) {
      bootToLogin();
    } else {
      return err;
    }
  },
);