import { useParams } from 'react-router-dom';

import AdminInboxesTable from '../InboxesTable';


const AdminUserInboxesPage = () => {
  const {userId = ''} = useParams<{userId: string}>()

  return <div>
    <AdminInboxesTable userId={userId} />
  </div>
}

export default AdminUserInboxesPage;