import { useState } from 'react';
import { ChevronRightIcon, PauseIcon } from 'evergreen-ui';
import { ICadenceEvent } from 'types/cadence';
import cx from 'classnames';

import _, {M} from 'constants/i18n';


const PausedForApprovalEvent = ({event}: {event: ICadenceEvent}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return <div onClick={() => setIsExpanded(!isExpanded)} className="cadence-event-expandable--container">
    <div className="cadence-event--header--container">
      <div>
        <div className="enrolled-event-title--container">
          <PauseIcon className="enrolled-event-title--icon" color="var(--color-blue-2)" border="2px solid var(--color-blue-2)" borderRadius="50px" padding="2px"/>
          <div className="enrolled-event-title--text">{_(M.CADENCE_EVENT_PAUSED_TITLE)}</div>
        </div>
        <p className="enrolled-event--description">{_(M.CADENCE_EVENT_PAUSED_DESC)}</p>
      </div>
      <ChevronRightIcon color="var(--color-neutral-9)" size={24} className={cx('cadence-event-expandable--indicator', {expanded: isExpanded})}/>
    </div>
    <div className={cx('cadence-event-expandable-content--container', {expanded: isExpanded})}>
      <div className="cadence-event-expandable-content--title">{_(M.CADENCE_EVENT_PAUSED_DRAFT)}</div>
      <div className="cadence-email-event-content--container">
        {!!event.event_data.email_subject && <div className="cadence-email-event-content--subject">{event.event_data.email_subject as string}</div>}
        <div className="cadence-email-event-content--body" dangerouslySetInnerHTML={{__html: event.event_data.email_content_html as string}} />
      </div>
    </div>
  </div>
};

export default PausedForApprovalEvent;