import { useParams } from 'react-router-dom';

import AdminContactListsTable from 'components/admin/ContactListsTable';


const AdminUserContactListsPage = () => {
  const {userId = ''} = useParams<{userId: string}>()

  return <div>
    <AdminContactListsTable userId={userId} />
  </div>
}

export default AdminUserContactListsPage;