import Pusher, { Channel } from 'pusher-js';

import { API_URL, DEBUG, PUSHER_CLIENT_ID } from 'constants/resources';
import { getToken } from './auth';

// remove the "&& false" to see Pusher debug logs
Pusher.logToConsole = DEBUG && false;

const pusher = new Pusher(PUSHER_CLIENT_ID, {
  cluster: 'us2',
  userAuthentication: {
    endpoint: `${API_URL}/api/v1/users/pusher-user-auth`,
    transport: 'ajax',
    headersProvider: () => ({
      Authorization: `Bearer ${getToken()}`
    }),
  },
});

export const signInWSConnection = () => {
  try {
    pusher.signin();
  } catch (err) {
    console.warn('Failed to authenticate websocket', err);
  }
}

const subscriptions: Record<string, Channel> = {};

export function listen<T>(channelName: string, eventType: string, callback: (data: T) => void) {
  const channel = subscriptions[channelName] || pusher.subscribe(channelName);
  if (!subscriptions[channelName]) {
    subscriptions[channelName] = channel;
  }
  return channel.bind(eventType, (data: T) => {
    try {
      callback(data);
    } catch (e) {
      console.warn(`Error receiving websocket event: ${eventType}, data: ${data}, error: ${e}`);
    }
  });
}