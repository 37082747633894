import { ICadence, ICadenceEnrollment, ICreateCadenceArgs, IListCadencesArgs, IListEnrollmentsArgs, ISendEmailNodeParams, ICadenceEvent, IListCadenceEventsArgs } from 'types/cadence';

import { queries } from './queries';
import { IContact } from 'types/contact';
import { API } from 'api';


export interface IPagination {
  next: number | null
  prev: number | null
  first: number
  last: number
  this: number
  total_items: number
}

export interface IHydratedEnrollment {
  cadence: ICadence,
  cadence_contact: ICadenceEnrollment,
  contact: IContact,
}

interface ISyncNewHSListContactsNow {
  cadenceId: number;
  hsListId: number;
  connectorId: number;
}

interface IEditCadenceHSListAutoEnrollment {
  cadenceId: number;
  hsListId: number;
  connectorId: number;
  clearListener: boolean;
}


const cadencePage = {type: 'Cadence' as const, id: 'PAGE'};
const enrollmentPage = {type: 'Enrollment' as const, id: 'PAGE'};
const cadenceEventsPage = {type: 'CadenceEvent' as const, id: 'PAGE'};


export const manuallyEnroll = (args: {cadenceId: number, contactEmail: string, contactName: string | null, gmailThreadId: string | null, scheduledFor: string}) => {
  const body = {
    contact_email: args.contactEmail,
    contact_name: args.contactName,
    gmail_thread_id: args.gmailThreadId,
    scheduled_for: args.scheduledFor || null,
  }
  return API.post(`/api/v1/cadences/${args.cadenceId}/enroll`, body);
}

export const enrollViaCsv = (body: {cadenceId: number, file: File, createList: boolean, listName: string, csvMapping?: null | Record<string, string | number>}) => {
  const qs = new URLSearchParams();
  if (body.createList) {
    qs.set('create_list', 'true');
    qs.set('create_list_name', body.listName || 'List from CSV enrollment');
  }
  const formData = new FormData();
  formData.append('file', body.file);
  if (body.csvMapping) {
    formData.append('csv_column_mapping', JSON.stringify(body.csvMapping));
  }

  return API.post(`/api/v1/cadences/${body.cadenceId}/enroll-via-csv?${qs}`, formData);
}

export const enrollViaHsList = ({listId, cadenceId, connectorId}: {cadenceId: number, listId: number, connectorId: number}) => {
  return API.post(`/api/v1/cadences/${cadenceId}/enroll-via-hubspot?hs_list_id=${listId}&connector_id=${connectorId}`);
}

export const enrollViaContactList = ({listId, cadenceId}: {cadenceId: number, listId: number}) => {
  return API.post(`/api/v1/cadences/${cadenceId}/enroll-via-contact-list?contact_list_id=${listId}`);
}




const cadencesQueries = queries.injectEndpoints({
  endpoints: (build) => ({

    createNewCadence: build.mutation<{cadence: ICadence}, ICreateCadenceArgs>({
      query: (body) => ({
        url: '/cadences/',
        method: 'POST',
        body,
      }),

      invalidatesTags: (res) => res ? [cadencePage, {type: 'Cadence' as const, id: res.cadence.id}] : []
    }),

    patchCadence: build.mutation<{cadence: ICadence}, Partial<ICadence>>({
      query: (body) => ({
        url: `/cadences/${body.id}`,
        method: 'PATCH',
        body,
      }),

      invalidatesTags: (res) => res? [cadencePage, {type: 'Cadence' as const, id: res.cadence.id}] : [],
    }),

    paginateCadences: build.query<{cadences: ICadence[], meta: {pagination: IPagination}}, IListCadencesArgs>({
      query: (body) => {
        const qs = new URLSearchParams();
        qs.set('deleted', body.deleted.toString());
        qs.set('limit', body.limit.toString());
        qs.set('offset', body.offset.toString());
        body.status.forEach(st => qs.append('status', st));
        return {
          url: `/cadences/?${qs}`,
          method: 'GET',
        }
      },

      providesTags: (res) => res ? [cadencePage, ...res.cadences.map(c => ({type: 'Cadence' as const, id: c.id}))] : [],
    }),

    paginateCadenceEnrollments: build.query<{cadence_contacts: IHydratedEnrollment[], meta: {pagination: IPagination}}, IListEnrollmentsArgs>({
      query: (body) => {
        const qs = new URLSearchParams();
        qs.set('limit', body.limit.toString());
        qs.set('offset', body.offset.toString());
        qs.set('status', body.status);

        if (body.cadenceId) {
          qs.set('cadence_id', body.cadenceId.toString());
        }

        return {
          url: `/cadence_contacts/?${qs}`,
          method: 'GET',
        }
      },

      providesTags: (res) => res? [enrollmentPage, ...res.cadence_contacts.map(c => ({type: 'Enrollment' as const, id: c.cadence_contact.id}))] : [],
    }),

    cancelCadenceContact: build.mutation<IHydratedEnrollment, {id: number}>({
      query: ({id}) => ({
        url: `/cadence_contacts/${id}/cancel`,
        method: 'POST',
      }),

      invalidatesTags: (res) => res? [cadenceEventsPage, enrollmentPage, {type: 'Enrollment' as const, id: res.cadence_contact.id}] : [],
    }),

    fetchCadence: build.query<{cadence: ICadence}, number>({
      query: (id) => `/cadences/${id}`,
      providesTags: (res) => res ? [{type: 'Cadence' as const, id: res.cadence.id}] : [],
    }),

    fetchCadenceEnrollment: build.query<IHydratedEnrollment, number>({
      query: (id) => `/cadence_contacts/${id}`,
      providesTags: (res) => res? [{type: 'Enrollment' as const, id: res.cadence_contact.id}] : [],
    }),

    postResumeEnrollment: build.mutation<IHydratedEnrollment, {id: number, params: ISendEmailNodeParams}>({
      query: (body) => ({
        url: `/cadence_contacts/${body.id}/resume`,
        method: 'POST',
        body: body.params,
      }),

      invalidatesTags: (res) => res? [enrollmentPage, {type: 'Enrollment' as const, id: res.cadence_contact.id}] : [],
    }),

    postManuallyEnroll: build.mutation<{cadence_contact: IHydratedEnrollment}, {cadenceId: number, contactEmail: string, contactName: string | null, gmailThreadId: string | null, scheduledFor: string}>({
      query: (body) => ({
        url: `/cadences/${body.cadenceId}/enroll`,
        method: 'POST',
        body: {
          contact_email: body.contactEmail,
          contact_name: body.contactName,
          gmail_thread_id: body.gmailThreadId,
          scheduled_for: body.scheduledFor || null,
        },
      }),

      invalidatesTags: (res) => res? [enrollmentPage, {type: 'Enrollment' as const, id: res.cadence_contact.cadence_contact.id}] : [],
    }),

    postManuallyEnrollViaCSV: build.mutation<void, {cadenceId: number, file: File, createList: boolean, listName: string, csvMapping?: null | Record<string, string | number>}>({
      query: (body) => {
        const qs = new URLSearchParams();
        if (body.createList) {
          qs.set('create_list', 'true');
          qs.set('create_list_name', body.listName || 'List from CSV enrollment');
        }
        const formData = new FormData();
        formData.append('file', body.file);
        if (body.csvMapping) {
          formData.append('csv_column_mapping', JSON.stringify(body.csvMapping));
        }
        return {
          url: `/cadences/${body.cadenceId}/enroll-via-csv?${qs}`,
          method: 'POST',
          body: formData
        };
      }
    }),

    postManuallyEnrollViaHSList: build.mutation<void, {cadenceId: number, listId: number, connectorId: number}>({
      query: ({listId, connectorId, cadenceId}) => {
        return {
          url: `/cadences/${cadenceId}/enroll-via-hubspot?hs_list_id=${listId}&connector_id=${connectorId}`,
          method: 'POST',
        };
      }
    }),


    paginateCadenceEvents: build.query<{cadence_events: ICadenceEvent[], meta: {pagination: IPagination}}, IListCadenceEventsArgs>({
      query: (body) => {
        const qs = new URLSearchParams();
        qs.set('limit', body.limit.toString());
        qs.set('offset', body.offset.toString());
        qs.set('sort_dir', body.sort_dir);
        body.eventType?.forEach(et => qs.append('event_type', et));

        if (body.cadenceId) {
          qs.set('cadence_id', body.cadenceId.toString());
        }

        if (body.contactId) {
          qs.set('contact_id', body.contactId.toString());
        }

        if (body.cadenceContactId) {
          qs.set('cadence_contact_id', body.cadenceContactId.toString());
        }

        return {
          url: `/cadence_events/?${qs}`,
          method: 'GET',
        }
      },

      providesTags: (res) => res? [cadenceEventsPage, ...res.cadence_events.map(c => ({type: 'CadenceEvent' as const, id: c.id}))] : [],
    }),

    searchHubspotLists: build.query<{lists: {id: number, connector_id: number, name: string, size: number}[], meta: {pagination: IPagination}}, {searchTerm?: string | null}>({
      query: ({searchTerm}) => {
        const qs = new URLSearchParams();
        if (searchTerm) {
          qs.set('search_term', searchTerm);
        }

        return {
          url: `/cadences/hubspot/contact-list/search?${qs}`,
          method: 'GET',
        };
      }
    }),

    editCadenceHSListAutoEnrollment: build.mutation<{cadence: ICadence}, IEditCadenceHSListAutoEnrollment>({
      query: body => {
        const qs = new URLSearchParams({
          clear_listener: `${body.clearListener}`,
          hs_list_id: body.hsListId.toString(),
          connector_id: body.connectorId.toString(),
        });

        return {
          url: `/cadences/${body.cadenceId}/enroll-new-hubspot-contacts-from-list?${qs}`,
          method: 'POST',
        }
      },
      invalidatesTags: (_r, _a, args) => [{type: 'Cadence', id: args.cadenceId}],
    }),

    syncHSListContactsNow: build.mutation<{cadence: ICadence}, ISyncNewHSListContactsNow>({
      query: body => {
        const qs = new URLSearchParams({
          hs_list_id: body.hsListId.toString(),
          connector_id: body.connectorId.toString(),
        });

        return {
          url: `/cadences/${body.cadenceId}/sync-now-from-hs-list?${qs}`,
          method: 'POST',
        };
      },
    }),

    postManuallyEnrollViaContactList: build.mutation<void, {cadenceId: number, listId: number}>({
      query: ({listId, cadenceId}) => {
        return {
          url: `/cadences/${cadenceId}/enroll-via-contact-list?contact_list_id=${listId}`,
          method: 'POST',
        };
      }
    }),
  }),

  overrideExisting: false,
});


export const {
  useCreateNewCadenceMutation,
  usePaginateCadencesQuery,
  usePrefetch,
  usePatchCadenceMutation,
  usePaginateCadenceEnrollmentsQuery,
  useFetchCadenceQuery,
  useFetchCadenceEnrollmentQuery,
  usePostResumeEnrollmentMutation,
  usePostManuallyEnrollMutation,
  useCancelCadenceContactMutation,
  usePaginateCadenceEventsQuery,
  usePostManuallyEnrollViaCSVMutation,
  useSearchHubspotListsQuery,
  useLazySearchHubspotListsQuery,
  usePostManuallyEnrollViaHSListMutation,
  useEditCadenceHSListAutoEnrollmentMutation,
  useSyncHSListContactsNowMutation,
  usePostManuallyEnrollViaContactListMutation,
} = cadencesQueries;