import { Pagination, Table } from 'evergreen-ui';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { dateStrToLocaleStr, getContactName } from 'utils/strings';
import { usePaginateEnrollmentsQuery } from 'api/admin/cadence';
import { IHydratedEnrollment } from 'api/cadence';


const limit = 10;

const EnrollmentRow = ({enrollment}: {enrollment: IHydratedEnrollment}) => {
  const nav = useNavigate();

  const goToEnrollment = () => {
    nav(`/admin/enrollments/${enrollment.cadence_contact.id}`);
  }

  return <Table.Row
    onClick={goToEnrollment}
    isSelectable
  >
    <Table.TextCell>{getContactName(enrollment.contact)} (id: {enrollment.contact.id})</Table.TextCell>
    <Table.TextCell>{enrollment.cadence_contact.status}</Table.TextCell>
    <Table.TextCell>{enrollment.cadence_contact.last_step_at ? dateStrToLocaleStr(enrollment.cadence_contact.last_step_at) : <em>(none)</em>}</Table.TextCell>
    <Table.TextCell>{dateStrToLocaleStr(enrollment.cadence_contact.started_at)}</Table.TextCell>
    <Table.TextCell>{enrollment.cadence.name}</Table.TextCell>
  </Table.Row>
}


const AdminCadenceEnrollmentsTable = ({cadenceId, userId}: {cadenceId?: string | number, userId?: string | number}) => {
  const [offset, setOffset] = useState(0);
  const {
    data,
  } = usePaginateEnrollmentsQuery({limit: 10, offset, cadence_id: cadenceId, user_id: userId});

  const onPrevPageClick = () => {
    if (!data) return;

    const currentOffset = (data.meta.pagination.this - 1) * limit;
    if (currentOffset > 0) {
      setOffset(currentOffset - limit);
    }
  };

  const onNextPage = () => {
    if (!data) return;

    const pagination = data.meta.pagination;
    const currentOffset = (pagination.this - 1) * limit;
    if (pagination.this < pagination.last) {
      setOffset(currentOffset + limit);
    }
  };

  const onPageChange = (page: number) => {
    setOffset((page - 1) * limit);
  };

  return <div>
    <Table>
      <Table.Head>
        <Table.TextHeaderCell>Contact</Table.TextHeaderCell>
        <Table.TextHeaderCell>Status</Table.TextHeaderCell>
        <Table.TextHeaderCell>Last step at</Table.TextHeaderCell>
        <Table.TextHeaderCell>Enrolled at</Table.TextHeaderCell>
        <Table.TextHeaderCell>Cadence</Table.TextHeaderCell>
      </Table.Head>
      <Table.Body>
        {data?.cadence_contacts.map((enrollment) => <EnrollmentRow enrollment={enrollment} key={enrollment.cadence_contact.id} />)}
      </Table.Body>
    </Table>
    {data && (
      <Pagination
        totalPages={data.meta.pagination.last}
        page={data.meta.pagination.this}
        onNextPage={onNextPage}
        onPreviousPage={onPrevPageClick}
        onPageChange={onPageChange}
      />
    )}
  </div>
}


export default AdminCadenceEnrollmentsTable;