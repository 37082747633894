import { Switch } from 'evergreen-ui';

import { IHubspotConnectorSettings } from 'types/user';
import _, { M } from 'constants/i18n';


const HubspotSettings = ({
  settings,
  onChange,
}: {onChange: (settings: IHubspotConnectorSettings) => void, settings: IHubspotConnectorSettings}) => {
  return <div>
    <div className="hubspot-connector-setting--container">
      <div>
        <label className="hubspot-connector-setting--label">{_(M.CREATE_HS_CONTACTS_LABEL)}</label>
        <div className="hubspot-connector-settings--help">{_(M.CREATE_HS_CONTACTS_DESC)}</div>
      </div>
      <Switch
        checked={settings.create_contacts_in_hubspot || true}
        onChange={() => onChange({...settings, create_contacts_in_hubspot: !settings.create_contacts_in_hubspot})}
      />
    </div>
    <div className="hubspot-connector-setting--container">
      <div>
        <label className="hubspot-connector-setting--label">{_(M.LOG_HS_EMAIL_SENDS_LABEL)}</label>
        <div className="hubspot-connector-settings--help">{_(M.LOG_HS_EMAIL_SENDS_DESC)}</div>
      </div>
      <Switch
        checked={settings.log_email_sends_as_contact_activity || true}
        onChange={() => onChange({...settings, log_email_sends_as_contact_activity: !settings.log_email_sends_as_contact_activity})}
      />
    </div>
    <div className="hubspot-connector-setting--container">
      <div>
        <label className="hubspot-connector-settings--label">{_(M.PULL_HS_CTC_PROPS_LABEL)}</label>
        <div className="hubspot-connector-settings--help">{_(M.PULL_HS_CTC_PROPS_DESC)}</div>
      </div>
      <Switch
        checked={settings.pull_contact_properties_from_hubspot || true}
        onChange={() => onChange({...settings, pull_contact_properties_from_hubspot: !settings.pull_contact_properties_from_hubspot})}
      />
    </div>
  </div>
};

export default HubspotSettings;