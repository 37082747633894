import { MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  EmptyState,
  IconButton,
  Table,
  EditIcon,
  Pagination,
  Spinner,
  Tooltip,
  Position,
  toaster,
  Dialog,
  TrashIcon,
} from 'evergreen-ui';

import CadenceIcon from 'components/icons/cadence';
import _, { M } from 'constants/i18n';
import { IContactList } from 'types/contact';
import { dateStrToLocaleStr } from 'utils/strings';
import './style.css';
import { useDeleteContactListMutation, usePaginateContactListsQuery, usePrefetch } from 'api/contactLists';
import CreateListDialog from '../CreateListDialog';


const ContactListRow = ({ list, onClickDelete }: { list: IContactList, onClickDelete: (listId: number) => void }) => {
  const nav = useNavigate();
  const fetchList = usePrefetch('fetchContactList');

  const onTrashClick = (e: MouseEvent) => {
    e.stopPropagation();
    onClickDelete(list.id);
  }

  const goToList = (e: MouseEvent, edit: boolean = false) => {
    e.stopPropagation();
    const url = edit
      ? `/contact-lists/${list.id}/edit`
      : `/contact-lists/${list.id}`;
    nav(url);
  };

  return (
    <Table.Row
      onClick={goToList}
      isSelectable
      onMouseEnter={() => fetchList(list.id)}
    >
      <Table.TextCell>{list.name}</Table.TextCell>
      <Table.TextCell>{list.description || <em>no description</em>}</Table.TextCell>
      <Table.TextCell>{dateStrToLocaleStr(list.created_at)}</Table.TextCell>
      <Table.Cell justifyContent='right'>
        <Tooltip content={_(M.BUTTON_CADENCE_EDIT)} position={Position.TOP}>
          <IconButton
            appearance='minimal'
            icon={EditIcon}
            onClick={(e: MouseEvent) => goToList(e, true)}
          />
        </Tooltip>
        <IconButton
          appearance='minimal'
          icon={TrashIcon}
          onClick={onTrashClick}
        />
      </Table.Cell>
    </Table.Row>
  );
};


const DeleteContactListDialog = ({listId, isShown, onClose}: {listId: number, isShown: boolean, onClose: () => void}) => {
  const [deleteList, deleteResult] = useDeleteContactListMutation();

  const onSubmit = () => {
    deleteList(listId).then((res) => {
      if ('data' in res) {
        toaster.success(_(M.SUCCESS));
        onClose();
      } else {
        toaster.danger(_(M.ERROR));
        onClose();
      }
    })
  }
  return <Dialog isShown={isShown} isConfirmLoading={deleteResult.isLoading} onConfirm={onSubmit} title={_(M.CONFIRM_DELETE_CONTACT_LIST)} onCloseComplete={onClose} confirmLabel={_(M.CONFIRM)} cancelLabel={_(M.CANCEL)}>
    <div>{_(M.CONFIRM_CANNOT_UNDO)}</div>
  </Dialog>;
};


const ContactLists = () => {
  const limit = 5;
  const [offset, setOffset] = useState(0);
  const [confirmDeleteListId, setConfirmDeleteListId] = useState<number | null>(null);
  const [newListDialogOpen, setNewListDialogOpen] = useState(false);

  const { isLoading, isFetching, isError, data } = usePaginateContactListsQuery({
    limit,
    offset,
  });

  const loading = isLoading || isFetching;

  if (loading) {
    return <Spinner delay={100}>{_(M.LOADING)}</Spinner>;
  }

  if (
    !loading &&
    !isError &&
    data?.items.length === 0
  ) {
    return (
      <>
        <CreateListDialog isShown={newListDialogOpen} onClose={() => setNewListDialogOpen(false)} />
        <EmptyState
          title={_(M.EMPTY_CONTACT_LISTS_TITLE)}
          description={_(M.EMPTY_CONTACT_LISTS_DESC)}
          icon={<CadenceIcon />}
          iconBgColor='var(--color-green-2)'
          primaryCta={
            <Button
              appearance='primary'
              onClick={() => setNewListDialogOpen(true)}
            >
              {_(M.NEW_CONTACT_LIST)}
            </Button>
          }
        />
      </>
    );
  }

  const onPrevPageClick = () => {
    if (!data) return;

    const currentOffset = (data.pagination.this - 1) * limit;
    if (currentOffset > 0) {
      setOffset(currentOffset - limit);
    }
  };

  const onNextPage = () => {
    if (!data) return;

    const pagination = data.pagination;
    const currentOffset = (pagination.this - 1) * limit;
    if (pagination.this < pagination.last) {
      setOffset(currentOffset + limit);
    }
  };

  const onPageChange = (page: number) => {
    setOffset((page - 1) * limit);
  };

  return (
    <div className='contact-list--container'>
      <div className="contact-lists--header">
        <div className='contact-lists-title--container'>
          <h2>{_(M.NAV_CONTACT_LISTS)}</h2>
          <a href="https://docs.tinycadence.com/contacts/contact-lists/what-is-a-contact-list" target="_blank" rel="noreferrer">{_(M.MORE_INFO)}</a>
        </div>
        <Button
          appearance='primary'
          onClick={() => setNewListDialogOpen(true)}
        >
          {_(M.NEW_CONTACT_LIST)}
        </Button>
      </div>
      <Table>
        <Table.Head>
          <Table.TextHeaderCell>{_(M.NAME)}</Table.TextHeaderCell>
          <Table.TextHeaderCell>{_(M.DESCRIPTION)}</Table.TextHeaderCell>
          <Table.TextHeaderCell>{_(M.CREATED_AT)}</Table.TextHeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Head>
        <Table.Body>
          {data?.items.map((list) => (
            <ContactListRow list={list} key={list.id} onClickDelete={setConfirmDeleteListId} />
          ))}
        </Table.Body>
      </Table>
      <CreateListDialog isShown={newListDialogOpen} onClose={() => setNewListDialogOpen(false)} />
      {data && (
        <Pagination
          totalPages={data.pagination.last}
          page={data.pagination.this}
          onNextPage={onNextPage}
          onPreviousPage={onPrevPageClick}
          onPageChange={onPageChange}
        />
      )}
      <DeleteContactListDialog isShown={!!confirmDeleteListId} onClose={() => setConfirmDeleteListId(null)} listId={confirmDeleteListId || 0}/>
    </div>
  );
};

export default ContactLists;
