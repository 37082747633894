import { adminQueries } from 'api/queries';
import { IPagination } from 'api/cadence';
import { ICompany, ICompanyUser } from 'types/company';


const companyPage = {type: 'Company' as const, id: 'PAGE'};

interface IListCompaniesArgs {
  offset: number;
  limit: number;
}

const companyQueries = adminQueries.injectEndpoints({
  endpoints: (build) => ({
    paginateCompanies: build.query<{items: ICompany[], pagination: IPagination}, IListCompaniesArgs>({
      query: (body) => {
        const qs = new URLSearchParams();
        qs.set('limit', body.limit.toString());
        qs.set('offset', body.offset.toString());
        return {
          url: `/companies/?${qs}`,
          method: 'GET',
        }
      },

      providesTags: (res) => res ? [companyPage, ...res.items.map(c => ({type: 'Company', id: c.id}))] : [],
    }),

    fetchCompany: build.query<{company: ICompany}, string>({
      query: (id: string) => `/companies/${id}`,

      providesTags: (res) => res ? [{type: 'Company', id: res.company.id}] : [],
    }),

    paginateCompanyUsers: build.query<{items: ICompanyUser[], pagination: IPagination}, {companyId: number | string, offset: number, limit: number}>({
      query: (body) => {
        const qs = new URLSearchParams();
        qs.set('limit', body.limit.toString());
        qs.set('offset', body.offset.toString());
        return {
          url: `/companies/${body.companyId}/users?${qs}`,
          method: 'GET',
        }
      },
    })
  }),
  overrideExisting: false,
});


export const {
  usePaginateCompaniesQuery,
  useFetchCompanyQuery,
  usePaginateCompanyUsersQuery,
} = companyQueries;