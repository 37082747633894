import { Switch } from 'evergreen-ui';
import { useEffect, useState } from 'react';

import _, { M } from 'constants/i18n';
import { Condition as ConditionType, IBranchNodeParams } from 'types/cadence';
import Condition from './condition';


const hashCondition = (condition: ConditionType, idx: number) => {
  if ('value' in condition) {
    const condValue = condition.value.join('__&__');
    return `field:${condition.field}_operator:${condition.operator}_value:${condValue || idx}`;
  } else {
    return `field:${condition.field}_value:${idx}`;
  }
};


const conditionSetToConditionsList = (conditions: ConditionType[]): ConditionType[] => {
  return (conditions
    .map(cond => {
      if ('value' in cond) {
        return {field: cond.field, operator: cond.operator, value: cond.value};
      } else if ('field' in cond) {
        return {field: cond.field};
      } else {
        // doesn't handle nested conditions for now
        return undefined;
      }
    })
    .filter(cond => !!cond) as ConditionType[]);
};


const EditConditionoBranchParams = ({
  params,
  onChange,
}: {params: IBranchNodeParams, onChange: (params: IBranchNodeParams) => void}) => {

  const paramConditions = params.conditions;
  const [evalOption, setEvalOption] = useState<'ANY' | 'ALL'>(paramConditions.evaluation);
  const [conditions, setConditions] = useState<ConditionType[]>(conditionSetToConditionsList(paramConditions.conditions));

  const addEmptyCondition = () => {
    setConditions(conditions.concat([{field: 'NAME', operator: 'CONTAINS', value: []}]));
  };

  const removeCondition = (idx: number) => {
    setConditions(conditions.filter((_c, i) => i !== idx));
  };

  const updateCondition = (newCondition: ConditionType, idx: number) => {
    setConditions(conditions.map((existingCondition, i) => i === idx ? newCondition : existingCondition));
  };

  useEffect(() => {
    onChange({
      conditions: {
        conditions,
        evaluation: evalOption,
        conditions_fail_next_node_id: paramConditions.conditions_fail_next_node_id,
      },
    })
  }, [evalOption, conditions, onChange, paramConditions.conditions_fail_next_node_id]);


  return <div className="new-rule-form--field-container">
    <div className="new-rule-form--field-title">{_(M.CONDITIONS)}</div>
    <div className="new-rule-form--field-description">{_(M.CONDITIONS_SUBTITLE)}</div>
    <div className="new-rule-conditions-eval--container">
      <div>{_(M.CONDITIONS_NEEDED)}</div>
      <div className="new-rule-conditions-eval--option">
        {_(M.ALL)} <Switch checked={evalOption === 'ANY'} onChange={() => setEvalOption(evalOption === 'ALL' ? 'ANY' : 'ALL')} /> {_(M.ANY)}
      </div>
    </div>
    {conditions.map((cond: ConditionType, idx: number) => (
      <Condition
        condition={cond}
        conjunction={evalOption === 'ALL' ? _(M.AND) : _(M.OR)}
        place={idx}
        key={hashCondition(cond, idx)}
        onDeleteCondition={() => removeCondition(idx)}
        onChange={(cond: ConditionType) => updateCondition(cond, idx)}
      />
    ))}
    <div className="new-rule-form--add-condition" onClick={addEmptyCondition}>{conditions.length ? _(M.ADD_ANOTHER_CONDITION) : _(M.ADD_CONDITION)}</div>
  </div>;
};

export default EditConditionoBranchParams;