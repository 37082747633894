import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { mergeDeepRight } from 'ramda';
import { Button, Checkbox, Spinner } from 'evergreen-ui';

import _, { M } from 'constants/i18n';
import { dateStrToLocaleStr } from 'utils/strings';
import { useFetchCadenceQuery, usePatchCadenceMutation } from 'api/cadence';
import { useAppDispatch, useAppSelector } from 'hooks';
import { editCadence, setEditingCadence } from 'store/cadence/slice';
import { editingCadenceHasChanged, getEditingCadence } from 'store/cadence/selector';
import { ICadence } from 'types/cadence';
import { TrackEventNames, tracker } from 'utils/tracking';

import HubSpotListSyncForCadence from './hubspotListSync';
import EditBizHoursSendDialog from './bizHoursDialog';
import './style.css';

const ViewCadence = () => {
  const { cadenceId: cadenceIdStr='' } = useParams<{cadenceId: string}>();
  const [editBizHoursVisible, setEditBizHoursVisible] = useState(false);
  const nav = useNavigate();
  const dispatch = useAppDispatch();
  const [patch, patchRes] = usePatchCadenceMutation();
  const saving = patchRes.isLoading;
  const editingCadence = useAppSelector(getEditingCadence);
  const cadenceId = parseInt(cadenceIdStr);
  const {
    isFetching,
    isLoading,
    isError: error,
    data,
  } = useFetchCadenceQuery(cadenceId);
  const hasChanged = useAppSelector(s => editingCadenceHasChanged(s, data?.cadence));

  const loading = isLoading || isFetching;

  useEffect(() => {
    const noEditingCadence = editingCadence === null;
    const editingCadenceIsNew = data && editingCadence !== null && data?.cadence.id !== editingCadence.id;
    if ((noEditingCadence || editingCadenceIsNew) && data?.cadence) {
      dispatch(setEditingCadence(data.cadence));
    }
  }, [data, dispatch, editingCadence]);

  if (loading) {
    return <Spinner delay={100}>{_(M.LOADING)}</Spinner>
  }

  if (!data || error) {
    return <div>{_(M.ERROR)}</div>
  }

  const setOption = (option: string, value: boolean) => {
    const newCadence = mergeDeepRight(editingCadence || {}, {
      data: { options: { ...(editingCadence?.data.options || {}), [option]: value }}
    });
    dispatch(editCadence(newCadence as Partial<ICadence>));
  };

  const onSaveCadence = async () => {
    if (saving || !editingCadence) return;

    patch(editingCadence).then(() => tracker.track(TrackEventNames.PC));
  };

  const onArchiveCadence = async () => {
    try {
      await patch({
        ...cadence,
        status: cadence.status === 'archived' ? 'active' : 'archived',
      });
      tracker.track(TrackEventNames.PC);
    } catch (error) {
      // TODO: log error
      console.warn('failed to archive cadence', error);
    }
  };

  const cadence = data.cadence;

  return <div className='view-cadence--container'>
    <div className="view-cadence--header">
      <div>
        <h2 className="view-cadence--title">{cadence.name}</h2>
        <div className="view-cadence--subtitle">{cadence.description}</div>
      </div>
      <div className="view-cadence--actions">
        <Button isLoading={saving || isFetching} disabled={!hasChanged} onClick={onSaveCadence} appearance='primary'>{_(M.SAVE)}</Button>
        <Button
          appearance="primary"
          intent="success"
          onClick={() => nav(`/cadences/${cadenceId}/enroll`)}
        >
          {_(M.ENROLL)}
        </Button>
        <Button onClick={() => nav(`/cadences/${cadenceId}/edit`)}>{_(M.NAV_CADENCE_EDIT)}</Button>
        <Button
          onClick={onArchiveCadence}
        >{cadence.status === 'archived' ? _(M.BUTTON_CADENCE_UNARCHIVE) : _(M.BUTTON_CADENCE_ARCHIVE)}</Button>
      </div>
    </div>
    <div className="view-cadence-dates--container">
      <div className="view-cadence-dates--date">
        <div className="view-cadence-dates--label">{_(M.CADENCE_SETTINGS_CREATED_AT)}</div>
        <div className="view-cadence-dates--value">{dateStrToLocaleStr(cadence.created_at)}</div>
      </div>
      <div className="view-cadence-dates--date">
        <div className="view-cadence-dates--label">{_(M.CADENCE_SETTINGS_UPDATED_AT)}</div>
        <div className="view-cadence-dates--value">{dateStrToLocaleStr(cadence.updated_at)}</div>
      </div>
    </div>
    <div>
      <div className="view-cadence-settings-header--container">
        <h3 className="view-cadence-settings--header">{_(M.CADENCE_SETTINGS_TITLE)}</h3>
        <a href="https://docs.tinycadence.com/cadences/cadences/cadence-settings" target="_blank" rel="noreferrer">{_(M.MORE_INFO)}</a>
      </div>
      <div>
        <div className="view-cadence-edit-biz-hours--container">
          <Checkbox
            label={_(M.CADENCE_EMAIL_SEND_DURING_BIZ_HOURS)}
            className="view-cadence-edit-biz-hours--label"
            checked={editingCadence?.data.options?.only_send_email_during_biz_hours || false}
            onChange={() => setOption('only_send_email_during_biz_hours', !editingCadence?.data.options?.only_send_email_during_biz_hours)}
          />
          <div onClick={() => setEditBizHoursVisible(true)} className="view-cadence-biz-hours-edit--btn">{_(M.EDIT)}</div>
        </div>
        <Checkbox
          label={_(M.CADENCE_EMAIL_SEND_AS_REPLIES)}
          checked={editingCadence?.data.options?.send_emails_as_replies || false}
          onChange={() => setOption('send_emails_as_replies', !editingCadence?.data.options?.send_emails_as_replies)}
        />
        <Checkbox
          label={_(M.CADENCE_EMAIL_PAUSE_BEFORE_SEND)}
          checked={editingCadence?.data.options?.pause_before_sending || false}
          onChange={() => setOption('pause_before_sending', !editingCadence?.data.options?.pause_before_sending)}
        />
        <Checkbox
          label={_(M.CADENCE_EMAIL_UNENROLL_ON_REPLY)}
          checked={editingCadence?.data.options?.unenroll_on_reply || false}
          onChange={() => setOption('unenroll_on_reply', !editingCadence?.data.options?.unenroll_on_reply)}
        />
        <Checkbox
          label={_(M.CADENCE_EMAIL_UNENROLL_ON_MTG)}
          checked={editingCadence?.data.options?.unenroll_on_mtg_scheduled || false}
          onChange={() => setOption('unenroll_on_mtg_scheduled', !editingCadence?.data.options?.unenroll_on_mtg_scheduled)}
        />
      </div>
    </div>
    <HubSpotListSyncForCadence />
    <EditBizHoursSendDialog isShown={editBizHoursVisible} onClose={() => setEditBizHoursVisible(false)}/>
  </div>;
};


export default ViewCadence;