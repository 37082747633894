import { useFetchEnrollmentQuery } from 'api/admin/cadence';
import { useParams } from 'react-router-dom';


const AdminEnrollmentCadencePage = () => {
  const {enrollmentId = ''} = useParams<{enrollmentId: string}>()

  const {
    data,
    isLoading,
    isFetching,
    isError,
  } = useFetchEnrollmentQuery(enrollmentId);

  if (!data || isLoading || isFetching) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error</div>
  }

  return <div>
    <pre>{JSON.stringify(data.cadence_contact.cadence, undefined, 2)}</pre>
  </div>

}

export default AdminEnrollmentCadencePage;