import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFetchUserQuery } from 'api/user';
import { useAppSelector } from 'hooks';
import { getCurrentUser } from 'store/user/selector';
import { AnyMessage, MessageTypes } from 'types/conversation';
import { dateStrToLocaleStr } from 'utils/strings';


// TODO: i18n
const messageTypeToLabel: Record<MessageTypes, string> = {
  [MessageTypes.ARCHIVE]: '...',
  [MessageTypes.UNARCHIVE]: '...',
  [MessageTypes.IM]: '...',
  [MessageTypes.EMAIL]: '...',
  [MessageTypes.DRAFT_SEND]: '...',
  [MessageTypes.SNOOZE]: '...',
  [MessageTypes.UNSNOOZE]: '...',
  [MessageTypes.DELETE]: '...',
  [MessageTypes.UNDELETE]: '...',

  [MessageTypes.LINK]: 'link',
  [MessageTypes.NOTE]: 'note',
  [MessageTypes.DRAFT]: 'draft',
  [MessageTypes.COMMENT]: 'comment',
};


const DeletedMessage = ({message}: {message: AnyMessage}) => {
  const userRes = useFetchUserQuery({userId: message.author_id});
  const user = userRes.data?.user;
  const currentUser = useAppSelector(getCurrentUser);
  const deletorIsCurrentUser = user !== undefined && currentUser?.id === user.id;
  const userName = deletorIsCurrentUser ? 'You' : user?.full_name || user?.email || 'unknown user';
  return <div className="deleted-message--container">
    <FontAwesomeIcon icon={faTrashCan} className="deleted-message--icon" size="sm" />
    {userName} deleted a {messageTypeToLabel[message.message_type]} on {dateStrToLocaleStr(message.deleted_at as string)}
  </div>
};

export default DeletedMessage;