import { adminQueries } from 'api/queries';
import { IPagination } from 'api/cadence';
import { IWorkerJob } from 'types/worker_job';


const jobsPage = {type: 'WorkerJob' as const, id: 'PAGE'};

interface IListJobsArgs {
  offset: number;
  limit: number;
}

const workerJobsQueries = adminQueries.injectEndpoints({
  endpoints: (build) => ({
    paginateJobs: build.query<{items: IWorkerJob[], pagination: IPagination}, IListJobsArgs>({
      query: (body) => {
        const qs = new URLSearchParams();
        qs.set('limit', body.limit.toString());
        qs.set('offset', body.offset.toString());
        return {
          url: `/worker_jobs/?${qs}`,
          method: 'GET',
        }
      },

      providesTags: (res) => res ? [jobsPage, ...res.items.map(c => ({type: 'WorkerJob', id: c.id}))] : [],
    }),

    fetchJob: build.query<{job: IWorkerJob}, number | string>({
      query: (id: number | string) => `/worker_jobs/${id}`,

      providesTags: (res) => res ? [{type: 'WorkerJob', id: res.job.id}] : [],
    }),

    pauseJob: build.mutation<{job: IWorkerJob}, number | string>({
      query: (id: number | string) => ({
        url: `/worker_jobs/${id}/pause`,
        method: 'POST'
      }),

      invalidatesTags: (res) => res ? [{type: 'WorkerJob', id: res.job.id}] : [],
    }),

    restartJob: build.mutation<{job: IWorkerJob}, number | string>({
      query: (id: number | string) => ({
        url: `/worker_jobs/${id}/restart`,
        method: 'POST'
      }),

      invalidatesTags: (res) => res ? [{type: 'WorkerJob', id: res.job.id}] : [],
    }),
  }),
  overrideExisting: false,
});


export const {
  usePaginateJobsQuery,
  useFetchJobQuery,
  usePauseJobMutation,
  useRestartJobMutation,
} = workerJobsQueries;