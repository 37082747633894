import { useParams } from 'react-router-dom';
import { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { Button, Dialog, Label, TextInputField, toaster } from 'evergreen-ui';
import Select from 'react-select';

import { dateStrToLocaleStr } from 'utils/strings';
import { useFetchConnectorQuery, useResetDailyConnectorIngestTaskMutation, useSetConnectorStatusMutation, useWatchInboxMutation } from 'api/admin/connector';
import { IConnector } from 'types/user';
import { ConnectorProviders } from 'constants/app';
import { useCreateInboxFromConnectorMutation, useDeleteInboxForConnectorMutation } from 'api/admin/inbox';



const ResetDailyTaskDialog = ({
  connector,
  isShown,
  onClose,
}: {connector: IConnector, isShown: boolean, onClose: () => void}) => {
  const [ghOrg, setGHOrg] = useState('');
  const [scheduledFor, setScheduledFor] = useState('');
  const [patch, result] = useResetDailyConnectorIngestTaskMutation();

  const submit = () => {
    patch({connectorId: connector.id, orgName: ghOrg, scheduledFor})
      .then(() => {
        toaster.success('Success')
        onClose()
      })
      .catch(() => {
        toaster.danger('Failed to reset task')
      })
  };

  return <Dialog title="Set connector status" isShown={isShown} isConfirmLoading={result.isLoading} onConfirm={submit} onCloseComplete={onClose}>
    <TextInputField
      label="Github Organization"
      placeholder="my-org-name"
      value={ghOrg}
      hint="This shold be the string after the first / in the GitHub URL"
      onChange={(e: ChangeEvent<HTMLInputElement>) => setGHOrg(e.target.value)}
    />
    <div>
      <Label>Scheduled for</Label>
      <input
        type='datetime-local'
        value={scheduledFor}
        onChange={(e) => setScheduledFor(e.currentTarget.value)}
      />
    </div>

  </Dialog>
};


const ChangeStatusDialog = ({
  connector,
  isShown,
  onClose,
}: {connector: IConnector, isShown: boolean, onClose: () => void}) => {
  const [selectedStatus, setSelectedStatus] = useState({value: connector.status, label: connector.status});
  const [patch, result] = useSetConnectorStatusMutation();

  const submit = () => {
    patch({connectorId: connector.id, status: selectedStatus.value})
      .then(onClose)
  };

  return <Dialog title="Set connector status" isShown={isShown} isConfirmLoading={result.isLoading} onConfirm={submit} onCloseComplete={onClose}>
    <Select
      value={selectedStatus}
      options={[{value: 'active', label: 'active'}, {value: 'disabled', label: 'disabled'}, {value: 'suspended', label: 'suspended'}]}
      isClearable={false}
      onChange={d => d && setSelectedStatus(d)}
      menuPortalTarget={document.body}
      styles={{ menuPortal: (base) => ({ ...base, fontSize: '12px', zIndex: 9999 }) }}
    />
  </Dialog>
};



const AdminConnectorPage = () => {
  const {connectorId = ''} = useParams<{connectorId: string}>()
  const [changeStatusDialogOpen, setChangeStatusDialogOpen] = useState(false);
  const [resetDailyTaskDialogOpen, setResetDailyTaskDialogOpen] = useState(false);
  const [createInbox, createInboxResult] = useCreateInboxFromConnectorMutation();
  const [deleteInbox, deleteInboxResult] = useDeleteInboxForConnectorMutation();
  const [watchInbox, watchInboxResult] = useWatchInboxMutation();

  useEffect(() => {
    if (createInboxResult.isSuccess) {
      toaster.success('Successfully created inbox');
    } else if (createInboxResult.isError) {
      const errData = 'data' in createInboxResult.error && createInboxResult.error.data;
      if (typeof errData === 'object' && errData !== null && 'detail' in errData) {
        toaster.danger(errData.detail as ReactNode);
      } else {
        toaster.danger('Failed to create inbox');
      }
    }
  }, [createInboxResult]);

  useEffect(() => {
    if (deleteInboxResult.isSuccess) {
      toaster.success('Successfully deleted inbox');
    } else if (deleteInboxResult.isError) {
      const errData = 'data' in deleteInboxResult.error && deleteInboxResult.error.data;
      if (typeof errData === 'object' && errData !== null && 'detail' in errData) {
        toaster.danger(errData.detail as ReactNode);
      } else {
        toaster.danger('Failed to delete inbox');
      }
    }
  }, [deleteInboxResult]);

  useEffect(() => {
    if (watchInboxResult.isSuccess) {
      toaster.success('Successfully watched inbox');
    } else if (watchInboxResult.isError) {
      const errData = 'data' in watchInboxResult.error && watchInboxResult.error.data;
      if (typeof errData === 'object' && errData !== null && 'detail' in errData) {
        toaster.danger(errData.detail as ReactNode);
      } else {
        toaster.danger('Failed to watch inbox');
      }
    }
  }, [watchInboxResult]);

  const {
    data,
    isLoading,
    isFetching,
    isError,
  } = useFetchConnectorQuery(connectorId);

  if (!data || isLoading || isFetching) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error</div>
  }

  const connector = data.connector;

  return <div>
    <ChangeStatusDialog connector={connector} isShown={changeStatusDialogOpen} onClose={() => setChangeStatusDialogOpen(false)}/>
    <ResetDailyTaskDialog connector={connector} isShown={resetDailyTaskDialogOpen} onClose={() => setResetDailyTaskDialogOpen(false)}/>
    <div>
      <h3>Connector details</h3>
      <div className="admin-page-actions--container">
        <Button onClick={() => setChangeStatusDialogOpen(true)}>Change status</Button>
        {connector.provider === ConnectorProviders.GITHUB && <Button onClick={() => setResetDailyTaskDialogOpen(true)}>Reset daily ingest</Button>}
        {connector.provider === ConnectorProviders.GMAIL && <Button onClick={() => createInbox({connectorId})} isLoading={createInboxResult.isLoading}>Create inbox</Button>}
        {connector.provider === ConnectorProviders.GMAIL && <Button onClick={() => deleteInbox({connectorId})} isLoading={deleteInboxResult.isLoading} intent="danger">Delete inbox</Button>}
        {connector.provider === ConnectorProviders.GMAIL && <Button onClick={() => watchInbox({connectorId})} isLoading={watchInboxResult.isLoading}>Watch Inbox</Button>}
      </div>
      <div className="admin-user-page-detail--container">
        <div className="admin-user-page-detail--label">Provider</div>
        <div className="admin-user-page-detail--value">{connector.provider}</div>
      </div>
      <div className="admin-user-page-detail--container">
        <div className="admin-user-page-detail--label">Status</div>
        <div className="admin-user-page-detail--value">{connector.status}</div>
      </div>
      <div className="admin-user-page-detail--container">
        <div className="admin-user-page-detail--label">External Account ID</div>
        <div className="admin-user-page-detail--value">{connector.external_account_id}</div>
      </div>
      <div className="admin-user-page-detail--container">
        <div className="admin-user-page-detail--label">User ID</div>
        <div className="admin-user-page-detail--value">{connector.user_id}</div>
      </div>
      <div className="admin-user-page-detail--container">
        <div className="admin-user-page-detail--label">Created</div>
        <div className="admin-user-page-detail--value">{dateStrToLocaleStr(connector.created_at)}</div>
      </div>
      <div className="admin-user-page-detail--container">
        <div className="admin-user-page-detail--label">Updated</div>
        <div className="admin-user-page-detail--value">{dateStrToLocaleStr(connector.updated_at)}</div>
      </div>
    </div>
    <div>
      <h3>Connector meta</h3>
      <pre>{JSON.stringify(connector.meta, undefined, 2)}</pre>
    </div>
  </div>
}

export default AdminConnectorPage;