import { Button } from 'evergreen-ui';
import { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { useParams } from 'react-router-dom';

import _, { M } from 'constants/i18n';
import { useLazySearchHubspotListsQuery } from 'api/cadence';
import { resetNewEnrollment, setEnrollConfiguration } from 'store/cadence/slice';
import { useAppDispatch } from 'hooks';


const EnrollByHubSpotList = () => {
  const {cadenceId: cadenceIdStr='0'} = useParams<{cadenceId: string}>();
  const cadenceId = parseInt(cadenceIdStr || '0');
  const [hsListId, setHsListId] = useState<null | number>(null);
  const [hsListSize, setHsListSize] = useState<null | number>(null);
  const [hsListConnectorId, setHsListConnectorId] = useState<null | number>(null);
  const [trigger] = useLazySearchHubspotListsQuery();
  const dispatch = useAppDispatch();

  const onSubmit = async () => {
    if (hsListId) {
      dispatch(setEnrollConfiguration({args: {cadenceId, listId: hsListId as number, connectorId: hsListConnectorId as number}, estSize: hsListSize || 0}));
    }
  };

  const loadOptions = (
    searchTerm: string,
    callback: (options: {label: string, value: number, connectorId: number, listSize: number}[]) => void
  ) => {
    trigger({searchTerm})
      .unwrap()
      .then(({lists}) => {
        callback(lists.map(list => ({
          label: `${list.name} (${list.size} ${_(M.CONTACTS)})`,
          value: list.id,
          connectorId: list.connector_id,
          listSize: list.size,
        })));
      })
  };

  return (
    <>
      <AsyncSelect
        cacheOptions
        onChange={s => {
          if (!s) {
            setHsListId(null);
            setHsListConnectorId(null);
            setHsListSize(null);
          } else {
            setHsListId(s.value);
            setHsListConnectorId(s.connectorId);
            setHsListSize(s.listSize)
          }
        }}
        loadOptions={loadOptions}
        defaultOptions
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
      />
      <div className="enroll-dialog--footer">
        <Button onClick={() => dispatch(resetNewEnrollment())}>{_(M.BACK)}</Button>
        <Button intent="success" appearance="primary" marginLeft={16} disabled={!hsListId} onClick={onSubmit}>{_(M.NEXT)}</Button>
      </div>
    </>
  );
};

export default EnrollByHubSpotList;