import { useParams } from 'react-router-dom';

import AdminCadenceEnrollmentsTable from 'components/admin/EnrollmentsTable';

const AdminCadenceEnrollmentsPage = () => {
  const {cadenceId = ''} = useParams<{cadenceId: string}>()

  return <AdminCadenceEnrollmentsTable cadenceId={cadenceId} />
}

export default AdminCadenceEnrollmentsPage;
