import { Outlet } from 'react-router-dom';

import './style.css';

const AuthOutlet = () => {

  return (
    <div className='tinycadence-auth--background'>
      <div className='tinycadence-auth--container'>
        <h3>Tiny Cadence</h3>
        <Outlet />
      </div>
    </div>
  );
};

export default AuthOutlet;