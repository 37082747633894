import { Dialog } from 'evergreen-ui';
import { equals } from 'ramda';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';

import _, { M } from 'constants/i18n';
import { useAppDispatch } from 'hooks';
import { useCreateNewCadenceMutation } from 'api/cadence';
import { setEditingCadence } from 'store/cadence/slice';
import { ICreateCadenceArgs } from 'types/cadence';
import { TrackEventNames, tracker } from 'utils/tracking';

import { CadenceTemplates } from './constants';
import './style.css';


const CreateCadenceDialog = ({
  isShown,
  onClose,
}: {isShown?: boolean, onClose: () => void}) => {
  const [create, result] = useCreateNewCadenceMutation();
  const [selectedTemplate, setSelectedTemplate] = useState<ICreateCadenceArgs | null>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const createAndNavigate = () => {
    if (!selectedTemplate) return;

    create(selectedTemplate).then((res) => {
      if ('data' in res && res.data !== undefined) {
        tracker.track(TrackEventNames.CC);
        dispatch(setEditingCadence(res.data.cadence));
        navigate(`/cadences/${res.data.cadence.id}/edit`)
        onClose();
      }
    });
  };


  return <Dialog width="80%" topOffset={80} isShown={isShown} isConfirmLoading={result.isLoading} onConfirm={createAndNavigate} title={_(M.CHOOSE_CADENCE_TEMPLATE_TITLE)} onCloseComplete={onClose} confirmLabel={_(M.CONFIRM)} cancelLabel={_(M.CANCEL)}>
    <div className="cadence-template-options--container">
      {CadenceTemplates.map((c, idx) => (
        <div key={idx} onClick={() => setSelectedTemplate(c.cadence)} className={cx('create-cadence-dialog-option--container', {selected: equals(c.cadence, selectedTemplate)})}>
          <div><c.Icon className='create-cadence-option--icon'/></div>
          <div className='create-cadence-option--name'>{c.cadence.name}</div>
          <div className='create-cadence-option--desc'>{c.cadence.description}</div>
        </div>
      ))}
    </div>
  </Dialog>;
};

export default CreateCadenceDialog;