import { PayloadAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchGmailConnectorLabels } from 'api/connectors';
import { AppDispatch, RootState } from 'store';

import { IBranchNodeParams, ICadence, ICadenceNode } from 'types/cadence';
import { getCsvColumnMapping, getEnrollmentArgs, getGmailLabelsLoading, getNewEnrollmentMethod } from './selector';
import { enrollViaContactList, enrollViaCsv, enrollViaHsList, manuallyEnroll, usePostManuallyEnrollMutation, usePostManuallyEnrollViaCSVMutation, usePostManuallyEnrollViaContactListMutation, usePostManuallyEnrollViaHSListMutation } from 'api/cadence';


export enum CadenceListView {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
};

export enum EnrollmentsListView {
  DONE = 'done',
  PAUSED = 'paused',
  IN_PROGRESS = 'in_progress',
  SCHEDULED = 'scheduled',
  CANCELLED = 'cancelled',
};


const initialState: ICadenceState = {
  editingCadence: null,
  gmailLabels: {
    data: null,
    isFetching: false,
    fetchFailed: false,
  },
  newEnrollment: {
    step: 'choose_method',
    enrollmentMethod: null,
    estimatedNumContacts: null,
    enrollmentArguments: null,
    csvColumnToPropDefIdMap: null,
    isEnrolling: false,
    enrollResult: null,
  },
};

interface ICadenceState {
  editingCadence: ICadence | null,
  gmailLabels: {
    data: null | {id: string, name: string}[],
    isFetching: boolean,
    fetchFailed: boolean,
  },
  newEnrollment: {
    step: 'choose_method' | 'configure_method' | 'csv_mapping' | 'review',
    enrollmentMethod: null | 'single' | 'bulk_csv' | 'hubspot_list' | 'contact_list',
    estimatedNumContacts: null | number,
    enrollmentArguments: null | Parameters<ReturnType<typeof usePostManuallyEnrollMutation>[0]>[0] | Parameters<ReturnType<typeof usePostManuallyEnrollViaCSVMutation>[0]>[0] | Parameters<ReturnType<typeof usePostManuallyEnrollViaHSListMutation>[0]>[0] | Parameters<ReturnType<typeof usePostManuallyEnrollViaContactListMutation>[0]>[0],
    csvColumnToPropDefIdMap: null | Record<string, number | string>,
    isEnrolling: boolean,
    enrollResult: null | 'error' | 'success',
  }
}

export const enrollCadence = createAsyncThunk<boolean, undefined, { dispatch: AppDispatch, state: RootState }>(
  'cadence/enroll',
  async (_, {getState, dispatch}) => {
    const state = getState();
    const method = getNewEnrollmentMethod(state);
    const args = getEnrollmentArgs(state);
    const mapping = getCsvColumnMapping(state);
    if (method === null) {
      return false;
    }
    const methodToApiCall: Record<typeof method, any> = {
      'bulk_csv': enrollViaCsv,
      'contact_list': enrollViaContactList,
      'hubspot_list': enrollViaHsList,
      'single': manuallyEnroll,
    }
    const apiCall = methodToApiCall[method];
    dispatch(cadenceSlice.actions.setNewEnrollmentLoading());
    try {
      const body: any = {...args};
      if (mapping) {
        body.csvMapping = mapping
      }
      await apiCall(body);
      return true;
    } catch (err) {
      // TODO: capture exception
      return false;
    }
  },
);


export const fetchGmailLabels = createAsyncThunk<{id: string, name: string}[] | undefined, undefined, {
  dispatch: AppDispatch,
  state: RootState,
}>(
  'cadence/fetchGmailLabels',
  async (_, {getState, dispatch}) => {
    if (!getGmailLabelsLoading(getState())) {
      dispatch(cadenceSlice.actions.setGmailLabelsLoading({ loading: true }));
      const {data} = await fetchGmailConnectorLabels();
      return data.labels;
    }
  }
);



const cadenceSlice = createSlice({
  name: 'cadence',
  initialState,
  reducers: {
    setEditingCadence: (state, action: PayloadAction<ICadence>) => {
      state.editingCadence = action.payload;
    },

    editCadence: (state, action: PayloadAction<Partial<ICadence>>) => {
      if (state.editingCadence === null) {
        console.warn('[TC.app] Ignoring editCadence action, editingCadence is null');
        return;
      } else {
        state.editingCadence = {...state.editingCadence, ...action.payload};
      }
    },

    setGmailLabelsLoading: (state, action: PayloadAction<{loading: boolean}>) => {
      state.gmailLabels.isFetching = action.payload.loading;
    },

    addOrEditNodeInCadence: (state, action: PayloadAction<{node: ICadenceNode, doNotChangeNextNode?: boolean}>) => {
      const cadence = state.editingCadence;

      // short circuit if not editing
      if (!cadence) return;

      const {
        node: newNode,
        doNotChangeNextNode,
      } = action.payload;

      const nodes = cadence.data.nodes;

      const nodeInCadence = nodes.find(({id}) => action.payload.node.id === id);

      if (nodeInCadence) {
        cadence.data.nodes = nodes.map((node) => {
          if (node.id !== action.payload.node.id) {
            return node;
          } else {
            return {
              ...newNode,
              next_node_id: doNotChangeNextNode ? node.next_node_id : newNode.next_node_id,
            };
          }
        });
      } else {
        cadence.data.nodes.push(action.payload.node);
      }
    },

    setNextNodeId: (state, action: PayloadAction<{parentNodeId: string, nextNodeId: string, asFallback?: boolean}>) => {
      const cadence = state.editingCadence;

      // short circuit if not editing
      if (!cadence) return;

      const {
        parentNodeId,
        nextNodeId,
        asFallback,
      } = action.payload;

      let previousNextNodeId: string | null = null;

      cadence.data.nodes = cadence.data.nodes.map((node) => {
        if (node.id === parentNodeId) {
          // we might be setting the node to go to if the branch conditions failed
          if (asFallback && node.action.type === 'BRANCH') {
            //@ts-ignore
            const params: IBranchNodeParams = {...node.action.params};
            previousNextNodeId = params.conditions.conditions_fail_next_node_id;
            params.conditions.conditions_fail_next_node_id = nextNodeId;
            return {
              ...node,
              action: {
                ...node.action,
                params,
              }
            };
          } else {
            previousNextNodeId = node.next_node_id;
            return {
              ...node, next_node_id: nextNodeId,
            };
          }
        } else {
          return node;
        }
      });

      // if another node was previously the parent's child, we have to set that node
      // as the child to the "next" nodea
      if (previousNextNodeId) {
        cadence.data.nodes = cadence.data.nodes.map((node) => {
          if (node.id === nextNodeId) {
            return {
              ...node,
              next_node_id: previousNextNodeId,
            };
          }
          return node;
        });
      }
    },

    deleteNode: (state, action: PayloadAction<{nodeId: string}>) => {
      const cadence = state.editingCadence;

      // short circuit if not editing
      if (!cadence) return;

      const node = cadence.data.nodes.find(({id}) => action.payload.nodeId === id);

      if (!node) return;

      const nextNodeId = node.next_node_id;

      const newNodes = cadence.data.nodes
        .filter(({id}) => action.payload.nodeId !== id)
        .map(node => {
          if (node.next_node_id === action.payload.nodeId) {
            return {
              ...node,
              next_node_id: nextNodeId,
            };
          } else {
            return node;
          }
        });

      if (cadence.data.start_node === action.payload.nodeId) {
        cadence.data.start_node = nextNodeId;
      }
      cadence.data.nodes = newNodes;
    },


    resetNewEnrollment: (state) => {
      state.newEnrollment.enrollmentMethod = null;
      state.newEnrollment.step = 'choose_method';
      state.newEnrollment.estimatedNumContacts = null;
      state.newEnrollment.csvColumnToPropDefIdMap = null;
      state.newEnrollment.enrollResult = null;
      state.newEnrollment.isEnrolling = false;
      state.newEnrollment.enrollmentArguments = null;
    },

    setNewEnrollmentMethod: (state, action: PayloadAction<ICadenceState['newEnrollment']['enrollmentMethod']>) => {
      if (action.payload === null) {
        state.newEnrollment.step = 'choose_method';
      } else {
        state.newEnrollment.step = 'configure_method';
      }
      state.newEnrollment.enrollmentMethod = action.payload;
    },

    setEnrollConfiguration: (state, action: PayloadAction<{args: ICadenceState['newEnrollment']['enrollmentArguments'], estSize?: number}>) => {
      state.newEnrollment.enrollmentArguments = action.payload.args;
      if (state.newEnrollment.enrollmentMethod === 'bulk_csv') {
        state.newEnrollment.step = 'csv_mapping';
      } else {
        state.newEnrollment.step = 'review';
      }
      state.newEnrollment.estimatedNumContacts = action.payload.estSize || 0;
    },

    finishedCSVMapping: (state) => {
      state.newEnrollment.step = 'review';
    },

    cancelCSVMapping: (state) => {
      state.newEnrollment.csvColumnToPropDefIdMap = null;
      state.newEnrollment.step = 'configure_method';
    },

    stepBackFromReview: (state) => {
      if (state.newEnrollment.step !== 'review') return;

      if (state.newEnrollment.enrollmentMethod === 'bulk_csv') {
        state.newEnrollment.step = 'csv_mapping';
      } else {
        state.newEnrollment.step = 'configure_method';
      }
    },

    setCsvColumnsForMapping: (state, action: PayloadAction<string[]>) => {
      const propDefMap: Record<string, number | string> = {};
      let guessedEmailColumn = false;
      let guessedNameColumn = false;
      action.payload.forEach(column => {
        const normalizedColumn = column.toLowerCase().trim();
        if (normalizedColumn.includes('email') && !guessedEmailColumn) {
          propDefMap[column] = 'email';
          guessedEmailColumn = true;
        } else if ((normalizedColumn.includes('fullname') || normalizedColumn === 'name') && !guessedNameColumn) {
          propDefMap[column] = 'name';
          guessedNameColumn = true;
        } else {
          propDefMap[column] = 'IGNORE';
        }
      });
      state.newEnrollment.csvColumnToPropDefIdMap = propDefMap;
    },

    setCsvColumnMapping: (state, action: PayloadAction<{value: number | string, column: string}>) => {
      if (state.newEnrollment.csvColumnToPropDefIdMap === null) {
        state.newEnrollment.csvColumnToPropDefIdMap = {};
      }
      state.newEnrollment.csvColumnToPropDefIdMap[action.payload.column] = action.payload.value;
    },

    setNewEnrollmentLoading: (state) => {
      state.newEnrollment.isEnrolling = true;
    }
  },

  extraReducers: (builder) => {
    builder.addCase(fetchGmailLabels.fulfilled, (state, action) => {
      if (!action.payload) return;

      state.gmailLabels.data = action.payload;
    });

    builder.addCase(enrollCadence.fulfilled, (state, action) => {
      state.newEnrollment.isEnrolling = false;
      state.newEnrollment.enrollResult = action.payload ? 'success' : 'error';
    });

    builder.addCase(enrollCadence.rejected, (state) => {
      state.newEnrollment.isEnrolling = false;
      state.newEnrollment.enrollResult = 'error';
    });

  },
});


export const { setEditingCadence, editCadence, addOrEditNodeInCadence, setNextNodeId, deleteNode, resetNewEnrollment, setNewEnrollmentMethod, setEnrollConfiguration, setCsvColumnMapping, setCsvColumnsForMapping, finishedCSVMapping, cancelCSVMapping, stepBackFromReview } = cadenceSlice.actions;
export default cadenceSlice.reducer;