import { ICadenceNode, IWaitNodeParams, IApplyLabelNodeParams } from 'types/cadence';
import _, { M } from 'constants/i18n';
import { IContact, IContactHandle, IContactWithProperties } from 'types/contact';
import { IContactPropertyDefinition } from 'types/user';
import { store } from 'store';
import { indexBy, prop } from 'ramda';

export const titleCase = (word: string) => word.slice(0,1).toUpperCase() + word.slice(1).toLowerCase();


const automatedHandles = [
  'noreply',
  'no-reply',
  'jira@',
  'mail.airtable.com',
  'notification@slack-mail.com',
  'calendar-notification@google.com',
  'hello@intercom.com',
  'MAILER-DAEMON',
  'webinar',
  '@email.figma.com',
];

const formalFileExtensions: Record<string, {name: string, icon: string}> = {
  'image/jpeg': {
    name: 'jpeg',
    icon: 'image',
  },
  'image/jpg': {
    name: 'jpg',
    icon: 'image',
  },
  'image/heic': {
    name: 'heic',
    icon: 'image',
  },
  'image/png': {
    name: 'png',
    icon: 'image',
  },
  'image/gif': {
    name: 'gif',
    icon: 'image',
  },

  'text/plain': {
    name: 'text',
    icon: 'file',
  },

  'application/json': {
    name: 'json',
    icon: 'file',
  },

  'application/vnd.rar': {
    name: 'rar',
    icon: 'file',
  },
  'application/x-tar': {
    name: 'tar',
    icon: 'file',
  },
  'application/zip': {
    name: 'zip',
    icon: 'file',
  },

  'application/pdf': {
    name: 'PDF',
    icon: 'pdf',
  },

  'application/vnd.ms-powerpoint': {
    name: 'Powerpoint',
    icon: 'powerpoint',
  },
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': {
    name: 'Office slides',
    icon: 'slides',
  },
  'application/vnd.google-apps.presentation': {
    name: 'Google slides',
    icon: 'slides',
  },

  'application/vnd.ms-excel': {
    name: 'Excel',
    icon: 'spreadsheet',
  },
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
    name: 'Office spreadsheet',
    icon: 'spreadsheet',
  },
  'application/vnd.google-apps.spreadsheet': {
    name: 'Google sheet',
    icon: 'spreadsheet',
  },

  'application/msword': {
    name: 'MS Word',
    icon: 'document',
  },
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
    name: 'Office word',
    icon: 'document',
  },
  'application/vnd.google-apps.document': {
    name: 'Google doc',
    icon: 'document',
  },
  'application/vnd.microsoft.portable-executable': {
    name: 'Windows executable',
    icon: 'file',
  },
  'application/pkcs7-signature': {
    name: 'Email hash',
    icon: 'file',
  },

  'video/webm': {
    name: 'Web video',
    icon: 'video',
  },
  'video/x-msvideo': {
    name: 'MS video',
    icon: 'video',
  },
  'video/ogg': {
    name: 'OGG video',
    icon: 'video',
  },
  'video/mp4': {
    name: 'MP4',
    icon: 'video',
  },
  'video/mpeg': {
    name: 'MPEG',
    icon: 'video',
  },
  'video/mpg': {
    name: 'MPG',
    icon: 'video',
  },
  'video/mp2': {
    name: 'MP2',
    icon: 'video',
  },
  'video/quicktime': {
    name: 'Quicktime',
    icon: 'video',
  },

  'audio/wav': {
    name: 'WAV audio',
    icon: 'file',
  },
  'audio/mpeg': {
    name: 'MPEG audio',
    icon: 'file',
  },
  'audio/webm': {
    name: 'Web audio',
    icon: 'file',
  },
  'audio/mp4': {
    name: 'MP4 audio',
    icon: 'file',
  },

  'application/octet-stream': {
    name: 'Unspecified',
    icon: 'file',
  },

  'application/vnd.google-apps.form': {
    name: 'Google Form',
    icon: 'file',
  },

  'application/vnd.google-apps.drawing': {
    name: 'Google Drawing',
    icon: 'drawing',
  },

};


export const iconNameForMimetype = (mimetype: string) => (formalFileExtensions[mimetype] || {icon: 'file'}).icon;

export const humanizeMimetype = (mimetype: string) => (formalFileExtensions[mimetype] || {name: mimetype}).name;


const randomColors = [
  {light: 'var(--color-orange-2)', dark: 'var(--color-orange-5)'},
  {light: 'var(--color-green-2-1)', dark: 'var(--color-green-2-3)'},
  {light: 'var(--color-yellow-2)', dark: 'var(--color-yellow-4)'},
  {light: 'var(--color-purple-2)', dark: 'var(--color-purple-5)'},
  {light: 'var(--color-neutral-4)', dark: 'var(--color-blue-2)'},
  {light: 'var(--color-blue-2-1)', dark: 'var(--color-blue-2-4)'},
  {light: 'var(--color-neutral-9)', dark: 'var(--color-neutral-12)'},
  {light: 'var(--color-neutral-7)', dark: 'var(--color-neutral-10)'},
  {light: 'var(--color-red-2)', dark: 'var(--color-red-5)'},
  {light: 'var(--color-green-1)', dark: 'var(--color-green-5)'},
  {light: 'var(--color-green-4)', dark: 'var(--color-green-5)'},
  {light: 'var(--color-blue-2-4)', dark: 'var(--color-blue-2-4)'},
];

export const dateStrToLocaleStr = (dateStr: string) => {
  return new Date(dateStr).toLocaleString('default', {month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: '2-digit'});
}

// take a string and consistently return the same color
export const stringToColor = (anyString: string) => {
  const asciiString = anyString.replace(/[^a-zA-Z0-9]/g, '');
  const int = parseInt(asciiString, 36) % Number.MAX_SAFE_INTEGER;
  return randomColors[int % randomColors.length];
};


export const validateEmail = (email: string) => {
  // Link to regex expression for email validation
  // https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
  const emailValidation = /[\S+]+@\S+\.\S+/;
  return emailValidation.test(String(email).toLowerCase());
};


export const extractHostName = (url: string) => {
  const { hostname } = new URL(url);
  return hostname;
};


export const getNodeSummary = (node: ICadenceNode) => {
  const type = node.action.type;

  if (type === 'WAIT') {
    const waitParams: IWaitNodeParams = node.action.params as IWaitNodeParams;
    if (waitParams.td_kwargs.weeks) {
      return `${_(M.NODE_TYPE_WAIT)} ${waitParams.td_kwargs.weeks} ${_(M.WEEKS)}`;
    } else if (waitParams.td_kwargs.days) {
      return `${_(M.NODE_TYPE_WAIT)} ${waitParams.td_kwargs.days} ${_(M.DAYS)}`;
    } else if (waitParams.td_kwargs.hours) {
      return `${_(M.NODE_TYPE_WAIT)} ${waitParams.td_kwargs.hours} ${_(M.HOURS)}`;
    } else if (waitParams.td_kwargs.minutes) {
      return `${_(M.NODE_TYPE_WAIT)} ${waitParams.td_kwargs.minutes} ${_(M.MINUTES)}`;
    }
    return _(M.NODE_TYPE_WAIT);
  } else if (type === 'BRANCH') {
    return _(M.NODE_TYPE_BRANCH);
  } else if (type === 'SEND_EMAIL') {
    return _(M.NODE_TYPE_SEND_EMAIL);
  } else if (type === 'APPLY_LABEL') {
    const labelParams: IApplyLabelNodeParams = node.action.params as IApplyLabelNodeParams;
    if (labelParams.labels.length === 1) {
      return `${_(M.NODE_TYPE_APPLY_LABEL)}: ${labelParams.labels[0].name}`;
    }
    return _(M.NODE_TYPE_APPLY_LABEL);
  }

  return 'Unknown step';
};

export const getContactName = (contact?: IContact | IContactWithProperties | null, propDefs: IContactPropertyDefinition[] = []) => {
  if (!contact) {
    return '(Unknown name)';
  }

  if ('properties' in contact) {
    if (propDefs.length === 0) {
      propDefs = store.getState().user.contactPropertyDefinitions;
    }
    const propDefsById = indexBy(prop('id'), propDefs);
    const nameProperties = contact.properties.map(prop => {
      const propDef = propDefsById[prop.prop_def_id];
      return {...prop, propDef};
    }).filter(p => !!p.propDef && p.propDef.code_name.includes('name'));
    const firstName = nameProperties.find(d => d.propDef.code_name === 'firstname' || d.propDef.code_name === 'first_name');
    const lastName = nameProperties.find(d => d.propDef.code_name === 'lastname' || d.propDef.code_name === 'last_name');
    if (firstName) {
      if (lastName) {
        return `${firstName.raw_value} ${lastName.raw_value}`;
      } else {
        return `${firstName.raw_value}`;
      }
    }
  }

  const handles: IContactHandle[] = contact.handles;

  const anyHandlesAreAutomated = handles.some(({handle}) => automatedHandles.some(automated => handle.includes(automated)));

  if (contact.name && !anyHandlesAreAutomated) {
    return contact.name;
  }

  const handleName = handles.reduce((sofar: string | null | undefined, h: IContactHandle) => sofar || h.name, contact.name);

  if (handleName && !anyHandlesAreAutomated) {
    return handleName;
  }

  const emailHandle = handles.find((h) => h.source === 'email');
  if (emailHandle) {
    return emailHandle.handle;
  }

  if (handles.length) {
    return `${handles[0].handle} (${handles[0].source})`
  }

  return '(Unknown name)';
};

export const getContactEmail = (contact?: IContact | IContactWithProperties | null) => {
  if (!contact) {
    return '(Unknown email)';
  }

  const handles: IContactHandle[] = contact.handles;

  const emailHandle = handles.find((h) => h.source === 'email');
  if (emailHandle) {
    return emailHandle.handle;
  }

  if (handles.length) {
    return `${handles[0].handle} (${handles[0].source})`
  }

  return '(Unknown email)';

};


export function escapeRegExp(str: string) {
  return str.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
}

export const getAddrAndNameFromEmail = (rawEmailAddress: string) => {
  // might be a name with an emaiil address beside it
  const split = rawEmailAddress.split(' <');
  if (split.length === 2) {
    return {
      name: split[0].replaceAll('"', ''),
      email: split[1].slice(0, -1),
    };
  }

  // might just be an email address enclosed in <>
  if (rawEmailAddress.startsWith('<') && rawEmailAddress.endsWith('>')) {
    const emailAddr = rawEmailAddress.slice(1, -1);
    return {name: emailAddr, email: emailAddr};
  }

  // might just be a plain email address
  if (!rawEmailAddress.includes(' ') && rawEmailAddress.includes('@')) {
    return {
      name: rawEmailAddress,
      email: rawEmailAddress,
    };
  }
  return {
    name: 'unknown',
    email: 'unknown'
  }
}

export const makeEmailName = ({email, name}: {email: string, name: string}) => {
  if (!email) {
    return '';
  }
  if (!name) {
    return `<${email}>`;
  } else {
    return `"${name}" <${email}>`;
  }
};
