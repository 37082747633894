import { MouseEvent, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Button,
  EmptyState,
  IconButton,
  Table,
  EditIcon,
  Pagination,
  ArchiveIcon,
  UploadIcon,
  ChartIcon,
  toaster,
  Tablist,
  Tab,
  Tooltip,
  Position,
} from 'evergreen-ui';

import {
  usePaginateCadencesQuery,
  usePatchCadenceMutation,
  usePrefetch,
} from 'api/cadence';
import CadenceIcon from 'components/icons/cadence';
import _, { M } from 'constants/i18n';
import { CadenceListView } from 'store/cadence/slice';
import { ICadence } from 'types/cadence';
import { TrackEventNames, tracker } from 'utils/tracking';
import CreateCadenceDialog from 'components/cadence/CreateCadenceDialog';

import './style.css';

const CadenceRow = ({ cadence }: { cadence: ICadence }) => {
  const nav = useNavigate();
  const fetchCadence = usePrefetch('fetchCadence');
  const [patch, patchRes] = usePatchCadenceMutation();

  const goToCadence = (e: MouseEvent, edit: boolean = false) => {
    e.stopPropagation();
    const url = edit
      ? `/cadences/${cadence.id}/edit`
      : `/cadences/${cadence.id}/`;
    nav(url);
  };

  const goToEnrollments = (e: MouseEvent) => {
    e.stopPropagation();
    nav(`/cadences/${cadence.id}/enrollments/`);
  };

  const onArchiveCadence = async (e: MouseEvent) => {
    e.stopPropagation();
    try {
      await patch({
        ...cadence,
        status: cadence.status === 'archived' ? 'active' : 'archived',
      });
      tracker.track(TrackEventNames.PC);
    } catch (error) {
      // TODO: log error
      console.warn('failed to archive cadence', error);
    }
  };

  useEffect(() => {
    if (patchRes.isError) {
      toaster.danger(_(M.FAILED_TO_SAVE));
    }
  }, [patchRes.isError]);

  const SecondIcon = cadence.status === 'archived' ? UploadIcon : ArchiveIcon;
  const secondTooltip =
    cadence.status === 'archived'
      ? M.BUTTON_CADENCE_UNARCHIVE
      : M.BUTTON_CADENCE_ARCHIVE;

  return (
    <>
      <Table.Row
        onClick={goToCadence}
        isSelectable
        onMouseEnter={() => fetchCadence(cadence.id)}
      >
        <Table.TextCell>{cadence.name}</Table.TextCell>
        <Table.TextCell>{cadence.description}</Table.TextCell>
        <Table.Cell justifyContent='right'>
          <Tooltip content={_(M.NAV_CADENCE_ENROLLMENTS)} position={Position.TOP}>
            <IconButton
              appearance='minimal'
              icon={ChartIcon}
              onClick={goToEnrollments}
            />
          </Tooltip>
          <Tooltip content={_(M.BUTTON_CADENCE_EDIT)} position={Position.TOP}>
            <IconButton
              appearance='minimal'
              icon={EditIcon}
              onClick={(e: MouseEvent) => goToCadence(e, true)}
            />
          </Tooltip>
          <Tooltip content={_(secondTooltip)} position={Position.TOP}>
            <IconButton
              appearance='minimal'
              icon={SecondIcon}
              onClick={onArchiveCadence}
            />
          </Tooltip>
        </Table.Cell>
      </Table.Row>
    </>
  );
};

const ViewToStatuses: Record<CadenceListView, string[]> = {
  [CadenceListView.ACTIVE]: ['active', 'disabled'],
  [CadenceListView.ARCHIVED]: ['archived'],
};

const CadencesList = () => {
  const [params, setParams] = useSearchParams();
  const view = params.get('view') || CadenceListView.ACTIVE;
  const limit = 5;
  const offset = isNaN(parseInt(params.get('offset') || '0')) ? 0 : parseInt(params.get('offset') || '0');
  const paginateCadences = usePrefetch('paginateCadences');
  const [createCadenceDialogShown, setCreateCadenceDialogShown] = useState(false);

  const { isLoading, isFetching, isError, data } = usePaginateCadencesQuery({
    limit,
    offset,
    deleted: false,
    status: ViewToStatuses[view as CadenceListView] || ViewToStatuses[CadenceListView.ACTIVE],
  });

  // track when data changes (i.e. when cadence list is requested)
  useEffect(() => {
    if (data) {
      tracker.track(TrackEventNames.LC);
    }
  }, [data]);

  const loading = isLoading || isFetching;

  const openCadenceDialog = () => {
    setCreateCadenceDialogShown(true);
  };

  if (
    !loading &&
    !isError &&
    data?.cadences.length === 0 &&
    view === CadenceListView.ACTIVE
  ) {
    return (
      <>
        <CreateCadenceDialog isShown={createCadenceDialogShown} onClose={() => setCreateCadenceDialogShown(false)} />
        <EmptyState
          title={_(M.EMPTY_CADENCE_TITLE)}
          description={_(M.EMPTY_CADENCE_DESC)}
          icon={<CadenceIcon />}
          iconBgColor='var(--color-green-2)'
          primaryCta={
            <Button
              appearance='primary'
              onClick={openCadenceDialog}
            >
              {_(M.NEW_CADENCE)}
            </Button>
          }
        />
      </>
    );
  }

  const onChangeView = (newView: CadenceListView) => {
    if (newView === view) return;

    setParams((params) => {
      params.set('view', newView);
      params.set('offset', '0');
      return params;
    });
  };

  const onPrevPageClick = () => {
    if (!data) return;

    const currentOffset = (data.meta.pagination.this - 1) * limit;
    if (currentOffset > 0) {
      setParams(prev => {
        prev.set('offset', (currentOffset - limit).toString());
        return prev;
      });
    }
  };

  const onNextPage = () => {
    if (!data) return;

    const pagination = data.meta.pagination;
    const currentOffset = (pagination.this - 1) * limit;
    if (pagination.this < pagination.last) {
      setParams(prev => {
        prev.set('offset', (currentOffset + limit).toString());
        return prev;
      });
    }
  };

  const onPageChange = (page: number) => {
    setParams(prev => {
      prev.set('offset', ((page - 1) * limit).toString());
      return prev;
    });
  };

  return (
    <div className='cadence-list--container'>
      <div className="cadence-list-header--container">
        <h2>{_(M.NAV_CADENCES)}</h2>
        <a href="https://docs.tinycadence.com/cadences/cadences/getting-started" target="_blank" rel="noreferrer">{_(M.CADENCES_TITLE_LEARN_MORE)}</a>
      </div>
      <Tablist marginBottom={16}>
        <Tab
          aria-controls={'panel-' + CadenceListView.ACTIVE}
          isSelected={view === CadenceListView.ACTIVE}
          key={CadenceListView.ACTIVE}
          onSelect={onChangeView.bind(this, CadenceListView.ACTIVE)}
          onMouseEnter={() => paginateCadences({limit, offset: 0, deleted: false, status: ViewToStatuses[CadenceListView.ACTIVE]})}
        >
          {_(M.ACTIVE)}
        </Tab>
        <Tab
          aria-controls={'panel-' + CadenceListView.ARCHIVED}
          isSelected={view === CadenceListView.ARCHIVED}
          key={CadenceListView.ARCHIVED}
          onSelect={onChangeView.bind(this, CadenceListView.ARCHIVED)}
          onMouseEnter={() => paginateCadences({limit, offset: 0, deleted: false, status: ViewToStatuses[CadenceListView.ARCHIVED]})}
        >
          {_(M.ARCHIVED)}
        </Tab>
      </Tablist>
      <Table>
        <Table.Head>
          <Table.TextHeaderCell>{_(M.NAME)}</Table.TextHeaderCell>
          <Table.TextHeaderCell>{_(M.DESCRIPTION)}</Table.TextHeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Head>
        <Table.Body>
          {data?.cadences.map((cadence) => (
            <CadenceRow cadence={cadence} key={cadence.id} />
          ))}
        </Table.Body>
      </Table>
      {data && (
        <Pagination
          totalPages={data.meta.pagination.last}
          page={data.meta.pagination.this}
          onNextPage={onNextPage}
          onPreviousPage={onPrevPageClick}
          onPageChange={onPageChange}
        />
      )}
    </div>
  );
};

export default CadencesList;
