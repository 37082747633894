import { NavLink, Outlet, useParams } from 'react-router-dom';

import { useFetchCadenceQuery } from 'api/admin/cadence';
import './style.css';

const AdminCadenceOutlet = () => {
  const {cadenceId = ''} = useParams<{cadenceId: string}>()

  const {
    data,
    isLoading,
    isFetching,
    isError,
  } = useFetchCadenceQuery(cadenceId);

  if (!data || isLoading || isFetching) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error</div>
  }

  const cadence = data.cadence;

  return <div className="admin-page-content--container">
    <h2>{cadence.name}</h2>
    <div className="admin-page-content-sub-nav--container">
      <NavLink className="admin-page-content-sub-nav--link" to={`/admin/cadences/${cadenceId}`} end>Summary</NavLink>
      <NavLink className="admin-page-content-sub-nav--link" to={`/admin/cadences/${cadenceId}/enrollments`}>Enrollments</NavLink>
    </div>
    <Outlet />
  </div>
};

export default AdminCadenceOutlet;