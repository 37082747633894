
export const BOOTSTRAP_LOCALSTORAGE_NAME = 'tc.boot_data';
const COOKIE_NAME = 'TC_APP_AUTH';

export const expireToken = () => {
  document.cookie = `${COOKIE_NAME}=;expires=${(new Date()).toUTCString()};path=/;domain=${document.domain}`;
  try {
    localStorage.removeItem(BOOTSTRAP_LOCALSTORAGE_NAME);
    return localStorage.removeItem(COOKIE_NAME);
  } catch (err) {
    return null;
  }
};

export const getToken = () => {
  const startIndex = document.cookie.indexOf(COOKIE_NAME);
  if (startIndex === -1) {
    return getCookieFromLocalStorage();
  }
  const startSlice = startIndex + COOKIE_NAME.length + 1;
  const endIndex = document.cookie.slice(startIndex).indexOf(';');
  if (endIndex === -1) {
    return document.cookie.slice(startSlice);
  } else {
    return document.cookie.slice(startSlice, startIndex + endIndex);
  }
};

export const getTokenExpiration = () => {
  const token = getToken();
  if (!token) return null;
  try {
    return (JSON.parse(atob(token.split('.')[1])) as {sub: string, exp: number}).exp
  } catch (err) {
    console.warn('Failed to parse token', err);
    return null;
  }
}

const getCookieFromLocalStorage = () => {
  try {
    return localStorage.getItem(COOKIE_NAME);
  } catch (err) {
    return null;
  }
};

export const setToken = (token: string) => {
  const expireDate = new Date();
  expireDate.setDate(expireDate.getDate() + 7);

  const cookie = `${COOKIE_NAME}=${token};expires=${expireDate.toUTCString()};path=/;domain=${document.domain}`;
  document.cookie = cookie;
  try {
    localStorage.setItem(COOKIE_NAME, token);
  } catch (err) {
    console.info('could not store token to localStorage');
  }
};