import { Pagination, Table } from 'evergreen-ui';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { dateStrToLocaleStr } from 'utils/strings';
import { usePaginateInboxesQuery } from 'api/admin/inbox';
import { IInbox } from 'types/inbox';

const limit = 10;

const InboxRow = ({inbox}: {inbox: IInbox}) => {
  const nav = useNavigate();

  const goToConnector = () => {
    nav(`/admin/inboxes/${inbox.id}`);
  }

  return <Table.Row
    onClick={goToConnector}
    isSelectable
  >
    <Table.TextCell>{inbox.name}</Table.TextCell>
    <Table.TextCell>{inbox.status || <em>No description</em>}</Table.TextCell>
    <Table.TextCell>{inbox.namespace}</Table.TextCell>
    <Table.TextCell>{dateStrToLocaleStr(inbox.created_at)}</Table.TextCell>
    <Table.TextCell>{inbox.updated_at ? dateStrToLocaleStr(inbox.updated_at) : <em>never</em>}</Table.TextCell>
  </Table.Row>
}


const AdminInboxesTable = ({userId}: {userId?: string | number}) => {
  const [offset, setOffset] = useState(0);
  const {
    data,
  } = usePaginateInboxesQuery({limit: 10, offset, user_id: userId});

  const onPrevPageClick = () => {
    if (!data) return;

    const currentOffset = (data.pagination.this - 1) * limit;
    if (currentOffset > 0) {
      setOffset(currentOffset - limit);
    }
  };

  const onNextPage = () => {
    if (!data) return;

    const pagination = data.pagination;
    const currentOffset = (pagination.this - 1) * limit;
    if (pagination.this < pagination.last) {
      setOffset(currentOffset + limit);
    }
  };

  const onPageChange = (page: number) => {
    setOffset((page - 1) * limit);
  };

  return <div>
    <Table>
      <Table.Head>
        <Table.TextHeaderCell>Name</Table.TextHeaderCell>
        <Table.TextHeaderCell>Status</Table.TextHeaderCell>
        <Table.TextHeaderCell>Namespace</Table.TextHeaderCell>
        <Table.TextHeaderCell>Created at</Table.TextHeaderCell>
        <Table.TextHeaderCell>Updated at</Table.TextHeaderCell>
      </Table.Head>
      <Table.Body>
        {data?.items.map((inbox) => <InboxRow inbox={inbox} key={inbox.id} />)}
      </Table.Body>
    </Table>
    {data && (
      <Pagination
        totalPages={data.pagination.last}
        page={data.pagination.this}
        onNextPage={onNextPage}
        onPreviousPage={onPrevPageClick}
        onPageChange={onPageChange}
      />
    )}
  </div>
}


export default AdminInboxesTable;