import { CadenceEventTypes } from 'types/cadence';
import { queries } from './queries';


export interface IHomeAnalyticsResponse {
  active_cadence_count: number;
  events_over_time: Array<Array<string | Record<CadenceEventTypes, number>>>,
}

export interface ICadenceAnalyticsResponse {
  events_over_time: Array<Array<string | Record<CadenceEventTypes, number>>>,
}


const analyticsQueries = queries.injectEndpoints({
  endpoints: (build) => ({

    homeAnalytics: build.query<IHomeAnalyticsResponse, void>({
      query: () => '/analytics/home',
      keepUnusedDataFor: 10,
      providesTags: () => [{type: 'Analytics', id: 'HOME'}]
    }),

    cadenceAnalytics: build.query<ICadenceAnalyticsResponse, {cadenceId: number, window: number}>({
      query: ({cadenceId, window}) => `/analytics/cadence?window_days=${window}&cadence_id=${cadenceId}`,
      keepUnusedDataFor: 10,
      providesTags: (_, _2, args) => [{type: 'Analytics', id: args.cadenceId}]
    }),
  }),

  overrideExisting: false,
});


export const { useHomeAnalyticsQuery, useCadenceAnalyticsQuery } = analyticsQueries;