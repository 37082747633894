import CRMSidebar from 'components/crm/CrmSidebar';
import { Outlet } from 'react-router-dom';


const ContactListsOutlet = () => {
  return <div>
    <div className='crm-outlet--sidebar'>
      <CRMSidebar />
    </div>

    <div className="crm-sidebar-sibling-content--container">
      <Outlet />
    </div>
  </div>
}

export default ContactListsOutlet;