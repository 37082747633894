import { adminQueries } from 'api/queries';
import { IPagination } from 'api/cadence';
import { IUser, UserStatus } from 'types/user';
import { BillingPlans } from 'constants/app';
import { ICompanyUser } from 'types/company';


const usersPage = {type: 'User' as const, id: 'PAGE'};

interface IListUsersArgs {
  offset: number;
  limit: number;
}

const usersQueries = adminQueries.injectEndpoints({
  endpoints: (build) => ({
    paginateUsers: build.query<{items: IUser[], pagination: IPagination}, IListUsersArgs>({
      query: (body) => {
        const qs = new URLSearchParams();
        qs.set('limit', body.limit.toString());
        qs.set('offset', body.offset.toString());
        return {
          url: `/users/?${qs}`,
          method: 'GET',
        }
      },

      providesTags: (res) => res ? [usersPage, ...res.items.map(c => ({type: 'User', id: c.id}))] : [],
    }),

    fetchUser: build.query<{user: IUser}, string>({
      query: (emailOrId: string) => `/users/${emailOrId}`,

      providesTags: (res) => res ? [usersPage, {type: 'User', id: res.user.id}] : [],
    }),

    toggleSuperuser: build.mutation<{user: IUser}, string>({
      query: (emailOrId: string) => ({
        method: 'POST',
        url: `/users/${emailOrId}/toggle-superuser`,
      }),

      invalidatesTags: (res) => res ? [usersPage, {type: 'User', id: res.user.id}] : [],
    }),

    patchGate: build.mutation<{user: IUser}, {userId: number, body: {allowed: boolean, name: string}}>({
      query: ({userId, body}) => ({
        method: 'PATCH',
        url: `/users/${userId}/gates`,
        body,
      }),

      invalidatesTags: (res) => res ? [usersPage, {type: 'User', id: res.user.id}] : [],
    }),

    changeUserStatus: build.mutation<{user: IUser}, {userId: number, status: UserStatus}>({
      query: ({userId, status}) => ({
        method: 'POST',
        url: `/users/${userId}/status/${status}`,
      }),

      invalidatesTags: (res) => res ? [usersPage, {id: res.user.id, type: 'User'}] : [],
    }),

    changeUserBillingPlan: build.mutation<{user: IUser}, {userId: number, plan: keyof typeof BillingPlans, unset_plan: boolean}>({
      query: ({userId, plan, unset_plan}) => ({
        method: 'POST',
        url: `/users/${userId}/set-plan`,
        body: {plan: plan.replace('tinycadence-plan:', ''), unset_plan},
      }),

      invalidatesTags: (res) => res ? [usersPage, {id: res.user.id, type: 'User'}] : [],
    }),

    setUserTrialExpiration: build.mutation<{user: IUser}, {userId: number, body: {expiration: string}}>({
      query: ({userId, body}) => ({
        method: 'PATCH',
        url: `/users/${userId}/set-trial-expiration`,
        body,
      }),

      invalidatesTags: (res) => res ? [usersPage, {type: 'User', id: res.user.id}] : [],
    }),

    backfillUserCompanies: build.mutation<{status: string}, void>({
      query: () => ({
        url: '/users/backfill/user-companies',
        method: 'POST',
      }),
    }),

    backfillSetActiveUsersInCRM: build.mutation<{status: string}, void>({
      query: () => ({
        url: '/users/backfill/set-active-users-in-crm',
        method: 'GET',
      }),
    }),

    paginateUserCompanies: build.query<{items: ICompanyUser[], pagination: IPagination}, {userId: number, offset: number, limit: number}>({
      query: ({userId, limit, offset}) => {
        const qs = new URLSearchParams();
        qs.set('limit', limit.toString());
        qs.set('offset', offset.toString());

        return {
          url: `/users/${userId}/companies?${qs}`,
          method: 'POST',
        }
      },
    }),
  }),
  overrideExisting: false,
});


export const {
  usePaginateUsersQuery,
  useFetchUserQuery,
  useToggleSuperuserMutation,
  usePatchGateMutation,
  useChangeUserStatusMutation,
  useChangeUserBillingPlanMutation,
  useSetUserTrialExpirationMutation,
  useBackfillUserCompaniesMutation,
  usePaginateUserCompaniesQuery,
  useBackfillSetActiveUsersInCRMMutation,
} = usersQueries;