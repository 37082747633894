import { ConnectorProviders } from 'constants/app';

export interface ISignupParams {
  email: string
  password: string
  full_name: string
  org_name?: string
}

export interface IProperty {
  namespace: string;
  name: string;
  value: unknown;
}

export enum UserStatus {
  INACTIVE = 'inactive',
  ACTIVE = 'active',
  SUSPENDED = 'suspended',
}

export interface IUser {
  id: number;
  full_name?: string | null;
  email: string;
  is_superuser: boolean;
  status: UserStatus;
  properties: IProperty[];
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

export interface IHubspotConnectorSettings {
  create_contacts_in_hubspot?: boolean;
  log_email_sends_as_contact_activity?: boolean;
  pull_contact_properties_from_hubspot?: boolean;
}


export interface IConnector {
  id: number;
  status: string;
  provider: ConnectorProviders;
  created_at: string;
  updated_at: string;
  user_id: number;
  external_account_id: string;
  meta: {
    email?: string;
    hub_domain?: string;
    settings?: IHubspotConnectorSettings;
    login?: string;
  };
}

export interface IContactPropertyDefinition {
  id: number;
  display_name: string;
  code_name: string;
  namespace: string;
  property_type: string; // TODO: enum
  meta: Record<string, unknown>;
  updated_at: string;
  created_at: string;
  deleted_at: string | null;
}

export interface IBootstrapResponse {
  user: IUser,
  connectors: IConnector[],
  num_unread_threads: number,
  contact_property_definitions: IContactPropertyDefinition[],
}


export interface IScheduledTask {
  id: number;
  scheduled_for: string;
  name: string;
  status: string;
  data: Record<string, unknown>;
  meta: Record<string, unknown>;
  ttl: string | undefined | null;
  updated_at: string | undefined | null;
}