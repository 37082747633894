import './style.css';

const ProgressBar = (props: {bgcolor: string, completed: number}) => {
  const { bgcolor, completed } = props;

  return (
    <div className="progress-bar--container">
      <div style={{width: `${completed + 1}%`, backgroundColor: bgcolor}} className="progress-bar--filler">
        <span className="progress-bar--label">{`${completed}%`}</span>
      </div>
    </div>
  );
};

export default ProgressBar;