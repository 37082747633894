import { Card, Spinner } from 'evergreen-ui';
import { useParams } from 'react-router-dom';
import { indexBy, prop } from 'ramda';

import { useFetchContactQuery } from 'api/contact';
import _, {M} from 'constants/i18n';
import { getContactName } from 'utils/strings';
import { ContactHandle, ContactProperty } from 'components/crm/ContactDrawer';
import { useAppSelector } from 'hooks';

import './style.css';
import { getContactPropertyDefinitions } from 'store/user/selector';

const ContactSummary = () => {
  const { contactId='' } = useParams<{contactId: string}>();
  const {
    isFetching,
    isLoading,
    isError: error,
    data,
  } = useFetchContactQuery(parseInt(contactId));

  const propDefs = useAppSelector(getContactPropertyDefinitions);

  const propDefsById = indexBy(prop('id'), propDefs);

  const loading = isLoading || isFetching;

  if (loading) {
    return <Spinner delay={100}>{_(M.LOADING)}</Spinner>
  }

  if (!data || error) {
    return <div>{_(M.ERROR)}</div>
  }

  const contact = data.contact;

  return <div className="contact-summary--container">
    <div>
      <Card padding="8px" marginBottom="8px">
        <div>{getContactName(contact)}</div>
      </Card>
      <Card padding="8px" marginBottom="8px">
        <h3 className="contact-summary-section--header">{_(M.CONTACT_DRAWER_HANDLES_TITLE)}</h3>
        <div>
          {data.contact.handles.map(handle => <ContactHandle key={`${handle.source}-${handle.handle}`} handle={handle}/>)}
        </div>
      </Card>
      <Card padding="8px" marginBottom="8px">
        <h3 className="contact-summary-section--header">{_(M.CONTACT_DRAWER_PROPERTIES_TITLE)}</h3>
        <div>{data.contact.properties.map(prop => (<ContactProperty key={prop.id} property={prop} definition={propDefsById[prop.prop_def_id]}/>))}</div>
      </Card>
    </div>
  </div>;
}

export default ContactSummary;