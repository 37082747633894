import { IContactWithProperties } from 'types/contact';
import { IContactPropertyDefinition } from 'types/user';

import { queries } from './queries';
import { IPagination } from './cadence';
import { IContactSearchResult } from 'types/search';


type ContactPropertyType = 'datetime' | 'date' | 'boolean' | 'number' | 'string';

const contactQueries = queries.injectEndpoints({
  endpoints: (build) => ({
    fetchContact: build.query<{contact: IContactWithProperties}, number>({
      query: (contactId) => ({
        url: `/contacts/${contactId}`
      }),

      providesTags: (res) => res ? [{type: 'Contact' as const, id: res.contact.id}] : [],
    }),

    paginateContacts: build.query<{items: IContactWithProperties[], pagination: IPagination}, {offset: number, limit: number}>({
      query: ({limit, offset}) => ({
        url: `/contacts/?limit=${limit}&offset=${offset}`
      }),

      providesTags: (res) => res ? [{type: 'Contact', id: 'PAGE'}, ...res.items.map(c => ({type: 'Contact' as const, id: c.id}))] : [],
    }),

    createContactPropDef: build.mutation<{prop_def: IContactPropertyDefinition}, {displayName: string, propertyType: ContactPropertyType}>({
      query: ({displayName, propertyType}) => ({
        url: '/contact-properties/definitions',
        method: 'POST',
        body: {
          property_type: propertyType,
          display_name: displayName,
        },
      }),
    }),

    mergeContacts: build.mutation<{status: string}, number[]>({
      query: (contactIds) => ({
        url: '/contacts/merge',
        method: 'POST',
        body: {
          contact_ids: contactIds,
        },
      }),

      invalidatesTags: (res, _, contactIds) => res ? [
        ...contactIds.map(id => ({type: 'Contact', id})),
        {type: 'Contact', id: 'PAGE'},
        {type: 'Analytics', id: 'HomeV2'},
      ] : [],
    }),

    upsertContactProperties: build.mutation<any[], {properties: {contact_id: number | string, prop_def_id: number, raw_value: string}[]}>({
      query: ({properties}) => ({
        url: '/contact-properties/',
        method: 'POST',
        body: {properties},
      }),

      invalidatesTags: (res, _, {properties}) => res ? [
        ...properties.map(prop => ({type: 'Contact', id: prop.contact_id})),
        {type: 'Contact', id: 'PAGE'},
        {type: 'Analytics', id: 'HomeV2'},
      ] : [],
    }),

    findContactByEmail: build.query<{contact: IContactWithProperties}, {email: string}>({
      query: ({email}) => `/contacts/find/email/${email}`,

      providesTags: res => res ? [{type: 'Contact', id: res.contact.id}] : [],
    }),

    searchContacts: build.query<{results: IContactSearchResult[]}, {namespace: string, term: string}>({
      query: ({namespace, term}) => `/search/contacts?namespace=${namespace}&term=${term}`,
    }),

    bulkFetchContacts: build.query<{contacts: IContactWithProperties[]}, number[]>({
      query: (contactIds) => {
        const qs = new URLSearchParams();
        contactIds.forEach(contactId => {
          qs.append('contact_ids', contactId.toString());
        });
        return `/contacts/bulk-fetch?${qs}`;
      },

      providesTags: res => res ? res.contacts.map(ctc => ({type: 'Contact', id: ctc.id})) : [],
    })
  }),

  overrideExisting: false,
})

export const {
  useFetchContactQuery,
  usePrefetch,
  useCreateContactPropDefMutation,
  usePaginateContactsQuery,
  useMergeContactsMutation,
  useUpsertContactPropertiesMutation,
  useLazySearchContactsQuery,
  useBulkFetchContactsQuery,
} = contactQueries;