import { HTMLProps, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { EmailMessage } from 'types/conversation';
import { UnquoteEmail } from 'utils/unquoteEmail';


interface IMessageFrameProps {
  onResize?: () => void,
  searchable?: boolean,
  message: EmailMessage,
}

const quietlySetHeight = (height: number, $el: HTMLElement) => {
  const heightStr = `${height}px`;
  if ($el.style.height !== heightStr) {
    $el.style.height = heightStr;
  }
}


const MessageFrame = ({onResize, searchable, message, ...iframeProps}: IMessageFrameProps & HTMLProps<HTMLIFrameElement>) => {
  const iframeTitle = useMemo(() => `msg-frame:${message.id}`, [message]);
  const iframeRef = useRef<null | HTMLIFrameElement>(null);
  const [iframeDocObserver, setIframeDocObserver] = useState<null | ResizeObserver>(null);
  const [lastFitSize, setLastFitSize] = useState<string | null>(null);

  useEffect(() => {
    if (!iframeRef.current) return

    const doc = iframeRef.current.contentDocument;
    if (!doc) return;

    doc.open();

    let htmlBodyContent = '<!DOCTYPE html>';

    if (message.body_html) {
      htmlBodyContent += message.body_html;
    } else if (message.body_text) {
      htmlBodyContent += document.createTextNode(message.body_text).textContent;
    }
    const unquoted = UnquoteEmail.unquote(htmlBodyContent, {});
    if (unquoted) {
      doc.write('<!DOCTYPE html>' + unquoted);
    } else {
      doc.write(htmlBodyContent);
    }
    doc.close()
  }, [message]);

  const onReevaluateContentSize = useCallback((entry: ResizeObserverEntry) => {
    const size = `${entry.contentRect.width}:${entry.contentRect.height}`;
    if (size === lastFitSize || !iframeRef.current) return;

    setLastFitSize(size);
    const $iframe = iframeRef.current;
    const doc = $iframe?.contentDocument;

    // We must set the height to zero in order to get a valid scrollHeight
    // if the document is wider and has a lower height now.
    quietlySetHeight(0, $iframe)

    // If documentElement has a scroll height, prioritize that as height
    // If not, fall back to body scroll height by setting it to auto
    let height = 0;
    let width = 0;
    if (doc && doc.documentElement && doc.documentElement.scrollHeight > 0) {
      width = doc.documentElement.scrollWidth;
      height = doc.documentElement.scrollHeight;
    } else if (doc && doc.body) {
      const style = window.getComputedStyle(doc.body);
      if (style.height === '0px') {
        doc.body.style.height = 'auto';
      }
      width = doc.body.scrollWidth;
      height = doc.body.scrollHeight;
    }

    if (width > $iframe.clientWidth) {
      // the message will scroll horizontally, and we need to add 20px to the height of
      // the iframe to allow for it's scrollbar. Otherwise it covers the last line of text.
      height += 20;
    }

    quietlySetHeight(height + 20, $iframe);
    // TODO: set the wrapper elt's height
    // _iframeWrapperEl.style.height = `${height}px`;
  }, [lastFitSize]);

  useEffect(() => {
    const node = iframeRef.current;
    if (node) {
      if (!iframeDocObserver) {
        const obs = new window.ResizeObserver(entries => {
          window.requestAnimationFrame(() => {
            onReevaluateContentSize(entries[0]);
          })
        })
        setIframeDocObserver(obs);

        // Observe the <html> element within the iFrame for changes to it's content
        // size. We need to disconnect the observer before the HTML element is deleted
        // or Chrome gets into a "maximum call depth" Observer error.
        const observedEl = node.contentDocument?.firstElementChild;
        if (observedEl) {
          obs.observe(observedEl);
        }
        node.contentWindow?.addEventListener('beforeunload', () => {
          obs.disconnect();
        });
        return () => {
          obs.disconnect();
        }
      }
    }

    return () => {
      iframeDocObserver?.disconnect();
    }
  }, [iframeDocObserver, onReevaluateContentSize]);


  return <iframe className="message-iframe" seamless {...iframeProps} title={iframeTitle} ref={iframeRef}/>
};

export default MessageFrame;