import { useFetchUserQuery } from 'api/user';
import { AnyMessage } from 'types/conversation';
import { dateStrToLocaleStr } from 'utils/strings';


const PrivateCommentMessage = ({message}: {message: AnyMessage}) => {
  const userRes = useFetchUserQuery({userId: message.author_id});

  const user = userRes.data?.user;

  return <div className="private-comment--container">
    <div className='private-comment-header--container'>
      <div>{user?.full_name || user?.email || <em>Teammate</em>}</div>
      <div>{dateStrToLocaleStr(message.created_at)}</div>
    </div>
    <div className="private-comment-content--container">
      <div dangerouslySetInnerHTML={{__html: message.body_html || ''}} />
    </div>
  </div>;
};

export default PrivateCommentMessage;