import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { faEnvelopesBulk, faHome, faPaperPlane, faAddressBook, faChartColumn, faBolt, faInbox, faTerminal, faGear } from '@fortawesome/free-solid-svg-icons';
import { Position, Tooltip } from 'evergreen-ui';

import logo from 'images/logo128.png';
import _, { M } from 'constants/i18n';
import { useAppSelector } from 'hooks';
import { getHasUnreadThreads, getIsSuperuser, getTrialExpiration, userHasGate, userHasPaid } from 'store/user/selector';
import { FeatureGateNames } from 'constants/gates';
import './style.css';
import { datediff } from 'utils/date';

const formatter = new Intl.RelativeTimeFormat('en', { style: 'short' });


const Sidebar = () => {
  const nav = useNavigate();
  const hasUnreadThreads = useAppSelector(getHasUnreadThreads);
  const hasEngMgrFeatures = useAppSelector(s => userHasGate(s, FeatureGateNames.MGR_INSIGHTS));
  const hasInboxes = useAppSelector(s => userHasGate(s, FeatureGateNames.INBOXES));
  const hasPaid = useAppSelector(userHasPaid);
  const isSuperuser = useAppSelector(getIsSuperuser);
  const trialExpires = useAppSelector(getTrialExpiration);
  const daysUntilTrialExpires = datediff(new Date(), trialExpires);

  return (
    <>
      <div className="tinycadence-sidebar--container">
        <div className="tinycadence-sidebar-links--container">
          <div onClick={() => nav('/')} className="tinycadence-sidebar-main--icon"><img className="tinycadence-topbar--logo" src={logo} alt="TinyCadence Logo"/></div>
          <Tooltip content={_(M.NAV_HOME)} position={Position.RIGHT}>
            <NavLink to="/" end className="tinycadence-sidebar-link">
              <FontAwesomeIcon className="tinycadence-sidebar-link--icon" size="sm" icon={faHome}/>
            </NavLink>
          </Tooltip>
          <Tooltip content={_(M.NAV_CADENCES)} position={Position.RIGHT}>
            <NavLink to="/cadences" className="tinycadence-sidebar-link">
              <FontAwesomeIcon className="tinycadence-sidebar-link--icon" size="sm" icon={faPaperPlane}/>
            </NavLink>
          </Tooltip>
          <Tooltip content={_(M.NAV_THREADS)} position={Position.RIGHT}>
            <NavLink to="/threads" className="tinycadence-sidebar-link">
              {hasUnreadThreads && <div className="tinycadence-unread-nav--dot" />}
              <FontAwesomeIcon className="tinycadence-sidebar-link--icon" size="sm" icon={faEnvelopesBulk}/>
            </NavLink>
          </Tooltip>
          {hasEngMgrFeatures &&
            <Tooltip content={_(M.NAV_MGR_METRICS)} position={Position.RIGHT}>
              <NavLink to="/mgr-metrics" className="tinycadence-sidebar-link">
                <FontAwesomeIcon className="tinycadence-sidebar-link--icon" size="sm" icon={faChartColumn}/>
              </NavLink>
            </Tooltip>
          }
          {hasInboxes &&
            <Tooltip content={_(M.NAV_INBOXES)} position={Position.RIGHT}>
              <NavLink to="/inboxes" className="tinycadence-sidebar-link">
                <FontAwesomeIcon className="tinycadence-sidebar-link--icon" size="sm" icon={faInbox}/>
              </NavLink>
            </Tooltip>
          }
          <Tooltip content={_(M.NAV_CONTACTS)} position={Position.RIGHT}>
            <NavLink to="/contacts" className="tinycadence-sidebar-link">
              <FontAwesomeIcon className="tinycadence-sidebar-link--icon" size="sm" icon={faAddressBook}/>
            </NavLink>
          </Tooltip>
        </div>
        <div className="tinycadence-sidebar-footer--container">
          {!hasPaid &&
            <Tooltip content={`${_(M.NAV_TRIAL_EXPIRES_IN)} ${formatter.format(daysUntilTrialExpires, 'day')}. ${_(M.NAV_UPSELL_TRIAL)}`} position={Position.RIGHT}>
              <Link to="/settings/billing" className="tinycadence-sidebar-trial-upsell--link">
                <FontAwesomeIcon size="lg" icon={faBolt}/>
              </Link>
            </Tooltip>
          }
          {isSuperuser &&
            <NavLink to="/admin" className='tinycadence-sidebar-link'>
              <Tooltip content="Admin" position={Position.RIGHT}>
                <FontAwesomeIcon icon={faTerminal} className='tinycadence-sidebar-link--icon' />
              </Tooltip>
            </NavLink>
          }
          <NavLink to="/settings" className='tinycadence-sidebar-link'>
            <Tooltip content={_(M.NAV_SETTINGS)} position={Position.RIGHT}>
              <FontAwesomeIcon icon={faGear} className='tinycadence-sidebar-link--icon' />
            </Tooltip>
          </NavLink>

          <div className="tinycadence-sidebar--version">{process.env.REACT_APP_VERSION}</div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;