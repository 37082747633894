import { EyeOpenIcon } from 'evergreen-ui';

import { ICadenceEvent } from 'types/cadence';
import _, {M} from 'constants/i18n';


const EmailOpenEvent = ({event}: {event: ICadenceEvent}) => {
  return <div>
    <div className="enrolled-event-title--container">
      <EyeOpenIcon className="enrolled-event-title--icon" color="var(--color-blue-2-3)"/>
      <div className="enrolled-event-title--text">{_(M.CADENCE_EVENT_EMAIL_OPEN_TITLE)}</div>
    </div>
    <p className="enrolled-event--description">{_(M.CADENCE_EVENT_EMAIL_OPEN_DESC)}</p>
  </div>
};

export default EmailOpenEvent;