import { Outlet } from 'react-router-dom';

import _, { M } from 'constants/i18n';
import { titleCase } from 'utils/strings';
import './style.css';

const CadenceEnrollmentsOutlet = () => {
  return (
    <div className="cadence-list--container">
      <h2 className="cadences-enrollments--title">
        {titleCase(_(M.NAV_CADENCE_ENROLLMENTS))}
      </h2>
      <Outlet />
    </div>
  );
};

export default CadenceEnrollmentsOutlet;
