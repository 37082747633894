import { NavLink, Outlet, useParams } from 'react-router-dom';

import { useFetchEnrollmentQuery } from 'api/admin/cadence';
import { getContactName } from 'utils/strings';
import './style.css';

const AdminEnrollmentOutlet = () => {
  const {enrollmentId = ''} = useParams<{enrollmentId: string}>()

  const {
    data,
    isLoading,
    isFetching,
    isError,
  } = useFetchEnrollmentQuery(enrollmentId);

  if (!data || isLoading || isFetching) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error</div>
  }

  const cadence = data.cadence_contact.cadence;

  return <div className="admin-page-content--container">
    <h2>{cadence.name} - {getContactName(data.cadence_contact.contact)}</h2>
    <div className="admin-page-content-sub-nav--container">
      <NavLink className="admin-page-content-sub-nav--link" to={`/admin/enrollments/${enrollmentId}`} end>Enrollment</NavLink>
      <NavLink className="admin-page-content-sub-nav--link" to={`/admin/enrollments/${enrollmentId}/cadence`}>Cadence</NavLink>
      <NavLink className="admin-page-content-sub-nav--link" to={`/admin/enrollments/${enrollmentId}/contact`}>Contact</NavLink>
      <NavLink className="admin-page-content-sub-nav--link" to={`/admin/enrollments/${enrollmentId}/events`}>Events</NavLink>
    </div>
    <Outlet />
  </div>
};

export default AdminEnrollmentOutlet;