import { adminQueries } from 'api/queries';
import { IPagination } from 'api/cadence';
import { IContactInList, IContactList } from 'types/contact';


const contactListPage = {type: 'ContactList' as const, id: 'PAGE'};

interface IListContactListsArgs {
  offset: number;
  limit: number;
  user_id?: string | number;
}

interface IListContactsInListArgs {
  offset: number;
  limit: number;
  listId?: string | number;
}

const contactListsQueries = adminQueries.injectEndpoints({
  endpoints: (build) => ({
    paginateContactLists: build.query<{items: IContactList[], pagination: IPagination}, IListContactListsArgs>({
      query: (body) => {
        const qs = new URLSearchParams();
        qs.set('limit', body.limit.toString());
        qs.set('offset', body.offset.toString());
        if (body.user_id) {
          qs.set('user_id', body.user_id.toString());
        }
        return {
          url: `/contact_lists/?${qs}`,
          method: 'GET',
        }
      },

      providesTags: (res) => res ? [contactListPage, ...res.items.map(c => ({type: 'ContactList', id: c.id}))] : [],
    }),

    fetchContactList: build.query<{data: IContactList}, string | number>({
      query: (id: number | string) => `/contact_lists/${id}`,

      providesTags: (res) => res ? [{type: 'ContactList', id: res.data.id}] : [],
    }),

    paginateContactsInList: build.query<{items: IContactInList[], pagination: IPagination}, IListContactsInListArgs>({
      query: ({listId, offset, limit}) => {
        const qs = new URLSearchParams();
        qs.set('limit', limit.toString());
        qs.set('offset', offset.toString());
        return {
          url: `/contact_lists/${listId}/contacts?${qs}`,
        }
      },

      providesTags: (res) => res ? [{type: 'ContactInList', id: 'PAGE'}, ...res.items.map(c => ({type: 'ContactInList' as const, id: c.id}))] : [],
    })
  }),
  overrideExisting: false,
});


export const {
  useFetchContactListQuery,
  usePaginateContactListsQuery,
  usePaginateContactsInListQuery,
} = contactListsQueries;