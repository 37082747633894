import { Button, TextInputField } from 'evergreen-ui';
import { ChangeEvent, useState } from 'react';

import _, { M } from 'constants/i18n';
import { validateEmail } from 'utils/strings';
import { useAppDispatch } from 'hooks';
import { resetNewEnrollment, setEnrollConfiguration } from 'store/cadence/slice';
import { useParams } from 'react-router-dom';


const toDateInputFormat = (date: Date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hour = date.getHours().toString().padStart(2, '0');
  const minute = date.getMinutes().toString().padStart(2, '0');
  return `${year}-${month}-${day}T${hour}:${minute}`;
}


const EnrollByEmail = () => {
  const [contactEmail, setContactEmail] = useState('');
  const [contactName, setContactName] = useState('');
  const [scheduledFor, setScheduledFor] = useState<string>('');
  const dispatch = useAppDispatch();
  const {cadenceId: cadenceIdStr='0'} = useParams<{cadenceId: string}>();
  const cadenceId = parseInt(cadenceIdStr || '0');

  const minScheduledDate = new Date();
  minScheduledDate.setHours(23, 59, 59, 999);

  const maxScheduledDate = new Date(minScheduledDate.valueOf());
  maxScheduledDate.setMonth(minScheduledDate.getMonth() + 1);

  const onNextClick = async () => {
    dispatch(setEnrollConfiguration({args: {cadenceId, contactEmail, contactName: contactName || null, gmailThreadId: null, scheduledFor}, estSize: 1}));
  };


  return (
    <>
      <TextInputField required autoComplete='email' label={_(M.EMAIL)} placeholder={_(M.EMAIL)} value={contactEmail} onChange={(e: ChangeEvent<HTMLInputElement>) => setContactEmail(e.target.value)} />
      <TextInputField label={_(M.NAME)} autoComplete='name' placeholder={_(M.NAME)} value={contactName} onChange={(e: ChangeEvent<HTMLInputElement>) => setContactName(e.target.value)} />
      <div>
        <label className="enroll-contact-scheduled--label">{_(M.SCHEDULE_ENROLL_LABEL)}</label>
        <input
          className="enroll-contact-scheduled--input"
          type='datetime-local'
          value={scheduledFor}
          onChange={(e) => setScheduledFor(e.currentTarget.value)}
          min={toDateInputFormat(minScheduledDate)}
          max={toDateInputFormat(maxScheduledDate)}
        />
        <div className="enroll-contact-scheduled--help">{_(M.SCHEDULE_ENROLL_HELP)}</div>
      </div>
      <div className="enroll-dialog--footer">
        <Button onClick={() => dispatch(resetNewEnrollment())}>{_(M.BACK)}</Button>
        <Button intent="success" appearance="primary" marginLeft={16} disabled={!validateEmail(contactEmail)} onClick={onNextClick}>{_(M.NEXT)}</Button>
      </div>
    </>
  )

};

export default EnrollByEmail;