import { ChangeEvent, ReactNode, useState } from 'react';

import Composer from 'components/shared/Composer';
import _, {M} from 'constants/i18n';

import './style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/free-solid-svg-icons';


const checkAnyLinksAreEmpty = (htmlString: string) => {
  const elt = document.createElement('div');
  elt.innerHTML = htmlString;
  const anchors = elt.querySelectorAll('a');

  let anyAreEmpty = false;

  anchors.forEach(anchor => {
    const href = anchor.href;
    if (href.startsWith('mailto:') && !href.includes('@')) {
      anyAreEmpty = true;
      return;
    }
    if (!href) {
      anyAreEmpty = true;
      return;
    }
    if (href === 'https://' || href === 'http://' || !href.includes('.')) {
      anyAreEmpty = true;
      return;
    }
    // TODO: more logic
  });

  return anyAreEmpty;
}


const EmailEditor = ({
  onSubjectChange,
  onBodyChange,
  subject,
  subjectDisabled,
  subjectHint,
  bodyHtml,
}: {
  subject: string,
  subjectDisabled?: boolean,
  subjectHint?: ReactNode,
  bodyHtml: string,
  onSubjectChange: (subject: string) => void,
  onBodyChange: (value: {html: string, plainText: string}) => void
}) => {
  const [anyLinksAreEmpty, setAnyLinksAreEmpty] = useState(false);

  const onChange = (value: {html: string, plainText: string}) => {
    setAnyLinksAreEmpty(checkAnyLinksAreEmpty(value.html));
    onBodyChange(value);
  };

  return <div className="email-editor--container">
    {/* hint={subjectHint} */}
    <input
      value={subject}
      placeholder={_(M.EMAIL_SUBJECT)}
      disabled={subjectDisabled}
      onChange={(e: ChangeEvent<HTMLInputElement>) => onSubjectChange(e.target.value)}
      className="email-editor-subject--input"
    />
    <Composer
      initialHtmlString={bodyHtml}
      onChange={onChange}
    />
    {anyLinksAreEmpty && <div className="email-editor-bad-link--warning"><FontAwesomeIcon icon={faWarning} /> {_(M.EMAIL_WARN_EMPTY_LINK)}</div>}
  </div>;

};

export default EmailEditor;