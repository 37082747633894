import { IConversation, DraftMessage, IDraftMessageContext, AnyMessage, PrivateComment, AnyMessageContext } from 'types/conversation';
import { v2queries } from '../queries';
import { IBrowserContext } from 'utils/context';
import { API } from 'api';

interface IPostConversationMessagePayload {
  conversationId: number | string
  body_text: string
  body_html: string
  context: Record<string, unknown> | IBrowserContext
  meta: Record<string, unknown>
}


interface IPostDraftMessagePayload {
  conversationId: number | string
  body_text: string
  body_html: string
  context: IDraftMessageContext
  meta: Record<string, unknown>
}

interface IPatchMessagePayload {
  conversationId: number | string
  messageId: number
  body_text?: string
  body_html?: string
  context?: AnyMessageContext
  meta?: Record<string, unknown>
}

interface IDeleteMessagePayload {
  conversationId: number | string
  messageId: number
}

export const fetchConversationMessage = (conversationId: number | string, messageId: number | string) => API.get<{message: AnyMessage}>(`/api/v2/conversations/${conversationId}/messages/${messageId}`);
export const fetchConversation = (conversationId: number | string) => API.get<{conversation: IConversation}>(`/api/v2/conversations/${conversationId}`);
export const patchConversation = (conversationId: number | string, payload: Partial<IConversation>) => API.patch<{conversation: IConversation}>(`/api/v2/conversations/${conversationId}`, payload);

const conversationQueries = v2queries.injectEndpoints({
  endpoints: (build) => ({
    fetchConversation: build.query<{conversation: IConversation}, number>({
      query: (convId) => ({
        url: `/conversations/${convId}`
      }),

      providesTags: (res) => res ? [{type: 'Conversation', id: res.conversation.id}] : [],
    }),

    postConversationComment: build.mutation<{message: PrivateComment}, IPostConversationMessagePayload>({
      query: ({conversationId, ...body}) => ({
        url: `/conversations/${conversationId}/comments`,
        method: 'POST',
        body,
      }),
    }),

    markConversationRead: build.mutation<{mark: string}, string | number>({
      query: (conversationId) => ({
        url: `/conversations/${conversationId}/read`,
        method: 'POST',
      }),
    }),

    createConversationDraft: build.mutation<{message: DraftMessage}, IPostDraftMessagePayload>({
      query: ({conversationId, ...body}) => ({
        url: `/conversations/${conversationId}/drafts`,
        method: 'POST',
        body,
      }),
    }),

    patchMessage: build.mutation<{message: AnyMessage}, IPatchMessagePayload>({
      query: ({conversationId, messageId, ...body}) => ({
        url: `/conversations/${conversationId}/messages/${messageId}`,
        method: 'PATCH',
        body,
      }),
    }),

    deleteMessage: build.mutation<{message: AnyMessage}, IDeleteMessagePayload>({
      query: ({conversationId, messageId, ...body}) => ({
        url: `/conversations/${conversationId}/messages/${messageId}`,
        method: 'DELETE',
        body,
      }),
    }),

    sendConversationDraft: build.mutation<{message: DraftMessage}, {conversationId: number, draftId: number}>({
      query: ({conversationId, draftId}) => ({
        url: `/conversations/${conversationId}/drafts/${draftId}/send`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useFetchConversationQuery,
  usePostConversationCommentMutation,
  useMarkConversationReadMutation,
  useCreateConversationDraftMutation,
  usePatchMessageMutation,
  useDeleteMessageMutation,
  useSendConversationDraftMutation,
} = conversationQueries;