import { adminQueries } from 'api/queries';
import { IHydratedEnrollment, IPagination } from 'api/cadence';
import { ICadence, ICadenceEvent } from 'types/cadence';


const cadencesPage = {type: 'Cadence' as const, id: 'PAGE'};
const enrollmentPage = {type: 'Enrollment' as const, id: 'PAGE'};
const cadenceEventPage = {type: 'CadenceEvent' as const, id: 'PAGE'};

interface IListCadencesArgs {
  offset: number;
  limit: number;
  user_id?: string | number;
}

interface IListEnrollmentsArgs {
  offset: number;
  limit: number;
  user_id?: string | number;
  cadence_id?: string | number;
}

interface IListCadenceEventsArgs {
  offset: number;
  limit: number;
  user_id?: string | number;
  cadence_id?: string | number;
  namespaces?: string[];
  contact_id?: string | number;
  cadence_contact_id?: string | number;
  event_type?: string[];
}


const cadencesQueries = adminQueries.injectEndpoints({
  endpoints: (build) => ({
    paginateCadences: build.query<{cadences: ICadence[], meta: {pagination: IPagination}}, IListCadencesArgs>({
      query: (body) => {
        const qs = new URLSearchParams();
        qs.set('limit', body.limit.toString());
        qs.set('offset', body.offset.toString());
        if (body.user_id) {
          qs.set('user_id', body.user_id.toString());
        }
        return {
          url: `/cadences/?${qs}`,
          method: 'GET',
        }
      },

      providesTags: (res) => res ? [cadencesPage, ...res.cadences.map(c => ({type: 'Cadence', id: c.id}))] : [],
    }),

    fetchCadence: build.query<{cadence: ICadence}, string>({
      query: (id: number | string) => `/cadences/${id}`,

      providesTags: (res) => res ? [{type: 'Cadence', id: res.cadence.id}] : [],
    }),

    paginateEnrollments: build.query<{cadence_contacts: IHydratedEnrollment[], meta: {pagination: IPagination}}, IListEnrollmentsArgs>({
      query: (body) => {
        const qs = new URLSearchParams();
        qs.set('limit', body.limit.toString());
        qs.set('offset', body.offset.toString());
        if (body.user_id) {
          qs.set('user_id', body.user_id.toString());
        }
        if (body.cadence_id) {
          qs.set('cadence_id', body.cadence_id.toString());
        }
        return {
          url: `/cadence_contacts/?${qs}`,
          method: 'GET',
        }
      },

      providesTags: (res) => res? [enrollmentPage, ...res.cadence_contacts.map(c => ({type: 'Enrollment' as const, id: c.cadence_contact.id}))] : [],
    }),

    fetchEnrollment: build.query<{cadence_contact: IHydratedEnrollment}, string | number>({
      query: (id: number | string) => `/cadence_contacts/${id}`,

      providesTags: (res) => res ? [{type: 'Enrollment', id: res.cadence_contact.cadence_contact.id}] : [],
    }),

    paginateCadenceEvents: build.query<{cadence_events: ICadenceEvent[], meta: {pagination: IPagination}}, IListCadenceEventsArgs>({
      query: (body) => {
        const qs = new URLSearchParams();
        qs.set('limit', body.limit.toString());
        qs.set('offset', body.offset.toString());
        if (body.user_id) {
          qs.set('user_id', body.user_id.toString());
        }
        if (body.cadence_id) {
          qs.set('cadence_id', body.cadence_id.toString());
        }
        if (body.cadence_contact_id) {
          qs.set('cadence_contact_id', body.cadence_contact_id.toString());
        }
        body.namespaces?.forEach(ns => qs.append('namespaces', ns));
        if (body.contact_id) {
          qs.set('contact_id', body.contact_id.toString());
        }
        body.event_type?.forEach(et => qs.append('event_tyype', et));

        return {
          url: `/cadence_events/?${qs}`,
        }
      },

      providesTags: (res) => res ? [cadenceEventPage, ...res.cadence_events.map(c => ({type: 'CadenceEvent' as const, id: c.id}))] : [],
    }),

  }),
  overrideExisting: false,
});


export const {
  useFetchCadenceQuery,
  usePaginateCadencesQuery,
  usePaginateEnrollmentsQuery,
  useFetchEnrollmentQuery,
  usePaginateCadenceEventsQuery,
} = cadencesQueries;