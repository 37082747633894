import { ICadenceEvent } from 'types/cadence';
import _, {M} from 'constants/i18n';
import { HistoryIcon } from 'evergreen-ui';


const getEnrolledFrom = (rawEnrolledFrom: string) => {
  switch (rawEnrolledFrom) {
  case 'hubspot_list_add':
    return M.CADENCE_EVENT_ENROLLED_FROM_HS_LIST;
  case 'webapp':
    return M.CADENCE_EVENT_ENROLLED_FROM_SITE;
  default:
    return M.CADENCE_EVENT_ENROLLED_FROM_GMAIL;
  }
}


const EnrolledEvent = ({event}: {event: ICadenceEvent}) => {
  const enrolledFrom = getEnrolledFrom(event.event_data.enrolled_from as string);
  return <div>
    <div className="enrolled-event-title--container">
      <HistoryIcon className="enrolled-event-title--icon" color="var(--color-green-6)"/>
      <div className="enrolled-event-title--text">{_(M.CADENCE_EVENT_ENROLLED_TITLE)}</div>
    </div>
    <p className="enrolled-event--description">{_(M.CADENCE_EVENT_ENROLLED_DESC)} {_(enrolledFrom)}</p>
  </div>
};

export default EnrolledEvent;