import cx from 'classnames';
import { useState } from 'react';
import { Switch, toaster } from 'evergreen-ui';
import axios, { AxiosError } from 'axios';

import { connectorHasSettings, getConnectorName } from 'utils/data';
import { useAppDispatch } from 'hooks';
import { receiveConnectors } from 'store/user/slice';
import { IConnector } from 'types/user';
import { disableConnector, enableConnector } from 'api/connectors';
import _, {M} from 'constants/i18n';

import { getProviderIcon } from './providers';
import ConnectorSettingsDialog from './settings';
import './style.css';


const ConnectorStatus = {
  ACTIVE: 'active',
  DISABLED: 'disabled',
  SUSPENDED: 'suspended',
};


// TODO: i18n
const Connector = ({
  connector,
  className = '',
}: {connector: IConnector, className?: string}) => {
  const Icon = getProviderIcon(connector.provider);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const connectorName = getConnectorName(connector);
  const isSuspended = connector.status === ConnectorStatus.SUSPENDED;
  const apiCall = connector.status === ConnectorStatus.DISABLED ? enableConnector : disableConnector;
  const dispatch = useAppDispatch();
  const callApi = async () => {
    if (isSuspended) return;
    const setTo = connector.status === ConnectorStatus.DISABLED ? 'enabled' : 'disabled';
    try {
      const {data} = await apiCall(connector.id);
      dispatch(receiveConnectors([data]));
      toaster.success(`Account was ${setTo}`)
    } catch (e: unknown) {
      const status = axios.isAxiosError(e) ? (e as AxiosError).response?.status : null;
      // 400 on this endpoint means it was already disabled
      if (status !== 400) {
        toaster.warning('Failed to update account, please try again or contact support')
      } else {
        toaster.success(`Account was ${setTo}`)
      }
    }
  };
  return (
    <>
      <div className={cx(className, 'connector-info--container')}>
        <div className="connector-info--title">
          <Icon className="connector-info--icon"/>
          <div className="connector-info--name">
            {connectorName}
          </div>
        </div>
        {connectorHasSettings(connector.provider) && <div className="connector-info-settings--link" onClick={() => setSettingsOpen(true)}>{_(M.NAV_SETTINGS)}</div>}
        <div className="connected-account-actions-status--container">
          <Switch disabled={isSuspended} checked={connector.status !== ConnectorStatus.DISABLED && !isSuspended} onChange={callApi} marginRight={4}></Switch>
          {isSuspended && <span>Suspended</span>}
          {!isSuspended && <span className="">{connector.status === ConnectorStatus.DISABLED ? 'Disabled' : 'Enabled'}</span>}
        </div>
        <ConnectorSettingsDialog connector={connector} isShown={settingsOpen} onClose={() => setSettingsOpen(false)}/>
      </div>
    </>
  );
};

export default Connector;