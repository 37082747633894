import { useRef, useState } from 'react';
import { AnyMessage, EmailMessage } from 'types/conversation';
import cx from 'classnames';

import MessageFrame from './iframe';
import MessageHeader from './header';


const EmailMessageContainer = ({message}: {message: AnyMessage}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setIsCollapsed(!isCollapsed)
  }

  return <div className="conversation-message--container">
    <MessageHeader
      message={message as EmailMessage}
      onToggleCollapse={toggleCollapsed}
      isCollapsed={isCollapsed}
    />
    <div className={cx('message-iframe-container', {collapsed: isCollapsed})} ref={containerRef}>
      <MessageFrame
        sandbox='allow-forms allow-same-origin allow-popups allow-scripts'
        message={message as EmailMessage} />
    </div>
  </div>;
};

export default EmailMessageContainer;