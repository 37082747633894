import { useAppDispatch, useAppSelector } from 'hooks';
import './style.css';
import { getNewEnrollmentMethod, getNewEnrollmentStep } from 'store/cadence/selector';
import SelectMethod from './selectMethod';
import { setNewEnrollmentMethod } from 'store/cadence/slice';
import EnrollByEmail from './byEmail';
import EnrollByCSV from './byCsv';
import EnrollByHubSpotList from './byHsList';
import EnrollByContactList from './byContactList';
import MapCsvColumns from './csvMapping';
import ReviewEnrollment from './review';


const methodToComponent = {
  'single': EnrollByEmail,
  'bulk_csv': EnrollByCSV,
  'contact_list': EnrollByContactList,
  'hubspot_list': EnrollByHubSpotList,
}

const CadenceEnroll = () => {
  const step = useAppSelector(getNewEnrollmentStep);
  const method = useAppSelector(getNewEnrollmentMethod);
  const dispatch = useAppDispatch();

  if (step === 'choose_method') {
    return <SelectMethod />;
  }

  if (step === 'configure_method') {
    if (method !== null) {
      const Component = methodToComponent[method];
      return <Component />
    } else {
      // invalid state, return to select method
      dispatch(setNewEnrollmentMethod(null));
    }
  }

  if (step === 'csv_mapping') {
    return <MapCsvColumns />;
  }

  if (step === 'review') {
    return <ReviewEnrollment />;
  }

  return <div>hello world</div>
};

export default CadenceEnroll;