import { Button, NewPersonIcon, NumberedListIcon, UploadIcon } from 'evergreen-ui';
import { FC, useMemo, useState } from 'react';
import cx from 'classnames';

import HubspotIcon from 'components/icons/hubspot';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getHasHubSpotConnectors } from 'store/user/selector';
import { setNewEnrollmentMethod } from 'store/cadence/slice';


const SelectMethod = () => {
  const [selectedMethod, setSelectedMethod] = useState<null | 'single' | 'bulk_csv' | 'contact_list' | 'hubspot_list'>(null);
  const hasHsConnector = useAppSelector(getHasHubSpotConnectors);
  const dispatch = useAppDispatch();

  // TODO: i18n
  const methods: {
    title: string,
    description: string,
    Icon: FC<{className?: string}>,
    method: 'single' | 'bulk_csv' | 'contact_list' | 'hubspot_list',
  }[] = useMemo(() => ([
    {
      title: 'By email address',
      description: 'Enroll a single contact by manually entering their email address',
      Icon: NewPersonIcon,
      method: 'single',
    },
    {
      title: 'By CSV',
      description: 'Enroll many contacts by uploading a CSV',
      Icon: UploadIcon,
      method: 'bulk_csv',
    },
    {
      title: 'By contact list',
      description: 'Choose an existing contact list in TinyCadence and enroll all contacts in it',
      Icon: NumberedListIcon,
      method: 'contact_list',
    },
    {
      title: 'By HubSpot list',
      description: 'Choose a HubSpot list and enroll all contacts in that list',
      Icon: HubspotIcon,
      method: 'hubspot_list',
      disabled: !hasHsConnector,
    },
  ]), [hasHsConnector]);

  return (
    <div>
      <div>Choose how to enroll contacts</div>
      <div className="select-method-options--container">
        {methods.map(method => (
          <div key={method.method} onClick={() => setSelectedMethod(method.method)} className={cx('select-method-dialog-option--container', {selected: method.method === selectedMethod})}>
            <div><method.Icon className='select-method-option--icon'/></div>
            <div className='select-method-option--name'>{method.title}</div>
            <div className='select-method-option--desc'>{method.description}</div>
          </div>
        ))}
      </div>
      <div className="enroll-dialog--footer">
        <Button disabled>Back</Button>
        <Button intent="success" appearance="primary" marginLeft={16} disabled={!selectedMethod} onClick={() => dispatch(setNewEnrollmentMethod(selectedMethod))}>Next</Button>
      </div>
    </div>
  )

};

export default SelectMethod;