import { ChevronRightIcon, HistoryIcon } from 'evergreen-ui';
import { useState } from 'react';
import cx from 'classnames';

import { ICadenceEvent } from 'types/cadence';
import _, {M} from 'constants/i18n';
import { dateStrToLocaleStr } from 'utils/strings';


const ScheduledForBizHoursSendEvent = ({event}: {event: ICadenceEvent}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return <div onClick={() => setIsExpanded(!isExpanded)} className="cadence-event-expandable--container">
    <div className="cadence-event--header--container">
      <div>
        <div className="enrolled-event-title--container">
          <HistoryIcon className="enrolled-event-title--icon" color="var(--color-green-4)"/>
          <div className="enrolled-event-title--text">{_(M.CADENCE_EVENT_EMAIL_SCHEDULED_TITLE)}</div>
        </div>
        <p className="enrolled-event--description">{_(M.CADENCE_EVENT_EMAIL_SEND_DESC)} {_(M.AT)} {dateStrToLocaleStr(event.event_data.scheduled_for as string)}</p>
      </div>
      <ChevronRightIcon color="var(--color-neutral-9)" size={24} className={cx('cadence-event-expandable--indicator', {expanded: isExpanded})}/>
    </div>
    <div className={cx('cadence-event-expandable-content--container', {expanded: isExpanded})}>
      <div className="cadence-event-expandable-content--title">{_(M.CADENCE_EVENT_EMAIL_SEND_CONTENT)}</div>
      <div className="cadence-email-event-content--container">
        {!!event.event_data.email_subject && <div className="cadence-email-event-content--subject">{event.event_data.email_subject as string}</div>}
        <div className="cadence-email-event-content--body" dangerouslySetInnerHTML={{__html: event.event_data.email_content_html as string}} />
      </div>
    </div>
  </div>
};

export default ScheduledForBizHoursSendEvent;