import { BillingPlans, ConnectorProviders, OnboardingSteps, UnknownBillingPlan } from 'constants/app';
import { RootState } from 'store';
import { IProperty, UserStatus } from 'types/user';


const EMPTY_PROPERTY = {
  namespace: '*',
  name: 'NOT_FOUND',
  value: undefined,
};

export const UserPropertyNames = {
  ONBOARDING_FINISHED: 'onboarding.finished',
  ONBOARDING_STEP: 'onboarding.step',
  SIGNUP_JOB_TYPE: 'job_type',
  SIGNUP_COMPANY_SIZE: 'company_size',
  SIGNUP_DISCOVERY_SOURCE: 'app_discovery_source',
  TRIAL_EXPIRATION: 'trial_expiration',
};

const root = (state: RootState) => state.user;

export const getContactPropertyDefinitions = (state: RootState) => root(state).contactPropertyDefinitions;

const bootstrapStatus = (state: RootState) => root(state).bootstrapStatus;
export const getIsBootstrapNotStarted = (state: RootState) => bootstrapStatus(state) === 'NOT_STARTED';
export const getIsBootstrapLoading = (state: RootState) => ['NOT_STARTED', 'IN_FLIGHT'].includes(bootstrapStatus(state));
export const getDidBootstrapFail = (state: RootState) => bootstrapStatus(state) === 'FAILED';
export const getDidBootstrapSucceed = (state: RootState) => bootstrapStatus(state) === 'SUCCESS';


const getConnectorsById = (state: RootState) => root(state).connectorsById;
export const getConnectorsArray = (state: RootState) => Object.values(getConnectorsById(state));
const getGoogleConnectors = (state: RootState) => getConnectorsArray(state).filter(conn => [ConnectorProviders.GCAL, ConnectorProviders.GMAIL, ConnectorProviders.GDRIVE].includes(conn.provider));
export const getHasNoGoogleConnectors = (state: RootState) => getGoogleConnectors(state).length === 0;
const getGitHubConnectors = (state: RootState) => getConnectorsArray(state).filter(conn => conn.provider === ConnectorProviders.GITHUB);
export const getHasNoGitHubConnectors = (state: RootState) => getGitHubConnectors(state).length === 0;
const getHubSpotConnectors = (state: RootState) => getConnectorsArray(state).filter(conn => conn.provider === ConnectorProviders.HUBSPOT);
export const getHasHubSpotConnectors = (state: RootState) => getHubSpotConnectors(state).length > 0;
export const getFirstHubSpotConnector = (state: RootState) => getHubSpotConnectors(state)[0];

export const getCurrentUser = (state: RootState) => root(state).user;
export const getCurrentUserEmail = (state: RootState) => getCurrentUser(state)?.email;
export const getCurrentUserEmailDomain = (state: RootState) => getCurrentUserEmail(state)?.split('@')[1] || '__NONE__';
export const getUserPropsByName = (state: RootState) => root(state).propertiesByName;
const getAllProps = (state: RootState) => Object.values(root(state).propertiesByName || {});

export const getHasUnreadThreads = (state: RootState) => root(state).hasUnansweredThreads;

const getUserPropByName = (state: RootState, name: string): IProperty => (getUserPropsByName(state) || {})[name] || EMPTY_PROPERTY;
const getUserGateByName = (state: RootState, name: string): IProperty => {
  const prop = getUserPropByName(state, name);
  if (prop.namespace === 'gates') {
    return prop;
  } else {
    return EMPTY_PROPERTY;
  }
};
export const userHasGate = (state: RootState, name: string): boolean => getUserGateByName(state, name).value === true;
export const getEnabledGateNames = (state: RootState): Set<string> => {
  return new Set(getAllProps(state).filter(prop => prop.namespace === 'GATES' && prop.value === true).map(prop => prop.name));
};
export const getBillingPlan = (state: RootState): string | undefined => (getUserPropByName(state, 'billing_plan')?.value as string | undefined);
export const userHasPaid = (state: RootState): boolean => {
  const planName = getBillingPlan(state);
  return typeof(planName) === 'string' && planName.startsWith('tinycadence-plan');
};
export const getPlanDetails = (state: RootState) => {
  const planName = getBillingPlan(state);
  if (planName) {
    return BillingPlans[planName] || UnknownBillingPlan;
  } else {
    return UnknownBillingPlan;
  }
}
export const getUserFinishedOnboarding = (state: RootState) => {
  const prop = getUserPropByName(state, UserPropertyNames.ONBOARDING_FINISHED);
  return prop.value === true;
};

export const getUserOnboardingStep = (state: RootState) => {
  const prop = getUserPropByName(state, UserPropertyNames.ONBOARDING_STEP);
  return prop.value;
};

export const getRawIsSuperuser = (state: RootState) => (getCurrentUser(state) || {}).is_superuser;
const getUserSignedUpDate = (state: RootState) => new Date(getCurrentUser((state) || {})?.created_at || new Date());
export const getIsUserSuspended = (state: RootState) => getCurrentUser(state)?.status === UserStatus.SUSPENDED;

export const getIsSuperuser = (state: RootState) => getRawIsSuperuser(state) && !root(state).forceNonSuperuser;
export const getForcedNormalUser = (state: RootState) => root(state).forceNonSuperuser;

export const getOnboardingStepIsDone = (state: RootState, step: OnboardingSteps) => {
  const prop = getUserPropByName(state, step)
  return !!(prop?.value);
}

export const getAllOnboardingStepsDone = (state: RootState) => {
  return getOnboardingStepIsDone(state, OnboardingSteps['onboarding.home.connect_crm']) &&
    getOnboardingStepIsDone(state, OnboardingSteps['onboarding.home.connect_google']) &&
    getOnboardingStepIsDone(state, OnboardingSteps['onboarding.home.install_addon']);
}

export const getTrialExpiration = (state: RootState) => {
  const prop = getUserPropByName(state, UserPropertyNames.TRIAL_EXPIRATION);
  const trialExpiresFromProp = new Date(prop.value as string);
  if (isNaN(trialExpiresFromProp.valueOf())) {
    const signedUpAt = getUserSignedUpDate(state);
    const trialExpires = new Date(signedUpAt);
    trialExpires.setDate(signedUpAt.getDate() + 30);
    return trialExpires;
  } else {
    return trialExpiresFromProp;
  }
}