import { adminQueries } from 'api/queries';
import { IPagination } from 'api/cadence';
import { IConnector } from 'types/user';


const connectorsPage = {type: 'Connector' as const, id: 'PAGE'};

interface IListConnectorsArgs {
  offset: number;
  limit: number;
  user_id?: string | number;
}


const connectorsQueries = adminQueries.injectEndpoints({
  endpoints: (build) => ({
    paginateConnectors: build.query<{connectors: IConnector[], meta: {pagination: IPagination}}, IListConnectorsArgs>({
      query: (body) => {
        const qs = new URLSearchParams();
        qs.set('limit', body.limit.toString());
        qs.set('offset', body.offset.toString());
        if (body.user_id) {
          qs.set('user_id', body.user_id.toString());
        }
        return {
          url: `/connectors/?${qs}`,
          method: 'GET',
        }
      },

      providesTags: (res) => res ? [connectorsPage, ...res.connectors.map(c => ({type: 'Connector', id: c.id}))] : [],
    }),

    fetchConnector: build.query<{connector: IConnector}, string>({
      query: (id: number | string) => `/connectors/${id}`,

      providesTags: (res) => res ? [{type: 'Connector', id: res.connector.id}] : [],
    }),

    setConnectorStatus: build.mutation<{connector: IConnector}, {connectorId: string | number, status: string}>({
      query: ({connectorId, status}) => {
        return {
          url: `/connectors/${connectorId}/set-status?status=${status}`,
          method: 'POST'
        };
      },

      invalidatesTags: (res) => res ? [{type: 'Connector', id: res.connector.id}]: [],
    }),

    resetDailyConnectorIngestTask: build.mutation<{status: string}, {connectorId: string | number, scheduledFor: string, orgName?: string}>({
      query: ({connectorId, scheduledFor, orgName}) => {
        return {
          url: `/connectors/${connectorId}/reset-ingest-task`,
          method: 'POST',
          body: {
            scheduled_for: scheduledFor,
            org_name: orgName,
          }
        };
      },

      invalidatesTags: (res) => res ? [{type: 'ScheduledTask', id: 'PAGE'}]: [],
    }),

    watchInbox: build.mutation<{status: string}, {connectorId: string}>({
      query: ({connectorId}) => {
        return {
          url: `/connectors/${connectorId}/watch-inbox`,
          method: 'POST',
        };
      },

      invalidatesTags: (res) => res ? [{type: 'ScheduledTask', id: 'PAGE'}]: [],
    }),
  }),
  overrideExisting: false,
});


export const {
  useFetchConnectorQuery,
  usePaginateConnectorsQuery,
  useSetConnectorStatusMutation,
  useResetDailyConnectorIngestTaskMutation,
  useWatchInboxMutation,
} = connectorsQueries;