import { Dialog, TextInputField } from 'evergreen-ui';
import { ChangeEvent, KeyboardEvent, useState } from 'react';

import _, { M } from 'constants/i18n';
import { useAddContactsToListMutation } from 'api/contactLists';
import './style.css';


const AddContactToListDialog = ({
  isShown,
  listId,
  onClose,
}: {isShown?: boolean, listId: number, onClose: () => void}) => {
  const [emailInput, setEmailInput] = useState('');
  const [add, result] = useAddContactsToListMutation();

  const addToList = () => {
    add({listId, emails: [emailInput], contactIds: []}).then(onClose);
  };

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      addToList();
    }
  }

  return <Dialog width="80%" topOffset={80} isShown={isShown} isConfirmLoading={result.isLoading} onConfirm={addToList} title={_(M.ADD_CONTACT_TO_LIST)} onCloseComplete={onClose} confirmLabel={_(M.CONFIRM)} cancelLabel={_(M.CANCEL)}>
    <div className="cadence-template-options--container">
      <TextInputField
        label={_(M.EMAIL)}
        onKeyDown={onKeyDown}
        value={emailInput}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setEmailInput(e.target.value)}
      />
    </div>
  </Dialog>;
};

export default AddContactToListDialog;