import { Button, TickCircleIcon } from 'evergreen-ui';
import { ReactNode, useEffect } from 'react';
import cx from 'classnames';

import { getGoogleOAuthURI } from 'api/auth';
import { putSetting } from 'api/user';
import GoogleButton from 'components/shared/GoogleButton';
import HubspotButton from 'components/shared/HubspotButton';
import { GOOGLE_ADDON_URL, OnboardingSteps, getHubSpotOauthUrl } from 'constants/app';
import _, {M} from 'constants/i18n';
import { useAppDispatch, useAppSelector } from 'hooks';
import { UserPropertyNames, getAllOnboardingStepsDone, getOnboardingStepIsDone } from 'store/user/selector';

import './style.css';
import { receiveUserProperties } from 'store/user/slice';


const openGoogle = async () => {
  try {
    const {data} = await getGoogleOAuthURI();
    window.open(data.authorization_url, '_blank')
  } catch (err) {
    console.warn('Could not get google auth URI', err);
  }
};


const OnboardingItem = ({
  header,
  description,
  cta,
  step,
  index,
}: {header: ReactNode, description: ReactNode, cta: ReactNode, step: OnboardingSteps, index: number}) => {

  const isDone = useAppSelector(s => getOnboardingStepIsDone(s, step));
  const dispatch = useAppDispatch();

  const markAsDone = async () => {
    try {
      const res = await putSetting(step, 'boolean', isDone ? 'false' : 'true');
      dispatch(receiveUserProperties(res.data.user.properties));
    } catch (e) {
      console.warn(`Failed to mark step done: ${step}`, e);
    }
  }

  return <div className={cx('onboarding-item--container', {done: isDone})}>
    <div>
      {isDone ? <TickCircleIcon color="success" size={48} marginRight={24} /> : <div className="onboarding-item--number">{index}</div>}
    </div>
    <div>
      <h3 className="onboarding-item--header">{header}</h3>
      <p>{description}</p>
      <div className="onboarding-item-cta--container">
        {cta}
        <div className="onboarding-item-cta--mark-done" onClick={markAsDone}>{_(M.ONBOARDING_SKIP_TEXT)}</div>
      </div>
    </div>
  </div>
}


const OnboardingHomeView = () => {
  const allOnboardingStepsComplete = useAppSelector(getAllOnboardingStepsDone);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (allOnboardingStepsComplete) {
      putSetting(UserPropertyNames.ONBOARDING_FINISHED, 'boolean', 'true').then(res => {
        dispatch(receiveUserProperties(res.data.user.properties));
      }).catch((e) => {
        console.warn('Failed to mark onboarding done', e);
      });
    }
  }, [allOnboardingStepsComplete, dispatch]);


  return <div className="app--page-default-container">
    <div>
      <h1>{_(M.ONBOARDING_WELCOME_HEADER)}</h1>
      <p>{_(M.ONBOARDING_WELCOME_DESCRIPTION)}</p>
    </div>
    <OnboardingItem
      header={_(M.ONBOARDING_CONNECT_GOOGLE_HEADER)}
      index={1}
      description={_(M.ONBOARDING_CONNECT_GOOGLE_DESCRIPTION)}
      cta={<GoogleButton onClick={openGoogle} />}
      step={OnboardingSteps['onboarding.home.connect_google']}
    />
    <OnboardingItem
      header={_(M.ONBOARDING_INSTALL_ADDON_HEADER)}
      index={2}
      description={_(M.ONBOARDING_INSTALL_ADDON_DESC)}
      cta={<Button size="large" appearance="primary" onClick={() => window.open(GOOGLE_ADDON_URL, '_blank')}>{_(M.INSTALL)}</Button>}
      step={OnboardingSteps['onboarding.home.install_addon']}
    />
    <OnboardingItem
      header={_(M.ONBOARDING_CONNECT_CRM_HEADER)}
      index={3}
      description={_(M.ONBOARDING_CONNECT_CRM_DESCRIPTION)}
      cta={<HubspotButton onClick={() => window.location.href = getHubSpotOauthUrl()} />}
      step={OnboardingSteps['onboarding.home.connect_crm']}
    />
  </div>;
}

export default OnboardingHomeView;