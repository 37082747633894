import { Outlet } from 'react-router-dom';

import './style.css';

const ThreadsOutlet = () => {
  return (
    <div className='threads-outlet--container'>
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default ThreadsOutlet;