import { useFetchCadenceQuery } from 'api/cadence';
import { relativeTimeFromDates } from 'utils/date';
import { IWaitNodeParams } from 'types/cadence';
import { useEffect, useMemo } from 'react';
import { sum } from 'ramda';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getEnrollmentResult, getIsEnrollmentLoading, getNewEnrollmentEstNumContacts, getNewEnrollmentScheduledFor } from 'store/cadence/selector';
import { Button, toaster } from 'evergreen-ui';
import _, { M } from 'constants/i18n';
import { enrollCadence, stepBackFromReview } from 'store/cadence/slice';
import { store } from 'store';


const ReviewEnrollment = () => {
  const {cadenceId: cadenceIdStr='0'} = useParams<{cadenceId: string}>();
  const cadenceId = parseInt(cadenceIdStr || '0');
  const {
    data,
  } = useFetchCadenceQuery(cadenceId);
  const estimatedNumContacts = useAppSelector(getNewEnrollmentEstNumContacts);
  const scheduledFor = useAppSelector(getNewEnrollmentScheduledFor);
  const isLoading = useAppSelector(getIsEnrollmentLoading);
  const result = useAppSelector(getEnrollmentResult);
  const dispatch = useAppDispatch();

  const emails = data?.cadence.data.nodes.filter((node) => node.action.type === 'SEND_EMAIL').length;
  const delays = data?.cadence.data.nodes.filter((node) => node.action.type === 'WAIT');

  useEffect(() => {
    if (!isLoading) {
      if (result === 'error') {
        toaster.danger(_(M.ERROR));
      } else if (result === 'success') {
        toaster.success(_(M.SUCCESS));
      }
    }
  }, [result, isLoading]);

  const relativeDelay = useMemo(() => {
    const delayPayload = {
      day: 0,
      week: 0,
      minute: 0,
      second: 0,
      hour: 0,
    };

    delays?.forEach(delay => {
      const kwargs = (delay.action.params as IWaitNodeParams).td_kwargs;
      delayPayload.day += (kwargs.days || 0);
      delayPayload.hour += (kwargs.hours || 0);
      delayPayload.minute += (kwargs.minutes || 0);
      delayPayload.second += (kwargs.seconds || 0);
      delayPayload.week += (kwargs.weeks || 0);
    });

    if (sum(Object.values(delayPayload)) === 0) {
      return undefined;
    } else {
      // this will be mostly correct
      const longestDelay = Math.max(...Object.values(delayPayload));
      const [period, amount] = Object.entries(delayPayload).find(([_period, amount]) => amount === longestDelay) as Array<string | number>;
      return new Intl.NumberFormat('default', {style: 'unit', unitDisplay: 'long', unit: period as string}).format(amount as number);
    }
  }, [delays]);

  const onSubmit = () => {
    enrollCadence()(dispatch, store.getState, {});
  }

  // TODO: i18n
  return <div>
    <h2>Review enrollment</h2>
    <div>
      <div className="enroll-review-info--container">
        <div className="enroll-review-info--label">Number of contacts</div>
        <div className="enroll-review-info--value">{estimatedNumContacts.toLocaleString()}</div>
      </div>
      <div />
      <div className="enroll-review-info--container">
        <div className="enroll-review-info--label">Enrollment schedule</div>
        <div className="enroll-review-info--value">{!scheduledFor ? 'Immediately' : relativeTimeFromDates(new Date(scheduledFor))}</div>
      </div>
      <div />
      <div className="enroll-review-info--container">
        <div className="enroll-review-info--label">Emails per contact</div>
        <div className="enroll-review-info--value">{emails !== undefined ? emails : '...'}</div>
      </div>
      <div />
      <div className="enroll-review-info--container">
        <div className="enroll-review-info--label">Length of enrollment</div>
        <div className="enroll-review-info--value">{relativeDelay || 'No delay between steps'}</div>
      </div>
    </div>
    <div>
      <Button onClick={onSubmit} isLoading={isLoading} disabled={isLoading || result === 'success'} appearance="primary" intent="success">Submit</Button>
      <Button onClick={() => dispatch(stepBackFromReview())} marginLeft={8}>{_(M.BACK)}</Button>
    </div>
  </div>
};


export default ReviewEnrollment;