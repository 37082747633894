import { EmptyState, InboxIcon } from 'evergreen-ui';


const SelectAnInbox = () => {
  // TODO: i18n
  return <EmptyState
    title="Select an inbox"
    icon={<InboxIcon />}
    iconBgColor="var(--color-blue-2-2)"
    description="Select an inbox in the sidebar to view your conversations"
  />;
};

export default SelectAnInbox;