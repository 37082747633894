export const uuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
    return v.toString(16);
  });
}

export const lclId = () => (Math.random() * 1000).toString(16).replace(/[a-f0-9]+\./, 'lcl.')

export const isLclId = (candidateId: string | number) => {
  if (typeof candidateId === 'string') {
    return candidateId.startsWith(candidateId);
  } else {
    return false;
  }
}