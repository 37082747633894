import debounce from 'lodash.debounce';
import { KeyboardEvent, useRef, useState } from 'react';
import { Popover } from 'evergreen-ui';
import cx from 'classnames';

import { useLazySearchContactsQuery } from 'api/contact';
import { getContactName, validateEmail } from 'utils/strings';

import './style.css';


const MultiEmailInput = ({
  onChange,
  emails,
  namespace,
  label,
}: {
  onChange: (emails: string[]) => void,
  emails: string[],
  namespace: string,
  label?: string,
}) => {
  const [search, res] = useLazySearchContactsQuery();
  const [nextEmailInput, setNextEmailInput] = useState('');
  const [searchResults, setSearchResults] = useState<{name: string, email: string}[]>([]);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const doSearch = async () => {
    try {
      const res = await search({term: nextEmailInput, namespace});
      if (res.data) {
        const results = res.data.results.map(result => {
          const handleId = parseInt(result.entity_id);
          const handle = result.contact.handles.find(({id}) => id === handleId);
          return {
            name: getContactName(result.contact),
            email: handle?.handle || '',
          }
        }).filter(({email}) => !!email);
        setSearchResults(results);
      }
    } catch (err) {
      console.warn(err);
    }
  };

  const onKeyUp = debounce(doSearch, 150);

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      takeInputAsEmail();
    }
  };

  const onAddEmail = (email: string) => {
    onChange(emails.concat([email]).filter(e => !!e));
    setNextEmailInput('');
  };

  const takeInputAsEmail = () => {
    if (validateEmail(nextEmailInput) && !emails.includes(nextEmailInput)) {
      if (emails.includes(nextEmailInput)) {
        setNextEmailInput('');
      } else {
        onAddEmail(nextEmailInput);
      }
    }
  };

  const onRemoveEmail = (email: string) => {
    onChange(emails.filter(em => em !== email).filter(e => !!e));
    setNextEmailInput('');
  };

  const focus = () => {
    const inputElts = containerRef.current?.getElementsByTagName('input');
    if (inputElts && (inputElts?.length || 0) > 0) {
      inputElts[0].focus();
    }
  }

  return (
    <div className="multi-email-input--container" onClick={focus} ref={containerRef}>
      {label !== undefined && <div className="multi-email-input--label">{label}</div>}
      {emails.filter(email => !!email).map(email =>
        <div className="multi-email-input--email" key={email}>
          <div className="multi-email-input-email--value">{email}</div>
          <div onClick={() => onRemoveEmail(email)} className="multi-email-input-email--delete">&times;</div>
        </div>
      )}
      <Popover
        isShown={!nextEmailInput ? false : undefined}
        content={
          <div>
            {!!nextEmailInput && <div onClick={takeInputAsEmail} className={cx('multi-email-input--option', {invalid: !validateEmail(nextEmailInput)})}>Use &quot;{nextEmailInput}&quot;</div>}
            {!!nextEmailInput && !res.isLoading && searchResults.length === 0 && <em className="multi-email-input--option invalid">No results</em>}
            {searchResults.map(res => (
              <div className="multi-email-input--option" onClick={() => onAddEmail(res.email)} key={res.email}>
                <div>{res.name}</div>
                <div>({res.email})</div>
              </div>
            ))}
            {res.isLoading && <em>loading</em>}
          </div>
        }
      >
        <input value={nextEmailInput} className="multi-email-input--input" onChange={(e) => setNextEmailInput(e.target.value)} onKeyUp={onKeyUp} onKeyDownCapture={onKeyDown} />
      </Popover>
    </div>
  )
};

export default MultiEmailInput;