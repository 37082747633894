import { HistoryIcon } from 'evergreen-ui';

import _, {M} from 'constants/i18n';
import { ICadenceEvent, IWaitNodeParams } from 'types/cadence';

const getWaitTime = (waitParams: IWaitNodeParams) => {
  if (waitParams.td_kwargs.weeks) {
    return `${_(M.NODE_TYPE_WAIT)} ${waitParams.td_kwargs.weeks} ${_(M.WEEKS)}`;
  } else if (waitParams.td_kwargs.days) {
    return `${_(M.NODE_TYPE_WAIT)} ${waitParams.td_kwargs.days} ${_(M.DAYS)}`;
  } else if (waitParams.td_kwargs.hours) {
    return `${_(M.NODE_TYPE_WAIT)} ${waitParams.td_kwargs.hours} ${_(M.HOURS)}`;
  } else if (waitParams.td_kwargs.minutes) {
    return `${_(M.NODE_TYPE_WAIT)} ${waitParams.td_kwargs.minutes} ${_(M.MINUTES)}`;
  }
  return _(M.NODE_TYPE_WAIT);
}

const ScheduledEvent = ({event}: {event: ICadenceEvent}) => {
  return <div>
    <div className="enrolled-event-title--container">
      <HistoryIcon className="enrolled-event-title--icon" color="var(--color-orange-4)"/>
      <div className="enrolled-event-title--text">{_(M.CADENCE_EVENT_SCHEDULED_TITLE)}</div>
    </div>
    <p className="enrolled-event--description">{getWaitTime(event.event_data as unknown as IWaitNodeParams)}</p>
  </div>
};

export default ScheduledEvent;