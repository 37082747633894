import { IconButton, PlusIcon, TrashIcon, Tooltip, Position } from 'evergreen-ui';
import { CSSProperties, MouseEvent, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'hooks';
import { deleteNode } from 'store/cadence/slice';
import { getChildrenNodeIds, getIsNodeSuccessBranchChild, getIsNodeFailBranchChild } from 'store/cadence/selector';
import { getNodeSummary } from 'utils/strings';
import _, { M } from 'constants/i18n';
import { ICadenceNode } from 'types/cadence';

import { nodeFullWidth, nodeLevelHeight, nodeMargin, nodePadding, nodeWidth } from './constants';


const AddNodeButton = ({
  openNewNodeModal,
  node,
}: {openNewNodeModal: (asConditionFallback: boolean) => void, node: ICadenceNode}) => {
  const [width, setWidth] = useState(25);
  if (node.action.type !== 'BRANCH') {
    return (
      <Tooltip content={_(M.CADENCE_ADD_STEP)} position={Position.TOP}>
        <div
          onClick={() => openNewNodeModal(false)}
          style={{position: 'relative', top: 28, left: -13}}
          className="cadence-view-edit-new-node--button">
          <PlusIcon/>
        </div>
      </Tooltip>
    );
  }

  return (
    <div className="cadence-view-edit-new-node--button-container" style={{width, left: -(width/2)}} onMouseEnter={() => setWidth(50)} onMouseLeave={() => setWidth(25)}>
      <Tooltip content={_(M.CONDITIONS_FAIL_PROMPT)} position={Position.TOP}>
        <div
          onClick={() => openNewNodeModal(true)}
          style={{position: 'absolute', right: 0, zIndex: -1}}
          className="cadence-view-edit-new-node--button">
          <PlusIcon/>
        </div>
      </Tooltip>
      <Tooltip content={_(M.CADENCE_ADD_STEP)} position={Position.TOP}>
        <div
          onClick={() => openNewNodeModal(false)}
          style={{position: 'absolute', left: 0}}
          className="cadence-view-edit-new-node--button">
          <PlusIcon/>
        </div>
      </Tooltip>
    </div>
  )
}


const Node = ({
  node,
  levelIdx,
  onEditNode,
  openNewNodeModal,
  leftOffset,
}: {node: ICadenceNode, levelIdx: number, onEditNode: (nodeId: string) => void, openNewNodeModal: (asConditionFallback: boolean) => void, leftOffset: number}) => {
  const dispatch = useAppDispatch();
  const childNodeIds = getChildrenNodeIds(node);
  const isBranchSuccessChild = useAppSelector(s => getIsNodeSuccessBranchChild(s, node.id));
  const isBranchFailChild = useAppSelector(s => getIsNodeFailBranchChild(s, node.id));
  const nodeHasChild = childNodeIds.length !== 0;
  const topOffset = (nodeLevelHeight * levelIdx + 180);
  const style: CSSProperties = {
    position: 'absolute',
    top: topOffset,
    left: leftOffset,
    padding: nodePadding,
    margin: nodeMargin,
    width: nodeWidth,
    borderTopColor: 'var(--color-purple-5)',
  };

  if (isBranchSuccessChild) {
    style.borderTopColor = 'var(--color-green-5)';
  } else if (isBranchFailChild) {
    style.borderTopColor = 'var(--color-red-3)';
  }

  const arrowStyle: CSSProperties = {
    position: 'absolute',
    top: topOffset + 83,
    left: leftOffset + (nodeFullWidth/2),
    height: 28,
  };

  const onDeleteNode = (e: MouseEvent) => {
    e.stopPropagation();
    dispatch(deleteNode({nodeId: node.id}));
  };


  return <div>
    <div className="cadence-grid-node--container" style={style} onClick={() => onEditNode(node.id)} id={`grid-node--${node.id}`}>
      <IconButton className="cadence-grid-node--delete" icon={TrashIcon} onClick={onDeleteNode}/>
      {getNodeSummary(node)}
    </div>
    <div className={!nodeHasChild ? 'cadence-view-edit-first-node--edge' : ''} style={arrowStyle}>
      <AddNodeButton node={node} openNewNodeModal={openNewNodeModal} />
    </div>
  </div>;
}


export default Node;