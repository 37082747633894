import { useFetchEnrollmentQuery, usePaginateCadenceEventsQuery } from 'api/admin/cadence';
import { useParams } from 'react-router-dom';


const AdminEnrollmentEventsPage = () => {
  const {enrollmentId = ''} = useParams<{enrollmentId: string}>();

  const fetchEnrollmentRes = useFetchEnrollmentQuery(enrollmentId);
  const namespace = fetchEnrollmentRes.data?.cadence_contact.cadence.namespace || '';

  const {
    data,
    isLoading,
    isFetching,
    isError,
  } = usePaginateCadenceEventsQuery({cadence_contact_id: enrollmentId, limit: 100, offset: 0, namespaces: [namespace]});

  if (!data || isLoading || isFetching) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error</div>
  }

  return <div>
    <pre>{JSON.stringify(data.cadence_events, undefined, 2)}</pre>
  </div>

}

export default AdminEnrollmentEventsPage;