import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Position, Tooltip } from 'evergreen-ui';
import cx from 'classnames';

import { useFetchInboxesQuery } from 'api/v2/inboxes';
import { isPrivateNamespace, isSharedNamespace } from 'utils/namespace';
import { IInbox } from 'types/inbox';
import _, {M} from 'constants/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressBook, faInbox, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch } from 'hooks';
import { receiveInboxes } from 'store/inbox/slice';
import { useEffect } from 'react';


const InboxLink = ({inbox}: {inbox: IInbox}) => {
  const nav = useNavigate();
  const {pathname} = useLocation();
  const isHighlighted = pathname.startsWith(`/inboxes/${inbox.id}`);

  return (
    <div
      className={cx('inboxes-sidebar-section--content', {selected: isHighlighted})}
      onClick={() => nav(`/inboxes/${inbox.id}`)}
    >{inbox.name}</div>
  );
}


const InboxesSidebar = () => {
  const res = useFetchInboxesQuery({limit: 25});

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (res.data) {
      dispatch(receiveInboxes({inboxes: res.data.items}));
    }
  }, [res.data, dispatch]);

  const personalInboxes = res.data?.items.filter(inb => isPrivateNamespace(inb.namespace));
  const sharedInboxes = res.data?.items.filter(inb => isSharedNamespace(inb.namespace));
  const hasNoInboxes = personalInboxes?.length === 0 && sharedInboxes?.length === 0;
  const hasPersonalInboxes = (personalInboxes?.length || 0) > 0;
  const hasSharedInboxes = (sharedInboxes?.length || 0) > 0;

  return <div className="inboxes-sidebar--container">
    <div className="inboxes-sidebar-nav--container">
      <Tooltip content={_(M.NAV_INBOXES)} position={Position.BOTTOM}>
        <NavLink className="inboxes-sidebar-nav--link" to='/inboxes'>
          <FontAwesomeIcon className="tinycadence-sidebar-link--icon" size="sm" icon={faInbox}/>
        </NavLink>
      </Tooltip>
      <Tooltip content={_(M.NAV_CADENCES)} position={Position.BOTTOM}>
        <NavLink to="/cadences" className="inboxes-sidebar-nav--link">
          <FontAwesomeIcon className="tinycadence-sidebar-link--icon" size="sm" icon={faPaperPlane}/>
        </NavLink>
      </Tooltip>
      <Tooltip content={_(M.NAV_CONTACTS)} position={Position.BOTTOM}>
        <NavLink to="/contacts" className="inboxes-sidebar-nav--link">
          <FontAwesomeIcon className="tinycadence-sidebar-link--icon" size="sm" icon={faAddressBook}/>
        </NavLink>
      </Tooltip>
    </div>
    {res.isLoading && <div>Loading...</div>}
    {hasNoInboxes && <div>you have no inboxes!</div>}
    {!hasNoInboxes &&
      <div className="inboxes-sidebar-section--container">
        <div className="inboxes-sidebar-section--title">Personal Inboxes</div>
        {!hasPersonalInboxes && <div>Connect a personal inbox</div>}
        {hasPersonalInboxes && <div>{personalInboxes?.map(inb => <InboxLink key={inb.id} inbox={inb} />)}</div>}
      </div>
    }
    {!hasNoInboxes &&
      <div className="inboxes-sidebar-section--container">
        <div className="inboxes-sidebar-section--title">Shared Inboxes</div>
        {!hasSharedInboxes && <div>Create a shared inbox</div>}
        {hasSharedInboxes && <div>{sharedInboxes?.map(inb =>
          <div className="inboxes-sidebar-section--content" key={inb.id}>{inb.name}</div>)}</div>
        }
      </div>
    }
  </div>
};

export default InboxesSidebar;