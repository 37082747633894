import { NavLink, Outlet } from 'react-router-dom';

import _, {M} from 'constants/i18n';
import { expireToken } from 'utils/auth';

import './style.css';


const signOut = () => {
  expireToken();
  window.location.href = '/';
};


const SettingsOutlet = () => {
  return (
    <div className='settings-outlet--container'>

      <div className='settings-outlet--sidebar'>
        <div className="settings-outlet-title--container">
          <h2 className='settings-outlet--title'>{_(M.NAV_SETTINGS)}</h2>
        </div>
        <div className="settings-outlet-links--container">
          <NavLink to="/settings" end className='settings-outlet--link'>{_(M.NAV_PROFILE)}</NavLink>
          <NavLink to="/settings/accounts" className='settings-outlet--link'>{_(M.NAV_ACCOUNTS)}</NavLink>
          {/* <NavLink to="/settings/notifications" className='settings-outlet--link'>{_(M.NAV_NOTIF_SETTINGS)}</NavLink> */}
          <NavLink to="/settings/billing" className='settings-outlet--link'>{_(M.NAV_BILLING_SETTINGS)}</NavLink>
          <div className='settings-outlet--link' onClick={signOut}>{_(M.NAV_LOGOUT)}</div>
        </div>
      </div>

      <div className="cadence-sidebar-sibling-content--container">
        <Outlet />
      </div>
    </div>
  );
};

export default SettingsOutlet;