let ResourcesConstants;

if (process.env.REACT_APP_ENV === 'prod') {
  ResourcesConstants = {
    WWW_URL: 'https://www.tinycadence.com',
    APP_URL: 'https://app.tinycadence.com',
    API_URL: 'https://app.api.tinycadence.com',
    HUBSPOT_CLIENT_ID: '36fb7c65-187b-4b98-8397-2d40ab2c59e5',
    PUSHER_CLIENT_ID: 'f62dcccfaab5860b7dad',
    HEAP_APP_ID: '2972481634',
    SENTRY_DSN: 'https://b078770a71d9ec10ea5aad26fe29f4b1@o4504831206555648.ingest.us.sentry.io/4505686301409280',
    STRIPE_PK: 'pk_live_51NfVykAzm60LgwsnXJ0DltqVZkYeruQpShFZSEU905IZP6iSVo5voSDSWPqWcyutGkZPdNb3Tk9mc7VhwrR5iN5E00Dh0hgLl0',
  };
} else if (process.env.REACT_APP_ENV === 'dev') {
  ResourcesConstants = {
    WWW_URL: 'https://www.tinycadence.com',
    APP_URL: 'https://app.dev-tinycadence.com',
    API_URL: 'https://app.api.dev-tinycadence.com',
    HUBSPOT_CLIENT_ID: '36fb7c65-187b-4b98-8397-2d40ab2c59e5',
    PUSHER_CLIENT_ID: '8006975ccd01a2adffac',
    HEAP_APP_ID: '762603075',
    SENTRY_DSN: 'https://b078770a71d9ec10ea5aad26fe29f4b1@o4504831206555648.ingest.us.sentry.io/4505686301409280',
    STRIPE_PK: 'pk_test_51NfVykAzm60LgwsnUpEF535gZxkvdhZEvJMT1jd3rRJJHIXpa7Y5iuuyKRTNei0LvmjVTGi48tXzsQKTkAdVqvcB00d4445Li2',
  };
} else {
  ResourcesConstants = {
    // API_URL: process.env.REACT_APP_API_URL || 'https://app.api.dev-tinycadence.com',
    API_URL: 'http://localhost:8000',
    WWW_URL: 'http://localhost:8000',
    APP_URL: 'https://localhost:3000',
    HUBSPOT_CLIENT_ID: '36fb7c65-187b-4b98-8397-2d40ab2c59e5',
    PUSHER_CLIENT_ID: '8006975ccd01a2adffac',
    HEAP_APP_ID: '762603075',
    SENTRY_DSN: '',
    STRIPE_PK: 'pk_test_51NfVykAzm60LgwsnUpEF535gZxkvdhZEvJMT1jd3rRJJHIXpa7Y5iuuyKRTNei0LvmjVTGi48tXzsQKTkAdVqvcB00d4445Li2',
  };
}

export const DEBUG = process.env.REACT_APP_ENV !== 'prod';
export const LOCAL = DEBUG && process.env.REACT_APP_ENV !== 'dev';
export const API_URL = ResourcesConstants.API_URL;
export const WWW_URL = ResourcesConstants.WWW_URL;
export const APP_URL = ResourcesConstants.APP_URL;
export const HUBSPOT_CLIENT_ID = ResourcesConstants.HUBSPOT_CLIENT_ID;
export const HEAP_APP_ID = ResourcesConstants.HEAP_APP_ID;
export const SENTRY_DSN = ResourcesConstants.SENTRY_DSN;
export const STRIPE_PK = ResourcesConstants.STRIPE_PK;
export const PUSHER_CLIENT_ID = ResourcesConstants.PUSHER_CLIENT_ID;