

export enum FeatureGateNames {
  MGR_INSIGHTS = 'has_manager_insights_app',
  INBOXES = 'has_inboxes',
}


export const FeatureGates = [
  {
    name: 'V2 App Pivot',
    gate: FeatureGateNames.MGR_INSIGHTS,
    description: 'Whether the user can access the engineering manager features that we\'re trying out.',
  },
  {
    name: 'Email inboxes',
    gate: FeatureGateNames.INBOXES,
    description: 'Whether the user can access the inbox features',
  },
]

