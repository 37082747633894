import { Button, PlusIcon, ResetIcon } from 'evergreen-ui';
import { useEffect, useRef, useState } from 'react';

import { useAppDispatch, useAppSelector, useIsVisible } from 'hooks';
import { getChildrenNodeIds, getEditingCadence, getGmailLabelsNeverLoaded, getOrderedCadenceNodeLists } from 'store/cadence/selector';
import { addOrEditNodeInCadence, editCadence, fetchGmailLabels, setNextNodeId } from 'store/cadence/slice';
import { ICadenceNode } from 'types/cadence';
import _, { M } from 'constants/i18n';
import { store } from 'store';

import EditNodeDialog from './EditNode/dialog';
import Node from './node';
import NodeArrow from './arrow';
import { gridWidth, nodeFullWidth } from './constants';
import './style.css';


const GridContainer = () => {
  const cadence = useAppSelector(getEditingCadence);
  const orderedNodeLists = useAppSelector(getOrderedCadenceNodeLists);
  const neverFetchedGmailLabels = useAppSelector(getGmailLabelsNeverLoaded);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const startNodeRef = useRef<HTMLDivElement | null>(null);
  const startIsVisible = useIsVisible(startNodeRef);
  const dispatch = useAppDispatch();
  const [scrollToStartBtnIsVisible, setScrollToStartBtnIsVisible] = useState(false);
  const [editNodeDialogIsVisible, setEditNodeDialogIsVisible] = useState(false);
  const [editingNodeId, setEditingNodeId] = useState<string | null>(null);
  const [editingNodeParentId, setEditingNodeParentId] = useState<string | null>(null);
  const [editingParentFallback, setEditingParentFallback] = useState(false);

  // scroll to start on render
  useEffect(() => {
    scrollToStart();
  }, [containerRef]);

  useEffect(() => {
    if (neverFetchedGmailLabels) {
      fetchGmailLabels()(dispatch, store.getState, {});;
    }
  }, [neverFetchedGmailLabels, dispatch]);

  useEffect(() => {
    if (!startIsVisible) {
      setScrollToStartBtnIsVisible(true);
    } else {
      setScrollToStartBtnIsVisible(false);
    }
  }, [startIsVisible]);

  // TODO: handle this case
  if (!cadence) return null;

  const {
    start_node,
    nodes,
  } = cadence.data;

  const scrollToStart = () => {
    if (containerRef.current) {
      const sidebarWidth = (64 + 280) / 2;
      containerRef.current.scrollTo({
        left: (gridWidth/2) - ((window.innerWidth/2) - sidebarWidth),
        top: 24,
        behavior: 'smooth',
      })
    }
  };

  const onSaveNode = (node: ICadenceNode) => {
    if (nodes.length === 0) {
      dispatch(editCadence({...cadence, data: {nodes: [node], start_node: node.id }}))
    } else {
      dispatch(addOrEditNodeInCadence({node}));
      if (editingNodeParentId) {
        dispatch(setNextNodeId({parentNodeId: editingNodeParentId, nextNodeId: node.id, asFallback: editingParentFallback}));
      }
      dispatch(addOrEditNodeInCadence({node, doNotChangeNextNode: true}));
    }
    setEditingNodeId(null);
    setEditingNodeParentId(null);
    setEditNodeDialogIsVisible(false);
  };

  const openNewNodeModal = (asFailure?: boolean, parentNodeId?: string) => {
    if (parentNodeId) {
      setEditingNodeParentId(parentNodeId);
    }
    setEditingParentFallback(!!parentNodeId && !!asFailure);
    setEditingNodeId(null);
    setEditNodeDialogIsVisible(true);
  };

  const onEditNode = (nodeId: string) => {
    setEditingNodeId(nodeId);
    setEditNodeDialogIsVisible(true);
  };

  const editingNode = nodes.find(({id}) => id === editingNodeId);
  const startNode = nodes.find(n => n.id === start_node);

  return (
    <>
      <div className='cadence-view-edit-grid--container' ref={containerRef}>
        <div style={{width: gridWidth, height: 3000, backgroundImage: 'radial-gradient(var(--color-neutral-7) 5%, transparent 20%)', backgroundSize: '20px 20px', backgroundRepeat: 'repeat', backgroundPosition: '48px 48px'}}>
          <div className="cadence-grid--start" style={{position: 'absolute', top: 80, left: (gridWidth/2 - 46)}} ref={startNodeRef}>{_(M.CADENCE_START)}</div>
          {!startNode &&
            <>
              <div className='cadence-view-edit-first-node--edge' style={{position: 'absolute', top: 105, height: 78,  left: (gridWidth/2 - 1)}}/>
              <div
                onClick={() => openNewNodeModal()}
                style={{position: 'absolute', top: 180, left: (gridWidth/2 - 12)}}
                className="cadence-view-edit-new-node--button">
                <PlusIcon />
              </div>
            </>
          }
          <EditNodeDialog onClose={() => setEditNodeDialogIsVisible(false)} editingNodeId={editingNodeId} isShown={editNodeDialogIsVisible} onConfirm={onSaveNode} defaultParams={editingNode?.action.params} defaultType={editingNode?.action.type} />
          {orderedNodeLists.map((nodeLevel, levelIdx) => {

            const startLeftOffset = (gridWidth / 2) - (nodeLevel.length * nodeFullWidth) / 2;

            return <div key={`cadence-grid-level--${levelIdx}`} style={{position: 'absolute', zIndex: 1}}>
              {nodeLevel.map((node, idx) => <Node key={node.id} leftOffset={startLeftOffset + (idx * nodeFullWidth)} node={node} levelIdx={levelIdx} onEditNode={onEditNode} openNewNodeModal={(asConditionFallback) => openNewNodeModal(asConditionFallback, node.id)} />)}
            </div>;
          })}
          <svg viewBox={`0 0 ${gridWidth} 3000`} style={{position: 'relative', zIndex: 0}}>
            {!!startNode && <path d={`M ${gridWidth/2},106 L ${gridWidth/2},260`} strokeWidth="2" stroke="var(--color-neutral-10)" />}
            {nodes.map(node => {
              const childIds = getChildrenNodeIds(node);

              return <g key={`node-svg-child-paths--${node.id}`}>
                {childIds.map(toNodeId => <NodeArrow fromNodeId={node.id} toNodeId={toNodeId} key={`cadence-grid-node--${toNodeId}`} />)}
              </g>
            })}
          </svg>
        </div>
      </div>
      {/* TODO: i18n */}
      {scrollToStartBtnIsVisible && <Button className="cadence-grid-return-to-start--button" iconBefore={ResetIcon} onClick={scrollToStart}>Return to start</Button>}
    </>
  );
}

export default GridContainer;