import { useContactAnalyticsQuery } from 'api/v2/analytics';
import { Checkbox } from 'evergreen-ui';
import { groupBy, prop } from 'ramda';
import { ReactElement, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Area, AreaChart, Tooltip, XAxis, YAxis } from 'recharts';
import Select from 'react-select';

import './style.css';


const THIRTY_DAYS_IN_MS = 30 * 24 * 60 * 60 * 1000;

const toDateInputFormat = (date: Date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hour = date.getHours().toString().padStart(2, '0');
  const minute = date.getMinutes().toString().padStart(2, '0');
  return `${year}-${month}-${day}T${hour}:${minute}`;
}


const ContactMetrics = () => {
  const [grouped, setGrouped] = useState(true);
  const [eventTypes, setEventTypes] = useState<string[]>([]);
  const maxScheduledDate = new Date();
  maxScheduledDate.setUTCHours(23, 59, 59, 999);
  const minScheduledDate = new Date();
  minScheduledDate.setMonth(minScheduledDate.getMonth() - 3);

  const { contactId='' } = useParams<{contactId: string}>();
  const [before, setBefore] = useState(toDateInputFormat(maxScheduledDate));
  const [after, setAfter] = useState(toDateInputFormat(new Date(maxScheduledDate.valueOf() - THIRTY_DAYS_IN_MS)));

  const res = useContactAnalyticsQuery({
    contactId,
    after,
    before,
  });


  const [chartData, areas, allEventTypes] = useMemo(() => {
    let chartData: Record<string, string | number>[] = [];
    let areas: ReactElement[] = [];
    const allEventTypes = new Set<string>();

    const groupedByDay = groupBy(prop('day'), res.data?.results || []);
    const allKeys: string[] = [];
    res.data?.results.forEach(res => {
      allEventTypes.add(res.event_type);
      const includeEvent = !allKeys.includes(res.event_type) && !grouped && (eventTypes.length === 0 || eventTypes.includes(res.event_type));
      if (includeEvent) {
        allKeys.push(res.event_type);
        areas.push(<Area dataKey={res.event_type} key={res.event_type}/>)
      }
    });

    if (grouped) {
      areas.push(<Area dataKey="activity" key="activity" />)
    }

    Object.entries(groupedByDay).forEach(([daystr, results])=> {
      // make sure the day is the "28th" if it's set to the 28th at midnight UTC
      const date = new Date(daystr);
      date.setDate(date.getDate() + 1);

      const datum: Record<string, string | number> = {
        name: date.toLocaleDateString('default', {month: 'short', day: 'numeric', weekday: 'short'}),
        epoch: date.valueOf(),
        activity: 0,
      };

      (results || []).forEach(res => {
        datum[res.event_type] = res.count;
        (datum.activity as number) += res.count;
      });

      allKeys.forEach(key => {
        if (!datum.hasOwnProperty(key)) {
          datum[key] = 0;
        }
      });

      chartData.push(datum);
    });

    chartData.sort((d1, d2) => (d1.epoch as number) - (d2.epoch as number));
    return [chartData, areas, allEventTypes];
  }, [grouped, res.data, eventTypes]);

  const eventTypeOptions: {value: string, label: string}[] = [];
  allEventTypes.forEach(et => {
    eventTypeOptions.push({
      label: et,
      value: et,
    });
  });


  return <div>
    <div className="contact-metrics-options--container">
      <div>
        <label className="enroll-contact-scheduled--label">after</label>
        <input
          className="enroll-contact-scheduled--input"
          type='datetime-local'
          value={after}
          onChange={(e) => setAfter(e.currentTarget.value)}
          min={toDateInputFormat(minScheduledDate)}
          max={toDateInputFormat(maxScheduledDate)}
        />
      </div>

      <div>
        <label className="enroll-contact-scheduled--label">before</label>
        <input
          className="enroll-contact-scheduled--input"
          type='datetime-local'
          value={before}
          onChange={(e) => setBefore(e.currentTarget.value)}
          min={toDateInputFormat(minScheduledDate)}
          max={toDateInputFormat(maxScheduledDate)}
        />
      </div>

      <Checkbox checked={!grouped} label="Split data by event" onChange={() => setGrouped(!grouped)}/>

      <Select className="contact-metrics-event--select" options={eventTypeOptions} isMulti onChange={(val) => setEventTypes(val.map(d => d.value))} isClearable/>
    </div>

    {res.data && <AreaChart width={730} height={250} data={chartData}>
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      {areas}
    </AreaChart>}
  </div>;
}

export default ContactMetrics;