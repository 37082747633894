import { Dialog, toaster } from 'evergreen-ui';
import { useState } from 'react';

import _, {M} from 'constants/i18n';
import { IConnector } from 'types/user';
import { usePatchConnectorSettingsMutation } from 'api/connectors';
import { useAppDispatch } from 'hooks';
import { receiveConnectors } from 'store/user/slice';

import HubspotSettings from './hubspot';


const ConnectorSettingsDialog = ({
  isShown,
  onClose,
  connector
}: {isShown?: boolean, onClose: () => void, connector: IConnector}) => {
  const [patch, result] = usePatchConnectorSettingsMutation();
  const [settings, setSettings] = useState(connector.meta.settings || {});
  const dispatch = useAppDispatch();

  const save = () => {
    patch({body: settings, connectorId: connector.id}).then((res) => {
      if ('data' in res && res.data !== undefined) {
        dispatch(receiveConnectors([res.data]));
        onClose();
        toaster.success(_(M.SUCCESS));
      } else {
        toaster.danger(_(M.FAILED_TO_SAVE));
      }
    });
  };

  return <Dialog isShown={isShown} isConfirmLoading={result.isLoading} onConfirm={save} title={_(M.HUBSPOT_SETTINGS_TITLE)} onCloseComplete={onClose} confirmLabel={_(M.CONFIRM)} cancelLabel={_(M.CANCEL)}>
    {connector.provider === 'hubspot' && <HubspotSettings settings={settings} onChange={setSettings} />}
  </Dialog>;

};

export default ConnectorSettingsDialog;