import { Button } from 'evergreen-ui';
import { Outlet } from 'react-router-dom';
import { useState } from 'react';

import _, {M} from 'constants/i18n';
import CadencesSidebar from 'components/cadence/CadencesSidebar';
import CreateCadenceDialog from 'components/cadence/CreateCadenceDialog';

import './style.css';

const CadencesOutlet = () => {
  const [createCadenceDialogShown, setCreateCadenceDialogShown] = useState(false);

  const openCadenceDialog = () => {
    setCreateCadenceDialogShown(true);
  };

  return (
    <div className='cadences-outlet--container'>
      <div className='cadences-outlet--sidebar'>
        <div className="cadences-outlet-title--container">
          <h2 className='cadences-outlet--title'>{_(M.NAV_CADENCES)}</h2>
          <Button appearance='primary' onClick={openCadenceDialog}>{_(M.NEW_CADENCE)}</Button>
        </div>
        <CadencesSidebar />
      </div>
      <div className="cadence-sidebar-sibling-content--container">
        <Outlet />
      </div>
      <CreateCadenceDialog isShown={createCadenceDialogShown} onClose={() => setCreateCadenceDialogShown(false)} />
    </div>
  );
};

export default CadencesOutlet;