import { PayloadAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { fetchBootstrap } from 'api/user';
import { AppDispatch, RootState } from 'store';
import { IBootstrapResponse, IConnector, IContactPropertyDefinition, IProperty, IUser } from 'types/user';
import { BOOTSTRAP_LOCALSTORAGE_NAME, getTokenExpiration } from 'utils/auth';
import { signInWSConnection } from 'utils/socket';
import { tracker } from 'utils/tracking';

type BootstrapStatus = 'NOT_STARTED' | 'IN_FLIGHT' | 'FAILED' | 'SUCCESS';

export interface UserState {
  bootstrapStatus: BootstrapStatus,
  user: IUser | null,
  hasUnansweredThreads: boolean,
  connectorsById: {[id: number]: IConnector},
  propertiesByName: {[propertyName: string]: IProperty} | null,
  forceNonSuperuser: boolean,
  contactPropertyDefinitions: IContactPropertyDefinition[],
}

const initialState: UserState = {
  bootstrapStatus: 'NOT_STARTED',
  user: null,
  connectorsById: {},
  hasUnansweredThreads: false,
  forceNonSuperuser: false,
  propertiesByName: null,
  contactPropertyDefinitions: [],
};

interface IStoredBootstrapData {
  data: IBootstrapResponse
  bootVersion: number
  expiresAt: number
}

const saveBootstrapForFastLoad = (bootData: IBootstrapResponse) => {
  const expiresAt = getTokenExpiration();
  if (!expiresAt) return;

  const data: IStoredBootstrapData = {
    data: bootData,
    expiresAt,
    bootVersion: 1,
  }
  try {
    localStorage.setItem(BOOTSTRAP_LOCALSTORAGE_NAME, JSON.stringify(data));
  } catch (error) {
    console.warn('Failed to store boot data');
  }
};

const getSavedBootData = () => {
  try {
    const strValue = localStorage.getItem(BOOTSTRAP_LOCALSTORAGE_NAME);
    if (strValue) {
      const actualValue: IStoredBootstrapData = JSON.parse(strValue);
      if (actualValue.bootVersion === 1 && actualValue.expiresAt * 1000 > Date.now()) {
        return actualValue.data;
      }
    }

  } catch (error) {
    try {
      localStorage.removeItem(BOOTSTRAP_LOCALSTORAGE_NAME);
    } catch (error) {}
  }
};

export const kickoffBootstrap = createAsyncThunk<{data?: IBootstrapResponse, status: BootstrapStatus} | undefined, undefined, {
  dispatch: AppDispatch,
  state: RootState,
}>(
  'user/kickoffBootstrap',
  async (_, {dispatch, getState}) => {
    const savedBootData = getSavedBootData();

    if (getState().user.bootstrapStatus === 'IN_FLIGHT') {
      // short circuit
      return undefined;
    }
    dispatch(userSlice.actions.updateBootstrapState('IN_FLIGHT'));
    if (savedBootData) {
      dispatch(userSlice.actions.receiveSavedBootData(savedBootData));
    }

    try {
      const {data} = await fetchBootstrap();
      tracker.identify(data.user);
      saveBootstrapForFastLoad(data);
      return {data, status: 'SUCCESS'};
    } catch (err) {
      return {status: 'FAILED'};
    }
  },
);


export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    toggleForceSuperuser: (state) => {
      state.forceNonSuperuser = !state.forceNonSuperuser;
    },

    updateBootstrapState: (state, action: PayloadAction<BootstrapStatus>) => {
      state.bootstrapStatus = action.payload;
    },

    receiveSavedBootData: (state, action: PayloadAction<IBootstrapResponse>) => {
      state.user = action.payload.user;
      state.bootstrapStatus = 'SUCCESS';
      action.payload.connectors.forEach(conn => {
        state.connectorsById[conn.id] = conn;
      })
      state.contactPropertyDefinitions = action.payload.contact_property_definitions;
      action.payload.user.properties.forEach(property => {
        if (!state.propertiesByName) {
          state.propertiesByName = {};
        }

        state.propertiesByName[property.name] = property;
      });
      // this should always be sett to false if we're reading from the saved data
      state.hasUnansweredThreads = false;
    },

    receiveConnectors: (state, action: PayloadAction<IConnector[]>) => {
      action.payload.forEach(conn => {
        state.connectorsById[conn.id] = conn;
      });
    },

    dismissUnreadThreads: (state) => {
      state.hasUnansweredThreads = false;
    },

    receiveUserProperties: (state, action: PayloadAction<IProperty[]>) => {
      action.payload.forEach(property => {
        if (!state.propertiesByName) {
          state.propertiesByName = {};
        }

        state.propertiesByName[property.name] = property;
      });
    },
  },

  extraReducers: (builder) => {
    builder.addCase(kickoffBootstrap.fulfilled, (state, action) => {
      // short circuited because a request was in-flight
      if (!action.payload) return;

      state.bootstrapStatus = action.payload.status;

      if (action.payload.data) {
        signInWSConnection();
        state.user = action.payload.data.user;
        action.payload.data.connectors.forEach(conn => {
          state.connectorsById[conn.id] = conn;
        })
        state.contactPropertyDefinitions = action.payload.data.contact_property_definitions;
        action.payload.data.user.properties.forEach(property => {
          if (!state.propertiesByName) {
            state.propertiesByName = {};
          }

          state.propertiesByName[property.name] = property;
        });
        state.hasUnansweredThreads = action.payload.data.num_unread_threads > 0;
      }
    })
  }
});

export const { toggleForceSuperuser, receiveConnectors, dismissUnreadThreads, receiveUserProperties } = userSlice.actions;
export default userSlice.reducer;