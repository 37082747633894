import { useParams } from 'react-router-dom';

import AdminConnectorsTable from 'components/admin/ConnectorsTable';


const AdminUserConnectorsPage = () => {
  const {userId = ''} = useParams<{userId: string}>()

  return <div>
    <AdminConnectorsTable userId={userId} />
  </div>
}

export default AdminUserConnectorsPage;