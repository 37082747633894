import { Dialog, SelectField } from 'evergreen-ui';
import { ChangeEvent, useEffect, useState } from 'react';

import { CadenceNodeActionParams, CadenceNodeActionType, ICadenceNode } from 'types/cadence';
import _, { M } from 'constants/i18n';
import { lclId } from 'utils/id';
import { useAppSelector } from 'hooks';
import { getNextNodeIdForNode } from 'store/cadence/selector';

import EditNodeParams from './params';

const EmptySendEmailNodeParams = {
  subject: '',
  body: {
    text: '',
    html: '',
  },
  reply_to_thread: false,
  pause_before_sending: false,
  send_during_business_days: false,
};

const EmptyBranchNodeParams = {
  conditions: {
    evaluation: ('ALL' as 'ANY' | 'ALL'),
    conditions: ([] as {field: string, operator: string, value: string[]}[]),
    conditions_fail_next_node_id: null,
  },
};

const EmptyWaitNodeParams = {
  td_kwargs: {},
};

const EmptyApplyLabelNodeParams = {
  labels: [],
  remove_other_labels: false,
};

const getDefaultParamsForType = (actionType: CadenceNodeActionType | null, defaultParams: null | CadenceNodeActionParams) => {
  if (actionType === 'WAIT') {
    if (defaultParams && 'td_kwargs' in defaultParams) {
      return defaultParams;
    } else {
      return EmptyWaitNodeParams;
    }
  } else if (actionType === 'SEND_EMAIL') {
    if (defaultParams && 'subject' in defaultParams) {
      return defaultParams;
    } else {
      return EmptySendEmailNodeParams;
    }
  } else if (actionType === 'BRANCH') {
    if (defaultParams && 'conditions' in defaultParams) {
      return defaultParams;
    } else {
      return EmptyBranchNodeParams;
    }
  } else if (actionType === 'APPLY_LABEL') {
    if (defaultParams && 'labels' in defaultParams) {
      return defaultParams;
    } else {
      return EmptyApplyLabelNodeParams;
    }
  }
};


const EditNodeDialog = ({
  isShown,
  onConfirm,
  defaultParams,
  defaultType='SEND_EMAIL',
  editingNodeId,
  onClose,
}: {isShown?: boolean, onConfirm: (node: ICadenceNode) => void, defaultType?: CadenceNodeActionType, defaultParams?: CadenceNodeActionParams, editingNodeId: string | null, onClose: () => void}) => {
  const [params, setParams] = useState<null | CadenceNodeActionParams>(defaultParams || null);
  const [actionType, setActionType] = useState<null | CadenceNodeActionType>(defaultType);
  const defaultParamsForModal = getDefaultParamsForType(actionType, params);
  const nodeId = editingNodeId || lclId();
  const nextNodeId = useAppSelector(s => getNextNodeIdForNode(s, nodeId));

  // change the params when the type changes
  useEffect(() => {
    setParams(getDefaultParamsForType(actionType, defaultParams || null) || null);
  }, [actionType, defaultParams]);

  const createNode = () => {
    if (!params || !actionType) return;
    onConfirm({
      id: nodeId,
      action: {
        type: (actionType as CadenceNodeActionType),
        params: params,
      },
      next_node_id: nextNodeId,
    });
  };

  // reset the state when showing/hiding or rendering for a new node
  useEffect(() => {
    if (!isShown) {
      setParams(null);
      setActionType(null);
    } else {
      setParams(defaultParams || null);
      setActionType(defaultType);
    }
  }, [isShown, defaultParams, defaultType]);

  return <Dialog width="90%" topOffset={32} isShown={isShown} onConfirm={createNode} title={_(M.EDIT_STEP)} onCloseComplete={onClose} confirmLabel={_(M.CONFIRM)} cancelLabel={_(M.CANCEL)}>
    <div>
      <div>
        <SelectField maxWidth={200} label={<div>{_(M.STEP_TYPE)} <a className="edit-node-dialog--help-link" href="https://docs.tinycadence.com/cadences/cadences/cadence-steps" target="_blank" rel="noreferrer">{_(M.MORE_INFO)}</a></div>} value={actionType?.toString()} onChange={(e: ChangeEvent<HTMLSelectElement>) => setActionType(e.target.value as CadenceNodeActionType)}>
          <option value={'SEND_EMAIL'}>{_(M.NODE_TYPE_SEND_EMAIL)}</option>
          <option value={'WAIT'}>{_(M.NODE_TYPE_WAIT)}</option>
          <option value={'APPLY_LABEL'}>{_(M.NODE_TYPE_APPLY_LABEL)}</option>
        </SelectField>
      </div>
      {actionType && defaultParamsForModal &&
        <EditNodeParams
          onChange={setParams}
          type={actionType}
          params={defaultParamsForModal}
          nodeId={nodeId}
        />
      }
    </div>
  </Dialog>;
};

export default EditNodeDialog;