import { TextInputField, Spinner, Button } from 'evergreen-ui';
import { ChangeEvent, FormEvent, useState } from 'react';
import { useParams } from 'react-router-dom';

import _, {M} from 'constants/i18n';
import { useFetchContactQuery, useUpsertContactPropertiesMutation } from 'api/contact';
import { useAppSelector } from 'hooks';
import { getContactPropertyDefinitions } from 'store/user/selector';


const ContactEdit = () => {
  const { contactId='' } = useParams<{contactId: string}>();
  const {
    isFetching,
    isLoading,
    isError: error,
    data,
  } = useFetchContactQuery(parseInt(contactId));
  const [upsert, result] = useUpsertContactPropertiesMutation();

  const propDefs = useAppSelector(getContactPropertyDefinitions);

  const lastNameProp = propDefs.find(def => def.code_name === 'lastname' || def.code_name === 'last_name');
  const firstNameProp = propDefs.find(def => def.code_name === 'firstname' || def.code_name === 'first_name');
  const companyProp = propDefs.find(def => def.code_name === 'company')

  const [lastName, setLastName] = useState(data?.contact.properties.find(prop => prop.prop_def_id === lastNameProp?.id)?.raw_value || '')
  const [firstName, setFirstName] = useState(data?.contact.properties.find(prop => prop.prop_def_id === firstNameProp?.id)?.raw_value || '')
  const [company, setCompany] = useState(data?.contact.properties.find(prop => prop.prop_def_id === companyProp?.id)?.raw_value || '')

  const loading = isLoading || isFetching;

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    const properties = [];

    if (lastNameProp) {
      properties.push({contact_id: parseInt(contactId), prop_def_id: lastNameProp.id, raw_value: lastName});
    }

    if (firstNameProp) {
      properties.push({contact_id: parseInt(contactId), prop_def_id: firstNameProp.id, raw_value: firstName});
    }

    if (companyProp) {
      properties.push({contact_id: parseInt(contactId), prop_def_id: companyProp.id, raw_value: company});
    }

    upsert({properties});
  };

  if (loading) {
    return <Spinner delay={100}>{_(M.LOADING)}</Spinner>
  }

  if (!data || error) {
    return <div>{_(M.ERROR)}</div>
  }

  return <div>
    <form onSubmit={onSubmit}>
      {firstNameProp &&
        <TextInputField
          label={firstNameProp.display_name}
          value={firstName}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setFirstName(e.currentTarget.value)}
        />
      }
      {lastNameProp &&
        <TextInputField
          label={lastNameProp.display_name}
          value={lastName}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setLastName(e.currentTarget.value)}
        />
      }
      {companyProp &&
        <TextInputField
          label={companyProp.display_name}
          value={company}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setCompany(e.currentTarget.value)}
        />
      }
      <Button type="submit" isLoading={result.isLoading}>{_(M.SAVE)}</Button>
    </form>

  </div>
}

export default ContactEdit;