import { Pagination, Table } from 'evergreen-ui';
import { useState } from 'react';

import { dateStrToLocaleStr, getContactName } from 'utils/strings';
import { usePaginateContactsInListQuery } from 'api/admin/contact_list';
import { IContactWithProperties } from 'types/contact';


const limit = 10;

const ContactRow = ({contact, relation, addedAt}: {contact: IContactWithProperties, relation: string, addedAt: string}) => {
  return <Table.Row>
    <Table.TextCell>{getContactName(contact)} (id: {contact.id})</Table.TextCell>
    <Table.TextCell>{dateStrToLocaleStr(addedAt)}</Table.TextCell>
    <Table.TextCell>{relation}</Table.TextCell>
  </Table.Row>
}


const AdminContactsInListTable = ({contactListId}: {contactListId?: string | number}) => {
  const [offset, setOffset] = useState(0);
  const {
    data,
  } = usePaginateContactsInListQuery({limit: 10, offset, listId: contactListId});

  const onPrevPageClick = () => {
    if (!data) return;

    const currentOffset = (data.pagination.this - 1) * limit;
    if (currentOffset > 0) {
      setOffset(currentOffset - limit);
    }
  };

  const onNextPage = () => {
    if (!data) return;

    const pagination = data.pagination;
    const currentOffset = (pagination.this - 1) * limit;
    if (pagination.this < pagination.last) {
      setOffset(currentOffset + limit);
    }
  };

  const onPageChange = (page: number) => {
    setOffset((page - 1) * limit);
  };

  return <div>
    <Table>
      <Table.Head>
        <Table.TextHeaderCell>Contact</Table.TextHeaderCell>
        <Table.TextHeaderCell>Added at</Table.TextHeaderCell>
        <Table.TextHeaderCell>Relationship</Table.TextHeaderCell>
      </Table.Head>
      <Table.Body>
        {data?.items.map((membership) => <ContactRow contact={membership.contact} relation={membership.relation_type} addedAt={membership.created_at} key={membership.id} />)}
      </Table.Body>
    </Table>
    {data && (
      <Pagination
        totalPages={data.pagination.last}
        page={data.pagination.this}
        onNextPage={onNextPage}
        onPreviousPage={onPrevPageClick}
        onPageChange={onPageChange}
      />
    )}
  </div>
}


export default AdminContactsInListTable;