import Select from 'react-select';
import { flatten } from 'ramda';
import { Button } from 'evergreen-ui';

import { useAppDispatch, useAppSelector } from 'hooks';
import { getCsvColumnMapping } from 'store/cadence/selector';
import { getContactPropertyDefinitions } from 'store/user/selector';
import _, { M } from 'constants/i18n';
import { cancelCSVMapping, finishedCSVMapping, setCsvColumnMapping } from 'store/cadence/slice';



const MapCsvColumns = () => {
  const mapping = useAppSelector(getCsvColumnMapping);
  const propDefs = useAppSelector(getContactPropertyDefinitions);
  const dispatch = useAppDispatch();

  const propDefsOptions = propDefs.map(def => ({
    value: def.id,
    label: <div>{def.display_name} <small>({def.code_name})</small></div>,
  }));

  // TODO: i18n
  const groupOptions = [
    {
      label: 'Standard properties',
      options: [
        {value: 'email', label: 'Email address'},
        {value: 'name', label: <div>Full name <small className="cadence-csv-mapping-option--description">(assigns the first word to the <pre>firstname</pre> property and <pre>lastname</pre> is everything else)</small></div>},
        {value: 'IGNORE', label: <div>Ignore this column</div>},
      ]
    },
    {
      label: 'Custom properties',
      options: propDefsOptions,
    }
  ];

  const allOptions = flatten(groupOptions.map(g => g.options));

  const hasSelectedEmail = Object.values(mapping || {}).some(val => val === 'email');

  const onSubmit = () => {
    dispatch(finishedCSVMapping());
  };

  return <div>
    <h3>Map CSV Columns</h3>
    <div className="enroll-mapping-column--container">
      <div>CSV column</div>
      <div className="enroll-mapping-column--value">Contact field</div>
    </div>
    {Object.entries(mapping || {}).map(([column, mappingValue]) => (
      <div key={column} className="enroll-mapping-column--container">
        <div>{column}</div>
        <Select
          className="enroll-mapping-column--value"
          value={allOptions.find(d => d.value === mappingValue)}
          options={groupOptions}
          isClearable={false}
          onChange={d => d && dispatch(setCsvColumnMapping({column, value: d.value}))}
          styles={{ control: (base) => ({...base, fontSize: '12px'}), menuPortal: (base) => ({ ...base, fontSize: '12px', zIndex: 9999 }) }}
          menuPortalTarget={document.body}
        />
      </div>
    ))}
    <div className="enroll-dialog--footer">
      <Button onClick={() => dispatch(cancelCSVMapping())}>{_(M.BACK)}</Button>
      <Button intent="success" appearance="primary" marginLeft={16} disabled={!hasSelectedEmail} onClick={onSubmit}>{_(M.NEXT)}</Button>
    </div>
    {!hasSelectedEmail && <div className="enroll-mapping--error">Email address is a required column</div>}
  </div>
};

export default MapCsvColumns;