import { TextInput } from 'evergreen-ui';
import { ChangeEvent } from 'react';

import _, { M } from 'constants/i18n';
import { IWaitNodeParams } from 'types/cadence';


const EditWaitParams = ({
  params,
  onChange,
}: {params: IWaitNodeParams, onChange: (params: IWaitNodeParams) => void}) => {

  const onUpdateKwargs = (key: string, valueStr: string) => {
    const value = parseInt(valueStr);
    if (value < 0) return;

    if (!isNaN(value)) {
      onChange({
        ...params,
        td_kwargs: {
          ...params.td_kwargs,
          [key]: value,
        },
      });
    } else if (!value) {
      onChange({
        ...params,
        td_kwargs: {
          ...params.td_kwargs,
          [key]: undefined,
        },
      });
    }
  };

  const tdKwargs = params.td_kwargs;

  return <div className="edit-delay--container">
    <TextInput
      type="number"
      min={0}
      placeholder={_(M.WEEKS)}
      value={tdKwargs.weeks || ''}
      borderTopRightRadius="3px"
      borderTopLeftRadius="3px"
      borderBottomRightRadius="0"
      borderBottomLeftRadius="0"
      onChange={(e: ChangeEvent<HTMLInputElement>) => onUpdateKwargs('weeks', e.target.value)}/>
    <TextInput
      type="number"
      min={0}
      placeholder={_(M.DAYS)}
      value={tdKwargs.days || ''}
      borderTop="none"
      borderRadius="0"
      onChange={(e: ChangeEvent<HTMLInputElement>) => onUpdateKwargs('days', e.target.value)}/>
    <TextInput
      type="number"
      min={0}
      placeholder={_(M.HOURS)}
      value={tdKwargs.hours || ''}
      borderTop="none"
      borderRadius="0"
      onChange={(e: ChangeEvent<HTMLInputElement>) => onUpdateKwargs('hours', e.target.value)}/>
    <TextInput
      type="number"
      placeholder={_(M.MINUTES)}
      value={tdKwargs.minutes || ''}
      min={0}
      borderTop="none"
      borderTopRightRadius="0"
      borderTopLeftRadius="0"
      borderBottomRightRadius="3px"
      borderBottomLeftRadius="3px"
      onChange={(e: ChangeEvent<HTMLInputElement>) => onUpdateKwargs('minutes', e.target.value)}/>
  </div>;
};

export default EditWaitParams;