import { Pagination, Table } from 'evergreen-ui';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { usePaginateCadencesQuery } from 'api/admin/cadence';
import { ICadence } from 'types/cadence';
import { dateStrToLocaleStr } from 'utils/strings';

const limit = 10;

const CadenceRow = ({cadence}: {cadence: ICadence}) => {
  const nav = useNavigate();

  const goToCadence = () => {
    nav(`/admin/cadences/${cadence.id}`);
  }

  return <Table.Row
    onClick={goToCadence}
    isSelectable
  >
    <Table.TextCell>{cadence.name}</Table.TextCell>
    <Table.TextCell>{cadence.description}</Table.TextCell>
    <Table.TextCell>{cadence.status}</Table.TextCell>
    <Table.TextCell>{cadence.namespace}</Table.TextCell>
    <Table.TextCell>{dateStrToLocaleStr(cadence.created_at)}</Table.TextCell>
    <Table.TextCell>{dateStrToLocaleStr(cadence.updated_at)}</Table.TextCell>
  </Table.Row>
}


const AdminCadencesTable = ({userId}: {userId?: string | number}) => {
  const [offset, setOffset] = useState(0);
  const {
    data,
  } = usePaginateCadencesQuery({limit: 10, offset, user_id: userId});

  const onPrevPageClick = () => {
    if (!data) return;

    const currentOffset = (data.meta.pagination.this - 1) * limit;
    if (currentOffset > 0) {
      setOffset(currentOffset - limit);
    }
  };

  const onNextPage = () => {
    if (!data) return;

    const pagination = data.meta.pagination;
    const currentOffset = (pagination.this - 1) * limit;
    if (pagination.this < pagination.last) {
      setOffset(currentOffset + limit);
    }
  };

  const onPageChange = (page: number) => {
    setOffset((page - 1) * limit);
  };

  return <div>
    <Table>
      <Table.Head>
        <Table.TextHeaderCell>Name</Table.TextHeaderCell>
        <Table.TextHeaderCell>Description</Table.TextHeaderCell>
        <Table.TextHeaderCell>Status</Table.TextHeaderCell>
        <Table.TextHeaderCell>Namespace</Table.TextHeaderCell>
        <Table.TextHeaderCell>Created at</Table.TextHeaderCell>
        <Table.TextHeaderCell>Updated at</Table.TextHeaderCell>
      </Table.Head>
      <Table.Body>
        {data?.cadences.map((cadence) => <CadenceRow cadence={cadence} key={cadence.id} />)}
      </Table.Body>
    </Table>
    {data && (
      <Pagination
        totalPages={data.meta.pagination.last}
        page={data.meta.pagination.this}
        onNextPage={onNextPage}
        onPreviousPage={onPrevPageClick}
        onPageChange={onPageChange}
      />
    )}
  </div>
}


export default AdminCadencesTable;
