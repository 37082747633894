import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Button, Spinner, toaster } from 'evergreen-ui';

import _, { M } from 'constants/i18n';
import { getContactName } from 'utils/strings';
import { useCancelCadenceContactMutation, useFetchCadenceEnrollmentQuery, usePaginateCadenceEventsQuery } from 'api/cadence';
import { TrackEventNames, tracker } from 'utils/tracking';

import './style.css';
import CadenceEnrollmentEvent from './events';



const ViewCadenceEnrollment = () => {
  const { enrollmentId: enrollmentIdStr='' } = useParams<{enrollmentId: string}>();

  const enrollmentId = parseInt(enrollmentIdStr)

  const {
    isLoading,
    isFetching,
    isError,
    data,
  } = useFetchCadenceEnrollmentQuery(enrollmentId);

  const [cancel, cancelRes] = useCancelCadenceContactMutation();

  useEffect(() => {
    if (cancelRes.isError) {
      toaster.danger(_(M.ERROR));
    } else if (cancelRes.isSuccess) {
      tracker.track(TrackEventNames.CE, {enrollmentId})
      toaster.success(_(M.SUCCESS));
    }
  }, [cancelRes.isError, cancelRes.isSuccess, enrollmentId]);

  const eventsRes = usePaginateCadenceEventsQuery({
    cadenceContactId: enrollmentId,
    sort_dir: 'desc',
    limit: 20,
    offset: 0,
  });

  const canCancel = ['paused', 'in_progress'].includes(data?.cadence_contact.status || '');

  if (isLoading || isFetching || eventsRes.isFetching || eventsRes.isLoading) {
    return <div>{_(M.LOADING)} <Spinner /></div>
  }

  if (!data || isError) {
    return <div>{_(M.ERROR)}</div>
  }

  const name = getContactName(data.contact);

  return <div className='view-cadence--container'>
    <div className="view-cadence--header">
      <div>
        <h2 className="view-cadence--title">{_(M.VIEW_ENROLLMENT_TITLE)} {name}</h2>
      </div>
      {canCancel && <Button onClick={() => cancel({id: enrollmentId})} isLoading={cancelRes.isLoading} intent="danger">{_(M.UNENROLL)}</Button>}
    </div>
    <div>
      <p>{_(M.VIEW_ENROLLMENT_EVENTS_TIMELINE)}</p>
      <div>
        {eventsRes.data?.cadence_events.map(ce => <CadenceEnrollmentEvent event={ce} key={ce.dedup_key} />)}
      </div>
    </div>
  </div>;
};


export default ViewCadenceEnrollment;