import { Button } from 'evergreen-ui';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';

import _, { M } from 'constants/i18n';
import { usePaginateContactListsQuery } from 'api/contactLists';
import { useAppDispatch } from 'hooks';
import { resetNewEnrollment, setEnrollConfiguration } from 'store/cadence/slice';


const EnrollByContactList = () => {
  const {cadenceId: cadenceIdStr='0'} = useParams<{cadenceId: string}>();
  const cadenceId = parseInt(cadenceIdStr || '0');
  const [contactListId, setContactListId] = useState<null | number>(null);
  const clistRes = usePaginateContactListsQuery({offset: 0, limit: 40});
  const dispatch = useAppDispatch();

  const clistOptions = useMemo(() => clistRes.data?.items.map(list => ({
    value: list.id,
    label: `${list.name} (${list.size} ${_(M.CONTACTS)})`,
  })) || [], [clistRes.data]);

  const onSubmit = async () => {
    if (contactListId) {
      const clist = clistRes.data?.items.find(({id}) => id === contactListId);
      dispatch(setEnrollConfiguration({args: {cadenceId, listId: contactListId}, estSize: clist?.size || 0}));
    }
  };

  return (
    <>
      <Select
        onChange={s => {
          if (!s) {
            setContactListId(null);
          } else {
            setContactListId(s.value);
          }
        }}
        options={clistOptions}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
        value={clistOptions.find(({value}) => value === contactListId)}
      />
      <div className="enroll-dialog--footer">
        <Button onClick={() => dispatch(resetNewEnrollment())}>{_(M.BACK)}</Button>
        <Button intent="success" appearance="primary" marginLeft={16} disabled={!contactListId} onClick={onSubmit}>{_(M.NEXT)}</Button>
      </div>
    </>
  )

};

export default EnrollByContactList;