import { Switch } from 'evergreen-ui';
import Select, { MultiValue } from 'react-select';

import _, { M } from 'constants/i18n';
import { IApplyLabelNodeParams } from 'types/cadence';
import { useAppSelector } from 'hooks';
import { getGmailLabels } from 'store/cadence/selector';


const EditApplyLabelParams = ({
  params,
  onChange,
}: {params: IApplyLabelNodeParams , onChange: (params: IApplyLabelNodeParams) => void}) => {

  const labels = useAppSelector(getGmailLabels);

  const onSelectChange = (value: MultiValue<{label: string, value: string}>) => {
    onChange({
      ...params,
      labels: value.map(op => ({id: op.value, name: op.label})),
    });
  };

  return <div>
    <div>
      <label>{_(M.LABELS)}</label>
      <Select
        isMulti
        placeholder={_(M.SELECT_PLACEHOLDER)}
        name="labels"
        options={labels?.map(l => ({label: l.name, value: l.id})) || []}
        value={params.labels.map(l => ({label: l.name, value: l.id}))}
        onChange={onSelectChange}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }), container: (base) => ({...base, maxWidth: '50%'})}}
        menuPortalTarget={document.body}
      />
    </div>
    <div className="edit-apply-label--field-container">
      <label>{_(M.REMOVE_OTHER_LABELS)}</label>
      <Switch checked={params.remove_other_labels || false} onChange={() => onChange({...params, remove_other_labels: !params.remove_other_labels})}/>
    </div>
  </div>;
};

export default EditApplyLabelParams;