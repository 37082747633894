import { adminQueries } from 'api/queries';


const searchQueries = adminQueries.injectEndpoints({
  endpoints: (build) => ({
    reindexContactsForNamespace: build.mutation<{status: string}, {namespace: string}>({
      query: ({namespace}) => {
        return {
          url: `/search/reindex-contacts?namespace=${namespace}`,
          method: 'POST'
        };
      },
    }),
  }),
  overrideExisting: false,
});


export const {
  useReindexContactsForNamespaceMutation,
} = searchQueries;