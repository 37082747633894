import _, { M } from 'constants/i18n';
import { ISendEmailNodeParams } from 'types/cadence';
import { useAppSelector } from 'hooks';
import { getEditingCadenceOption, getSendEmailNodeAncestor } from 'store/cadence/selector';
import EmailEditor from 'components/shared/EmailEditor';
import { Button, toaster } from 'evergreen-ui';
import { useSendTestEmailMutation } from 'api/connectors';
import { useEffect } from 'react';


const EditSendEmailParams = ({
  params,
  onChange,
  nodeId,
}: {params: ISendEmailNodeParams, onChange: (params: ISendEmailNodeParams) => void, nodeId: string}) => {

  const directSendEmailParent = useAppSelector(s => getSendEmailNodeAncestor(s, nodeId));
  const isSentAsReply = (useAppSelector(s => getEditingCadenceOption(s, 'send_emails_as_replies')) as boolean | undefined);
  const [sendTest, sendTestResult] = useSendTestEmailMutation();

  const sendEmailAncestor = directSendEmailParent;

  const onUpdateSubject = (value: string) => {
    onChange({
      ...params,
      subject: value,
    });
  };

  const onUpdateBody = ({html, plainText}: {html: string, plainText: string}) => {
    onChange({
      ...params,
      body: {
        text: plainText,
        html,
      },
    });
  };

  useEffect(() => {
    if (sendTestResult.isSuccess) {
      toaster.success(`${_(M.SEND_TEST_EMAIL_SUCCESS)} ${sendTestResult.data.sent_to}`);
    } else if (sendTestResult.isError) {
      toaster.danger(_(M.SEND_TEST_EMAIL_FAIL));
    }
  }, [sendTestResult]);

  const subjectValue = isSentAsReply && sendEmailAncestor ? (sendEmailAncestor.action.params as ISendEmailNodeParams).subject : params.subject;

  return <>
    <Button
      onClick={() => sendTest({subject: params.subject, body: params.body})}
      isLoading={sendTestResult.isLoading}
      disabled={sendTestResult.isLoading}
      intent="success"
      marginBottom="16px"
    >{_(M.SEND_TEST_EMAIL_BTN)}</Button>
    <EmailEditor
      subjectDisabled={isSentAsReply && !!sendEmailAncestor}
      subjectHint={isSentAsReply && !!sendEmailAncestor ? _(M.EMAIL_REPLY_STAYS_SAME) : undefined}
      subject={subjectValue}
      bodyHtml={params.body.html}
      onBodyChange={onUpdateBody}
      onSubjectChange={onUpdateSubject}
    />
  </>;
};

export default EditSendEmailParams;