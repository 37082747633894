import { Button, TextInputField, toaster } from 'evergreen-ui';
import { SyntheticEvent, useEffect } from 'react';

import { usePatchCurrentUserMutation } from 'api/user';
import _, {M} from 'constants/i18n';
import { useAppSelector } from 'hooks';
import { getCurrentUser } from 'store/user/selector';

import './style.css';


const GeneralSettings = () => {
  const [patch, patchRes] = usePatchCurrentUserMutation();
  const user = useAppSelector(getCurrentUser);

  const onSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = new FormData(e.currentTarget);
    const payload = {
      full_name: form.get('full_name') as string || null,
      password: form.get('password') as string || null,
    };
    patch(payload);
  }

  useEffect(() => {
    if (patchRes.isError) {
      toaster.danger(_(M.FAILED_TO_SAVE));
    } else if (patchRes.isSuccess) {
      toaster.success(_(M.SUCCESS));
    }
  }, [patchRes.isError, patchRes.isSuccess]);

  return (
    <div className="settings-content--container">
      <h3 className="settings-content--header">{_(M.NAV_PROFILE)}</h3>
      <form onSubmit={onSubmit}>
        <div className="settings-profile-info--container">{_(M.ID).toUpperCase()}: {user?.id}</div>
        <div className="settings-profile-info--container">{_(M.EMAIL)}: {user?.email}</div>
        <TextInputField label={_(M.NAME)} defaultValue={user?.full_name || ''} name="full_name" />
        <TextInputField label={_(M.PW)} type="password" name="password" />
        <Button type="submit" isLoading={patchRes.isLoading}>{_(M.SAVE)}</Button>
      </form>
    </div>
  )
};

export default GeneralSettings;