import { useParams } from 'react-router-dom';
import { Spinner } from 'evergreen-ui';

import { usePaginateCadenceEventsQuery } from 'api/cadence';
import _, {M} from 'constants/i18n';
import CadenceEnrollmentEvent from 'components/cadence/ViewCadenceEnrollment/events';


const ContactEvents = () => {
  const { contactId='' } = useParams<{contactId: string}>();
  const eventsRes = usePaginateCadenceEventsQuery({
    offset: 0,
    limit: 50,
    contactId: parseInt(contactId),
    sort_dir: 'desc',
  });

  if (eventsRes.isFetching || eventsRes.isLoading) {
    return <div>{_(M.LOADING)} <Spinner /></div>
  }

  if (!eventsRes.data || eventsRes.isError) {
    return <div>{_(M.ERROR)}</div>
  }

  const isEmpty = eventsRes.data?.cadence_events.length === 0;

  return <div className='view-cadence--container'>
    <div>
      {eventsRes.data?.cadence_events.map(ce => <CadenceEnrollmentEvent event={ce} key={ce.dedup_key} />)}
      {isEmpty && <p><em>{_(M.CONTACT_EVENTS_NO_EVENTS)}</em></p>}
    </div>
  </div>;
}

export default ContactEvents;