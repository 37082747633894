import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { AnyMessage, ConversationStatus, IConversation } from 'types/conversation';

const root = (state: RootState) => state.inbox;

const inboxesById = (state: RootState) => root(state).inboxesById;

const convIdsByInboxId = (state: RootState) => root(state).convIdsByInboxId;
const convIdsForInbox = createSelector(
  [convIdsByInboxId, (_: RootState, inbId: number) => inbId],
  (convIdsByInbox, inbId) => convIdsByInbox[inbId] || [],
);

const convById = (state: RootState) => root(state).conversationsById;

export const getConversation = createSelector(
  [convById, (_: RootState, convId: number) => convId],
  (byId, convId) => byId[convId],
);

export const sortedConversationsForInbox = createSelector(
  [convIdsForInbox, convById, (_: RootState, _i: number, status?: ConversationStatus | null) => status],
  (ids, byId, filterStatus) => {
    const convs: IConversation[] = [];
    ids.forEach(id => {
      const conv = byId[id];
      if (conv) {
        convs.push(conv);
      }
    });
    const getEpoch = (c: IConversation) => Date.parse(c.last_message_at || c.updated_at || c.created_at);
    convs.sort((conv1, conv2) => getEpoch(conv2) - getEpoch(conv1));
    return convs.filter(({status}) => !filterStatus || filterStatus === status);
  },
);

const msgIdsByConversationId = (state: RootState) => root(state).msgIdsByConversationId;
const msgIdsForConversation = createSelector(
  [msgIdsByConversationId, (_: RootState, convId: number) => convId],
  (msgIdsByConvId, convId) => msgIdsByConvId[convId] || [],
);

const msgsById = (state: RootState) => root(state).messagesById;

export const sortedMessagesForConversation = createSelector(
  [msgIdsForConversation, msgsById],
  (ids, byId) => {
    const msgs: AnyMessage[] = [];
    ids.forEach(msgId => {
      const msg = byId[msgId];
      if (msg) {
        msgs.push(msg);
      }
    });
    msgs.sort((msg1, msg2) => Date.parse(msg1.created_at) - Date.parse(msg2.created_at));
    return msgs;
  },
);

const oldestMessageForConversation = createSelector(
  [sortedMessagesForConversation],
  (msgs) => msgs[0]
);

export const sortedMessageIdsForConversation = createSelector(
  [sortedMessagesForConversation],
  (msgs) => msgs.map(msg => msg.id),
);

export const oldestMessageDateForConversation = createSelector(
  [oldestMessageForConversation],
  (msg) => msg ? msg.created_at : undefined,
)

const getConversationForMessage = createSelector(
  [msgsById, convById, (_: RootState, msgId: number) => msgId],
  (msgById, convsById, msgId) => convsById[msgById[msgId]?.conversation_id]
);

export const getNamespaceForMessage = createSelector(
  [getConversationForMessage, inboxesById],
  (conv, inbById) => inbById[conv?.inbox_id]?.namespace
);