import { useChangeUserBillingPlanMutation, useChangeUserStatusMutation, useFetchUserQuery, usePatchGateMutation, useSetUserTrialExpirationMutation, useToggleSuperuserMutation } from 'api/admin/users';
import { Button, Dialog, Label, Switch, TextInputField, toaster } from 'evergreen-ui';
import { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';

import { IUser, UserStatus } from 'types/user';
import { FeatureGates } from 'constants/gates';
import { BillingPlans } from 'constants/app';
import './style.css';
import { useAppSelector } from 'hooks';
import { getBillingPlan } from 'store/user/selector';


const FeatureGateDialog = ({
  user,
  isShown,
  onClose,
}: {user: IUser, isShown: boolean, onClose: () => void}) => {
  const [selectedGate, setSelectedGate] = useState({label: FeatureGates[0].name, value: FeatureGates[0]});
  const [allowed, setAllowed] = useState(user.properties.find(d => d.name === selectedGate.value.gate)?.value === true);
  const [patch, result] = usePatchGateMutation();

  useEffect(() => {
    setAllowed(user.properties.find(d => d.name === selectedGate.value.gate)?.value === true);
  }, [selectedGate, user.properties]);

  const submit = () => {
    patch({userId: user.id, body: {name: selectedGate.value.gate, allowed}})
      .then(onClose)
  };

  return <Dialog title="Change feature gates" isShown={isShown} isConfirmLoading={result.isLoading} onConfirm={submit} onCloseComplete={onClose}>
    <Select
      value={selectedGate}
      options={FeatureGates.map(d => ({label: `${d.name} (${d.gate})`, value: d}))}
      isClearable={false}
      onChange={d => d && setSelectedGate(d)}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      menuPortalTarget={document.body}
    />
    <small>Gate description: <em>{selectedGate.value.description}</em></small>
    <div style={{marginTop: 24}}>
      Enabled
      <Switch checked={allowed} onChange={() => setAllowed(!allowed)} />
    </div>
  </Dialog>
};

const UserStatusDialog = ({
  user,
  isShown,
  onClose,
}: {user: IUser, isShown: boolean, onClose: () => void}) => {
  const [selectedStatus, setSelectedStatus] = useState({label: user.status, value: user.status});
  const [patch, result] = useChangeUserStatusMutation();

  useEffect(() => {
    setSelectedStatus({label: user.status, value: user.status});
  }, [user.status]);

  const submit = () => {
    patch({userId: user.id, status: selectedStatus.value})
      .then(onClose)
  };

  const options = [
    {label: UserStatus.ACTIVE, value: UserStatus.ACTIVE},
    {label: UserStatus.INACTIVE, value: UserStatus.INACTIVE},
    {label: UserStatus.SUSPENDED, value: UserStatus.SUSPENDED},
  ]

  return <Dialog title="Change user status" isShown={isShown} isConfirmLoading={result.isLoading} onConfirm={submit} onCloseComplete={onClose}>
    <Label>Status</Label>
    <Select
      value={selectedStatus}
      options={options}
      isClearable={false}
      onChange={d => d && setSelectedStatus(d)}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      menuPortalTarget={document.body}
    />
  </Dialog>
};

const UserBillingPlanDialog = ({
  user,
  isShown,
  onClose,
}: {user: IUser, isShown: boolean, onClose: () => void}) => {
  const planCode = useAppSelector(getBillingPlan);
  const plan = planCode ? BillingPlans[planCode] : null;
  const [selectedPlan, setSelectedPlan] = useState(plan ? {label: plan.name, value: planCode} : null);
  const [patch, result] = useChangeUserBillingPlanMutation();

  useEffect(() => {
    const plan = planCode ? BillingPlans[planCode] : null;
    setSelectedPlan(plan ? {label: plan.name, value: planCode} : null);
  }, [planCode]);

  const submit = () => {
    patch({userId: user.id, plan: selectedPlan?.value || 'tinycadence-plan:friends-and-family', unset_plan: !selectedPlan})
      .then(onClose)
  };

  const options = Object.entries(BillingPlans).map(([codeName, nameAndDesc]) => ({
    label: nameAndDesc.name,
    value: codeName,
  }));

  return <Dialog title="Change user status" isShown={isShown} isConfirmLoading={result.isLoading} onConfirm={submit} onCloseComplete={onClose}>
    <Label>Billing plan</Label>
    <Select
      value={selectedPlan}
      options={options}
      isClearable
      onChange={setSelectedPlan}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      menuPortalTarget={document.body}
    />
    <p>{selectedPlan ? BillingPlans[selectedPlan.value as string]?.description : 'No plan, the user will become a free tier user'}</p>
  </Dialog>
};

const UserTrialExpirationDialog = ({
  user,
  isShown,
  onClose,
}: {user: IUser, isShown: boolean, onClose: () => void}) => {
  const [propValue, setPropValue] = useState('');
  const [patch, result] = useSetUserTrialExpirationMutation();

  const submit = () => {
    patch({userId: user.id, body: {expiration: propValue}})
      .then(onClose)
  };

  return <Dialog title="Change user trial expiration" isShown={isShown} isConfirmLoading={result.isLoading} isConfirmDisabled={isNaN(Date.parse(propValue))} onConfirm={submit} onCloseComplete={onClose}>
    <Label>Change the expiration date for a user's trial</Label>
    <TextInputField value={propValue} onChange={(e: ChangeEvent<HTMLInputElement>) => setPropValue(e.target.value)} />
  </Dialog>
};



const AdminUserPage = () => {
  const {userId = ''} = useParams<{userId: string}>()

  const {
    data,
    isLoading,
    isFetching,
    isError,
  } = useFetchUserQuery(userId)

  const [postToggleSuperuser, postToggleResult] = useToggleSuperuserMutation();
  const [featureGateDialogShown, setFeatureGateDialogShown] = useState(false);
  const [statusDialogShown, setStatusDialogShown] = useState(false);
  const [billingDialogShown, setBillingDialogShown] = useState(false);
  const [trialExpirationDialogShown, setTrialExpirationDialogShown] = useState(false);

  useEffect(() => {
    if (postToggleResult.isSuccess) {
      toaster.success('Success');
    } else if (postToggleResult.isError) {
      toaster.warning('Failed to toggle superuser');
    }
  }, [postToggleResult.isSuccess, postToggleResult.isError]);

  if (!data || isLoading || isFetching) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error</div>
  }

  const user = data.user

  return <div>
    <UserTrialExpirationDialog user={user} isShown={trialExpirationDialogShown} onClose={() => setTrialExpirationDialogShown(false)}/>
    <FeatureGateDialog user={user} isShown={featureGateDialogShown} onClose={() => setFeatureGateDialogShown(false)}/>
    <UserStatusDialog user={user} isShown={statusDialogShown} onClose={() => setStatusDialogShown(false)}/>
    <UserBillingPlanDialog user={user} isShown={billingDialogShown} onClose={() => setBillingDialogShown(false)}/>
    <div className="admin-page-actions--container">
      <Button onClick={() => setFeatureGateDialogShown(true)} >Change Feature Gates</Button>
      <Button onClick={() => postToggleSuperuser(userId)} isLoading={postToggleResult.isLoading}>Toggle superuser</Button>
      <Button onClick={() => setStatusDialogShown(true)}>Change status</Button>
      <Button onClick={() => setBillingDialogShown(true)}>Set billing plan</Button>
      <Button onClick={() => setTrialExpirationDialogShown(true)}>Set trial expiration</Button>
    </div>
    <div>
      <div>
        <h3>User details</h3>
        <div className="admin-user-page-detail--container">
          <div className="admin-user-page-detail--label">full name</div>
          <div className="admin-user-page-detail--value">{user.full_name}</div>
        </div>
        <div className="admin-user-page-detail--container">
          <div className="admin-user-page-detail--label">email</div>
          <div className="admin-user-page-detail--value">{user.email}</div>
        </div>
        <div className="admin-user-page-detail--container">
          <div className="admin-user-page-detail--label">status</div>
          <div className="admin-user-page-detail--value">{user.status}</div>
        </div>
        <div className="admin-user-page-detail--container">
          <div className="admin-user-page-detail--label">is admin</div>
          <div className="admin-user-page-detail--value">{user.is_superuser ? 'yes' : 'no'}</div>
        </div>
      </div>
      <div>
        <h3>User Properties</h3>
        {user.properties.map(prop => (
          <div className="admin-user-page-detail--container" key={prop.name}>
            <div className="admin-user-page-detail--label">{prop.name} <small>({prop.namespace})</small></div>
            <div className="admin-user-page-detail--value">{String(prop.value as any)}</div>
          </div>
        ))}
      </div>
    </div>
  </div>
}

export default AdminUserPage;