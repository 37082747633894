import { Navigate, useLocation } from 'react-router-dom';

import { useAppSelector } from 'hooks';
import { getCurrentUser } from 'store/user/selector';

import Sidebar from './Sidebar';


const AdminRequiredRoute = ({ children }: {children: JSX.Element | null}) => {
  const isLoggedIn = useAppSelector(getCurrentUser);

  const location = useLocation();

  const nextSearch = btoa(new URLSearchParams(location.search).toString());

  const nextParams = new URLSearchParams({next: location.pathname, search: nextSearch});

  if (!isLoggedIn) {
    return <Navigate to={`/auth/login?${nextParams}`} replace />;
  }

  if (!isLoggedIn.is_superuser) {
    return <Navigate to='/' replace />;
  }

  return (
    <div className="app--full-height">
      <div className="sidebar-sibling-content--container">
        <Sidebar />
        <div className="app--full-width">
          {children}
        </div>
      </div>
    </div>
  );
};


export default AdminRequiredRoute;