import { CaretDownIcon, CaretUpIcon, Spinner } from 'evergreen-ui';
import { flatten, sum } from 'ramda';
import { Area, AreaChart, Tooltip, XAxis, YAxis } from 'recharts';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import _, { M } from 'constants/i18n';
import GoogleButton from 'components/shared/GoogleButton';
import { getGoogleOAuthURI } from 'api/auth';
import { IHomeAnalyticsResponse, useHomeAnalyticsQuery } from 'api/analytics';
import { useAppSelector } from 'hooks';
import { getCurrentUser, getHasNoGoogleConnectors, getUserFinishedOnboarding } from 'store/user/selector';
import { percentChange } from 'utils/math';
import { CadenceEventTypes } from 'types/cadence';
import OnboardingHomeView from 'components/onboarding/Home';

import './style.css';


const PrevWeekComparison = ({prev, current}: {prev: number, current: number}) => {
  const startedPctChange = percentChange(prev, current);

  if (isNaN(startedPctChange) || !isFinite(startedPctChange)) return null;

  const fromPrevWeekTxt = _(M.HOME_COMPARISON_PREV_WEEK);

  if (startedPctChange === 0) {
    return <div className="metric-comparison neutral">
      - {_(M.HOME_COMPARISON_NO_CHANGE)} {fromPrevWeekTxt}
    </div>;
  }

  const icon = startedPctChange < 0 ? <CaretDownIcon /> : <CaretUpIcon />;
  const relMsg = startedPctChange < 0 ? M.HOME_COMPARISON_DOWN : M.HOME_COMPARISON_UP;

  return <div className={cx('metric-comparison', {bad: startedPctChange < 0})}>
    {icon} {_(relMsg)} {Math.abs(startedPctChange)}% {fromPrevWeekTxt}
  </div>;
};


const openGoogle = async () => {
  try {
    const {data} = await getGoogleOAuthURI();
    window.location.href = data.authorization_url;
  } catch (err) {
    console.warn('Could not get google auth URI', err);
  }
};


const StartedLineChart = ({eventsOverTime}: {eventsOverTime: Array<Array<string | Record<CadenceEventTypes, number>>>}) => {
  if (eventsOverTime.length === 0) return null;

  const chartData = eventsOverTime.map(([datestr, value]) => {
    const eventCount = (value as Record<CadenceEventTypes, number>);
    return {
      datestr,
      started: eventCount.enrolled,
      hubspotSyncs: eventCount.hubspot_activity_created + eventCount.hubspot_contact_created,
      emailsSent: eventCount.email_send,
      emailsOpened: eventCount.email_open,
      linksClicked: eventCount.email_click,
    };
  });

  return (
    <div className="home--content-section">
      <h4 className="home-content-chart--header">{_(M.HOME_CHART_HEADER)}</h4>
      <AreaChart width={730} height={250} data={chartData}>
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
          </linearGradient>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
          </linearGradient>
          <linearGradient id="colorEo" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#12caaa" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="#12caaa" stopOpacity={0}/>
          </linearGradient>
          <linearGradient id="colorHs" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#ff5c35" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="#ff5c35" stopOpacity={0}/>
          </linearGradient>
          <linearGradient id="colorEc" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#b112ca" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="#b112ca" stopOpacity={0}/>
          </linearGradient>
        </defs>
        <XAxis dataKey="datestr" />
        <YAxis />
        <Tooltip />
        {/* TODO: i18n */}
        <Area type="monotone" dataKey="hubspotSyncs" name="HubSpot contacts & activities created" stroke="#ff5c35" fillOpacity={1} fill="url(#colorHs)" />
        <Area type="monotone" dataKey="started" name="Cadence enrollments" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
        <Area type="monotone" dataKey="emailsSent" name="Emails sent" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
        <Area type="monotone" dataKey="emailsOpened" name="Emails opened" stroke="#12caaa" fillOpacity={1} fill="url(#colorEo)" />
        <Area type="monotone" dataKey="linksClicked" name="Email links clicked" stroke="#b112ca" fillOpacity={1} fill="url(#colorEc)" />
      </AreaChart>
    </div>
  );
};


const getStartedThisWeek = (data: IHomeAnalyticsResponse | undefined, eventTypes: CadenceEventTypes[]) => {
  if (!data) return 0;

  const events = data.events_over_time.slice(-8).map(d => d[1]) as Record<CadenceEventTypes, number>[];
  return sum(flatten(events.map(counts => Object.entries(counts).map(([et, count]) => eventTypes.includes(et as CadenceEventTypes) ? count : 0))));
};


const getStartedLastWeek = (data: IHomeAnalyticsResponse | undefined, eventTypes: CadenceEventTypes[]) => {
  if (!data) return 0;

  const events = data.events_over_time.slice(0, 7).map(d => d[1]) as Record<CadenceEventTypes, number>[];
  return sum(flatten(events.map(counts => Object.entries(counts).map(([et, count]) => eventTypes.includes(et as CadenceEventTypes) ? count : 0))));
};


const HomeView = () => {
  const user = useAppSelector(getCurrentUser);
  const hasNoGoogleConnectors = useAppSelector(getHasNoGoogleConnectors);
  const onboardingDone = useAppSelector(getUserFinishedOnboarding);
  const text = user && user.full_name ? `, ${user.full_name.split(' ')[0]}` : ` ${_(M.TO)} TinyCadence`;

  const {
    data,
    isLoading,
    isError,
  } = useHomeAnalyticsQuery(undefined, {skip: !onboardingDone});

  if (!onboardingDone) {
    return <OnboardingHomeView />;
  }

  return <div className="app--page-default-container">
    <h2 className="home--header">{_(M.HOME_HEADER)}{text}!</h2>
    {isLoading && <div>{_(M.LOADING)}<Spinner /></div>}
    {!isLoading && data && hasNoGoogleConnectors &&
      <div className="home--content-section home--google-auth">
        <h3>{_(M.HOME_CONNECT_GOOGLE_ACCOUNT)}</h3>
        <GoogleButton onClick={openGoogle} />
      </div>
    }
    {isError && <div>{_(M.ERROR)}</div>}
    {data && <div>
      <div className="home-info--container">
        {_(M.HOME_ACTIVE_CADENCES_START)} {data.active_cadence_count} {_(M.HOME_ACTIVE_CADENCES_END)} {_(data.active_cadence_count !== 1 ? M.CADENCES : M.CADENCE)}. <Link to='/cadences'>{_(M.HOME_VIEW_CADENCES)}</Link>
      </div>
      <div className="home--content-section home-analytics-topline--container">
        <div className="home-analytics-topline--metric">
          <h3>
            {getStartedThisWeek(data, [CadenceEventTypes.email_send])}
            <PrevWeekComparison prev={getStartedLastWeek(data, [CadenceEventTypes.email_send])} current={getStartedThisWeek(data, [CadenceEventTypes.email_send])}/>
          </h3>
          <div className="home-analytics-topline--subtitle">{_(M.HOME_TOPLINE_METRIC_HELP_1)}</div>
        </div>
        <div className="home-analytics-topline--metric">
          <h3>
            {getStartedThisWeek(data, [CadenceEventTypes.hubspot_activity_created, CadenceEventTypes.hubspot_contact_created])}
            <PrevWeekComparison
              prev={getStartedLastWeek(data, [CadenceEventTypes.hubspot_activity_created, CadenceEventTypes.hubspot_contact_created])}
              current={getStartedThisWeek(data, [CadenceEventTypes.hubspot_activity_created, CadenceEventTypes.hubspot_contact_created])}
            />
          </h3>
          <div className="home-analytics-topline--subtitle">{_(M.HOME_TOPLINE_METRIC_HELP_2)}</div>
        </div>
      </div>
      <StartedLineChart eventsOverTime={data.events_over_time}/>
    </div>}
  </div>
};

export default HomeView;