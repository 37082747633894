import { CadenceNodeActionParams, IBranchNodeParams, ISendEmailNodeParams, IWaitNodeParams, CadenceNodeActionType, IApplyLabelNodeParams } from 'types/cadence';
import EditApplyLabelParams from './applyLabelParams';

import EditConditionoBranchParams from './conditionBranchParams';
import EditSendEmailParams from './sendEmailParams';
import EditWaitParams from './waitParams';


const EditNodeParams = ({
  type,
  params,
  onChange,
  nodeId,
}: {type: CadenceNodeActionType, params: CadenceNodeActionParams, onChange: (newParams: CadenceNodeActionParams) => void, nodeId: string}) => {
  if (type === 'WAIT') {
    return <EditWaitParams params={params as IWaitNodeParams} onChange={onChange} />;
  } else if (type === 'SEND_EMAIL') {
    return <EditSendEmailParams params={params as ISendEmailNodeParams} onChange={onChange} nodeId={nodeId}/>;
  } else if (type === 'BRANCH') {
    return <EditConditionoBranchParams params={params as IBranchNodeParams} onChange={onChange} />;
  } else if (type === 'APPLY_LABEL') {
    return <EditApplyLabelParams params={params as IApplyLabelNodeParams} onChange={onChange} />;
  }
  return <div />;
};


export default EditNodeParams;