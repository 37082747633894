const DOMWalkers = {
  *walk(rootNode: Node, ...treeWalkerArgs: any) {
    const walker = document.createTreeWalker(rootNode, ...treeWalkerArgs);
    let node = walker.nextNode();
    while (node) {
      yield node;
      node = walker.nextNode();
    }
    return;
  },

  walkBackwards(node: Node): Node[] {
    if (!node) {
      return [];
    }
    let allNodes: Node[] = [];
    node.childNodes.forEach(childNode => {
      const moreNodes = this.walkBackwards(childNode);
      allNodes = allNodes.concat(moreNodes);
    });
    allNodes.push(node);
    return allNodes.reverse();
  },
};
export default DOMWalkers;