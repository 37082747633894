import { ConnectorProviders } from 'constants/app';
import { IConnector } from 'types/user';

export const getConnectorName = (connector: IConnector): string => {
  switch(connector.provider) {
  case ConnectorProviders.HUBSPOT:
    return connector.meta.hub_domain as string || connector.meta.email as string || ' ';
  case ConnectorProviders.GMAIL:
  case ConnectorProviders.GDRIVE:
  case ConnectorProviders.GCAL:
    return connector.meta.email as string || ' ';
  case ConnectorProviders.GITHUB:
    return connector.meta.login as string || ' ';
  default:
    return ' ';
  }
};

export const connectorHasSettings = (provider: ConnectorProviders) => {
  return ['hubspot'].includes(provider)
}

export const nullOrUndefined = (target: any) => target === null || target === undefined;