import { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'hooks';
import { getCurrentUser, getDidBootstrapSucceed, getIsBootstrapLoading, getIsBootstrapNotStarted } from 'store/user/selector';
import { kickoffBootstrap, receiveUserProperties } from 'store/user/slice';
import _, { M } from 'constants/i18n';
import routes from 'routes';
import { listen } from 'utils/socket';
import { IProperty } from 'types/user';
import { DEBUG } from 'constants/resources';

import './App.css';
import { adminQueries, queries } from 'api/queries';

const embedHubSpot = () => {
  if (DEBUG) return;
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.id = 'hs-script-loader';
  script.async = true;
  script.defer = true;
  script.src = '//js-na1.hs-scripts.com/24131527.js';
  document.body.appendChild(script);
}


function App() {
  const dispatch = useAppDispatch();

  const router = createBrowserRouter(routes);

  const shouldBootstrap = useAppSelector(getIsBootstrapNotStarted);
  const bootstrapDone = useAppSelector(getDidBootstrapSucceed);
  const currentUser = useAppSelector(getCurrentUser);
  const loading = useAppSelector(getIsBootstrapLoading);

  useEffect(() => {
    if (shouldBootstrap) {
      dispatch(kickoffBootstrap());
    }
  }, [shouldBootstrap, dispatch]);

  useEffect(() => {
    if (bootstrapDone && currentUser) {
      listen<{properties: IProperty[]}>(`user=${currentUser.id}`, 'USER_PROPERTIES_UPDATED', ({properties}) => {
        dispatch(receiveUserProperties(properties));
      });
      listen<{entities: {type: string, id: string | number}[]}>(`user=${currentUser.id}`, 'ENTITIES_UPDATED', ({entities}) => {
        dispatch(queries.util.invalidateTags(entities));
        dispatch(adminQueries.util.invalidateTags(entities));
      });
      embedHubSpot();
    }
  }, [bootstrapDone, currentUser, dispatch]);

  if (loading) {
    return <div className="app-bootstrap--loading">{_(M.LOADING)}</div>
  }

  return <RouterProvider router={router}/>;
}

export default App;
