import { CadenceEventTypes, ICadenceEvent } from 'types/cadence';
import { dateStrToLocaleStr } from 'utils/strings';

import EnrolledEvent from './enrolled';
import UnenrolledEvent from './unenrolled';
import CancelledEvent from './cancelled';
import EmailSendEvent from './email_send';
import AutomatedUnenrollEvent from './auto_unenroll';
import FinishedEvent from './finished';
import BranchEvalEvent from './branch';
import HubspotActivityCreateEvent from './hs_activity';
import HubspotContactCreateEvent from './hs_contact';
import HubspotPropertiesPulledEvent from './hs_prop_pull';
import LabelAppliedEvent from './label_applied';
import PausedForApprovalEvent from './paused';
import ScheduledForBizDaySendEvent from './scheduled_biz_day_send';
import ScheduledEvent from './scheduled';
import './style.css';
import EmailOpenEvent from './email_open';
import ScheduledForBizHoursSendEvent from './scheduled_biz_hours_send';
import EmailClickEvent from './email_click';


const CadenceEnrollmentEvent = ({event}: {event :ICadenceEvent}) => {
  let elt = null;

  switch (event.event_type) {
  case CadenceEventTypes.enrolled:
    elt = <EnrolledEvent event={event} />
    break;
  case CadenceEventTypes.unenrolled:
    elt = <UnenrolledEvent event={event} />
    break;
  case CadenceEventTypes.cancelled:
    elt = <CancelledEvent event={event} />
    break;
  case CadenceEventTypes.email_send:
    elt = <EmailSendEvent event={event} />
    break;
  case CadenceEventTypes.automated_unenroll:
    elt = <AutomatedUnenrollEvent event={event} />
    break;
  case CadenceEventTypes.finished:
    elt = <FinishedEvent event={event} />
    break;
  case CadenceEventTypes.conditional_branch_eval:
    elt = <BranchEvalEvent event={event} />
    break;
  case CadenceEventTypes.hubspot_activity_created:
    elt = <HubspotActivityCreateEvent event={event} />
    break;
  case CadenceEventTypes.hubspot_contact_created:
    elt = <HubspotContactCreateEvent event={event} />
    break;
  case CadenceEventTypes.hubspot_contact_properties_pulled:
    elt = <HubspotPropertiesPulledEvent event={event} />
    break;
  case CadenceEventTypes.label_applied:
    elt = <LabelAppliedEvent event={event} />
    break;
  case CadenceEventTypes.paused_for_email_send_approval:
    elt = <PausedForApprovalEvent event={event} />
    break;
  case CadenceEventTypes.scheduled_for_business_day_email_send:
    elt = <ScheduledForBizDaySendEvent event={event} />
    break;
  case CadenceEventTypes.scheduled_for_next_step:
    elt = <ScheduledEvent event={event} />
    break;
  case CadenceEventTypes.email_open:
    elt = <EmailOpenEvent event={event} />
    break;
  case CadenceEventTypes.scheduled_for_business_hours_email_send:
    elt = <ScheduledForBizHoursSendEvent event={event} />;
    break;
  case CadenceEventTypes.email_click:
    elt = <EmailClickEvent event={event} />;
    break;
  default:
    break;
  }

  if (elt === null) {
    console.warn(`Unable to render cadence event of type ${event.event_type}`);
  }
  return <div className="cadence-event--container">
    <div className="cadence-event--date">{dateStrToLocaleStr(event.timestamp)}</div>
    <div>{elt}</div>
  </div>
};

export default CadenceEnrollmentEvent;