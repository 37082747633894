import { ICadenceEvent } from 'types/cadence';

import _, {M} from 'constants/i18n';
import HubspotIcon from 'components/icons/hubspot';

const getCreateResult = (syncStatus: string) => {
  switch(syncStatus) {
  case 'contact_already_exists':
    return M.CADENCE_EVENT_HS_CONTACT_CREATE_ALREADY_EXISTS;
  case 'failure':
    return M.CADENCE_EVENT_HS_CONTACT_CREATE_FAIL;
  case 'success':
  default:
    return M.CADENCE_EVENT_HS_CONTACT_CREATE_SUCCESS;
  }
}


const HubspotContactCreateEvent = ({event}: {event: ICadenceEvent}) => {

  const createResult = getCreateResult(event.event_data.sync_status as string);
  return <div>
    <div className="enrolled-event-title--container">
      <HubspotIcon className="enrolled-event-title--icon"/>
      <div className="enrolled-event-title--text">{_(M.CADENCE_EVENT_HS_CONTACT_CREATE_TITLE)}</div>
    </div>
    <p className="enrolled-event--description">{_(M.CADENCE_EVENT_HS_CONTACT_CREATE_DESC)} {_(createResult)}</p>
  </div>
};

export default HubspotContactCreateEvent;