import AdminConnectorsTable from 'components/admin/ConnectorsTable';


const AdminConnectorsPage = () => {

  return <div className="admin-page-content--container">
    <h2>All connectors</h2>
    <div>
      <AdminConnectorsTable />
    </div>
  </div>
}


export default AdminConnectorsPage;
