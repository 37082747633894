export interface IListCadencesArgs {
  deleted: boolean;
  offset: number;
  limit: number;
  status: string[];
}

export interface IListEnrollmentsArgs {
  offset: number;
  limit: number;
  status: 'paused' | 'error' | 'in_progress' | 'done' | 'scheduled' | 'cancelled';
  cadenceId?: number;
}

export enum CadenceEventTypes {
  enrolled = 'enrolled',
  unenrolled = 'unenrolled',
  cancelled = 'cancelled',
  email_send = 'email_send',
  conditional_branch_eval = 'conditional_branch_eval',
  label_applied = 'label_applied',
  scheduled_for_next_step = 'scheduled_for_next_step',
  scheduled_for_business_day_email_send = 'scheduled_for_business_day_email_send',
  scheduled_for_business_hours_email_send = 'scheduled_for_business_hours_email_send',
  paused_for_email_send_approval = 'paused_for_email_send_approval',
  automated_unenroll = 'automated_unenroll',
  finished = 'finished',
  email_open = 'email_open',
  email_click = 'email_click',

  hubspot_contact_created = 'hubspot_contact_created',
  hubspot_activity_created = 'hubspot_activity_created',
  hubspot_contact_properties_pulled = 'hubspot_contact_properties_pulled',
}

export interface IListCadenceEventsArgs {
  offset: number;
  limit: number;
  cadenceId?: number;
  contactId?: number;
  cadenceContactId?: number;
  eventType?: CadenceEventTypes[];
  sort_dir: 'desc' | 'asc';
}


export interface ICreateCadenceArgs {
  name: string;
  description?: string | null;
  meta: Record<string, unknown>;
  data: ICadenceData;
}

export type CadenceNodeActionType = 'SEND_EMAIL' | 'WAIT' | 'BRANCH' | 'APPLY_LABEL';
export type CadenceNodeActionParams = IWaitNodeParams | ISendEmailNodeParams | IBranchNodeParams | IApplyLabelNodeParams;

export interface ISendEmailNodeParams {
  subject: string;
  body: {
    text: string;
    html: string;
  };
  reply_to_thread: boolean;
  pause_before_sending: boolean;
  send_during_business_days: boolean;
}

interface IGenericCondition {
  field: string,
  operator: string,
  value: string[],
}

interface ISpecialCondition {
  field: 'CONTACT_EMAILED' | 'CONTACT_SCHEDULED_MEETING';
}

export type Condition = ISpecialCondition | IGenericCondition;

export interface IBranchNodeParams {
  conditions: {
    evaluation: 'ANY' | 'ALL',
    conditions: Condition[],
    conditions_fail_next_node_id: string | null;
  },
}

export interface IWaitNodeParams {
  td_kwargs: {
    weeks?: number,
    days?: number,
    hours?: number,
    minutes?: number,
    seconds?: number,
  };
}

export interface IApplyLabelNodeParams {
  labels: {name: string, id: string}[],
  remove_other_labels: boolean | null,
}

export interface ICadenceNode {
  id: string;
  action: {
    type: CadenceNodeActionType;
    params: CadenceNodeActionParams;
  }
  next_node_id: string | null;
}

export interface ISingleDaySchedule {
  start_hour?: number | null
  start_minute?: number | null
  end_hour?: number | null
  end_minute?: number | null
}

export interface IBizHourSchedule {
  timezone: string
  monday: ISingleDaySchedule | null
  tuesday: ISingleDaySchedule | null
  wednesday: ISingleDaySchedule | null
  thursday: ISingleDaySchedule | null
  friday: ISingleDaySchedule | null
  saturday: ISingleDaySchedule | null
  sunday: ISingleDaySchedule | null
}


export interface ICadenceDataOptions {
  only_send_email_during_biz_hours?: boolean,
  biz_hour_schedule?: IBizHourSchedule | null,
  only_send_email_on_biz_days?: boolean;
  send_emails_as_replies?: boolean;
  pause_before_sending?: boolean;
  unenroll_on_reply?: boolean;
  unenroll_on_mtg_scheduled?: boolean;
}

interface ICadenceData {
  start_node: string | null;
  nodes: ICadenceNode[];
  options?: null | ICadenceDataOptions;
  hs_list_for_new_contact_auto_enrollment?: number[];
}

export interface ICadence {
  id: number;
  name: string;
  namespace: string;
  description: string | null | undefined;
  data: ICadenceData;
  status: 'active' | 'disabled' | 'archived';
  meta: Record<string, unknown>;
  updated_by: number;
  created_by: number;
  updated_at: string;
  created_at: string;
  deleted_at: string | null;
}

export interface ICadenceEnrollment {
  id: number;
  cadence_id: number;
  contact_id: number;
  namespace: string;
  current_node_id: string | null;
  status: 'paused' | 'error' | 'in_progress' | 'done';
  started_at: string;
  last_step_at: string | null;
  meta: Record<string, unknown> | null;
}


export interface ICadenceEvent {
  id: number;
  cadence_id: number;
  cadence_contact_id: number;
  contact_id: number;
  namespace: string;
  event_type: CadenceEventTypes;
  dedup_key: string;
  timestamp: string;
  event_data: Record<string, unknown>; // TODO: explicit types based on event_type
  meta: Record<string, unknown>;
  // things in the biz day scheduled event
  email_subject: string;
  scheduled_for: string;
  email_content_html: string;
}