import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';

import { store } from 'store';
import { SENTRY_DSN } from 'constants/resources';

import App from './App';
import './index.css';
import { ThemeProvider } from 'evergreen-ui';
import tinyTheme from 'theme';

Sentry.init({dsn: SENTRY_DSN});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const FallbackComponent = () => <div>An error has occurred, please refresh the page</div>

root.render(
  <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
    <Provider store={store}>
      <ThemeProvider value={tinyTheme}>
        <App />
      </ThemeProvider>
    </Provider>
  </Sentry.ErrorBoundary>
);